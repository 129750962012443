import { EBrandActionTypes } from './brandActionsTypes';

const initialState = {
  currentBrand: {},
  brand: {},
  loading: false,
  brands: [],
  deleteSuccess: false,
};

interface State {
  currentBrand: any;
  loading: boolean;
  error?: string;
  deleteSuccess?: boolean;
  brand: any;
  brands: Array<any>;
}

interface BrandAction extends State {
  type: EBrandActionTypes;
  payload: any;
}

type TBrandReducerActions = BrandAction;

export default function(
  state: State = initialState,
  action: TBrandReducerActions
) {
  switch (action.type) {
    case EBrandActionTypes.GET_BRANDS:
      return { ...state, loading: true, error: null };
    case EBrandActionTypes.GET_BRANDS_SUCCESS:
      return {
        ...state,
        brands: action.payload.brands,
        loading: false,
      };
    case EBrandActionTypes.GET_BRANDS_ERROR:
      return { ...state, error: action.error, loading: false };

    case EBrandActionTypes.SAVE_BRAND:
      return {
        ...state,
        loading: true,
        submitSuccess: false,
        saveSuccess: false,
      };
    case EBrandActionTypes.SAVE_BRAND_SUCCESS: {
      const isDraft = action.payload.isDraft;
      let stateProps = {};
      if (isDraft) {
        stateProps = {
          submitSuccess: false,
          brand: action.payload.brand,
        };
      } else {
        stateProps = { submitSuccess: !!action.payload.submitted };
      }
      return {
        ...state,
        loading: false,
        error: null,
        saveSuccess: !!action.payload.notifySuccess,
        ...stateProps,
      };
    }
    case EBrandActionTypes.SAVE_BRAND_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        submitSuccess: false,
        saveSuccess: false,
      };

    case EBrandActionTypes.GET_BRAND:
      return { ...state, loading: true };
    case EBrandActionTypes.GET_BRAND_SUCCESS:
      return {
        ...state,
        brand: action.payload.brand,
        loading: false,
      };
    case EBrandActionTypes.GET_BRAND_ERROR:
      return { ...state, error: action.payload.message, loading: false };

    case EBrandActionTypes.DELETE_BRAND:
      return { ...state, loading: true };
    case EBrandActionTypes.DELETE_BRAND_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      };
    case EBrandActionTypes.DELETE_BRAND_ERROR:
      return { ...state, error: action.payload.message, loading: false };

    case EBrandActionTypes.RESET_STATE:
      return {
        ...state,
        error: false,
        brand: {},
        brands: [],
        submitSuccess: false,
        saveSuccess: false,
        loginSuccess: false,
      };

    default:
      return state;
  }
}
