import React from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

import { DynamicTable, QrPdf } from '../../../../../Components';

import { NoAssets, QRHeadContainer } from '../styles';

interface Props {
  tables: any;
  assetsTemplate: any;
  lotId: string;
}

const ReviewSubmitAsset = ({ tables, assetsTemplate, lotId }: Props) => {
  return (
    <div className="row">
      {Object.entries(tables).length > 0 ? (
        Object.keys(tables).map(tableKey => {
          const table = tables[tableKey];
          const outboundTableHeaders = table[0]
            ? table[0]?.map(field => field.header)
            : [];

          const outboundTableRows = table
            ? Object.keys(table).map(key => table[key]?.map(f => f.value))
            : [];

          const tableTitle = () => {
            const assetTemplate = assetsTemplate.find(
              ({ template }) => template._id == tableKey
            )?.template;

            return (
              assetTemplate && (
                <div className="d-flex flex-row">
                  {assetTemplate.name}
                  <QRHeadContainer>
                    <QrPdf
                      qr={[
                        {
                          lotId: lotId || '',
                          skuku: assetTemplate.skuku,
                          name: assetTemplate.name,
                        },
                      ]}
                      tooltip={i18n.t('Generate QR code for this asset only.')}
                    />
                  </QRHeadContainer>
                </div>
              )
            );
          };

          return (
            <div className="col-12 mb-3" key={tableKey}>
              <DynamicTable
                defaultShow={false}
                readonly={true}
                headers={outboundTableHeaders}
                data={outboundTableRows}
                header={tableTitle()}
              />
            </div>
          );
        })
      ) : (
        <div className="col-12 mb-3">
          <NoAssets>
            {i18n.t('There are no outbound assets for this facility.')}
          </NoAssets>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(ReviewSubmitAsset as any) as any;
