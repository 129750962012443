import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form';
import Toast from '../../../Lib/Utils/toast';
import {
  ContentBox,
  Title,
  Button,
  Loading,
  PopUp,
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from '../../../Components';

import { FaUserAlt } from 'react-icons/fa';
import { IconContainer, ActionButtonsContainer } from './styles';
import OrganizationUser from './OrganizationUser';
import OrganizationDetails from './OrganizationDetails';
import OrganizationBrand from './OrganizationBrand';
import OrganizationMarketplace from './OrganizationMarketplace';
import {
  doSaveOrganizations,
  resetState,
  doGetOrganization,
} from '../../../Redux/Organization/organizationActions';
import { doGetBrands } from '../../../Redux/Brand/brandActions';
import { doGetMarketplaces } from '../../../Redux/Marketplace/marketplaceActions';
import {
  doGetOrganizationUsers,
  doDeleteOrganization,
  doGetOrganizations,
} from '../../../Redux/Organization/organizationActions';
import { doGetUsers } from '../../../Redux/User/userActions';
import { doGetFacilities } from '../../../Redux/Facility/facilityActions';
import { Organization } from '../../../Lib/Utils/Validations';
import { ScannerApp } from '../../../Lib/Configs';

interface RootState {
  organization: any;
  facility: any;
  user: any;
  brand: any;
  marketplace: any;
}

const mapState = (state: RootState) => ({
  saveSuccess: state.organization.saveSuccess,
  deleteSuccess: state.organization.deleteSuccess,
  userLoading: state.user.loading,
  currentOrganization: state.organization.currentOrganization,
  error: state.organization.error,
  users: state.user.users,
  organizationUsers: state.organization.users,
  brands: state.brand.brands,
  brandLoading: state.brand.loading,
  marketplaces: state.marketplace.marketplaces,
  marketplaceLoading: state.marketplace.loading,
  facilities: state.facility.facilities,
  facilitiesLoading: state.facility.loading,
  organizations: state.organization.organizations,
  organizationsLoading: state.organization.loading,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  saveOrganizationAction: (payload, notifySuccess) =>
    dispatch(doSaveOrganizations(payload, notifySuccess)),
  deleteOrganizationAction: payload => dispatch(doDeleteOrganization(payload)),
  getOrganizationAction: payload => dispatch(doGetOrganization(payload)),
  resetFormAction: () => dispatch(resetState()),
  getUsersAction: () => dispatch(doGetUsers(true)),
  getBrandsAction: () => dispatch(doGetBrands()),
  getMarketplacesAction: () => dispatch(doGetMarketplaces()),
  getOrganizationUsersAction: payload =>
    dispatch(doGetOrganizationUsers(payload)),
  getFacilitiesAction: (includeDrafts, includeMembers) =>
    dispatch(doGetFacilities(includeDrafts, includeMembers, null, true)),
  getOrganizationsAction: () => dispatch(doGetOrganizations()),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & { history?: any; location?: any; match?: any };

const NewUser = (props: Props) => {
  const {
    getUsersAction,
    getBrandsAction,
    getMarketplacesAction,
    saveOrganizationAction,
    deleteOrganizationAction,
    getOrganizationAction,
    getOrganizationUsersAction,
    resetFormAction,
    organizationUsers,
    currentOrganization,
    users,
    match,
    saveSuccess,
    deleteSuccess,
    error,
    history,
    getFacilitiesAction,
    facilitiesLoading,
    facilities,
    organizations,
    organizationsLoading,
    getOrganizationsAction,
    location,
    userLoading,
    brandLoading,
    brands,
    marketplaces,
    marketplaceLoading,
  } = props;

  const loading =
    facilitiesLoading ||
    organizationsLoading ||
    userLoading ||
    brandLoading ||
    marketplaceLoading;

  const organizationColor = ScannerApp.mainColor;

  const entityId = match.params.organizationId;
  const isDraft = !!match.path.match('/draft');

  const [data, setData] = useState({} as any);
  const [popUpShow, setPopUpShow] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const formMethods = useForm({ mode: 'onBlur' });

  const tabs = [
    i18n.t('Overview'),
    i18n.t('Users'),
    i18n.t('Brands'),
    i18n.t('Marketplace'),
  ];

  const validate = async () => {
    formMethods.register({ name: 'name' }, Organization.name);
    formMethods.register({ name: 'description' }, Organization.description);

    formMethods.setValue(
      Object.keys(data).map(key => ({
        [key]: data[key],
      }))
    );

    const result = await formMethods.triggerValidation();

    if (!result) {
      setData({ ...data, validate: result });
    }

    return result;
  };

  const getMappedData = () => {
    const mappedData = {
      ...data,
    };

    return mappedData;
  };

  const saveOrganization = async (notifySuccess?: boolean) => {
    const result = await validate();
    if (!result) {
      Toast.error(i18n.t('There are items that require your attention.'));
      return;
    }

    const mappedData = getMappedData();

    await saveOrganizationAction(mappedData, notifySuccess);
    getUsersAction();
    getBrandsAction();
    getMarketplacesAction();
    getFacilitiesAction(false, true);
    getOrganizationsAction();
    if (entityId) {
      await getOrganizationUsersAction(entityId);
    }
  };

  const getDefaultActiveTab = () => {
    const query = new URLSearchParams(location.search);
    const tabFromQuery = query.get('tab');
    const findTabIndex = tabs.findIndex(
      tab => tab.replace(/[^a-zA-Z0-9]/g, '') === tabFromQuery
    );
    return findTabIndex !== -1 ? findTabIndex : 0;
  };

  const handleTabChange = index => {
    const tabName = tabs[index].replace(/[^a-zA-Z0-9]/g, '');
    const query = new URLSearchParams(location.search);
    const stepFromQuery = query.get('step');
    const stepSearch = stepFromQuery ? `&step=${stepFromQuery}` : '';
    history.push({
      search: `?tab=${tabName}${stepSearch}`,
    });
    setTabIndex(index);
  };

  const handleBackButton = () => {
    saveOrganization(false);
    handleTabChange(tabIndex - 1);
  };

  const handleNextButton = () => {
    saveOrganization(false);
    handleTabChange(tabIndex + 1);
  };

  const [mappedOrganizationUsers, setmappedOrganizationUsers] = useState(
    [] as any
  );

  const [mappedOrganizationBrands, setmappedOrganizationBrands] = useState(
    [] as any
  );

  const [
    mappedOrganizationMarketplaces,
    setmappedOrganizationMarketplaces,
  ] = useState([] as any);

  useEffect(() => {
    const mappedOrganizationUsers = [] as any;
    users.forEach(user => {
      const access = user.accessControl?.find(
        entry =>
          entry.type === 'organization' &&
          entry.resourceId === currentOrganization._id
      )?.permission;
      if (access)
        mappedOrganizationUsers.push({
          title: `${user.firstName} ${user.lastName}`,
          data: {
            ...user,
            emailConfirm: user.email,
            permission: access,
          },
        });
    });
    setmappedOrganizationUsers(mappedOrganizationUsers);
  }, [currentOrganization, users]);

  useEffect(() => {
    const newMappedOrganizationBrands = [] as any;
    brands.forEach(brand => {
      if (
        brand.organizationId &&
        brand.organizationId === currentOrganization._id
      )
        newMappedOrganizationBrands.push({
          title: `${brand.name}`,
          data: {
            ...brand,
            logo: { src: brand.logo },
          },
        });
    });
    setmappedOrganizationBrands(newMappedOrganizationBrands);
  }, [currentOrganization, brands]);

  useEffect(() => {
    if (marketplaces) {
      const mapperOrganizationMarketplaces = [] as any;
      marketplaces.forEach(marketplace => {
        if (
          marketplace.organizations &&
          marketplace.organizations.includes(currentOrganization._id)
        )
          mapperOrganizationMarketplaces.push({
            title: `${marketplace.name}`,
            data: {
              ...marketplace,
            },
          });
      });
      setmappedOrganizationMarketplaces(mapperOrganizationMarketplaces);
    }
  }, [currentOrganization, marketplaces]);

  useEffect(() => {
    if (entityId) {
      getOrganizationAction(entityId);
      getOrganizationUsersAction(entityId);
    }

    getUsersAction();
    getBrandsAction();
    getMarketplacesAction();
    getFacilitiesAction(false, true);
    getOrganizationsAction();

    // will be called on component unmount
    return () => {
      resetFormAction();
    };
  }, []);

  useEffect(() => {
    if (saveSuccess || deleteSuccess) {
      Toast.success(i18n.t('Data saved successfully.'));

      if (entityId) {
        getOrganizationAction(entityId);
        getOrganizationUsersAction(entityId);
      }

      getUsersAction();

      if (!entityId || deleteSuccess) {
        history.push('/organizations');
      }
    } else if (error) {
      Toast.error(error);
    }
  }, [error, saveSuccess, deleteSuccess]);

  useEffect(() => {
    const { _id, name, description } = currentOrganization;

    setData({
      ...data,
      _id,
      id: _id,
      name,
      description,
      organizationUsers,
      organizationBrands: _id
        ? brands?.filter(brand => brand.organizationId === _id)
        : [],
      brands: _id ? brands?.filter(brand => brand.organizationId === _id) : [],
      organizationMarketplaces: _id
        ? marketplaces?.filter(market => market.organizations.includes(_id))
        : [],
    });
  }, [currentOrganization, organizationUsers, brands, marketplaces]);

  const handleChange = (name: string, value: any) => {
    setData({ ...data, [name]: value });
  };

  const handleChangeMultiple = (obj: any) => {
    setData({ ...data, ...obj });
  };

  const handleDelete = async () => {
    setPopUpShow(true);
  };

  const popUpConfirmAction = () => {
    deleteOrganizationAction(entityId);
    setPopUpShow(false);
  };

  return (
    <>
      <Title
        title={i18n.t('Add Organization')}
        subtitle={data.name}
        icon={
          <IconContainer>
            <FaUserAlt size={18} color="rgb(216, 244, 12)" />
          </IconContainer>
        }
      />
      <ContentBox>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={handleTabChange}
          initialSelectedIndex={getDefaultActiveTab()}
        >
          <TabList>
            {tabs.map((tab, index) => (
              <Tab key={index}>{tab}</Tab>
            ))}

            <ActionButtonsContainer>
              {tabIndex > 0 && (
                <Button
                  text={i18n.t('Back')}
                  type="secondary"
                  action={handleBackButton}
                />
              )}
              <Button
                text={i18n.t('Save')}
                type="secondary"
                action={() => saveOrganization(true)}
              />
            </ActionButtonsContainer>
          </TabList>
          <FormContext {...formMethods}>
            <TabPanel>
              <OrganizationDetails
                onChange={(name, value) => handleChange(name, value)}
                formErrors={formMethods.errors}
                isDraft={isDraft}
                {...data}
              />
            </TabPanel>

            <TabPanel>
              <OrganizationUser
                onChange={(name, value) => handleChange(name, value)}
                onChangeMultiple={obj => handleChangeMultiple(obj)}
                organizationUsers={mappedOrganizationUsers}
                users={users}
                facilities={facilities}
                organizations={organizations}
              />
            </TabPanel>

            <TabPanel>
              <OrganizationBrand
                onChange={(name, value) => handleChange(name, value)}
                onChangeMultiple={obj => handleChangeMultiple(obj)}
                organizationBrands={mappedOrganizationBrands}
                brands={brands}
                organizationColor={organizationColor}
              />
            </TabPanel>

            <TabPanel>
              <OrganizationMarketplace
                onChange={(name, value) => handleChange(name, value)}
                onChangeMultiple={obj => handleChangeMultiple(obj)}
                organizationMarketplaces={mappedOrganizationMarketplaces}
                marketplaces={marketplaces}
                organizationColor={organizationColor}
              />
            </TabPanel>
          </FormContext>
        </Tabs>
      </ContentBox>
      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(NewUser) as any) as any
) as any;
