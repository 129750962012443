import { EUserActionTypes } from './userActionsTypes';

const initialState = {
  currentUser: {},
  user: {},
  loading: false,
  users: [],
  loginSuccess: false,
  deleteSuccess: false,
  organizations: [],
};

interface State {
  currentUser: any;
  loading: boolean;
  error?: string;
  deleteSuccess?: boolean;
  users: Array<any>;
  organizations?: Array<any>;
}

interface UserAction extends State {
  type: EUserActionTypes;
  payload: any;
}

type TUserReducerActions = UserAction;

export default function(
  state: State = initialState,
  action: TUserReducerActions
) {
  switch (action.type) {
    case EUserActionTypes.LOGIN_USER:
      return { ...state, loading: true, loginSuccess: false, error: null };
    case EUserActionTypes.LOGIN_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload.user,
        loading: false,
        loginSuccess: true,
      };
    case EUserActionTypes.LOGIN_USER_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loginSuccess: false,
      };

    case EUserActionTypes.GET_USERS:
      return { ...state, loading: true, error: null };
    case EUserActionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        loading: false,
      };
    case EUserActionTypes.GET_USERS_ERROR:
      return { ...state, error: action.error, loading: false };

    case EUserActionTypes.SAVE_USER:
      return {
        ...state,
        loading: true,
        submitSuccess: false,
        saveSuccess: false,
      };
    case EUserActionTypes.SAVE_USER_SUCCESS: {
      const isDraft = action.payload.isDraft;
      let stateProps = {};
      if (isDraft) {
        stateProps = {
          submitSuccess: false,
          user: action.payload.user,
        };
      } else {
        stateProps = { submitSuccess: !!action.payload.submitted };
      }
      return {
        ...state,
        loading: false,
        error: null,
        saveSuccess: !!action.payload.notifySuccess,
        ...stateProps,
      };
    }
    case EUserActionTypes.SAVE_USER_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        submitSuccess: false,
        saveSuccess: false,
      };

    case EUserActionTypes.GET_USER:
      return { ...state, loading: true };
    case EUserActionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        loading: false,
      };
    case EUserActionTypes.GET_USER_ERROR:
      return { ...state, error: action.payload.message, loading: false };

    case EUserActionTypes.DELETE_USER:
      return { ...state, loading: true };
    case EUserActionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      };
    case EUserActionTypes.DELETE_USER_ERROR:
      return { ...state, error: action.payload.message, loading: false };

    case EUserActionTypes.GET_ORGANIZATIONS:
      return { ...state, loading: true, error: null };
    case EUserActionTypes.GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.payload.organizations,
        loading: false,
      };
    case EUserActionTypes.GET_ORGANIZATIONS_ERROR:
      return { ...state, error: action.error, loading: false };

    case EUserActionTypes.RESET_STATE:
      return {
        ...state,
        error: false,
        user: {},
        submitSuccess: false,
        saveSuccess: false,
        loginSuccess: false,
      };

    default:
      return state;
  }
}
