import { WriteIcon, ReadIcon } from '../../Components';
import React from 'react';

const OrganizationPermissionTypes: Array<{
  value: string;
  label: string;
  icon: any;
}> = [
  {
    value: 'admin',
    label: 'Organization Admin (Full Access)',
    icon: <WriteIcon />,
  },
  // { value: 'manager', label: 'Organization Manager (Read/Write)' },
  {
    value: 'user',
    label: 'Organization Viewer (Read Only)',
    icon: <ReadIcon />,
  },
];
export default OrganizationPermissionTypes;
