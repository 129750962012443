import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { doGetFacilities } from '../../../Redux/Facility/facilityActions';
import { linkToTransaction } from '../../../Services/Api/authExplorer';
import { doGetClaims } from '../../../Redux/Claim/claimActions';
import moment from 'moment';
import { CustomTooltip, ToggleTable } from '../../../Components';
import Toast from '../../../Lib/Utils/toast';

import {
  doGetJourneys,
  doGetJourneyTemplate,
} from '../../../Redux/Journey/journeyActions';

import { HeaderFilter, ArrowLeftIcon, Loading } from '../../../Components';

import { Container, BackButtonTittle, DataList } from './styles';
import Table, { RowsType } from './Table';

interface RootState {
  journey: any;
  facility: any;
  claim: any;
}

const mapState = (state: RootState) => ({
  currentJourney: state.journey.journeys,
  currentTemplate: state.journey.currentTemplate,
  facilities: state.facility.facilities,
  claims: state.claim.claims || [],
  error: state.journey.error,

  journeyLoading: state.journey.loading,
  claimsLoading: state.claim.loading,
  facilitiesLoading: state.facility.loading,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getJourneyAction: journeyId => dispatch(doGetJourneys(journeyId)),
  getJourneyTemplateAction: (journeyTemplateId, actionType) =>
    dispatch(doGetJourneyTemplate(journeyTemplateId, actionType)),
  getFacilitiesAction: () => dispatch(doGetFacilities()),
  getClaimsAction: includeDrafts => dispatch(doGetClaims(null, includeDrafts)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & { history?: any; location?: any; match?: any };

const tableHeaders = [
  { text: i18n.t('Journey information') },
  { text: i18n.t('Start date') },
  { text: i18n.t('End date') },
  { text: i18n.t('Blockchain proof'), colspan: 2 },
];

const copyClipboard = text => {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
  Toast.dark(i18n.t('Copied to clipboard!'));
};

const infoTitle = (
  title,
  value,
  tooltip = i18n.t('Click to copy.'),
  navigate = false
) =>
  value === '-' ? (
    <>
      <span className="txInfo">{title}</span>
      <span>-</span>
    </>
  ) : (
    <>
      <span className="txInfo">{title}</span>
      <span
        className="txInfo"
        data-tip
        data-for={`${title}-${value}`}
        onClick={() =>
          navigate
            ? window.open(linkToTransaction(value), '_blank')
            : copyClipboard(value)
        }
      >
        {value}
      </span>
      <CustomTooltip id={`${title}-${value}`}>{tooltip}</CustomTooltip>
    </>
  );

const ProofJourney = (props: Props) => {
  const {
    match,
    getJourneyAction,
    getFacilitiesAction,
    getJourneyTemplateAction,
    getClaimsAction,
    facilities,
    claims,
    currentJourney,
    currentTemplate,
    history,
    journeyLoading,
    claimsLoading,
    facilitiesLoading,
  } = props;

  const [journeyInformationRows, setJourneyInformationRows] = useState(
    [] as RowsType
  );

  const [customLoading, setcustomLoading] = useState(true);

  const loading =
    journeyLoading || claimsLoading || facilitiesLoading || customLoading;

  useEffect(() => {
    getJourneyAction(match.params.journeyId);
    getFacilitiesAction();
    getJourneyTemplateAction(match.params.journeyTemplateId, 'get-entity');
    getClaimsAction(false);
  }, []);

  useEffect(() => {
    if (
      currentTemplate &&
      currentJourney &&
      facilities &&
      claims &&
      Object.keys(currentTemplate).length &&
      Object.keys(currentJourney).length &&
      Object.keys(facilities).length &&
      Object.keys(claims).length
    ) {
      const { tx, createdAt, stepTemplates, journeyName } = currentTemplate;
      const { steps } = currentJourney;

      const newRows = [] as RowsType;

      const startDate = moment(createdAt).format('DD MMM, YYYY');
      const endDate = moment().format('DD MMM, YYYY');

      newRows.push({
        values: [
          { value: journeyName },
          { value: startDate },
          { value: endDate },
        ],
      });

      if (tx && tx.status === 'success') {
        newRows[0].values.push({
          infoTitle: true,
          value: infoTitle(i18n.t('Transaction hash'), tx.txHash),
        });
        newRows[0].values.push({
          infoTitle: true,
          value: infoTitle(
            i18n.t('Address'),
            tx.txAddress,
            i18n.t('Click to open.'),
            true
          ),
        });
      }

      steps
        ?.sort((a, b) => a.order - b.order)
        .forEach(stepData => {
          const { step } = stepData;
          const { tx, inputsData, outputsData } = step;

          const transaction = tx && tx.status === 'success' ? tx : null;

          newRows.push({
            values: [
              { value: step.facility?.name, colspan: 3 },
              {
                infoTitle: true,
                value: infoTitle(
                  i18n.t('Transaction hash'),
                  transaction ? transaction.txHash : '-'
                ),
              },
              {
                infoTitle: true,
                value: infoTitle(
                  i18n.t('Address'),
                  transaction ? transaction.txAddress : '-',
                  i18n.t('Click to open.'),
                  true
                ),
              },
            ],
            subHeader: true,
            collapsible: true,
          });

          const facility = facilities.find(
            ({ _id }) => _id === step.facility?._id
          );
          newRows.push({
            values: [
              { value: <strong>{i18n.t('Description')}</strong> },
              { value: step.description, colspan: 4 },
            ],
          });

          newRows.push({
            values: [
              { value: <strong>{i18n.t('Email')}</strong> },
              { value: facility?.email[0].address, colspan: 4 },
            ],
          });

          newRows.push({
            values: [
              { value: <strong>{i18n.t('Phone')}</strong> },
              {
                value: `(${facility?.phone[0].countryCode}) ${facility?.phone[0].number}`,
                colspan: 4,
              },
            ],
          });

          newRows.push({
            values: [
              { value: <strong>{i18n.t('Web')}</strong> },
              {
                value: facility?.url[0],
                colspan: 4,
              },
            ],
          });

          const stepClaims =
            claims.filter(claim => {
              const stepTemplate = stepTemplates.find(
                stepTemplate => stepTemplate.order === stepData.order
              );
              return stepTemplate?.stepTemplate.claims.includes(claim._id);
            }) || [];

          newRows.push({
            values: [
              { value: <strong>{i18n.t('Claims')}</strong> },
              {
                value: (
                  <>
                    <br />
                    <ul>
                      {stepClaims.map((claim, idx) => (
                        <li key={`${idx}-${claim._id}`}>
                          {claim.links.length ? (
                            <a
                              href={claim.links[0]}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {claim.name}
                            </a>
                          ) : (
                            claim.name
                          )}
                        </li>
                      ))}
                    </ul>
                  </>
                ),
                colspan: 2,
              },
              {
                value: (
                  <>
                    <div className="infoTitle">
                      {infoTitle('Transaction hash', '')}
                    </div>
                    {stepClaims.map((claim, idx) => {
                      const { tx } = claim;
                      const transaction =
                        tx && tx.status === 'success' ? tx : null;
                      return (
                        <div
                          key={`${idx}-block-${claim._id}`}
                          className="infoTitle"
                        >
                          {infoTitle(
                            '',
                            transaction ? transaction.txHash : '-'
                          )}
                        </div>
                      );
                    })}
                  </>
                ),
              },
              {
                value: (
                  <>
                    <div className="infoTitle">{infoTitle('Address', '')}</div>
                    {stepClaims.map((claim, idx) => {
                      const { tx } = claim;
                      const transaction =
                        tx && tx.status === 'success' ? tx : null;
                      return (
                        <div
                          key={`${idx}-block-${claim._id}`}
                          className="infoTitle"
                        >
                          {infoTitle(
                            '',
                            transaction ? transaction.txAddress : '-',
                            i18n.t('Click to open.'),
                            true
                          )}
                        </div>
                      );
                    })}
                  </>
                ),
              },
            ],
          });

          const inboundTitles =
            inputsData &&
            inputsData.reduce((group, val) => {
              group[val['skuku']] = val.name || '';
              return group;
            }, {});

          const inboundHeaders =
            inputsData &&
            inputsData.reduce((group, val) => {
              group[val['skuku']] = Object.keys(val.attributes);
              return group;
            }, {});

          const inboundData =
            inputsData &&
            inputsData.reduce((group, val) => {
              (group[val['skuku']] = group[val['skuku']] || []).push(
                val.attributes
              );
              return group;
            }, {});

          newRows.push({
            values: [
              { value: <strong>{i18n.t('Inbounds')}</strong> },
              {
                value: inputsData?.length ? (
                  <ToggleTable
                    titles={inboundTitles}
                    headers={inboundHeaders}
                    data={inboundData}
                  />
                ) : (
                  i18n.t('No inbound assets.')
                ),
                colspan: 4,
              },
            ],
          });

          const outboundTitles =
            outputsData &&
            outputsData.reduce((group, val) => {
              group[val['skuku']] = val.name || '';
              return group;
            }, {});

          const outboundHeaders =
            outputsData &&
            outputsData.reduce((group, val) => {
              group[val['skuku']] = Object.keys(val.attributes);
              return group;
            }, {});

          const outboundData =
            outputsData &&
            outputsData.reduce((group, val) => {
              (group[val['skuku']] = group[val['skuku']] || []).push(
                val.attributes
              );
              return group;
            }, {});

          newRows.push({
            values: [
              { value: <strong>{i18n.t('Outbounds')}</strong> },
              {
                value: outputsData?.length ? (
                  <ToggleTable
                    titles={outboundTitles}
                    headers={outboundHeaders}
                    data={outboundData}
                  />
                ) : (
                  i18n.t('No outbound assets.')
                ),
                colspan: 4,
              },
            ],
          });
        });

      setJourneyInformationRows(newRows);
      setcustomLoading(false);
    }
  }, [currentTemplate, currentJourney, facilities, claims]);

  const allRows = journeyInformationRows;

  return (
    <>
      <Container>
        <HeaderFilter
          title={currentJourney && currentJourney.journeyName}
          subtitleArray={
            currentJourney
              ? [i18n.t('Blockchain Proof'), currentJourney.lotId]
              : []
          }
          actionButton={false}
          withFilters={false}
          searchInput={false}
          titleIcon={
            <BackButtonTittle
              onClick={() => {
                currentJourney &&
                  history.push(
                    `/journeys/${match.params.journeyTemplateId}/view/`,
                    {
                      state: {
                        data: {
                          journeyName: currentJourney.journeyName,
                        },
                      },
                    }
                  );
              }}
            >
              <ArrowLeftIcon fill="#D8F40C" />
            </BackButtonTittle>
          }
        />

        {!loading && <Table headers={tableHeaders} rows={allRows} />}
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(ProofJourney) as any) as any
);
