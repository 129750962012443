import React, { useEffect } from 'react';
import i18n from 'i18next';
import moment from 'moment';
import {
  ClaimReviewSubmitContainer as Container,
  ClaimReviewSubmitItemsContainer as ItemsContainer,
  ClaimReviewSubmitIconContainer as IconContainer,
  ClaimReviewSubmitItemsWrapper as ItemsWrapper,
} from './styles';

import {
  EditableItem,
  CheckIcon,
  Link,
  InputsList,
  MediaUpload,
  FrequencyIcon,
} from '../../../Components';

import { ClaimTypes } from '../../../Lib/Configs';

interface Props {
  onChange: Function;
  formMethods: any;

  claimShortName?: string;
  claimName?: string;
  claimType?: string;
  claimBullets?: string;
  selectedVerificationOption?: number;
  attestorInfo?: any;
  attestorText?: string;

  attestorFrequency?: number;
  attestorFrequencyDays?: number;
  attestorPeriodStartDate?: Date | null;
  attestorPeriodEndDate?: Date | null;
  isAttestorNoEndDate?: boolean;

  facilities?: Array<any>;
  facilityId?: string;

  isDraft?: boolean;
  entityId?: string;
  embedded?: boolean;

  links?: Array<any>;
  verificationLinks?: Array<any>;
}

const ClaimReviewSubmit = ({
  onChange,
  formMethods,
  isDraft,
  entityId,
  embedded,
  ...data
}: Props) => {
  const {
    claimShortName,
    claimName,
    claimType,
    claimBullets,
    selectedVerificationOption = 0,
    attestorInfo,
    attestorText,
    attestorFrequency = 0,
    attestorFrequencyDays,
    attestorPeriodStartDate,
    attestorPeriodEndDate,
    isAttestorNoEndDate = false,
    facilities = [],
    facilityId = '',
    links,
    verificationLinks,
  } = data;

  const claimBulletsArray =
    typeof claimBullets === 'object' ? claimBullets : claimBullets?.split('\n');

  const selectedVerificationText = {
    0: i18n.t('This claim is not verifiable'),
    1: i18n.t('Use 3rd party attestation'),
    2: i18n.t('Self upload of evidence'),
  };

  const selectedAttestorFrequencyText = {
    0: i18n.t('One time'),
    1: i18n.t('Required for each lot/batch'),
    2: i18n.t('Periodic'),
  };

  const { errors, clearError } = formMethods;

  useEffect(() => {
    clearError();
  }, []);

  const detailsError =
    !!errors.claimShortName ||
    !!errors.claimName ||
    !!errors.claimType ||
    !!errors.claimBullets;

  const verificationError =
    !!errors.attestorSelect ||
    !!errors.attestorText ||
    !!errors.attestorFrequencyDays ||
    !!errors.attestorPeriodStartDate ||
    !!errors.attestorPeriodEndDate;

  const mappingError = !!errors.facilityId;

  const newClaimType = claimType && ClaimTypes.find(c => c.value === claimType);

  const stringFromDate =
    attestorPeriodStartDate &&
    `${i18n.t('From')} ${moment(attestorPeriodStartDate).format(
      'MM/DD/YYYY'
    )} ${isAttestorNoEndDate ? i18n.t('to no end date') : ''}`;

  const stringEndFate =
    attestorPeriodEndDate &&
    !isAttestorNoEndDate &&
    `${i18n.t('to')} ${moment(attestorPeriodEndDate).format('MM/DD/YYYY')}`;

  const stringDatesPeriod = `${stringFromDate ? stringFromDate : ''} ${
    stringEndFate ? stringEndFate : ''
  }`;

  const facilitySelected = facilities.find(({ _id }) => _id == facilityId);

  const baseUrl = isDraft
    ? entityId
      ? `/claims/${entityId}/draft`
      : '/claims/draft'
    : `/claims/${entityId}/edit`;

  return (
    <Container>
      <ItemsWrapper>
        <EditableItem
          incomplete={detailsError}
          title={i18n.t('Summary')}
          collapsible={true}
          linkTo={
            embedded ? { search: '?tab=Overview' } : `${baseUrl}?tab=Overview`
          }
          content={
            <>
              <ItemsContainer>
                <EditableItem
                  title={i18n.t('Public claim name')}
                  content={claimName}
                  editable={false}
                />
                <EditableItem
                  title={i18n.t('Claim type')}
                  content={
                    newClaimType && (
                      <IconContainer>
                        {newClaimType && newClaimType.icon}{' '}
                        {newClaimType && newClaimType.label}
                      </IconContainer>
                    )
                  }
                  editable={false}
                />
              </ItemsContainer>

              <ItemsContainer>
                <EditableItem
                  title={i18n.t('System claim name')}
                  content={claimShortName}
                  editable={false}
                />
                <EditableItem
                  title={i18n.t('Claim bullets')}
                  content={
                    claimBulletsArray &&
                    claimBulletsArray.length > 0 &&
                    !(
                      claimBulletsArray.length === 1 &&
                      claimBulletsArray[0] === ''
                    ) && (
                      <ul>
                        {claimBulletsArray?.map((bullet, index) => (
                          <li key={index}>{bullet}</li>
                        ))}
                      </ul>
                    )
                  }
                  editable={false}
                />
              </ItemsContainer>
            </>
          }
        />
      </ItemsWrapper>

      <ItemsWrapper>
        <EditableItem
          incomplete={verificationError}
          collapsible={true}
          title={i18n.t('Verification')}
          linkTo={
            embedded
              ? { search: '?tab=Verification' }
              : `${baseUrl}?tab=Verification`
          }
          content={
            <>
              <ItemsContainer>
                <EditableItem
                  title={i18n.t('How will this claim will be verified?')}
                  content={
                    links && links.length > 0
                      ? selectedVerificationText[2]
                      : selectedVerificationText[selectedVerificationOption]
                  }
                  editable={false}
                />
              </ItemsContainer>

              {selectedVerificationOption === 1 && (
                <>
                  <ItemsContainer>
                    <EditableItem
                      title={i18n.t('Company')}
                      content={attestorInfo?.company}
                      editable={false}
                    />
                    <EditableItem
                      title={i18n.t('Attestor name')}
                      content={`${
                        attestorInfo ? attestorInfo?.salutation : ''
                      } ${attestorInfo ? attestorInfo?.firstName : ''} ${
                        attestorInfo ? attestorInfo?.lastName : ''
                      }`}
                      editable={false}
                    />
                    <EditableItem
                      title={i18n.t('Email')}
                      content={attestorInfo?.email}
                      editable={false}
                    />
                  </ItemsContainer>

                  <ItemsContainer>
                    <EditableItem
                      title={i18n.t('Attestation text')}
                      content={attestorText}
                      editable={false}
                    />
                  </ItemsContainer>
                </>
              )}

              {(selectedVerificationOption !== 0 ||
                (links && links.length > 0)) && (
                <>
                  <ItemsContainer>
                    <EditableItem
                      title={i18n.t('How frequently is attestation required?')}
                      content={selectedAttestorFrequencyText[attestorFrequency]}
                      editable={false}
                    />

                    {attestorFrequency === 2 && (
                      <EditableItem
                        title={i18n.t('Frequency')}
                        content={
                          attestorFrequencyDays && (
                            <IconContainer>
                              <FrequencyIcon />
                              {` ${i18n.t('Every')} ${attestorFrequencyDays} ${
                                attestorFrequencyDays > 1
                                  ? i18n.t('days')
                                  : i18n.t('day')
                              }`}
                            </IconContainer>
                          )
                        }
                        editable={false}
                      />
                    )}
                  </ItemsContainer>

                  {(attestorFrequency === 0 || (links && links.length > 0)) && (
                    <ItemsContainer>
                      <EditableItem
                        title={i18n.t('Documents')}
                        editable={false}
                        content={
                          <MediaUpload
                            proloadedItemsLinks={
                              links && links.length ? links : verificationLinks
                            }
                            readonly
                            showLink
                            linksList={[2]}
                          />
                        }
                      />
                    </ItemsContainer>
                  )}

                  {attestorFrequency === 2 && (
                    <ItemsContainer>
                      <EditableItem
                        title={i18n.t(
                          'During what period are attestation requests to be made?'
                        )}
                        editable={false}
                        content={stringDatesPeriod}
                      />
                    </ItemsContainer>
                  )}
                </>
              )}
            </>
          }
        />
      </ItemsWrapper>

      <ItemsWrapper>
        <EditableItem
          incomplete={mappingError}
          collapsible={true}
          title={i18n.t('Mapping')}
          linkTo={
            embedded ? { search: '?tab=Mapping' } : `${baseUrl}?tab=Mapping`
          }
          content={
            <ItemsContainer>
              <EditableItem
                title={i18n.t('Facilities that this claim will be linked to')}
                editable={false}
                content={
                  facilitySelected && <p>{facilitySelected.registeredName}</p>
                }
              />
            </ItemsContainer>
          }
        />
      </ItemsWrapper>
    </Container>
  );
};

export default ClaimReviewSubmit;
