import { WriteIcon, ReadIcon } from '../../Components';
import React from 'react';

const FacilityPermissionTypes: Array<{
  value: string;
  label: string;
  icon: any;
}> = [
  // { value: 'admin', label: 'Facility Admin (Full Access)' },
  {
    value: 'manager',
    label: 'Facility Manager (Read/Write)',
    icon: <WriteIcon />,
  },
  { value: 'user', label: 'Facility User (Read Only)', icon: <ReadIcon /> },
];
export default FacilityPermissionTypes;
