export const getUUIDForFile = (_id = '000000') =>
  _id.substring(_id.length - 6, _id.length);

export const orderSort = ({ order: numberA }, { order: numberB }) => {
  if (numberA === -1) return 1;
  if (numberB === -1) return -1;

  if (numberA > numberB) {
    return 1;
  }
  if (numberB > numberA) {
    return -1;
  }
  return 0;
};

export const capitalizeString = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
