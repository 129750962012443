import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';

import {
  FacilityDetailsContainer as Container,
  FacilityDetailsFormContainer as FormContainer,
  FormSubHeader,
} from './styles';

import {
  Label,
  CustomTooltip,
  InfoIcon,
  Checkbox,
  ColorPicker,
} from '../../../Components';

interface Props {
  onChange: Function;
  organizationColor?: string;
  themes?: { default?: { colorPrimary?: string; useDefaultColor?: boolean } };
}

const FacilityColor = ({
  onChange,
  organizationColor = '',
  ...data
}: Props) => {
  const { themes } = data;

  const colorPrimary = themes?.default?.colorPrimary || organizationColor;
  const useDefaultColor =
    themes?.default?.useDefaultColor === undefined
      ? true
      : themes?.default?.useDefaultColor;

  useEffect(() => {
    const newThemes = {
      default: {
        colorPrimary: colorPrimary,
        useDefaultColor: themes?.default?.useDefaultColor,
      },
    };
    if (themes?.default?.useDefaultColor) {
      newThemes.default.colorPrimary = organizationColor;
    }
    if (JSON.stringify(themes) !== JSON.stringify(newThemes))
      onChange('themes', newThemes);
  }, [themes]);

  return (
    <Container className="row">
      <FormContainer className="row">
        <div className="col-12">
          <div className="mb-3">
            <Label>
              {i18n.t(
                'When the facility information is viewed by the end consumer in the scanner app, the look and feel for this facility can take on the default color scheme from the consumer scanner app, or it can take on the color you choose here.'
              )}
            </Label>
          </div>
          <Label>
            <Checkbox
              onChange={evt =>
                onChange('themes', {
                  default: {
                    ...themes?.default,
                    useDefaultColor: !useDefaultColor,
                  },
                })
              }
              checked={useDefaultColor}
              name="defaultColor"
              disabled={organizationColor === ''}
            />
            {i18n.t('Set to default scanner app main color')}
          </Label>
        </div>

        <div className="col-12">
          <Label>
            {i18n.t('Color')}
            <div data-tip data-for="colorSelector">
              <InfoIcon />
            </div>
            <CustomTooltip id="colorSelector" placement="right">
              <span>
                {i18n.t(
                  'This is the default color of the mobile consumer scanner app.'
                )}
              </span>
            </CustomTooltip>
          </Label>
          <ColorPicker
            defaultColor={colorPrimary}
            onChange={colorPrimary =>
              onChange('themes', {
                default: {
                  useDefaultColor,
                  colorPrimary,
                },
              })
            }
            disabled={useDefaultColor}
          />
        </div>
      </FormContainer>
    </Container>
  );
};

export default FacilityColor;
