import * as brandService from '../../Services/Api/brandService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { EBrandActionTypes } from './brandActionsTypes';
import { SaveBrandActionType } from '../../Services/Api/brandService/types';

const getBrands = createAction(EBrandActionTypes.GET_BRANDS);
const getBrandsSuccess = createAction(
  EBrandActionTypes.GET_BRANDS_SUCCESS,
  (brands: any) => ({ brands })
);
const getBrandsError = createAction(
  EBrandActionTypes.GET_BRANDS_ERROR,
  (message: string) => ({ message })
);

const getBrand = createAction(EBrandActionTypes.GET_BRAND);
const getBrandSuccess = createAction(
  EBrandActionTypes.GET_BRAND_SUCCESS,
  (brand: any) => ({ brand })
);
const getBrandError = createAction(
  EBrandActionTypes.GET_BRAND_ERROR,
  (message: string) => ({ message })
);

const deleteBrand = createAction(EBrandActionTypes.DELETE_BRAND);
const deleteBrandSuccess = createAction(
  EBrandActionTypes.DELETE_BRAND_SUCCESS,
  (brand: any) => ({ brand })
);
const deleteBrandError = createAction(
  EBrandActionTypes.DELETE_BRAND_ERROR,
  (message: string) => ({ message })
);

const saveBrand = createAction(EBrandActionTypes.SAVE_BRAND);
const saveBrandSuccess = createAction(
  EBrandActionTypes.SAVE_BRAND_SUCCESS,
  (
    brand: any,
    isDraft?: boolean,
    submitted?: boolean,
    notifySuccess?: boolean
  ) => ({
    brand,
    isDraft,
    submitted,
    notifySuccess,
  })
);
const saveBrandError = createAction(
  EBrandActionTypes.SAVE_BRAND_ERROR,
  (message: string) => ({ message })
);

export const resetState = createAction(EBrandActionTypes.RESET_STATE);

export const doGetBrands = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async dispatch => {
  dispatch(getBrands());
  return await tap(
    brandService.getBrands(),
    brands => {
      dispatch(getBrandsSuccess(brands));
    },
    error => {
      const _message = 'Failed to retrieve Brands. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getBrandsError(message));
    }
  );
};

export const doSaveBrand = (
  payload: any,
  actionType: SaveBrandActionType,
  notifySuccess?: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(saveBrand());
  return await tap(
    brandService.saveBrand(payload, actionType),
    response => {
      let data = response.data ? response.data.data : {};
      const isDraft = !!actionType.match('draft');
      if (isDraft) {
        data = { ...data.draft, _id: data._id }; // The Draft has its own _id different from the user _id
      }
      dispatch(
        saveBrandSuccess(
          data,
          isDraft,
          !!actionType.match('submit'),
          notifySuccess
        )
      );
    },
    error => {
      const _message = 'Failed to save Brand. Please try again later';

      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(saveBrandError(message));
    }
  );
};
