import React from 'react';
import i18n from 'i18next';
import styled from 'styled-components';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import StepDetail from './StepDetail';
import Props from './types';
import { useHistory } from 'react-router';
import { PencilIcon, CustomTooltip, InProgressButton } from '../index';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
`;

const NameContainer = styled.div`
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Name = styled.div`
  min-width: 110px;
  text-align: right;
  justify-content: flex-end;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  text-transform: uppercase;
  color: #a7bf2e;
  align-items: center;
`;

const IconContainer = styled.div`
  margin: 0 20px 0 10px;
  display: flex;
  background: #d8f40c;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  color: #a7bf2e;
  & path {
    fill: #a7bf2e;
  }
`;

const TitleContainer = styled.div`
  flex: 1;
  border-bottom: 1px solid rgba(117, 117, 117, 0.15);
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 55px;
`;

const ValueContainer = styled.div`
  font-family: Encode Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #303030;
  flex: 1;
  line-height: 55px;
`;

const StepContainer = styled.div`
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  display: flex;
  flex: 1;
  width: 100%;
`;

const StatusContainer = styled.div`
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  display: flex;
  flex: 1;
`;

const Status = styled.div`
  justify-content: flex-end;
  margin-right: 20px;
`;

const ActionContainer = styled.button`
  &:focus {
    outline: none;
  }
  outline: none;
  border: 0;
  display: flex;
  background-color: #000;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
`;

const StepInformation = styled.div<{ open: boolean }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: height 0ms 400ms, opacity 400ms 0ms;
  height: ${({ open }) => (open ? 'auto' : '0px')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  width: 100%;
`;

const Link = styled.button`
  justify-content: flex-end;
  margin-right: 10px;

  &:focus {
    outline: none;
  }
  outline: none;
  border: 0;
  width: 20px;
  height: 20px;
  background: #000000;
  border-radius: 20px;
  display: flex;
  color: #d8f40c;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Step = ({
  number,
  name,
  value,
  icon,
  step,
  status,
  open = false,
  openAction,
  inboundAssets,
  outboundAssets,
  queryLink,
}: Props & { openAction: any | null }) => {
  const handleActionClick = () => {
    openAction(number);
  };

  const history = useHistory();

  const handleEditClick = () => {
    if (typeof queryLink === 'function') {
      queryLink();
    } else {
      history.push({
        search: queryLink,
      });
    }
  };

  const IconComponent = icon && icon;

  return (
    <Container>
      <NameContainer>
        <Name>{name.length > 12 ? name.substring(0, 12) + ' ...' : name}</Name>
        <IconContainer>{IconComponent}</IconContainer>
      </NameContainer>
      <TitleContainer>
        <StepContainer>
          {queryLink && (
            <>
              <div data-tip data-for="facility">
                <Link onClick={handleEditClick}>
                  <PencilIcon />
                </Link>
              </div>
              <CustomTooltip id="facility" placement="right">
                <span>{i18n.t('Edit facility.')}</span>
              </CustomTooltip>
            </>
          )}
          <ValueContainer>{value}</ValueContainer>
          {/* <InProgressButton action={() => {}}/> */}
          <StatusContainer>
            <Status>{i18n.t(status)}</Status>
            {/* <Status>{i18n.t(step.isComplex ? 'Complex' : 'Simple')}</Status>*/}
            <ActionContainer onClick={handleActionClick}>
              {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </ActionContainer>
          </StatusContainer>
        </StepContainer>
        <StepInformation open={open}>
          <StepDetail
            description={step && step.description}
            stepDescription={step && step.stepDescription}
            claims={step && step.claims}
            inboundAssets={inboundAssets}
            outboundAssets={outboundAssets}
          />
        </StepInformation>
      </TitleContainer>
    </Container>
  );
};

export default Step;
