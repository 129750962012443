import * as claimService from '../../Services/Api/claimService';
import * as verificationService from '../../Services/Api/verificationService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { EClaimActionTypes } from './claimActionsTypes';
import {
  GetClaimActionType,
  SaveClaimActionType,
} from '../../Services/Api/claimService/types';

const getClaims = createAction(EClaimActionTypes.GET_CLAIMS);
const getClaimsSuccess = createAction(
  EClaimActionTypes.GET_CLAIMS_SUCCESS,
  (claims: any) => ({ claims })
);
const getClaimsError = createAction(
  EClaimActionTypes.GET_CLAIMS_ERROR,
  (message: string) => ({ message })
);

const getClaim = createAction(EClaimActionTypes.GET_CLAIM);
const getClaimSuccess = createAction(
  EClaimActionTypes.GET_CLAIM_SUCCESS,
  (claim: any) => ({ claim })
);
const getClaimError = createAction(
  EClaimActionTypes.GET_CLAIM_ERROR,
  (message: string) => ({ message })
);

const addClaimVerification = createAction(
  EClaimActionTypes.ADD_CLAIM_VERIFICATION
);

const addClaimVerificationSuccess = createAction(
  EClaimActionTypes.ADD_CLAIM_VERIFICATION_SUCCESS,
  (addVerification: any) => ({ addVerification })
);

const addClaimVerificationError = createAction(
  EClaimActionTypes.ADD_CLAIM_VERIFICATION_ERROR,
  (message: string) => ({ message })
);

const saveClaim = createAction(EClaimActionTypes.SAVE_CLAIM);

const saveClaimSuccess = createAction(
  EClaimActionTypes.SAVE_CLAIM_SUCCESS,
  (
    claim: any,
    isDraft?: boolean,
    submitted?: boolean,
    notifySuccess?: boolean
  ) => ({ claim, isDraft, submitted, notifySuccess })
);

const saveClaimError = createAction(
  EClaimActionTypes.SAVE_CLAIM_ERROR,
  (message: string) => ({ message })
);

const saveClaimClear = createAction(EClaimActionTypes.SAVE_CLAIM_CLEAR);

const deleteClaim = createAction(
  EClaimActionTypes.DELETE_CLAIM,
  (claimId: any) => ({ claimId })
);
const deleteClaimSuccess = createAction(
  EClaimActionTypes.DELETE_CLAIM_SUCCESS,
  (payload: any) => payload
);
const deleteClaimError = createAction(
  EClaimActionTypes.DELETE_CLAIM_ERROR,
  (message: string) => ({ message })
);

const resetClaims = createAction(EClaimActionTypes.RESET_CLAIMS);

export const doCleanClaims = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async dispatch => {
  dispatch(resetClaims());
};

export const doGetClaims = (
  facilityId = null,
  includeDrafts?: boolean,
  payload?: any
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getClaims());
  if (payload?._id === '') {
    dispatch(getClaimsSuccess([]));
  } else {
    return await tap(
      claimService.getClaims(facilityId, includeDrafts, payload || null),
      claims => {
        claims = [
          ...new Map(claims.map(item => [item['name'], item])).values(),
        ]; // keep unique names
        dispatch(getClaimsSuccess(claims));
      },
      error => {
        const _message = 'Failed to retrieve Claims. Please try again later';
        const message =
          error instanceof ClientError ? _message : 'Internal Error';
        dispatch(getClaimsError(message));
      }
    );
  }
};

export const doGetClaim = (
  claimId,
  actionType: GetClaimActionType
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getClaim());
  return await tap(
    claimService.getClaim(claimId, actionType),
    response => {
      let data = response.data ? response.data.data : {};
      const isDraft = !!actionType.match('draft');
      if (isDraft) {
        data = { ...data.draft, _id: data._id }; // The Draft has its own _id different from the claim _id
      }
      dispatch(getClaimSuccess(data));
    },
    error => {
      const _message = 'Failed to retrieve Claim. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getClaimError(message));
    }
  );
};

export const doAddClaimVerification = (
  verificationId,
  files
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(addClaimVerification());
  return await tap(
    claimService.addClaimVerification({ verificationId, files }),
    response => {
      dispatch(
        addClaimVerificationSuccess(response.data ? response.data.data : [])
      );
    },
    error => {
      const _message = 'Failed to retrieve Attestors. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(addClaimVerificationError(message));
    }
  );
};

export const doSaveClaim = (
  claim,
  verification,
  actionType: SaveClaimActionType,
  notifySuccess?: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(saveClaim());
  return await tap(
    claimService.saveClaim(claim, verification, actionType),
    response => {
      let data = response.data ? response.data.data : {};
      const isDraft = !!actionType.match('draft');
      if (isDraft) {
        data = { ...data.draft, _id: data._id }; // The Draft has its own _id different from the claim _id
      }
      dispatch(
        saveClaimSuccess(
          data,
          isDraft,
          !!actionType.match('submit'),
          notifySuccess
        )
      );
    },
    error => {
      const _message = 'Failed to save Claim. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(saveClaimError(message));
      dispatch(saveClaimClear());
    }
  );
};

export const doAddClaimVerificationLinks = (
  verificationId,
  links
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(addClaimVerification());
  return await tap(
    verificationService.addVerificationLinks({ verificationId, links }),
    response => {
      dispatch(
        addClaimVerificationSuccess(response.data ? response.data.data : [])
      );
    },
    error => {
      const _message =
        'Failed to add verification links. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(addClaimVerificationError(message));
    }
  );
};

export const doClearClaims = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async dispatch => {
  dispatch(saveClaimClear());
};

export const doDeleteClaim = (
  claimId: string,
  isDraft: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(deleteClaim(claimId));
  return await tap(
    claimService.deleteClaim(claimId, isDraft),
    response => {
      dispatch(deleteClaimSuccess(response.data));
    },
    error => {
      const _message = 'Failed to delete Journey. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(deleteClaimError(message));
    }
  );
};
