import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { ChromePicker } from 'react-color';
import { FormInput, CloseCircleIcon } from '../';
import {
  Container,
  InputContainer,
  ColorPickerContainer,
  Cover,
} from './styles';
import { EditableInput } from 'react-color/lib/components/common';

const inputStyles = {
  input: {
    border: 'none',
  },
  label: {
    fontSize: '12px',
    color: '#999',
  },
};

interface Props {
  defaultColor?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  onChange: any;
}

const ColorPicker = (props: Props) => {
  const {
    defaultColor = '',
    placeholder = i18n.t('Select a color'),
    required = false,
    onChange,
    disabled = false,
  } = props;

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [value, setValue] = useState(defaultColor);

  useEffect(() => {
    setValue(defaultColor);
  }, [defaultColor]);

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  const handleChange = (color, evt) => {
    setValue(color.hex);
  };

  const handleResetValue = () => {
    !disabled && setValue('');
  };

  return (
    <Container>
      <InputContainer>
        <FormInput
          placeholder={placeholder}
          value={value}
          onFocus={() => setShowColorPicker(true)}
          required={required}
          disabled={disabled}
        />
        <div className="clearButton" onClick={handleResetValue}>
          <CloseCircleIcon />
        </div>
      </InputContainer>
      <ColorPickerContainer>
        {!disabled && showColorPicker && (
          <>
            <Cover onClick={() => setShowColorPicker(false)} />
            <ChromePicker color={value} onChangeComplete={handleChange} />
          </>
        )}
      </ColorPickerContainer>
    </Container>
  );
};

export default ColorPicker;
