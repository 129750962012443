import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import {
  ContentBox,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Button,
  CustomTooltip,
} from '../../../../../Components';
import Periodic from './Periodic';
import Lot from './Lot';
import OneTime from './OneTime';
import Others from './Others';
import {
  ActionButtonsContainer,
  TabBubble,
  ClaimsTabListContainer,
} from '../styles';

interface Props {
  data: any;
  onTabsEnd: Function;
  onChange?: any;
  periodicClaims?: Array<any>;
  lotClaims?: Array<any>;
  onetimeClaims?: Array<any>;
  othersClaims?: Array<any>;
  number: number;
}

const tabs = [
  i18n.t('Unverified'),
  i18n.t('One-time'),
  i18n.t('Periodic'),
  i18n.t('Lot'),
];

const Claims = ({
  data,
  onTabsEnd,
  onChange,
  periodicClaims,
  lotClaims,
  onetimeClaims,
  othersClaims,
  number,
}: Props) => {
  const [firstLoad, setFirstLoad] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });

  const handleChange = (name, value) => {
    onChange('claimsData', { [name]: value });
  };

  const [tabIndex, setTabIndex] = useState(0);

  const handleBackButton = () => {
    setTabIndex(tabIndex - 1);
  };

  const handleNextButton = () => {
    setTabIndex(tabIndex + 1);
  };

  useEffect(() => {
    setTabIndex(0);
  }, [number]);

  useEffect(() => {
    if (othersClaims && !firstLoad[0]) {
      setFirstLoad({ ...firstLoad, 0: true });
      handleChange('othersClaims', othersClaims);
    }
  }, [othersClaims]);

  useEffect(() => {
    if (onetimeClaims && !firstLoad[1]) {
      setFirstLoad({ ...firstLoad, 1: true });
      handleChange('onetimeClaims', onetimeClaims);
    }
  }, [onetimeClaims]);

  useEffect(() => {
    if (periodicClaims && !firstLoad[2]) {
      setFirstLoad({ ...firstLoad, 2: true });
      handleChange('periodicClaims', periodicClaims);
    }
  }, [periodicClaims]);

  useEffect(() => {
    if (lotClaims && !firstLoad[3]) {
      setFirstLoad({ ...firstLoad, 3: true });
      handleChange('lotClaims', lotClaims);
    }
  }, [lotClaims]);

  const hasClaimsExpired = claims => {
    if (claims) {
      const haveVerification = claims.find(
        c => c.supportingVerifications.length > 0
      );
      if (haveVerification) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const findExpiredClaims = claims.filter(claim => {
          const expirationDate = new Date(
            claim.supportingVerifications[0].expirationDate
          );
          return expirationDate < today;
        });

        return findExpiredClaims.length > 0;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <ContentBox>
      <Tabs
        withBorderRadius={false}
        backgroundColor="#303030"
        selectedIndex={tabIndex}
        onSelect={tabIndex => setTabIndex(tabIndex)}
      >
        <ClaimsTabListContainer>
          <TabList>
            {tabs.map((tab, index) => {
              const claimsCount = {
                0: othersClaims?.length || 0,
                1: onetimeClaims?.length || 0,
                2: periodicClaims?.length || 0,
                3: lotClaims?.length || 0,
              }[index];

              const claimExpired = {
                0: hasClaimsExpired(othersClaims),
                1: false,
                2: hasClaimsExpired(periodicClaims),
                3: hasClaimsExpired(lotClaims),
              }[index];

              return (
                <Tab
                  key={index}
                  className={`react-tabs__tab ${
                    claimsCount !== 0 ? 'claimTabWithBubble' : ''
                  }
                  ${claimExpired ? 'expiredClaim' : ''}`}
                >
                  {tab}
                  {claimsCount !== 0 && (
                    <>
                      <TabBubble data-tip data-for={`claimtab_${index}`}>
                        {claimsCount}
                      </TabBubble>
                      <CustomTooltip id={`claimtab_${index}`}>
                        {claimExpired
                          ? i18n.t('One or more claims require attention.')
                          : i18n.t('All claims are up to date.')}
                      </CustomTooltip>
                    </>
                  )}
                </Tab>
              );
            })}
          </TabList>
        </ClaimsTabListContainer>
        <TabPanel>
          <Others
            data={data}
            onChange={handleChange}
            claims={othersClaims || []}
          />
        </TabPanel>
        <TabPanel>
          <OneTime
            data={data}
            onChange={handleChange}
            claims={onetimeClaims || []}
          />
        </TabPanel>
        <TabPanel>
          <Periodic
            data={data}
            onChange={handleChange}
            claims={periodicClaims || []}
          />
        </TabPanel>
        <TabPanel>
          <Lot data={data} onChange={handleChange} claims={lotClaims || []} />
        </TabPanel>

        <ActionButtonsContainer className="pr-4 pb-3">
          {tabIndex > 0 && (
            <Button
              text={i18n.t('Back')}
              type="dark"
              action={handleBackButton}
            />
          )}
          {tabIndex < tabs.length - 1 && (
            <Button
              text={i18n.t('Next')}
              type="dark"
              action={handleNextButton}
            />
          )}
          {tabIndex == tabs.length - 1 && (
            <Button
              text={i18n.t('Next')}
              type="dark"
              action={() => onTabsEnd()}
            />
          )}
        </ActionButtonsContainer>
      </Tabs>
    </ContentBox>
  );
};

export default withTranslation()(Claims as any) as any;
