import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeadContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  @media (max-width: 799px) {
    flex-direction: column;
  }
  flex-wrap: wrap;
`;

const FilterContainer = styled.div<{ show?: boolean; selects?: number }>`
  /* transition: all 0.5s linear; */
  display: flex;
  /* overflow: hidden; */
  position: relative;
  label {
    font-size: 12px;
  }
  ${prop =>
    prop.show
      ? `visibility: visible; opacity: 1; max-height: fit-content;`
      : `visibility: hidden; opacity: 0; max-height: 0;`}
  flex: 1;
  justify-content: space-between;

  ${({ selects }) =>
    selects && selects > 5
      ? `
  @media (max-width: 1300px) {
    flex-direction: column;
    select {
      width: 100% !important;
      margin: 5px 0;
    }
  }
  `
      : `
  @media (max-width: 799px) {
    flex-direction: column;
    select {
      width: 100% !important;
      margin: 5px 0;
    }
  }
  `}
`;

const FilterTitle = styled.div`
  font-family: 'Encode Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #a7bf2e;
  display: flex;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  > div {
    margin-bottom: 0;
  }
`;

const IconContainer = styled.div`
  background: #000000;
  border-radius: 4px 12px 8px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #303030;
  margin: 0 0 0 10px;
`;

const ControlContainer = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
  align-items: center;

  > div {
    margin-top: 0;
    margin-bottom: 0;
  }

  button {
    width: max-content;
  }
`;

const InputContainer = styled.div`
  &:first-child:not(.remember) {
    flex: 0;
  }
  flex: 1;
  margin-right: 20px;
  flex-direction: row;
  display: flex;
  align-items: center;
  &.select {
    > div {
      width: 100%;
    }
  }
  &.date {
    flex: 0;
    > div {
      width: 180px;
    }
    .datepicker-overlay {
      padding: 0 !important;
      left: -135px !important;

      ::after {
        right: 20px;
        left: auto;
      }
    }
  }
  label {
    display: flex;
    align-items: center;
  }
  @media (max-width: 799px) {
    margin-right: 0px;
    order: 3;
    &::first-child: {
      order: 1;
    }
  }
`;

const CloseContainer = styled.div`
  align-self: flex-end;
  margin: 0 0 0 30px;
  @media (max-width: 799px) {
    order: 2;
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
  }
`;

export {
  Container,
  HeadContainer,
  FilterContainer,
  FilterTitle,
  TitleContainer,
  ControlContainer,
  IconContainer,
  Title,
  InputContainer,
  CloseContainer,
};
