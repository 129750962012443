import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { RiInboxArchiveLine } from 'react-icons/ri';
import moment from 'moment';
import Toast from '../../../Lib/Utils/toast';
import { doGetJourneyTemplates } from '../../../Redux/Journey/journeyActions';
import { doGetFacilities } from '../../../Redux/Facility/facilityActions';
import { doGetBrands } from '../../../Redux/Catalog/catalogActions';

import {
  Table,
  HeaderFilter,
  Button,
  JourneyIcon,
  PencilIcon,
  InProgressButton,
  TrashIcon,
  PopUp,
  NavLink,
  Loading,
} from '../../../Components';
import { Container } from './styles';
import { isOrgAdmin } from '../../../Lib/Utils/auth';

interface RootState {
  facility: any;
  journey: any;
  catalog: any;
}

const mapState = (state: RootState) => ({
  facilities: state.facility.facilities,
  journeyTemplates: state.journey.journeyTemplates,
  loading: state.journey.loading,
  error: state.journey.error,
  brands: state.catalog.brands,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getJourneysTemplatesAction: (includeDrafts, includeArchived) =>
    dispatch(doGetJourneyTemplates(includeDrafts, includeArchived)),
  getFacilitiesAction: () => dispatch(doGetFacilities()),
  getBrandsAction: () => dispatch(doGetBrands()),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & { history?: any };

const JourneysList = (props: Props) => {
  const {
    history,
    journeyTemplates,
    facilities,
    loading,
    getJourneysTemplatesAction,
    getFacilitiesAction,
    getBrandsAction,
    brands,
    error,
  } = props;

  const [rows, setRows] = useState([] as any);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [filters, setFilters] = useState({});
  const [textFilter, setTextFilter] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showArchivePopup, setShowArchivePopup] = useState(false);
  const [selected, setSelected] = useState() as any;
  const [facilitiesFilterOptions, setFacilitiesFilterOptions] = useState([]);
  const [brandsFilterOptions, setBrandsFilterOptions] = useState([]);

  useEffect(() => {
    getJourneysTemplatesAction(true, true);
    getFacilitiesAction();
    getBrandsAction();
  }, []);

  useEffect(() => {
    const newFacilitiesFilterOptions = facilities.map(
      ({ _id, registeredName }) => ({
        value: _id,
        label: registeredName,
      })
    );
    setFacilitiesFilterOptions(newFacilitiesFilterOptions);
  }, [facilities]);

  useEffect(() => {
    const newBrandsFilterOptions = brands.map(({ _id, name }) => ({
      value: _id,
      label: name,
    }));
    setBrandsFilterOptions(newBrandsFilterOptions);
  }, [brands]);

  useEffect(() => {
    if (error) {
      Toast.error(error);
    }
  }, [error]);

  const headers = [
    { text: i18n.t('Journey name'), order: true },
    { text: i18n.t('Description'), order: true },
    { text: i18n.t('Created'), order: true },
    { text: i18n.t('Updated'), order: true },
    { text: '', order: false, key: 'isArchivable' },
    { text: '', order: false, key: 'inProgress' },
  ];

  useEffect(() => {
    const defaultRows = journeyTemplates
      .sort((a, b) => (moment(a.updatedAt).isBefore(b.updatedAt) ? 1 : -1))
      .map((row, index) => {
        const {
          _id,
          journeyName,
          description,
          createdAt,
          updatedAt,
          stepTemplates,
          isDraft,
          archived,
        } = row;

        return {
          _id,
          journeyName,
          description,
          createdAt: moment(createdAt).format('MM/DD/YYYY'),
          updatedAt: moment(updatedAt).format('MM/DD/YYYY'),
          archived,
          inProgress: isDraft ? (
            <InProgressButton
              action={evt => {
                evt.stopPropagation();
                history.push(`/journeys/wizard/${_id}/draft`);
              }}
            />
          ) : (
            false
          ),
          navData: {
            url: isDraft ? null : `/journeys/${_id}/view/`,
            data: { journeyName },
          },
          facilities:
            stepTemplates && stepTemplates.map(s => s.stepTemplate.facilityId),
        };
      })
      .map(row => Object.values(row));

    setRows(defaultRows);
  }, [journeyTemplates]);

  const pagesBtw = 2;

  const selects = [
    {
      type: 'checkbox',
      label: i18n.t('In progress'),
      key: 'inProgress',
    },
    {
      type: 'checkbox',
      label: i18n.t('Archived'),
      key: 'archived',
    },
    {
      placeholder: i18n.t('Search Facility'),
      options: facilitiesFilterOptions,
      key: 'facility',
    },
  ];

  const inputHandler = value => {
    setTextFilter(value);
  };

  const handleResetFilters = () => {
    setFilters({});
  };

  const applyFilters = () => {
    let newFilteredRows = rows;

    if (!filters['archived']) {
      newFilteredRows = newFilteredRows.filter(row => !row[5]);
    }

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        if (key === 'inProgress') {
          newFilteredRows = newFilteredRows.filter(row => !!row[6]);
        }

        if (key === 'archived') {
          newFilteredRows = newFilteredRows.filter(row => !!row[5]);
        }

        if (key === 'facility') {
          newFilteredRows = newFilteredRows.filter(row =>
            row[8].includes(filters[key])
          );
        }

        if (key === 'brand') {
          newFilteredRows = newFilteredRows.filter(
            row => row[6] == filters[key]
          );
        }
      }
    });

    if (textFilter && textFilter !== '') {
      newFilteredRows = newFilteredRows.filter(
        row =>
          row
            .join(' ')
            .toUpperCase()
            .search(textFilter.toUpperCase()) !== -1
      );
    }

    setFilteredRows(newFilteredRows);
  };

  const handleDeleteJourney = () => {
    if (selected) {
      const newRows = rows.filter(r => r[0] !== selected.journeyName);
      setRows(newRows);
      applyFilters();
    }
  };

  useEffect(() => applyFilters(), [filters, textFilter, rows]);

  return (
    <>
      <Container>
        <HeaderFilter
          title={i18n.t('Product Journeys')}
          titleIcon={<JourneyIcon />}
          searchPlaceholder={i18n.t('Search all columns')}
          actionButtonText={`${i18n.t('Journey Wizard')}`}
          setFilters={setFilters}
          inputHandler={inputHandler}
          actionButtonHandler={() => history.push('/journeys/wizard/draft')}
          selects={selects}
          resetFiltersHandler={handleResetFilters}
          name="journeys"
          useDropdown={true}
          actionButton={!!isOrgAdmin()}
        />
        <Table
          headers={headers}
          rows={filteredRows}
          pagination
          rowsPerPage={20}
          pagesBtw={pagesBtw}
          ignoreKeys={[0, 7, 8]}
          clickable
          navDataKey={7}
          editable={!!isOrgAdmin()}
          handleEdit={data =>
            data[6]
              ? history.push(`/journeys/wizard/${data[0]}/draft`)
              : history.push(`/journeys/wizard/${data[0]}/edit`)
          }
        />
        <PopUp
          cancelAction={() => setShowDeletePopup(false)}
          confirmAction={() => {
            setShowDeletePopup(false);
            handleDeleteJourney();
          }}
          show={showDeletePopup}
          cancelText={i18n.t('Cancel')}
          confirmText={i18n.t('Yes')}
          title={i18n.t('Delete journey?')}
          content={i18n.t('Deleting the journey cannot be undone. Proceed?')}
        />
        <PopUp
          cancelAction={() => setShowArchivePopup(false)}
          confirmAction={() => {
            setShowArchivePopup(false);
            handleDeleteJourney();
          }}
          show={showArchivePopup}
          cancelText={i18n.t('Cancel')}
          confirmText={i18n.t('Yes')}
          title={i18n.t('Archive journey?')}
          content={i18n.t('You cannot delete the journey because')}
        />
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(JourneysList) as any) as any
);
