import React, { useRef, useEffect, useState } from 'react';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps';
import { compose, withProps } from 'recompose';
import { InfoWindowStyled, Container } from './styles';
import { useHistory } from 'react-router';

interface Props {
  zoom?: number;
  center?: number[];
  markers?: Array<{
    lat: number;
    lng: number;
    infoText?: any;
    linkTo?: string;
    address?: string;
  }>;
  children?: any;
}

const Map = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyC-0EsmxYeXdRXikdSO51R3S4MR-d8my8A&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <Container />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({ markers, zoom, center }: Props) => {
  const [markersWihGeo, setMarkersWihGeo] = useState([] as any);

  const mapRef = useRef(null);

  const history = useHistory();

  const fitBounds = map => {
    const nWindow = window as any;
    const bounds = new nWindow.google.maps.LatLngBounds();
    markers &&
      markers.forEach(place => {
        bounds.extend({ lng: place.lng, lat: place.lat });
        return place;
      });
    map.fitBounds(bounds);
  };

  const loadHandler = map => {
    // fitBounds(map);
  };

  useEffect(() => {
    mapRef &&
      markersWihGeo &&
      markersWihGeo.length > 0 &&
      fitBounds(mapRef.current);
  }, [markersWihGeo]);

  useEffect(() => {
    loadHandler(mapRef.current);
  }, [mapRef]);

  const nWindow = window as any;
  const geocoder = new nWindow.google.maps.Geocoder();

  const markerWithGeocoding = marker => {
    return new Promise((resolve, reject) => {
      if (marker.lat && marker.lng) {
        resolve(marker);
      } else if (marker.address) {
        geocoder.geocode({ address: marker.address }, function(
          results,
          status
        ) {
          if (status === 'OK') {
            const newPosition = results[0].geometry.location;
            resolve({
              ...marker,
              lat: newPosition.lat(),
              lng: newPosition.lng(),
            });
          } else {
            resolve(marker);
          }
        });
      } else {
        resolve(marker);
      }
    });
  };

  const markersWithGeocoding = async () => {
    if (markers) {
      const newMarkers = await Promise.all(
        markers.map(marker => markerWithGeocoding(marker))
      );
      setMarkersWihGeo(newMarkers);
    }
  };

  useEffect(() => {
    markers && markers.length > 0 && markersWithGeocoding();
  }, [markers]);

  return (
    <div>
      <GoogleMap
        ref={mapRef}
        defaultZoom={zoom}
        defaultCenter={center}
        defaultOptions={{
          streetViewControl: false,
          scaleControl: true,
          mapTypeControl: false,
          panControl: false,
          zoomControl: true,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: true,
          scrollwheel: false,
          minZoom: 2,
        }}
      >
        {markersWihGeo &&
          markersWihGeo.map((marker, idx) => (
            <Marker
              icon={require('../../Rsc/Images/map_marker.svg')}
              key={idx}
              position={{
                lat: marker.lat,
                lng: marker.lng,
              }}
              clickable={!!marker.linkTo}
              onClick={() => marker.linkTo && history.push(marker.linkTo)}
            >
              {marker.infoText && (
                <InfoWindow>
                  <InfoWindowStyled
                    clickable={!!marker.linkTo}
                    onClick={() => marker.linkTo && history.push(marker.linkTo)}
                  >
                    {marker.infoText}
                  </InfoWindowStyled>
                </InfoWindow>
              )}
            </Marker>
          ))}
      </GoogleMap>
    </div>
  );
});

export default Map;
