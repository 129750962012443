import { jsPDF } from 'jspdf';

export const generateQrPdf = (
  qr: Array<{
    qrId: string;
    skuku: string;
    lotId: string;
    name: string;
  }>,
  lotIdSelected: boolean,
  skukuSelected: boolean,
  assetNameSelected: boolean
) => {
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'pt',
    format: [250, 250],
  });

  doc.setProperties({
    title: 'QRCodes',
    author: 'Discover UI',
    creator: 'SUKU',
  });

  qr.forEach(({ qrId, skuku, lotId, name }, index) => {
    const qrCanvaElement: any = document.querySelector(`#${qrId} > canvas`);
    const canvaUrl = qrCanvaElement?.toDataURL();

    const labels: Array<string> = [];

    if (skukuSelected) {
      labels.push(skuku);
    }

    if (lotIdSelected) {
      labels.push(lotId);
    }

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const qrSize = 200;

    const canvasWidth = qrSize;
    const canvasHeight = qrSize;

    const marginX = (pageWidth - canvasWidth) / 2;
    const marginY = (pageHeight - canvasHeight) / 2;

    doc.addImage(canvaUrl, 'PNG', marginX, marginY, canvasWidth, canvasHeight);
    doc.setFontSize(10);
    doc.text(labels.join('-'), pageWidth / 2, marginY + qrSize + 20, {
      align: 'center',
    });
    doc.setFontSize(9);
    if (assetNameSelected) {
      doc.text(name, pageWidth / 2, 20, {
        align: 'center',
      });
    }

    if (index !== qr.length - 1) {
      doc.addPage();
    }
  });

  doc.output('dataurlnewwindow');
};
