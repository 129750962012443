import React, { useState, useEffect } from 'react';
import i18n from 'i18next';

import {
  JourneyStepsContainer as Container,
  JourneyDetailsSubtitle as Subtitle,
  JourneyDetailsContainer as DetailsContainer,
  TabsContainer,
} from '../styles';

import { Label } from '../../../../Components';
import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  StepTabs,
  CustomTooltip,
  ContentBox,
} from '../../../../Components';
import JourneyStepsFacility from './JourneyStepsFacility';
import JourneyStepsInboundAssets from './JourneyStepsInboundAssets';
import JourneyStepsOutboundAssets from './JourneyStepsOutboundAssets';
import { StepTemplate } from '../types';

interface Props {
  onChange: Function;
  onRefresh: Function;
  stepTemplates?: Array<StepTemplate>;
  facilities?: Array<any>;
  claims?: Array<any>;
  assets?: Array<any>;
  assetsInventory?: Array<any>;
}

const JourneySteps = ({
  onChange,
  onRefresh,
  assetsInventory,
  ...data
}: Props) => {
  const { stepTemplates = [], facilities, claims, assets } = data;

  const [tabIndex, setTabIndex] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [activeStep, setActiveStep] = useState({
    facilityTypeIcon: 'QuestionMark',
  } as any);

  const isActiveFacility = activeStep.isActiveFacility;

  const maxStepNumber = Math.max(
    ...stepTemplates.map(o => {
      return o.number;
    })
  );

  const previousStep =
    activeStep &&
    stepTemplates &&
    stepTemplates.find(step => {
      if (activeStep.number === 0) {
        return null;
      } else {
        return step.number === activeStep.number - 1;
      }
    });

  useEffect(() => {
    handleChange();
    onRefresh('claim');
  }, []);

  useEffect(() => {
    const step = stepTemplates && stepTemplates.find(step => step.active);
    if (step) {
      setActiveStep(step);
    }

    if (stepTemplates) {
      const maxItemNumber = Math.max(...stepTemplates.map(i => i.number));
      const newTemplates = stepTemplates.map(step => {
        if (step.isParallel) {
          if (step.number === 0) {
            return { ...step, isParallel: false };
          } else {
            const previousStep = stepTemplates.find(
              prev => prev.number === step.number - 1
            );
            if (previousStep?.facilityType) {
              return {
                ...step,
                facilityType: previousStep?.facilityType,
                facilityTypeIcon: previousStep?.facilityTypeIcon,
              };
            } else {
              return { ...step, isParallel: false };
            }
          }
        } else {
          return step;
        }
      });

      const formattedSteps = [] as any;
      newTemplates.forEach(st => {
        const inboundAssetsReceived = getInboundAssetsReceived(
          st,
          formattedSteps
        );
        const outboundAssetsReceived = getOutboundAssetsReceived({
          ...st,
          inboundAssetsReceived,
        });
        const formatted = {
          ...st,
          inboundAssetsReceived,
          outboundAssetsReceived,
        };
        formattedSteps.push(formatted);
      });

      if (JSON.stringify(stepTemplates) !== JSON.stringify(formattedSteps))
        onChange('stepTemplates', formattedSteps);
    }
  }, [assets, stepTemplates]);

  const handleChange = (
    name?: string | null,
    value?: any,
    items?: Array<any>
  ) => {
    if (activeStep) {
      const templates = items || stepTemplates;
      const { number } = activeStep;

      const maxItemNumber = Math.max(...templates.map(i => i.number));

      const newTemplates = templates.map(step => {
        if (step.isParallel) {
          if (step.number === 0) {
            return { ...step, isParallel: false };
          } else if (step.number === -1) {
            const previousStep = templates.find(
              prev => prev.number === maxItemNumber
            );
            if (previousStep.facilityType) {
              return {
                ...step,
                facilityType: previousStep.facilityType,
                facilityTypeIcon: previousStep.facilityTypeIcon,
              };
            } else {
              return { ...step, isParallel: false };
            }
          } else {
            const previousStep = templates.find(
              prev => prev.number === step.number - 1
            );
            if (previousStep.facilityType) {
              return {
                ...step,
                facilityType: previousStep.facilityType,
                facilityTypeIcon: previousStep.facilityTypeIcon,
              };
            } else {
              return { ...step, isParallel: false };
            }
          }
        } else {
          return step;
        }
      });

      const formattedSteps = [] as any;
      newTemplates.forEach(st => {
        if (name && st.number === number) {
          const newKeyValue = { [name]: value };
          st = {
            ...st,
            ...newKeyValue,
          };
        }
        const inboundAssetsReceived = getInboundAssetsReceived(
          st,
          formattedSteps
        );
        const outboundAssetsReceived = getOutboundAssetsReceived({
          ...st,
          inboundAssetsReceived,
        });
        const formatted = {
          ...st,
          inboundAssetsReceived,
          outboundAssetsReceived,
        };
        formattedSteps.push(formatted);
      });
      onChange('stepTemplates', formattedSteps);
    }
  };

  const handleStepsChange = items => {
    setIsLastStep(items.some(x => x.number === -1 && x.active === true));

    const prevActive = stepTemplates && stepTemplates.find(step => step.active);
    const newActive = items.find(step => step.active);

    if (JSON.stringify(prevActive) !== JSON.stringify(newActive)) {
      handleChange(null, null, items);
    }

    if (prevActive?.number !== newActive?.number) {
      setTimeout(() => {
        setTabIndex(0);
      }, 50);
    }
  };

  const getInboundAssetsReceived = (
    step: any,
    stepsChain: Array<any>
  ): Array<any> => {
    if (!step || step.number === 0 || stepsChain.length === 0) {
      return [];
    }

    let previousStep;
    if (step.number === -1) {
      const maxItemNumber = Math.max(...stepsChain.map(i => i.number));
      previousStep = stepsChain.find(s => s.number === maxItemNumber);
    } else {
      previousStep = stepsChain.find(s => s.number === step.number - 1);
    }
    let received = [] as any;
    received = received.concat(
      (previousStep?.outboundAssetsInventory || []).filter(i => i.selected)
    );
    received = received.concat(
      (previousStep?.outboundAssetsReceived || []).filter(
        i => i.selected && !received.find(o => o._id === i._id)
      )
    );
    received = received.map(r => ({
      ...r,
      selected: !!step.inboundAssetsReceived.find(
        o => o.selected && o._id === r._id
      ),
    }));
    return received;
  };

  const getOutboundAssetsReceived = (step: any): Array<any> => {
    let received = [] as any;
    received = received.concat(
      (step.inboundAssetsInventory || []).filter(i => i.selected)
    );
    received = received.concat(
      (step.inboundAssetsReceived || []).filter(
        i => i.selected && !received.find(o => o._id === i._id)
      )
    );
    received = received.map(r => ({
      ...r,
      selected: !!step.outboundAssetsReceived.find(
        o => o.selected && o._id === r._id
      ),
    }));
    return received;
  };

  const handleClean = stepNummber => {
    if (stepTemplates) {
      const selectedStep = stepTemplates.find(
        ({ number }) => number === stepNummber
      );

      if (selectedStep) {
        selectedStep.facilityClaims = [];
        selectedStep.facilityId = '';
        selectedStep.description = '';
        selectedStep.isComplex = false;
        selectedStep.facilityType = '';
        selectedStep.facilityTypeIcon = 'QuestionMark';
        selectedStep.name = '';
        selectedStep.isActiveFacility = false;
        selectedStep.showInboundReceivedDate = false;
        selectedStep.showOutboundReceivedDate = false;
        selectedStep.noInboundAssets = false;
        selectedStep.noOutboundAssets = false;
        selectedStep.outboundAssetsReceived = [];
        selectedStep.inboundAssetsReceived = [];
        selectedStep.outboundAssetsInventory =
          selectedStep.outboundAssetsInventory?.map(outbound => ({
            ...outbound,
            selected: false,
          })) || [];
        selectedStep.inboundAssetsInventory =
          selectedStep.inboundAssetsInventory?.map(inbound => ({
            ...inbound,
            selected: false,
          })) || [];

        handleChange();
      }
    }
  };

  return (
    <DetailsContainer>
      <div className="col-12 mb-5">
        <Label>{i18n.t('Create a supply chain journey')}</Label>
        <Subtitle className="mb-3">
          {i18n.t(
            'Congratulations! You are now ready to create your end to end supply chain'
          )}
        </Subtitle>
        <Subtitle>
          {i18n.t(
            'Add as many steps as you need to represent your supply chain'
          )}
        </Subtitle>
      </div>
      <ContentBox>
        <Container>
          <StepTabs
            items={stepTemplates}
            assetsInventory={assetsInventory}
            handleStepsChange={handleStepsChange}
            handleClean={handleClean}
          />
          <div className="tabs-wrapper">
            <Tabs
              withBorderRadius={false}
              backgroundColor="#0F0F0F"
              selectedIndex={tabIndex}
              onSelect={tabIndex => setTabIndex(tabIndex)}
            >
              {activeStep && (
                <>
                  <TabList>
                    <Tab>{i18n.t('Facility')}</Tab>
                    {isActiveFacility ? (
                      <Tab>{i18n.t('Inbound Assets')}</Tab>
                    ) : (
                      <>
                        <Tab data-tip data-for="inbound" disabled>
                          {i18n.t('Inbound Assets')}
                        </Tab>
                        <CustomTooltip id="inbound" placement="bottom">
                          <span>
                            {i18n.t('This facility status is set to Static')}
                          </span>
                        </CustomTooltip>
                      </>
                    )}
                    {isActiveFacility ? (
                      <Tab>{i18n.t('Outbound Assets')}</Tab>
                    ) : (
                      <>
                        <Tab data-tip data-for="outbound" disabled>
                          {i18n.t('Outbound Assets')}
                        </Tab>
                        <CustomTooltip id="outbound" placement="bottom">
                          <span>
                            {i18n.t('This facility status is set to Static')}
                          </span>
                        </CustomTooltip>
                      </>
                    )}
                  </TabList>
                  <TabPanel>
                    <TabsContainer>
                      <JourneyStepsFacility
                        onChange={handleChange}
                        {...activeStep}
                        facilities={facilities}
                        claims={claims}
                        previousStep={previousStep}
                      />
                    </TabsContainer>
                  </TabPanel>

                  {isActiveFacility && (
                    <>
                      <TabPanel>
                        <TabsContainer>
                          <JourneyStepsInboundAssets
                            onChange={handleChange}
                            isFirstStep={activeStep?.number === 0}
                            {...activeStep}
                          />
                        </TabsContainer>
                      </TabPanel>
                      <TabPanel>
                        <TabsContainer>
                          <JourneyStepsOutboundAssets
                            isLastStep={isLastStep}
                            onChange={handleChange}
                            {...activeStep}
                          />
                        </TabsContainer>
                      </TabPanel>
                    </>
                  )}
                </>
              )}
            </Tabs>
          </div>
        </Container>
      </ContentBox>
    </DetailsContainer>
  );
};

export default JourneySteps;
