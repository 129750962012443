import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ObjectID } from 'bson';
import { useForm, FormContext } from 'react-hook-form';
import { Facility } from '../../../Lib/Utils/Validations';
import Toast from '../../../Lib/Utils/toast';
import {
  doSaveFacility,
  doGetFacility,
  resetCurrentFacility,
  doGetFacilities,
  doGetMembers,
} from '../../../Redux/Facility/facilityActions';
import { doGetOrganizations } from '../../../Redux/Organization/organizationActions';

import { doGetUsers } from '../../../Redux/User/userActions';
import {
  FacilityIcon,
  ContentBox,
  Title,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Button,
  Loading,
} from '../../../Components';

import { FacilityActionButtonsContainer } from './styles';

import FacilityDetails from './FacilityDetails';
import FacilityContact from './FacilityContact';
import FacilityUsers from './FacilityUsers';
import FacilityColor from './FacilityColor';
import FacilityMedia from './FacilityMedia';
import FacilityReviewSubmit from './FacilityReviewSubmit';
import { DataProps } from './types';
import { getCurrentOrganization } from '../../../Lib/Utils/auth';
import { ScannerApp } from '../../../Lib/Configs';

interface RootState {
  facility: any;
  user: any;
  organization: any;
}

interface InheritProps {
  showTitle?: boolean;
  onSave?: Function;
  facilityId?: string;
  draft?: boolean;
}

const mapState = (state: RootState) => ({
  facility: state.facility.currentFacility,
  submitSuccess: state.facility.submitSuccess,
  saveSuccess: state.facility.saveSuccess,
  facilityLoading: state.facility.loading,
  error: state.facility.error,
  users: state.user.users,
  usersLoading: state.user.loading,
  facilities: state.facility.facilities,
  members: state.facility.members,
  membersLoading: state.facility.loading,
  organizations: state.organization.organizations,
  organizationsLoading: state.organization.loading,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  saveFacilityAction: (payload, actionType, notifySuccess) =>
    dispatch(doSaveFacility(payload, actionType, notifySuccess)),
  getFacilityAction: (facilityId, actionType) =>
    dispatch(doGetFacility(facilityId, actionType)),
  resetFormAction: () => dispatch(resetCurrentFacility()),
  getUsersAction: () => dispatch(doGetUsers(false)),
  getFacilitiesAction: () => dispatch(doGetFacilities()),
  getMembersAction: organization => dispatch(doGetMembers(organization)),
  getOrganizationsAction: () => dispatch(doGetOrganizations()),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
  InheritProps & { history?: any; location?: any; match?: any };

const NewFacility = (props: Props) => {
  const {
    saveFacilityAction,
    getFacilityAction,
    resetFormAction,
    getUsersAction,
    history,
    match,
    location,
    submitSuccess,
    saveSuccess,
    onSave,
    error,
    facilityLoading,
    facility,
    showTitle = true,
    facilityId,
    draft,
    users,
    facilities,
    getFacilitiesAction,
    getMembersAction,
    members,
    getOrganizationsAction,
    organizations,
    organizationsLoading,
    usersLoading,
    membersLoading,
  } = props;

  const loading =
    facilityLoading || organizationsLoading || usersLoading || membersLoading;

  const entityId = match.params.facilityId || facilityId;
  const isDraft = draft != null ? draft : !!match.path.match('/draft');

  const currentOrganization = getCurrentOrganization();

  const organizationColor = ScannerApp.mainColor;

  const [data, setData] = useState({ registeredName: '' } as DataProps);

  const [tabIndex, setTabIndex] = useState(0);

  const formMethods = useForm({ mode: 'onBlur' });

  const tabs = [
    i18n.t('Overview'),
    i18n.t('Contact'),
    i18n.t('Users'),
    i18n.t('Media'),
    i18n.t('Color'),
    isDraft || !entityId ? i18n.t('Review & Submit') : i18n.t('Review & Save'),
  ];

  useEffect(() => {
    if (entityId) {
      getFacilityAction(entityId, isDraft ? 'get-draft' : 'get-entity');
    }
    getUsersAction();
    getFacilitiesAction();
    getOrganizationsAction();

    return () => {
      resetFormAction();
    };
  }, []);

  useEffect(() => {
    if (submitSuccess) {
      Toast.success(i18n.t('Your data has been successfully submitted.'));
      // If onSave exists then this component is controlled as child and should not redirect
      if (onSave) {
        history.push({
          search: '',
        });
        onSave(facility._id || entityId, true);
      } else {
        history.push(`/facilities`);
      }
    } else if (saveSuccess) {
      Toast.success(i18n.t('Your data has been successfully saved.'));
    } else if (error) {
      Toast.error(error);
    }
  }, [error, submitSuccess, saveSuccess]);

  useEffect(() => {
    let {
      _id,
      registeredName,
      description,
      claimsSummary,
      quote = {},
      url,
      ethAddress,
      email,
      address,
      location,
      phone,
      photos,
      videos,
      logo,
      primaryContact = {},
      users = [],
      organizationId,
      themes,
    } = facility;

    // const facilityAddress = address && address.length ? address[0] : {};
    const facilityAddress = address ? address : {};
    const {
      country,
      name,
      addressLineOne,
      addressLineTwo,
      city,
      state,
      zipCode,
      noAddress,
    } = facilityAddress;

    const contactPhone = phone && phone.length ? phone[0] : {};
    const { countryCode, number, type, ext, noPhone } = contactPhone;
    const {
      salutation: contactSalutation,
      firstName: contactFirstName,
      lastName: contactLastName,
      email: contactEmail = {},
      phone: facilityContactPhone = {},
    } = primaryContact;

    if (photos && photos.length) {
      photos = photos.map(p => ({ src: p }));
    }
    if (videos && videos.length) {
      videos = videos.map(v => ({ src: v }));
    }
    if (logo) {
      logo = { src: logo };
    }
    const facilityUsers = users.map(
      ({ _id, firstName, lastName, email, password, accessControl }) => ({
        title: `${firstName} ${lastName}`,
        data: {
          facilityUserId: _id,
          facilityUserEmail: email,
          facilityUserEmailConfirm: email,
          facilityUserLastName: lastName,
          facilityUserName: firstName,
          facilityUserPermission: accessControl?.find(
            entry =>
              entry.type === 'facility' && entry.resourceId === facility._id
          )?.permission,
          facilityUserPassword: password,
        },
      })
    );

    setData({
      _id,
      registeredName,
      description,
      noWebsite: !!(!url || url.length === 0 || url[0] === ''),
      quoteSource: quote.by,
      quote: quote.quote,
      website: url && url.length ? url[0] : '',
      ethAddress,
      email: email && email.length ? email[0].address : '',
      facilityCountryCode: countryCode,
      facilityPhone: number,
      facilityPhoneType: type,
      facilityPhoneExt: ext,
      country,
      zipCode,
      state: state || city,
      city,
      addressName: name,
      addressLineOne,
      addressLineTwo,
      longitude:
        location?.coordinates && location?.coordinates.length > 0
          ? location.coordinates[0]
          : undefined,
      latitude:
        location?.coordinates && location?.coordinates.length > 1
          ? location.coordinates[1]
          : undefined,
      photos,
      videos,
      logo,
      contactSalutation,
      contactFirstName,
      contactLastName,
      contactEmail: contactEmail.address,
      contactCountryCode: facilityContactPhone.countryCode,
      contactPhone: facilityContactPhone.number,
      contactPhoneType: facilityContactPhone.type,
      contactPhoneExt: facilityContactPhone.ext,
      facilityUsers,
      previousUsers: users,
      noAddress,
      noPhone: phone && phone.length ? phone[0].noPhone : false,
      noEmail: email && email.length ? email[0].noEmail : false,
      organizationId,
      themes,
    });
  }, [facility]);

  useEffect(() => {
    if (location) {
      const newIndex = getDefaultActiveTab();
      if (tabIndex !== newIndex) handleTabChange(newIndex);
    }
  }, [location, tabIndex]);

  useEffect(() => {
    if (members) {
      const facilityUsers = members.map(
        ({ _id, firstName, lastName, email, confirmEmail, password }) => ({
          title: `${firstName} ${lastName}`,
          data: {
            facilityUserId: _id,
            facilityUserEmail: email,
            facilityUserEmailConfirm: confirmEmail,
            facilityUserLastName: lastName,
            facilityUserName: firstName,
            facilityUserPermission: 'rw',
            facilityUserPassword: password,
          },
        })
      );
      setData({ ...data, facilityUsers });
    }
  }, [members]);

  const handleChange = (name: string, value: any) => {
    setData({ ...data, [name]: value });
  };

  const handleChangeMultiple = (obj: any) => {
    setData({ ...data, ...obj });
  };

  const getDefaultActiveTab = () => {
    const query = new URLSearchParams(location.search);
    const tabFromQuery = query.get('tab');
    const findTabIndex = tabs.findIndex(
      tab => tab.replace(/[^a-zA-Z0-9]/g, '') === tabFromQuery
    );
    return findTabIndex !== -1 ? findTabIndex : 0;
  };

  const handleTabChange = index => {
    const tabName = tabs[index].replace(/[^a-zA-Z0-9]/g, '');
    const query = new URLSearchParams(location.search);
    const stepFromQuery = query.get('step');
    const stepSearch = stepFromQuery ? `&step=${stepFromQuery}` : '';
    history.push({
      search: `?tab=${tabName}${stepSearch}`,
    });
    setTabIndex(index);
  };

  const handleBackButton = () => {
    saveFacility(false);
    handleTabChange(tabIndex - 1);
  };

  const handleNextButton = () => {
    saveFacility(false);
    handleTabChange(tabIndex + 1);
  };

  const getMappedData = () => {
    const {
      _id,
      website,
      quote,
      quoteSource,
      country,
      zipCode,
      state,
      city,
      addressLineOne,
      addressLineTwo,
      addressName,
      latitude,
      longitude,
      email,
      facilityCountryCode,
      facilityPhone,
      facilityPhoneExt,
      facilityPhoneType,
      contactSalutation,
      contactFirstName,
      contactLastName,
      contactEmail,
      contactCountryCode,
      contactPhone,
      contactPhoneType,
      contactPhoneExt,
      facilityUsers = [],
      noAddress,
      noPhone,
      noEmail,
      themes,
    } = data;

    const users = facilityUsers.map(
      ({
        data: {
          facilityUserId,
          facilityUserEmail,
          facilityUserEmailConfirm,
          facilityUserLastName,
          facilityUserName,
          facilityUserPassword,
          facilityUserPermission,
        },
      }) => ({
        _id: facilityUserId,
        firstName: facilityUserName,
        lastName: facilityUserLastName,
        email: facilityUserEmail,
        username: facilityUserEmail,
        confirmEmail: facilityUserEmailConfirm,
        password: facilityUserPassword,
        permission: facilityUserPermission,
      })
    );

    const mappedData = {
      ...data,
      _id: _id || entityId || new ObjectID().toString(),
      quote: { quote: quote, by: quoteSource },
      url: website ? [website] : [],
      address: {
        country: noAddress ? '' : country,
        zipCode: noAddress ? '' : zipCode,
        state: noAddress ? '' : state,
        city: noAddress ? '' : city,
        addressLineOne: noAddress ? '' : addressLineOne,
        addressLineTwo: noAddress ? '' : addressLineTwo,
        name: noAddress ? '' : addressName,
        additionalAddressInfo: '',
        noAddress,
      },
      location: {
        coordinates: [
          longitude ? Number(longitude) : null,
          latitude ? Number(latitude) : null,
        ],
      },
      email: [{ address: noEmail ? '' : email, verified: true, noEmail }],
      phone: [
        {
          countryCode: noPhone ? '' : facilityCountryCode,
          number: noPhone ? '' : facilityPhone,
          type: noPhone ? '' : facilityPhoneType,
          ext: noPhone ? '' : facilityPhoneExt,
          default: true,
          noPhone,
        },
      ],
      primaryContact: {
        salutation: contactSalutation,
        firstName: contactFirstName,
        lastName: contactLastName,
        email: { address: contactEmail },
        phone: {
          countryCode: contactCountryCode,
          number: contactPhone,
          ext: contactPhoneExt,
          type: contactPhoneType,
        },
      },
      users,
      themes,
    };

    return mappedData;
  };

  const validate = async () => {
    const { noWebsite = false, country, noAddress, noEmail, noPhone } = data;

    formMethods.register({ name: 'registeredName' }, Facility.name);
    formMethods.register({ name: 'description' }, Facility.longDescription);
    formMethods.register({ name: 'quote' }, Facility.quote);
    formMethods.register({ name: 'quoteSource' }, Facility.quoteSource);
    formMethods.register({ name: 'website' }, Facility.website(noWebsite));

    formMethods.register({ name: 'contactSalutation' }, Facility.salutation);
    formMethods.register({ name: 'contactFirstName' }, Facility.contactName);
    formMethods.register({ name: 'contactLastName' }, Facility.contactName);
    formMethods.register(
      { name: 'contactEmail' },
      noEmail ? Facility.general : Facility.email
    );
    formMethods.register(
      { name: 'contactCountryCode' },
      noPhone ? Facility.general : Facility.countryCode
    );
    formMethods.register(
      { name: 'contactPhone' },
      noPhone ? Facility.general : Facility.phone
    );
    formMethods.register(
      { name: 'contactPhoneExt' },
      noPhone ? Facility.general : Facility.phoneExt
    );
    formMethods.register(
      { name: 'contactPhoneType' },
      noPhone ? Facility.general : Facility.phoneType
    );

    formMethods.register(
      { name: 'addressName' },
      noAddress ? Facility.general : Facility.addressName
    );
    formMethods.register(
      { name: 'country' },
      noAddress ? Facility.general : Facility.country
    );
    formMethods.register(
      { name: 'state' },
      noAddress ? Facility.general : Facility.state(country)
    );
    formMethods.register(
      { name: 'city' },
      noAddress ? Facility.general : Facility.city
    );
    formMethods.register(
      { name: 'zipCode' },
      noAddress ? Facility.general : Facility.zipCode
    );
    formMethods.register(
      { name: 'addressLineOne' },
      noAddress ? Facility.general : Facility.address
    );
    formMethods.register(
      { name: 'longitude' },
      noAddress ? Facility.general : Facility.location
    );
    formMethods.register(
      { name: 'latitude' },
      noAddress ? Facility.general : Facility.location
    );
    formMethods.register(
      { name: 'email' },
      noAddress ? Facility.general : Facility.email
    );
    formMethods.register(
      { name: 'facilityCountryCode' },
      noAddress ? Facility.general : Facility.countryCode
    );
    formMethods.register(
      { name: 'facilityPhone' },
      noAddress ? Facility.general : Facility.phone
    );
    formMethods.register(
      { name: 'facilityPhoneExt' },
      noAddress ? Facility.general : Facility.phoneExt
    );
    formMethods.register(
      { name: 'facilityPhoneType' },
      noAddress ? Facility.general : Facility.phoneType
    );

    formMethods.register({ name: 'photos' }, Facility.photo());
    formMethods.register({ name: 'logo' }, Facility.logo);
    formMethods.register({ name: 'facilityUsers' }, Facility.users());

    formMethods.setValue(
      Object.keys(data).map(key => ({
        [key]: data[key],
      }))
    );

    const result = await formMethods.triggerValidation();

    if (!result) {
      setData({ ...data, validate: result });
    }

    return result;
  };

  const saveFacility = async (notifySuccess?: boolean) => {
    // Name must be entered at minimum
    if (!data.registeredName) {
      Toast.error(i18n.t('Must enter name to save data.'));
      handleTabChange(0);
      return;
    }

    if (!isDraft) {
      const result = await validate();
      if (!result) {
        Toast.error(i18n.t('There are items that require your attention.'));
        return;
      }
    }

    const actionType = isDraft
      ? data._id
        ? 'save-draft'
        : 'create-draft'
      : 'save-entity';
    const mappedData = getMappedData();
    saveFacilityAction(mappedData, actionType, notifySuccess);
    onSave && onSave(mappedData._id);
  };

  const submitFacility = async () => {
    const result = await validate();
    if (result) {
      const mappedData = getMappedData();
      const actionType =
        isDraft || !entityId ? 'submit-create-entity' : 'submit-save-entity';
      saveFacilityAction(mappedData, actionType, false);
    } else {
      Toast.error(i18n.t('There are items that require your attention.'));
    }
  };

  const copyFacility = newFacility => {
    let { photos, videos, logo } = newFacility;
    const {
      _id,
      users = [],
      address,
      phone,
      primaryContact = {},
      organizationId,
      themes,
    } = newFacility;

    getMembersAction(_id);

    const facilityAddress = address ? address : {};
    const {
      name,
      country,
      addressLineOne,
      addressLineTwo,
      city,
      state,
      zipCode,
      noAddress,
    } = facilityAddress;

    const contactPhone = phone && phone.length ? phone[0] : {};
    const { countryCode, number, type, ext } = contactPhone;
    const {
      salutation: contactSalutation,
      firstName: contactFirstName,
      lastName: contactLastName,
      email: contactEmail = {},
      phone: facilityContactPhone = {},
    } = primaryContact;

    if (photos && photos.length) {
      photos = photos.map(p => ({ src: p }));
    }
    if (videos && videos.length) {
      videos = videos.map(v => ({ src: v }));
    }
    if (logo && logo.length) {
      logo = { src: logo };
    }
    const facilityUsers = users.map(
      ({ id, firstName, lastName, email, confirmEmail, password }) => ({
        title: `${firstName} ${lastName}`,
        data: {
          facilityUserId: id,
          facilityUserEmail: email,
          facilityUserEmailConfirm: confirmEmail,
          facilityUserLastName: lastName,
          facilityUserName: firstName,
          facilityUserPermission: 'rw',
          facilityUserPassword: password,
        },
      })
    );

    setData({
      registeredName: '',
      description: newFacility.description,
      noWebsite: !!(
        !newFacility.url ||
        newFacility.url.length === 0 ||
        newFacility.url[0] === ''
      ),
      quoteSource: newFacility.quote?.by,
      quote: newFacility.quote?.quote,
      website:
        newFacility.url && newFacility.url.length ? newFacility.url[0] : '',
      ethAddress: newFacility.ethAddress,
      email:
        newFacility.email && newFacility.email.length
          ? newFacility.email[0].address
          : '',
      facilityCountryCode: countryCode,
      facilityPhone: number,
      facilityPhoneType: type,
      facilityPhoneExt: ext,
      country: country,
      zipCode: zipCode,
      state: state || city,
      city: city,
      addressName: name,
      addressLineOne: addressLineOne,
      addressLineTwo: addressLineTwo,
      longitude:
        newFacility.location?.coordinates &&
        newFacility.location?.coordinates.length > 0
          ? newFacility.location.coordinates[0]
          : undefined,
      latitude:
        newFacility.location?.coordinates &&
        newFacility.location?.coordinates.length > 1
          ? newFacility.location.coordinates[1]
          : undefined,
      photos,
      videos,
      logo,
      contactSalutation,
      contactFirstName,
      contactLastName,
      contactEmail: contactEmail.address,
      contactCountryCode: facilityContactPhone.countryCode,
      contactPhone: facilityContactPhone.number,
      contactPhoneType: facilityContactPhone.type,
      contactPhoneExt: facilityContactPhone.ext,
      facilityUsers,
      previousUsers: users,
      noAddress,
      organizationId,
      themes,
    });
  };

  return (
    <>
      {showTitle && (
        <Title
          title={i18n.t('New Facility')}
          subtitle={data.registeredName}
          icon={<FacilityIcon />}
        />
      )}
      <ContentBox>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={handleTabChange}
          initialSelectedIndex={getDefaultActiveTab()}
        >
          <TabList>
            {tabs.map((tab, index) => (
              <Tab key={index}>{tab}</Tab>
            ))}

            <FacilityActionButtonsContainer>
              {tabIndex > 0 && (
                <Button
                  text={i18n.t('Back')}
                  type="secondary"
                  action={handleBackButton}
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  text={i18n.t('Save')}
                  type="secondary"
                  action={() => saveFacility(true)}
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  action={handleNextButton}
                  text={i18n.t('Save & Next')}
                  type="secondary"
                />
              )}
              {tabIndex === tabs.length - 1 && (
                <Button
                  text={
                    isDraft || !entityId ? i18n.t('Submit') : i18n.t('Save')
                  }
                  type="secondary"
                  action={() => {
                    submitFacility();
                  }}
                />
              )}
            </FacilityActionButtonsContainer>
          </TabList>

          <FormContext {...formMethods}>
            <TabPanel>
              <FacilityDetails
                onChange={(name, value) => handleChange(name, value)}
                onChangeMultiple={obj => handleChangeMultiple(obj)}
                formErrors={formMethods.errors}
                facilities={facilities}
                copyFacility={copyFacility}
                {...data}
              />
            </TabPanel>
            <TabPanel>
              <FacilityContact
                onChange={(name, value) => handleChange(name, value)}
                formErrors={formMethods.errors}
                {...data}
              />
            </TabPanel>
            <TabPanel>
              <FacilityUsers
                onChange={(name, value) => handleChange(name, value)}
                onChangeMultiple={obj => handleChangeMultiple(obj)}
                {...data}
                users={users}
              />
            </TabPanel>
            <TabPanel>
              <FacilityMedia
                onChange={(name, value) => handleChange(name, value)}
                {...data}
              />
            </TabPanel>
            <TabPanel>
              <FacilityColor
                onChange={(name, value) => handleChange(name, value)}
                {...data}
                organizationColor={organizationColor}
              />
            </TabPanel>
            <TabPanel>
              <FacilityReviewSubmit
                onChange={(name, value) => handleChange(name, value)}
                {...data}
                formMethods={formMethods}
                isDraft={isDraft}
                entityId={entityId}
                embedded={!!onSave}
              />
            </TabPanel>
          </FormContext>
        </Tabs>
      </ContentBox>
      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(NewFacility) as any) as any
) as any;
