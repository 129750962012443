import React, { useState, useEffect } from 'react';
import { TableContainer, TableStyled } from './styles';
import { Button } from '../../../../Components';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

type CollapsedRow = Array<{
  index: number;
  collapsed: boolean;
  subHeader: boolean | undefined;
}>;

export type RowsType = Array<{
  values: Array<{
    value: any;
    colspan?: number;
    infoTitle?: boolean;
  }>;
  subHeader?: boolean;
  colspan?: number;
  collapsible?: boolean;
}>;

interface Props {
  headers: Array<{
    text: string;
    colspan?: number;
  }>;
  rows: RowsType;
}

const Table = (props: Props) => {
  const { headers, rows } = props;

  const [collapsed, setcollapsed] = useState([] as CollapsedRow);

  useEffect(() => {
    if (rows.length) {
      const defaultCollapsed = rows.map((row, index) => ({
        index,
        collapsed: false,
        subHeader: row.subHeader,
      }));
      setcollapsed(defaultCollapsed);
    }
  }, [rows]);

  const findCollapsed = index => {
    return collapsed.find(row => row.index === index);
  };

  const hiddenCollapsed = index => {
    if (collapsed.length) {
      const collapsedRow = findCollapsed(index);
      if (collapsedRow?.subHeader) {
        return false;
      } else {
        const subHeader = collapsed
          .sort((a, b) => a.index - b.index)
          .reverse()
          .filter(row => row.index < index)
          .find(({ subHeader }) => subHeader);

        return subHeader ? subHeader.collapsed : false;
      }
    }
  };

  const handleCollapse = index => {
    if (collapsed.length) {
      const newCollapsed = [...collapsed].map(row => {
        if (row.index === index) {
          return {
            ...row,
            collapsed: !row.collapsed,
          };
        } else {
          return row;
        }
      });
      setcollapsed(newCollapsed);
    }
  };

  return (
    <TableContainer>
      <TableStyled>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th colSpan={header.colspan} key={`${index}${header.text}`}>
                {header.text}
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 &&
            rows.map((row, index) => {
              return (
                <tr
                  key={`rowHeader${index}`}
                  className={`${row.subHeader ? 'subHeader' : 'normalRow'} ${
                    hiddenCollapsed(index) ? 'd-none' : ''
                  }`}
                >
                  {row.values.map((rowValue, rowValueIndex) => (
                    <td
                      colSpan={rowValue.colspan}
                      key={`rowValue${rowValueIndex}`}
                      className={rowValue.infoTitle ? 'infoTitle' : ''}
                    >
                      {rowValue.value}
                    </td>
                  ))}
                  {row.collapsible &&
                  findCollapsed(index)?.collapsed !== undefined ? (
                    <td>
                      <Button
                        width={24}
                        height={24}
                        icon={
                          !findCollapsed(index)?.collapsed ? (
                            <MdKeyboardArrowUp size={24} color="000" />
                          ) : (
                            <MdKeyboardArrowDown size={24} color="000" />
                          )
                        }
                        type="roundWhite"
                        action={() => handleCollapse(index)}
                      />
                    </td>
                  ) : (
                    <td></td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </TableStyled>
    </TableContainer>
  );
};

export default Table;
