import { EFacilityActionTypes } from './facilityActionsTypes';

const initialState = {
  facilities: [],
  currentFacility: {},
  loading: false,
  submitSuccess: false,
  saveSuccess: false,
  deleteSuccess: false,
  members: null,
};

interface State {
  facilities: any;
  currentFacility: any;
  loading: boolean;
  error?: string;
  submitSuccess: boolean;
  saveSuccess: boolean;
  deleteSuccess?: boolean;
  members: Array<any> | null;
}

interface FacilityAction extends State {
  type: EFacilityActionTypes;
  payload: any;
  error?: any;
}

type TFacilityReducerActions = FacilityAction;

export default function(
  state: State = initialState,
  action: TFacilityReducerActions
) {
  switch (action.type) {
    case EFacilityActionTypes.GET_FACILITIES:
      return { ...state, loading: true, error: null, deleteSuccess: false };
    case EFacilityActionTypes.GET_FACILITIES_SUCCESS:
      return {
        ...state,
        facilities: action.payload.facilities,
        loading: false,
      };
    case EFacilityActionTypes.GET_FACILITIES_ERROR:
      return { ...state, error: action.error, loading: false };

    case EFacilityActionTypes.SAVE_FACILIY:
      return {
        ...state,
        loading: true,
        submitSuccess: false,
        saveSuccess: false,
      };
    case EFacilityActionTypes.SAVE_FACILIY_SUCCESS: {
      const isDraft = action.payload.isDraft;
      let stateProps = {};
      if (isDraft) {
        stateProps = {
          submitSuccess: false,
          currentFacility: action.payload.facility,
        };
      } else {
        stateProps = {
          submitSuccess: !!action.payload.submitted,
          currentFacility: action.payload.facility,
        };
      }
      return {
        ...state,
        loading: false,
        error: null,
        saveSuccess: !!action.payload.notifySuccess,
        ...stateProps,
      };
    }
    case EFacilityActionTypes.SAVE_FACILIY_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        submitSuccess: false,
        saveSuccess: false,
      };

    case EFacilityActionTypes.GET_FACILITY:
      return { ...state, loading: true, members: null };
    case EFacilityActionTypes.GET_FACILITY_SUCCESS:
      return {
        ...state,
        currentFacility: action.payload.facility,
        loading: false,
      };
    case EFacilityActionTypes.GET_FACILITY_ERROR:
      return { ...state, error: action.payload.message, loading: false };

    case EFacilityActionTypes.RESET_CURRENT_FACILITY:
      return {
        ...state,
        currentFacility: {},
        submitSuccess: false,
        saveSuccess: false,
      };

    case EFacilityActionTypes.DELETE_FACILITY:
      return { ...state, loading: true, error: false };
    case EFacilityActionTypes.DELETE_FACILITY_SUCCESS:
      return { ...state, deleteSuccess: true, loading: false, error: false };
    case EFacilityActionTypes.DELETE_FACILITY_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        deleteSuccess: false,
      };

    case EFacilityActionTypes.GET_MEMBERS:
      return { ...state, loading: true, members: null };
    case EFacilityActionTypes.GET_MEMBERS_SUCCESS:
      return {
        ...state,
        members: action.payload.members,
        loading: false,
      };
    case EFacilityActionTypes.GET_MEMBERS_ERROR:
      return { ...state, error: action.payload.message, loading: false };

    default:
      return state;
  }
}
