import i18n from 'i18next';

const name = {
  required: { value: true, message: i18n.t('Required field') },
  maxLength: { value: 50, message: i18n.t('Max 50 characters') },
};

const type = {
  maxLength: { value: 40, message: i18n.t('Max 40 characters') },
  required: { value: true, message: i18n.t('This field is required') },
  pattern: {
    value: /^[a-zA-Z0-9-/ $.\/(),&]+$/,
    message: i18n.t(
      `Only alpha numeric characters and the following special characters are allowed: dash (-), slash (/), ampersand (&), comma (,), parenthesis ((, )), and period (.)`,
      { keySeparator: '>', nsSeparator: '|' }
    ),
  },
};

const shortDescription = {
  required: { value: true, message: i18n.t('This field is required') },
  maxLength: { value: 75, message: i18n.t('Max 75 characters') },
};

const longDescription = {
  required: { value: true, message: i18n.t('This field is required') },
  maxLength: { value: 750, message: i18n.t('Max 750 characters') },
};

const quote = {
  maxLength: { value: 150, message: i18n.t('Max 150 characters') },
};

const quoteSource = {
  maxLength: { value: 50, message: i18n.t('Max 50 characters') },
};

const website = noWebsite => {
  return {
    validate: {
      requiredField: value => {
        return noWebsite
          ? undefined
          : value === undefined || value === null || value.length === 0
          ? i18n.t('This field is required')
          : undefined;
      },
      webMaxLength: value => {
        return noWebsite
          ? undefined
          : value.length > 500
          ? i18n.t('Max 500 characters')
          : undefined;
      },
      webPattern: value => {
        return noWebsite
          ? undefined
          : !value.match(
              /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i
            )
          ? i18n.t('Enter a valid website')
          : undefined;
      },
    },
  };
};

const salutation = {};

const contactName = {
  required: { value: true, message: i18n.t('This field is required') },
  maxLength: { value: 75, message: i18n.t('Max 75 characters') },
};

const email = {
  required: { value: true, message: i18n.t('This field is required') },
  pattern: {
    value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
    message: i18n.t('Enter a valid email'),
  },
};

const phone = {
  required: { value: true, message: i18n.t('This field is required') },
  pattern: {
    value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i,
    message: i18n.t('Enter a valid phone number'),
  },
};

const countryCode = {
  required: { value: true, message: i18n.t('Required') },
  maxLength: { value: 10, message: i18n.t('Max 10 characters') },
  pattern: {
    value: /^[+\d](?:\d*)?$/i,
    message: i18n.t('Only + and numbers allowed'),
  },
};

const phoneExt = {
  maxLength: { value: 10, message: i18n.t('Max 10 characters') },
};

const phoneType = {
  required: { value: true, message: i18n.t('Required') },
};

const addressName = {
  maxLength: { value: 75, message: i18n.t('Max 75 characters') },
};

const country = {
  required: { value: true, message: i18n.t('This field is required') },
};

const state = country => ({
  validate: {
    countrySelected: state => {
      return country && !state ? i18n.t('This field is required') : undefined;
    },
  },
});

const city = {
  required: { value: true, message: i18n.t('This field is required') },
  maxLength: { value: 50, message: i18n.t('Max 50 characters') },
};

const zipCode = {
  maxLength: { value: 10, message: i18n.t('Max 10 characters') },
};

const address = {
  required: { value: true, message: i18n.t('This field is required') },
  maxLength: { value: 75, message: i18n.t('Max 75 characters') },
};

const location = {
  maxLength: { value: 25, message: i18n.t('Max 25 characters') },
};

const password = {
  required: { value: true, message: i18n.t('This field is required') },
  maxLength: { value: 50, message: i18n.t('Max 50 characters') },
  pattern: {
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{4,}$/i,
    message: i18n.t(
      'Password must contain at least one upper case letter, a number and an special character'
    ),
  },
};

const photo = () => {
  return {
    validate: {
      photoLength: value => {
        return value === undefined || value === null || value.length === 0
          ? i18n.t('Required field')
          : undefined;
      },
    },
  };
};

const logo = {
  required: { value: true, message: i18n.t('A logo is required') },
};

const claims = noClaims => {
  return {
    validate: {
      claimsLength: value => {
        return noClaims
          ? undefined
          : value === undefined || value === null || value.length === 0
          ? i18n.t('Required field')
          : undefined;
      },
    },
  };
};

const users = () => {
  return {};
};

const general = { validate: {} };

export default {
  name,
  type,
  shortDescription,
  longDescription,
  quote,
  quoteSource,
  website,
  salutation,
  contactName,
  email,
  phone,
  countryCode,
  phoneExt,
  phoneType,
  addressName,
  country,
  state,
  city,
  zipCode,
  address,
  location,
  password,
  photo,
  claims,
  users,
  general,
  logo,
};
