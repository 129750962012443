import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import FacilitiesList from '../../Facility/List';
import NewFacility from '../../Facility/New';
import { Loading, Label } from '../../../Components';
import {
  JourneyDetailsSubtitle as Subtitle,
  JourneyDetailsContainer as Container,
} from './styles';

interface Props {
  onChange: Function;
  onRefresh: (entityName: string) => void;
  createMode?: boolean;
  editCallback?: Function;
  setCreateMode?: Function;
  facilities?: Array<any>;
  refFacilities?: Array<string>;
  currentFacilityId?: string;
}

const FacilityStep = ({
  onChange,
  onRefresh,
  createMode,
  currentFacilityId,
  facilities,
  refFacilities = [],
  setCreateMode = () => {},
}: Props) => {
  const [facilityId, setFacilityId] = useState(currentFacilityId);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (createMode) {
      setFacilityId(undefined);
      setLoading(true);
      setTimeout(() => setLoading(false), 500);
    }
  }, [createMode]);

  const handleSave = (facilityId, submit) => {
    if (facilityId && !refFacilities.includes(facilityId)) {
      onChange('refFacilities', [...refFacilities, facilityId], true);
    }
    onRefresh('facility');

    if (submit) {
      setCreateMode(false);
      setEditMode(false);
    }
  };

  return (
    <Container>
      <div className="col-12 mb-5">
        <Label>{i18n.t('Create facilities')}</Label>
        <Subtitle className="mb-3">
          {i18n.t('Here you can create')} <b>{i18n.t('new')}</b>{' '}
          {i18n.t('facilities that will be part of this supply chain.')}
        </Subtitle>
        <Subtitle>
          {i18n.t(
            'If a facility already exists (e.g., it was created as part of another journey or it was created separately under the'
          )}{' '}
          <b>{i18n.t('Facilities')}</b>{' '}
          {i18n.t('tab), then youll be able to add it to this journey in')}{' '}
          <b>{i18n.t('Step')} 5</b>.
        </Subtitle>
        <Subtitle>
          {i18n.t(
            'Once this journey is submitted in Step 6, any facility not mapped to a journey'
          )}
        </Subtitle>
      </div>
      {loading ? (
        <Loading top={300} />
      ) : createMode || editMode ? (
        <NewFacility
          showTitle={false}
          onSave={handleSave}
          facilityId={facilityId}
          draft={
            !facilityId ||
            !!facilities?.find(f => f._id === facilityId && f.isDraft)
          }
        />
      ) : (
        <FacilitiesList
          showHeader={false}
          parentFacilities={facilities}
          editCallback={facilityId => {
            setFacilityId(facilityId);
            setEditMode(true);
          }}
        />
      )}
    </Container>
  );
};

export default FacilityStep as any;
