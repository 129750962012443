import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';

import { Organization } from '../../../Lib/Utils/Validations';
import { Container, FormContainer, FormSubHeader } from './styles';

import { Label, FormInput, InfoIcon, CustomTooltip } from '../../../Components';

interface Props {
  onChange: Function;
  id?: string;
  name?: string;
  description?: string;
  formErrors?: any;
  validate?: boolean;
  isDraft?: boolean;
}

const OrganizationDetails = ({
  onChange,
  formErrors,
  isDraft,
  ...data
}: Props) => {
  const { name, description, validate } = data;

  const { errors, control, setValue, triggerValidation } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    setValue([{ description }, { name }]);
  }, [name, description]);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      triggerValidation();
    }
  }, [formErrors, validate]);

  return (
    <Container>
      <FormSubHeader>
        <div className="mr-2">{i18n.t('Add an organization')}</div>
        <div data-tip data-for="addorganization">
          <InfoIcon />
        </div>
        <CustomTooltip id="addorganization" placement="right">
          <span>
            {i18n.t(
              'An organization is a client. All users in the system are assigned to an organization.'
            )}
          </span>
        </CustomTooltip>
      </FormSubHeader>
      <div>
        <FormContainer id="OrganizationForm">
          <div className="row label-spacing">
            <div className="col-5">
              <Label>{i18n.t('Name')}</Label>
              <Controller
                as={
                  <FormInput
                    required
                    placeholder={i18n.t('The organizaiton Name')}
                    maxLength={50}
                  />
                }
                name="name"
                value={name}
                control={control}
                onChange={([evt]) => {
                  onChange(evt.target.name, evt.target.value);
                  return evt.target.value;
                }}
                rules={Organization.name}
                defaultValue={name}
              />
              {errors.name && (
                <span className="inlineErrorMessage">
                  {errors.name.message}.
                </span>
              )}
            </div>

            <div className="col-5">
              <Label>{i18n.t('Description')}</Label>
              <Controller
                as={
                  <FormInput
                    rows={3}
                    type="textarea"
                    required
                    placeholder={i18n.t('Enter organization description')}
                    maxLength={500}
                  />
                }
                name="description"
                value={description}
                control={control}
                onChange={([evt]) => {
                  onChange(evt.target.name, evt.target.value);
                  return evt.target.value;
                }}
                rules={Organization.description}
                defaultValue={description}
              />
              {errors.description && (
                <span className="inlineErrorMessage">
                  {errors.description.message}.
                </span>
              )}
            </div>
          </div>
        </FormContainer>
      </div>
    </Container>
  );
};

export default OrganizationDetails;
