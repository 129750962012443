import styled from 'styled-components';

const Container = styled.div``;

const InputContainer = styled.div`
  position: relative;
  .clearButton {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
  }
`;

const ColorPickerContainer = styled.div`
  position: relative;
  > div {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
  }
`;

const Cover = styled.div`
  position: fixed !important;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
`;

export { Container, InputContainer, ColorPickerContainer, Cover };
