import { authAxiosCall } from '../axiosCall';
import { AxiosResponse } from 'axios';

import { SaveBMarketplace, SaveBMarketplaceActionType } from './types';

const modelName = 'marketplaces';

export const getMarketplaces = async payload =>
  authAxiosCall(`/${modelName}`, {
    method: 'GET',
    query: payload,
  });

export const getMarketplace = async marketplaceId =>
  authAxiosCall(`/${modelName}/${marketplaceId}`, {
    method: 'GET',
  });

export const deleteMarketplace = async marketplaceId => {
  return authAxiosCall(`/${modelName}/${marketplaceId}`, {
    method: 'DELETE',
  });
};

export const saveMarketplace = async (
  {
    _id, // the draft _id
    id,
    name,
    description,
    themes,
    organizations,
  }: SaveBMarketplace,
  actionType: SaveBMarketplaceActionType
): Promise<AxiosResponse<any> | undefined> => {
  let path = '';
  let method = '';
  const source = 'api';
  try {
    switch (actionType) {
      case 'create-draft':
        path = `/drafts/collections/${modelName}`;
        method = 'POST';
        break;
      case 'save-draft':
        path = `/drafts/${_id}`;
        method = 'PUT';
        break;
      case 'submit-create-entity':
        path = `/${modelName}`;
        method = 'POST';
        break;
      case 'submit-save-entity':
        path = `/${modelName}/${_id}`;
        method = 'PUT';
        break;
    }
    const response = await authAxiosCall(path, {
      method,
      source,
      body: JSON.stringify({
        name,
        description,
        themes,
        organizations,
      }),
    });
    return response;
  } catch (e) {
    if (e.message === `${modelName} already exists`) {
    } else {
      throw e;
    }
  }
};
