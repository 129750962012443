import React, { Dispatch, useState, useEffect } from 'react';
import { Dropdown, Label } from '..';
import i18n from 'i18next';
import { connect, ConnectedProps } from 'react-redux';
import { doGetOrganizations } from '../../Redux/Organization/organizationActions';
import {
  isSuperAdmin,
  getOrganizations,
  getOrganization,
  changeOrganization,
} from '../../Lib/Utils/auth';

interface RootState {
  organization: any;
}

const mapState = (state: RootState) => ({
  organizations: state.organization.organizations,
  organizationsLoading: state.organization.loading,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getOrganizationsAction: () => dispatch(doGetOrganizations()),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
  handleOrganizationChange?: Function;
  modelType: string;
  defaultValue?: string;
  allowAll?: boolean;
};

const OrgSelector = ({
  organizations,
  getOrganizationsAction,
  handleOrganizationChange,
  modelType,
  defaultValue,
  allowAll = true,
}: Props) => {
  const userOrgs = getOrganizations() || [];
  const showOrganizationsDropdown = isSuperAdmin() || userOrgs?.length > 1;

  useEffect(() => {
    getOrganizationsAction();
  }, []);

  const handleChange = selected => {
    handleOrganizationChange && handleOrganizationChange(selected);
  };

  const defaultOrganization = defaultValue || getOrganization()?._id || 'all';

  const options = !isSuperAdmin()
    ? organizations
        .filter(({ _id }) => {
          return userOrgs.find(({ resourceId }) => resourceId === _id);
        })
        .map(({ _id, name }) => ({ value: _id, label: name }))
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        })
    : organizations
        .map(({ _id, name }) => ({ value: _id, label: name }))
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });

  if (allowAll) {
    options.unshift({
      value: 'all',
      label: i18n.t('All'),
    });
  }

  return (
    showOrganizationsDropdown && (
      <div className="col-12">
        <Label>
          {`${i18n.t('This')} ${modelType.toLowerCase()} ${i18n.t(
            'will be created under the following organization'
          )}:`}
        </Label>
        <Dropdown
          placeholder={i18n.t('Select an organization')}
          options={options}
          action={handleChange}
          defaultOption={defaultOrganization}
        />
      </div>
    )
  );
};

export default connector(OrgSelector) as any;
