import * as organizationService from '../../Services/Api/organizationService';
import * as brandService from '../../Services/Api/brandService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { EOrganizationActionTypes } from './organizationActionsTypes';

const getOrganizations = createAction(
  EOrganizationActionTypes.GET_ORGANIZATIONS
);
const getOrganizationsSuccess = createAction(
  EOrganizationActionTypes.GET_ORGANIZATIONS_SUCCESS,
  (organizations: any) => ({ organizations })
);
const getOrganizationsError = createAction(
  EOrganizationActionTypes.GET_ORGANIZATIONS_ERROR,
  (message: string) => ({ message })
);

const getOrganization = createAction(EOrganizationActionTypes.GET_ORGANIZATION);
const getOrganizationSuccess = createAction(
  EOrganizationActionTypes.GET_ORGANIZATION_SUCCESS,
  (organization: any) => ({ organization })
);
const getOrganizationError = createAction(
  EOrganizationActionTypes.GET_ORGANIZATION_ERROR,
  (message: string) => ({ message })
);

const getOrganizationUsers = createAction(
  EOrganizationActionTypes.GET_ORGANIZATION_USERS
);
const getOrganizationUsersSuccess = createAction(
  EOrganizationActionTypes.GET_ORGANIZATION_USERS_SUCCESS,
  (users: any) => ({ users })
);
const getOrganizationUsersError = createAction(
  EOrganizationActionTypes.GET_ORGANIZATION_USERS_ERROR,
  (message: string) => ({ message })
);

const saveOrganization = createAction(
  EOrganizationActionTypes.SAVE_ORGANIZATION
);
const saveOrganizationSuccess = createAction(
  EOrganizationActionTypes.SAVE_ORGANIZATION_SUCCESS,
  (organization: any, notifySuccess?: boolean) => ({
    organization,
    notifySuccess,
  })
);
const saveOrganizationError = createAction(
  EOrganizationActionTypes.SAVE_ORGANIZATION_ERROR
);

const deleteOrganization = createAction(
  EOrganizationActionTypes.DELETE_ORGANIZATION
);
const deleteOrganizationSuccess = createAction(
  EOrganizationActionTypes.DELETE_ORGANIZATION_SUCCESS
);
const deleteOrganizationError = createAction(
  EOrganizationActionTypes.DELETE_ORGANIZATION_ERROR
);

export const resetState = createAction(EOrganizationActionTypes.RESET_STATE);

export const doGetOrganizations = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async dispatch => {
  dispatch(getOrganizations());
  return await tap(
    organizationService.getOrganizations(),
    response => {
      const organizations = response;
      dispatch(getOrganizationsSuccess(organizations));
    },
    error => {
      const _message =
        'Failed to retrieve organizations. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getOrganizationsError(message));
    }
  );
};

export const doGetOrganization = (
  organizationId: string
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getOrganization());
  return await tap(
    organizationService.getOrganization(organizationId),
    response => {
      const organization = response.data.data;
      dispatch(getOrganizationSuccess(organization));
    },
    error => {
      const _message =
        'Failed to retrieve organizations. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getOrganizationError(message));
    }
  );
};

export const doGetOrganizationUsers = (
  organizationId: string
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getOrganizationUsers());

  return await tap(
    organizationService.getOrganizationUsers(organizationId),
    response => {
      const users = response.data.data;
      dispatch(getOrganizationUsersSuccess(users));
    },
    error => {
      const _message =
        'Failed to retrieve organizations. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getOrganizationUsersError(message));
    }
  );
};

export const doSaveOrganizations = (
  organization: any,
  notifySuccess?: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(saveOrganization());

  return await tap(
    organizationService.saveOrganization(organization),
    response => {
      const organization = response.data;
      dispatch(saveOrganizationSuccess(organization, notifySuccess));
    },
    error => {
      const _message = 'Failed to save organizations Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(saveOrganizationError(message));
    }
  );
};

export const doDeleteOrganization = (
  id
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(deleteOrganization());
  return await tap(
    organizationService.deleteOrganization(id),
    response => {
      const payload = response.data;
      dispatch(deleteOrganizationSuccess(payload));
    },
    error => {
      const _message = 'Failed to save organizations Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(deleteOrganizationError(message));
    }
  );
};
