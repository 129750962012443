import { AxiosResponse } from 'axios';
import { ObjectID } from 'bson';
import { authAxiosCall } from '../axiosCall';
import { GetUserActionType, SaveUserActionType, SaveUser } from './types';
import { uploadMedia } from '../mediaService';
import { getUUIDForFile } from '../../../Lib/Utils/functions';
import {
  getOrganizationUsers,
  addUserToOrganization,
} from '../organizationService/index';
import { getOrganization, isSuperAdmin } from '../../../Lib/Utils/auth';

export const getAllUsers = async () => {
  return authAxiosCall('/users', {
    method: 'GET',
    query: {
      archived: 'false',
    },
  });
};

export const getKetCloakUsers = async (includeAdmins?: boolean) => {
  let response = await authAxiosCall('/users', {
    method: 'GET',
    source: 'keycloak_api',
  });
  let users = response?.data || [];
  users = users.filter(u => !!u.email && !!u.firstName);

  if (!includeAdmins) {
    // Get top-level users
    response = await authAxiosCall(`/organizations/organizations/members`, {
      method: 'GET',
      source: 'keycloak_api',
    });
    const orgUsers = response?.data || [];

    users = users.filter(u => !orgUsers.find(o => o.id === u.id));
  }

  return users;
};

export const getDraftUsers = async () => {
  let users = [];
  const response = await authAxiosCall('/drafts/collections/users', {
    method: 'GET',
  });
  const drafts = response && response.data ? response.data.data : [];
  users = users.concat(
    drafts.map(d => ({
      ...d.draft,
      userId: d.draft.id,
      _id: d._id,
      isDraft: true,
      createdAt: d.createdAt,
      updatedAt: d.updatedAt,
    }))
  );
};

export const getUsers = async (
  includeAll?: boolean
): Promise<AxiosResponse<any> | undefined> => {
  const organizationId = getOrganization()?._id;
  if (includeAll || (!organizationId && isSuperAdmin())) {
    // Super admin with no organizationId has access to all users
    const response = await getAllUsers();
    return response?.data.data;
  }
  if (organizationId) {
    const organizationUsers = await getOrganizationUsers(organizationId);
    return organizationUsers?.data.data;
  } else {
    throw new Error('Failed to load users missing organization ID');
  }
};

export const getUser = async (
  userId: string,
  actionType?: GetUserActionType
): Promise<AxiosResponse<any> | undefined> => {
  if (actionType === 'get-draft') {
    return authAxiosCall(`/drafts/${userId}`, {
      method: 'GET',
    });
  } else if (actionType === 'get-discover') {
    return authAxiosCall(`/users/${userId}`, {
      method: 'GET',
    });
  } else if (actionType === 'get-keycloak') {
    return authAxiosCall(`/users/${userId}`, {
      method: 'GET',
      query: { type: 'keycloak' },
    });
  } else {
    return authAxiosCall(`/users/${userId}`, {
      method: 'GET',
      source: 'keycloak_api',
    });
  }
};

const makeUserSuperAdmin = async userId => {
  const accessEntry = {
    resourceId: '000000000000000000000000',
    type: 'global',
    permission: 'admin',
  };
  return authAxiosCall(`/users/${userId}/access-control`, {
    method: 'POST',
    body: accessEntry,
  });
};

const revokeSuperAdmin = async userId => {
  const accessEntry = {
    resourceId: '000000000000000000000000',
    type: 'global',
  };
  return authAxiosCall(`/users/${userId}/access-control`, {
    method: 'DELETE',
    body: accessEntry,
  });
};

export const deleteUser = async userId => {
  return authAxiosCall(`/users/${userId}`, {
    method: 'DELETE',
  });
};

export const saveUser = async (
  {
    _id, // the draft _id
    id,
    firstName,
    lastName,
    email,
    password,
    userPermission,
    orgPermission,
    groups,
    superAdmin,
  }: SaveUser,
  actionType: SaveUserActionType,
  defaultToCurrentOrg = true
): Promise<AxiosResponse<any> | undefined> => {
  let path = '';
  let method = '';
  const source = 'api';
  const organizationId = getOrganization()?._id;
  try {
    switch (actionType) {
      case 'create-draft':
        path = `/drafts/collections/users`;
        method = 'POST';
        break;
      case 'save-draft':
        path = `/drafts/${_id}`;
        method = 'PUT';
        break;
      case 'submit-create-entity':
        const userCreateResponse = await authAxiosCall(`/users/`, {
          method: 'POST',
          body: {
            username: email,
            email,
            firstName,
            lastName,
            facilities: groups,
            password,
            userPermission,
          },
        });
        // Adds user to current ORG
        if (organizationId && defaultToCurrentOrg)
          await addUserToOrganization(
            userCreateResponse?.data?.data._id,
            organizationId,
            orgPermission ?? 'user'
          );
        if (superAdmin) {
          await makeUserSuperAdmin(userCreateResponse?.data?.data._id);
        }
        return userCreateResponse;
      case 'save-entity':
      case 'submit-save-entity':
        const response = await authAxiosCall(`/users/${id}`, {
          method: 'PATCH',
          body: {
            userName: email,
            email,
            firstName,
            lastName,
            facilities: groups,
            userPermission,
          },
        });
        if (orgPermission)
          await addUserToOrganization(
            response?.data?.data._id,
            organizationId,
            orgPermission
          );
        if (superAdmin) {
          await makeUserSuperAdmin(response?.data?.data._id);
        } else revokeSuperAdmin(id);
        return response;
    }
    const response = await authAxiosCall(path, {
      method,
      source,
      body: JSON.stringify({
        username: email,
        email,
        firstName,
        lastName,
        facilities: groups,
        password,
        userPermission,
      }),
    });
    return response;
  } catch (e) {
    if (e.message === 'user already exists') {
    } else {
      throw e;
    }
  }
};

export const getOrganizations = async () =>
  await authAxiosCall(`/organizations`, {
    method: 'GET',
    source: 'keycloak_api',
  });
