import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';

import { FacilityMediaContainer as Container } from './styles';

import {
  Label,
  MediaUpload,
  CustomTooltip,
  InfoIcon,
} from '../../../Components';

interface Props {
  onChange: Function;
  photos?: Array<any>;
  videos?: Array<any>;
  logo?: any;
  assetMediaFirstLoad?: boolean;
}

const FacilityMedia = ({ onChange, ...data }: Props) => {
  const { photos = [], videos = [], logo, assetMediaFirstLoad = true } = data;

  const {
    errors,
    setValue,
    register,
    setError,
    clearError,
    triggerValidation,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    assetMediaFirstLoad === false && triggerValidation();
    onChange('assetMediaFirstLoad', false);
  }, []);

  useEffect(() => {
    register({ name: 'photos' });
    register({ name: 'logo' });
  }, [register]);

  useEffect(() => {
    setValue('photos', photos);
    if (photos.length === 0) {
      setError('photos', 'required', i18n.t('At least one photo is required'));
    } else {
      clearError('photos');
    }
  }, [photos]);

  useEffect(() => {
    setValue('logo', logo);
    if (logo === undefined || logo === '') {
      setError('logo', 'required', i18n.t('A logo is required'));
    } else {
      clearError('logo');
    }
  }, [logo]);

  const defaultLogo = (logo ? [logo] : []) as Array<any>;

  return (
    <Container className="row">
      <div className="col-12 mb-3">
        <Label>
          {`* `}
          {i18n.t('Add a logo for your facility')}
          <div data-tip data-for="logo">
            <InfoIcon />
          </div>
          <CustomTooltip id="logo" placement="right">
            <span>
              {`* `}
              {i18n.t(
                'The logo must be square and be between 60x60px and 180x180px.'
              )}
            </span>
          </CustomTooltip>
        </Label>
      </div>
      <div className="col-12 media-container">
        <div>
          {errors.logo && (
            <span className="inlineErrorMessage mr-3 mt-2">
              {errors.logo.message}.
            </span>
          )}
        </div>
        <MediaUpload
          proloadedItemsLinks={defaultLogo}
          handleChange={item => {
            let newItem = item;
            if (Array.isArray(item)) {
              newItem = item.length === 0 ? '' : item[0];
            }
            onChange('logo', newItem);
          }}
          accept={['image/png', 'image/jpg', 'image/jpeg']}
          maxFileSize={2 * 1024 * 1024} // 2 MB
          showLink
          placeholder={i18n.t('Add logo')}
          multiple={false}
          dimensions={{
            min: { width: 60, height: 60 },
            max: { width: 180, height: 180 },
            square: true,
          }}
        />
      </div>

      <div className="col-12 mb-3">
        <Label>
          {`* `}
          {i18n.t(
            'Add one or more photos that you like displayed in the media gallery for facility'
          )}
          <div data-tip data-for="morephotos">
            <InfoIcon />
          </div>
          <CustomTooltip id="morephotos" placement="right">
            <span>{i18n.t('Upload photos that are 4x3')}</span>
          </CustomTooltip>
        </Label>
        <div className="instructions mt-2">
          {i18n.t('Drag the images to rearrange them')}
        </div>
      </div>
      <div className="col-12 media-container">
        <div>
          {errors.photos && (
            <span className="inlineErrorMessage mr-3 mt-2">
              {errors.photos.message}.
            </span>
          )}
        </div>
        <MediaUpload
          proloadedItemsLinks={photos}
          handleChange={items => onChange('photos', items)}
          accept={['image/png', 'image/jpg', 'image/jpeg']}
          maxFileSize={2 * 1024 * 1024} // 2 MB
          showLink
        />
      </div>
      <div className="col-12 mb-3">
        <Label>
          {i18n.t(
            'Add one or more videos that you like displayed in the media gallery for facility'
          )}
          <div data-tip data-for="morevideos">
            <InfoIcon />
          </div>
          <CustomTooltip id="morevideos" placement="right">
            <span>{i18n.t('Upload videos that are')}</span>
          </CustomTooltip>
        </Label>
        <div className="instructions mt-2">
          {i18n.t('Drag the videos to rearrange them')}
        </div>
      </div>
      <div className="col-12 media-container">
        <MediaUpload
          proloadedItemsLinks={videos}
          handleChange={items => onChange('videos', items)}
          type="video"
          accept={['video/mp4']}
          maxFileSize={10 * 1024 * 1024} // 10 MB
          featured={[0]}
        />
      </div>
    </Container>
  );
};

export default FacilityMedia;
