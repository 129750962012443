import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import ItemControls from './ItemControls';
import { MdAdd } from 'react-icons/md';
import { FaFlagCheckered, FaRegQuestionCircle } from 'react-icons/fa';
import {
  Container,
  List,
  Item,
  ItemName,
  ItemIcon,
  ParrallelLine,
} from './styles';
import { PopUp, EraseIcon } from '../';
import { FacilityTypeIcons } from '../../Lib/Configs';

interface EnumListItem {
  number: number;
  name: string;
  active: boolean;
  icon: any;
  facilityType?: string;
  facilityTypeIcon?: string;
  facilityClaims?: Array<string>;
  isParallel?: boolean;
}

interface Props {
  items: Array<EnumListItem>;
  handleStepsChange: any;
  assetsInventory?: Array<any>;
  handleClean?: Function;
}

const StepTabs = ({
  items,
  assetsInventory,
  handleStepsChange,
  handleClean,
}: Props) => {
  const [itemsList, setItemsList] = useState(items);
  const [popUpShow, setPopUpShow] = useState(false);
  const [popUpAction, setPopUpAction] = useState<() => void>();

  useEffect(() => {
    if (items && items.length) {
      if (JSON.stringify(items) !== JSON.stringify(itemsList))
        setItemsList(items);
    }
  }, [items]);

  useEffect(() => {
    const initialItems = [...items];
    setItemsList([...initialItems]);
  }, []);

  useEffect(() => {
    handleStepsChange(itemsList);
  }, [itemsList]);

  const numberSort = ({ number: numberA }, { number: numberB }) => {
    if (numberA != null && numberB != null) {
      if (numberA > numberB) {
        return 1;
      }
      if (numberB > numberA) {
        return -1;
      }
    }
    return 0;
  };

  const handleItemClick = selectedNumber => {
    const newItemsList = itemsList.map(item => ({
      ...item,
      active: item.number === selectedNumber,
    }));
    setItemsList(newItemsList);
  };

  const handleAddStep = () => {
    const maxItemNumber = Math.max(...itemsList.map(i => i.number));
    const newStep = {
      number: maxItemNumber + 1,
      name: `${i18n.t('Step')} ${maxItemNumber + 2}`,
      active: false, // maxItemNumber === -1,
      icon: <FaRegQuestionCircle />,
      description: '',
      facilityClaims: [],
      facilityType: '',
      facilityTypeIcon: 'QuestionMark',
      inboundAssetsReceived: [],
      outboundAssetsReceived: [],
      inboundAssetsInventory: assetsInventory,
      outboundAssetsInventory: assetsInventory,
      isParallel: false,
    };
    const newItemsList = [...itemsList];
    newItemsList.push(newStep);
    setItemsList(newItemsList);
  };

  const handleDeleteStep = action => {
    setPopUpShow(true);
    setPopUpAction(action);
  };

  const popUpConfirmAction = () => {
    if (popUpAction) {
      popUpAction();
    }
    setPopUpShow(false);
  };

  return (
    <Container>
      <List>
        <Item className="addStep" onClick={handleAddStep}>
          <ItemName>{i18n.t('Add step')}</ItemName>
          <ItemIcon>
            <MdAdd />
          </ItemIcon>
        </Item>

        {itemsList
          .sort(numberSort)
          .map(
            (
              {
                number,
                name,
                active,
                icon,
                facilityType,
                facilityTypeIcon,
                isParallel,
              },
              index
            ) => {
              const IconComponent = facilityTypeIcon
                ? FacilityTypeIcons[facilityTypeIcon]
                : icon;
              return (
                <Item
                  key={index}
                  onClick={() => handleItemClick(number)}
                  className={active ? 'active' : ''}
                >
                  {isParallel && <ParrallelLine></ParrallelLine>}
                  <ItemName>
                    <span>{facilityType || name}</span>
                  </ItemName>
                  <ItemIcon>{IconComponent}</ItemIcon>
                  {active && (
                    <ItemControls
                      number={number}
                      itemsList={itemsList}
                      setItemsList={setItemsList}
                      deleteItemAction={handleDeleteStep}
                      cleanItemAction={handleClean}
                    />
                  )}
                </Item>
              );
            }
          )}
        {/* {lastItem && (
          <Item
            className={`lastStep ${lastItem.active && 'active'}`}
            onClick={() => handleItemClick(-1)}
          >
            {lastItem.isParallel && <ParrallelLine></ParrallelLine>}
            <ItemName>
              <span>{lastItem.facilityType || lastItem.name}</span>
            </ItemName>
            <ItemIcon>{LastItemIcon}</ItemIcon>
            {lastItem.active && (
              <ItemControls
                number={lastItem.number}
                itemsList={itemsList}
                setItemsList={setItemsList}
                deleteItemAction={handleDeleteStep}
                icon={<EraseIcon color="#fff" />}
                showArrows={false}
                tooltip={i18n.t('Clean step')}
                handleClick={handleClean}
              />
            )}
          </Item>
        )} */}
      </List>
      <PopUp
        title={i18n.t('Delete this step?')}
        content={i18n.t(
          'Are you sure you want to delete this step? All information will be lost.'
        )}
        cancelText={i18n.t('No')}
        confirmText={i18n.t('Yes')}
        confirmAction={popUpConfirmAction}
        cancelAction={() => setPopUpShow(false)}
        show={popUpShow}
      />
    </Container>
  );
};

export default StepTabs;
