import { unAuthAxiosCall } from '../axiosCall';
import { AxiosResponse } from 'axios';
import { KEYCLOAK_REALM } from '../../../config';
import {
  setInitialOrganization,
  setToken,
  setCurrentUser,
} from '../../../Lib/Utils/auth';
import * as userService from '../../../Services/Api/userService';

import jwtDecode from 'jwt-decode';

export const login = async (
  username: string,
  password: string
): Promise<any | undefined> => {
  const params = new URLSearchParams();
  params.append('client_id', 'discover-ui');
  params.append('username', username);
  params.append('password', password);
  params.append('grant_type', 'password');

  const keyCloakUser = await unAuthAxiosCall(
    `/auth/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`,
    {
      method: 'POST',
      source: 'keycloak',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: params,
    }
  );
  const data = keyCloakUser?.data;
  const decoded = jwtDecode(data.access_token);
  setToken(data);
  const userResponse = await userService.getUser(decoded.sub, 'get-keycloak');
  const userData = userResponse?.data?.data;
  if (!userData) throw new Error('User not found');
  decoded.discoverUser = userData;
  setCurrentUser(decoded);
  setInitialOrganization();
  return { keyCloakUser, userData, decoded };
};

export const refresh = async (
  refreshToken: string
): Promise<AxiosResponse<any> | undefined> => {
  const params = new URLSearchParams();
  params.append('client_id', 'discover-ui');
  params.append('refresh_token', refreshToken);
  params.append('grant_type', 'refresh_token');

  return unAuthAxiosCall(
    `/auth/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`,
    {
      method: 'POST',
      source: 'keycloak',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: params,
    }
  );
};
