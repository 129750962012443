import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import {
  ContentBox,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Button,
  Loading,
  CustomTooltip,
} from '../../../../Components';
import Toast from '../../../../Lib/Utils/toast';

import { ActionButtonsContainer } from './styles';

import Asset from './Asset';
import Claims from './Claims';
import ReviewSubmit from './ReviewSubmit';

import { generateNewRow } from './helper';
import { isFacilityManager } from '../../../../Lib/Utils/auth';

interface Props {
  number: number;
  history?: any;
  match?: any;
  location?: any;
  parentData?: {
    outboundData: any;
    inboundData: any;
    claimsData: any;
    reviewSubmit: any;
  };
  saveData?: any;
  claims?: Array<any>;
  step?: any;
  lotId?: string;
  periodicClaims?: any;
  lotClaims?: any;
  onetimeClaims?: any;
  othersClaims?: any;
  doAddClaimVerificationAction?: any;
  addVerification?: any;
  stepSubmitSuccess?: any;
  doSaveAssetsAction?: any;
  doUpdateStepAction?: any;
  doDeleteJourneyAssetsAction?: any;
  asset?: any;
  inputsSkuku?: number;
  outputsSkuku?: number;
  userFacilities?: Array<string>;
  nextChecked?: boolean;
  previousChecked?: boolean;
  previousOutputs?: Array<any> | null;
  facilityData?: Array<any>;
  loading: boolean;
  template?: any;
  getJourney: any;
  complete: boolean;
}

const tabs = [
  i18n.t('Claims'),
  i18n.t('Inbound'),
  i18n.t('Outbound'),
  i18n.t('Review & Submit'),
];

const defaultParentData = {
  outboundData: { tableFields: {} },
  inboundData: { tableFields: {} },
  claimsData: {},
  reviewSubmit: {},
};

const StepContent = ({
  history,
  match,
  location,
  parentData = defaultParentData,
  step,
  lotId,
  periodicClaims,
  lotClaims,
  onetimeClaims,
  othersClaims,
  doAddClaimVerificationAction,
  addVerification,
  doSaveAssetsAction,
  template,
  asset,
  doUpdateStepAction,
  userFacilities,
  nextChecked,
  previousChecked,
  doDeleteJourneyAssetsAction,
  previousOutputs,
  loading,
  getJourney,
  number,
  complete,
  stepSubmitSuccess,
}: Props) => {
  const { outboundData, inboundData, claimsData, reviewSubmit } = parentData;

  const startData = {
    outboundData,
    inboundData,
    claimsData,
    reviewSubmit: {
      certified: complete,
    },
  };

  const [data, setData] = useState(startData);
  const [tabIndex, setTabIndex] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);

  const periodicClaimsData = periodicClaims;
  const lotClaimsData = lotClaims;
  const onetimeClaimsData = onetimeClaims;
  const othersClaimsData = othersClaims;

  const userFacility =
    userFacilities?.includes(step.facility._id) || !!isFacilityManager();

  const onChange = (type: string, newValue: any) => {
    setData({
      ...data,
      [type]: { ...data[type], ...newValue },
    });
  };

  const assetsInput = step.populatedInputs.map(({ asset }) => asset);
  const assetsInputTemplate = template.inputs;
  const assetsOutput = step.populatedOutputs.map(({ asset }) => asset);
  const assetsOutputTemplate = template.outputs;

  useEffect(() => {
    setFirstLoad(true);
  }, [number]);

  useEffect(() => {
    if (firstLoad) {
      setData(startData);
      setFirstLoad(false);
    }
  }, [firstLoad]);

  useEffect(() => {
    if (!firstLoad) {
      const editLocked = !userFacility || nextChecked;
      if (
        assetsOutput &&
        assetsOutput.length > 0 &&
        outboundData &&
        Object.entries(data.outboundData?.tableFields || {}).length === 0
      ) {
        let newFieldsStatus = {};
        const newFields = [] as any;
        assetsOutput.forEach((asset, idx) => {
          const { templateDetails } = asset;
          const { 0: assetTemplate } = templateDetails;
          const newRow = generateNewRow(
            idx,
            asset.attributes,
            assetTemplate,
            asset._id,
            asset.subDocumentId,
            editLocked,
            null,
            null
          );
          if (newFields[assetTemplate._id]) {
            newFields[assetTemplate._id].push(newRow);
          } else {
            newFields[assetTemplate._id] = [newRow];
          }
          newFieldsStatus = {
            ...newFieldsStatus,
            ...newFields,
          };
        });

        if (
          Object.entries(data.outboundData?.tableFields || {}).length === 0 &&
          JSON.stringify(newFieldsStatus) !==
            JSON.stringify(data.outboundData.tableFields)
        ) {
          setData({
            ...data,
            outboundData: {
              ...data.outboundData,
              tableFields: newFieldsStatus,
            },
          });
        }
      }
    }
  }, [assetsOutput, firstLoad]);

  useEffect(() => {
    if (!firstLoad) {
      const editLocked = !userFacility || nextChecked;
      if (
        assetsInput &&
        assetsInput.length > 0 &&
        inboundData &&
        Object.entries(data.inboundData.tableFields).length === 0
      ) {
        let newFieldsStatus = {};
        const newFields = [] as any;
        assetsInput.forEach((asset, idx) => {
          const { templateDetails } = asset;
          const { 0: assetTemplate } = templateDetails;
          const newRow = generateNewRow(
            idx,
            asset.attributes,
            assetTemplate,
            asset._id,
            asset.subDocumentId,
            editLocked,
            null,
            null
          );
          if (newFields[assetTemplate._id]) {
            newFields[assetTemplate._id].push(newRow);
          } else {
            newFields[assetTemplate._id] = [newRow];
          }
          newFieldsStatus = {
            ...newFieldsStatus,
            ...newFields,
          };
        });

        if (
          Object.entries(data.inboundData?.tableFields || {}).length === 0 &&
          JSON.stringify(newFieldsStatus) !==
            JSON.stringify(data.inboundData.tableFields)
        ) {
          setData({
            ...data,
            inboundData: { ...data.inboundData, tableFields: newFieldsStatus },
          });
        }
      }
    }
  }, [assetsInput, firstLoad]);

  useEffect(() => {
    if (!firstLoad) {
      const editLocked = !userFacility || nextChecked;
      if (
        previousOutputs &&
        assetsInputTemplate.length !== 0 &&
        assetsInput.length === 0 &&
        (!data.inboundData?.tableFields ||
          Object.entries(data.inboundData?.tableFields || {}).length === 0)
      ) {
        let newFieldsStatus = {};
        const newFields = [] as any;
        previousOutputs.forEach(({ asset }, idx) => {
          const { templateDetails } = asset;
          const { 0: assetTemplate } = templateDetails;
          const newRow = generateNewRow(
            idx,
            asset.attributes,
            assetTemplate,
            null,
            asset.subDocumentId,
            editLocked,
            null,
            null,
            false
          );
          if (newFields[assetTemplate._id]) {
            newFields[assetTemplate._id].push(newRow);
          } else {
            newFields[assetTemplate._id] = [newRow];
          }
          newFieldsStatus = {
            ...newFieldsStatus,
            ...newFields,
          };
        });

        if (
          Object.entries(data.inboundData?.tableFields || {}).length === 0 &&
          JSON.stringify(newFieldsStatus) !==
            JSON.stringify(data.inboundData.tableFields)
        ) {
          setData({
            ...data,
            inboundData: { ...data.inboundData, tableFields: newFieldsStatus },
          });
        }
      } else if (
        assetsInputTemplate.length === 0 &&
        Object.entries(data.inboundData?.tableFields || {}).length !== 0
      ) {
        setData({
          ...data,
          inboundData: { ...data.inboundData, tableFields: {} },
        });
      }
    }
  }, [previousOutputs, assetsInput, data, firstLoad, assetsInputTemplate]);

  useEffect(() => {
    if (tabIndex === 0) {
      if (addVerification?.verification) {
        Toast.success(i18n.t('Your data has been successfully submitted.'));
      } else if (addVerification?.error) {
        Toast.error(addVerification.error);
      }
    }
  }, [addVerification]);

  useEffect(() => {
    if (tabIndex === 1 || tabIndex === 2) {
      if (asset.submitSuccess) {
        Toast.success(i18n.t('Your data has been successfully submitted.'));
        getJourney();
      } else if (asset.error) {
        Toast.error(asset.error);
      }
    }
  }, [asset]);

  useEffect(() => {
    if (tabIndex === 3) {
      if (
        !loading &&
        (asset.submitSuccess ||
          addVerification.verification ||
          stepSubmitSuccess)
      ) {
        Toast.success(i18n.t('Your data has been successfully submitted.'));
        const { journeyTemplateId } = match?.params;
        history.push(`/journeys/${journeyTemplateId}/view`);
      } else if (asset.error || addVerification.error) {
        if (asset.error) {
          Toast.error(asset.error);
        }
        if (addVerification.error) {
          Toast.error(addVerification.error);
        }
      }
    }
  }, [asset, addVerification, stepSubmitSuccess, loading]);

  useEffect(() => {
    if (!firstLoad) {
      if (Object.keys(data.claimsData).length === 0) {
        const newClaimsData = {
          ...data.claimsData,
          periodicClaims: periodicClaimsData,
          lotClaimsData: lotClaimsData,
          onetimeClaims: onetimeClaimsData,
          othersClaims: othersClaimsData,
        };

        onChange('claimsData', newClaimsData);
      }
    }
  }, [data, firstLoad]);

  const getDefaultActiveTab = () => {
    const query = new URLSearchParams(location.search);
    const tabFromQuery = query.get('tab');
    const findTabIndex = tabs.findIndex(
      tab => tab.replace(/[^a-zA-Z0-9]/g, '') === tabFromQuery
    );
    return findTabIndex !== -1 ? findTabIndex : 0;
  };

  const handleTabChange = index => {
    const tabName = tabs[index].replace(/[^a-zA-Z0-9]/g, '');
    const query = new URLSearchParams(location.search);
    const stepFromQuery = query.get('step');
    const stepSearch = stepFromQuery ? `&step=${stepFromQuery}` : '';
    history.push({
      search: `?tab=${tabName}${stepSearch}`,
    });
    setTabIndex(index);
  };

  useEffect(() => {
    if (location) {
      const newIndex = getDefaultActiveTab();
      if (tabIndex !== newIndex) handleTabChange(newIndex);
    }
  }, [location, tabIndex]);

  const handleBackButton = () => {
    handleTabChange(tabIndex - 1);
  };

  const handleNextButton = () => {
    handleTabChange(tabIndex + 1);
  };

  const saveClaims = () => {
    const {
      periodicClaims = [],
      lotClaims = [],
      onetimeClaims = [],
      othersClaims = [],
    } = data.claimsData;

    const newClaims = []
      .concat(periodicClaims)
      .concat(lotClaims)
      .concat(onetimeClaims)
      .concat(othersClaims);

    newClaims &&
      newClaims.length > 0 &&
      newClaims.forEach(
        ({
          mediaLinks,
          supportingVerifications,
        }: {
          mediaLinks?: Array<any>;
          supportingVerifications?: Array<any>;
        }) => {
          if (mediaLinks && mediaLinks.length > 0) {
            const { _id } =
              supportingVerifications && supportingVerifications[0];
            const userFiles = mediaLinks.filter(link => link.user !== false);
            doAddClaimVerificationAction(_id, userFiles);
          }
        }
      );
  };

  const saveAssets = (inbound = true, complete = false) => {
    let newAssets = [] as any;

    if (inbound) {
      if (data.inboundData.inboundMessage) {
        const updatedStep = {
          templateLocator: step.templateLocator,
          messages: {
            inbound: data.inboundData.inboundMessage,
            outbound: step.messages.outbound,
          },
        };
        userFacility && doUpdateStepAction(step._id, updatedStep);
      }

      newAssets = Object.keys(data.inboundData.tableFields || [])
        .map(key => {
          return (
            data.inboundData.tableFields &&
            data.inboundData.tableFields[key]
              .filter(row => (row ? row[0].disabled === false : false))
              .map(row => row.map(field => ({ ...field, template: key })))
          );
        })
        .flat()
        .map(row => {
          const attributes = row
            .map(({ header, value }) => ({ [header]: value }))
            .reduce((result, item) => {
              const key = Object.keys(item)[0];
              result[key] = item[key];
              return result;
            }, {});
          const templateId = row[0].template;
          const assetId = row[0].id;
          const marketableProduct = row[0].marketableProduct;
          const name = row[0].name;

          const skuku = assetsInputTemplate.find(
            ({ template }) => template._id === templateId
          ).skuku;
          return {
            skuku: skuku,
            lotId: lotId,
            version: 0,
            attributes,
            assetId,
            marketableProduct,
            name,
          };
        });

      if (newAssets.length) {
        userFacility && doSaveAssetsAction(newAssets, true, step._id, complete);
      } else if (!step.complete && complete) {
        doUpdateStepAction(step._id, { complete: true });
      }

      if (data.inboundData.deletedRows) {
        const assetsToDelete = data.inboundData.deletedRows.map(({ id }) => ({
          stepId: step._id,
          subDocumentId: step.inputs.find(({ assetId }) => assetId === id)._id,
        }));
        doDeleteJourneyAssetsAction(assetsToDelete, true);
      }
    } else {
      if (data.outboundData.outboundMessage) {
        const updatedStep = {
          templateLocator: step.templateLocator,
          messages: {
            outbound: data.outboundData.outboundMessage,
            inbound: step.messages.inbound,
          },
        };
        userFacility && doUpdateStepAction(step._id, updatedStep);
      }

      newAssets = Object.keys(data.outboundData.tableFields || [])
        .map(key => {
          return data.outboundData.tableFields[key]
            .filter(row => row[0].disabled === false)
            .map(row => row.map(field => ({ ...field, template: key })));
        })
        .flat()
        .map(row => {
          const attributes = row
            .map(({ header, value }) => ({ [header]: value }))
            .reduce((result, item) => {
              const key = Object.keys(item)[0];
              result[key] = item[key];
              return result;
            }, {});
          const templateId = row[0].template;
          const assetId = row[0].id;
          const marketableProduct = row[0].marketableProduct;
          const name = row[0].name;
          const skuku = assetsOutputTemplate?.find(
            ({ template }) => template._id === templateId
          ).skuku;
          return {
            skuku: skuku,
            lotId: lotId,
            version: 0,
            attributes,
            assetId,
            marketableProduct,
            name,
          };
        });

      if (newAssets.length) {
        userFacility &&
          doSaveAssetsAction(newAssets, false, step._id, complete);
      } else if (!step.complete && complete) {
        doUpdateStepAction(step._id, { complete: true });
      }

      if (data.outboundData.deletedRows) {
        const assetsToDelete = data.outboundData.deletedRows.map(({ id }) => ({
          stepId: step._id,
          subDocumentId: step.outputs.find(({ assetId }) => assetId === id)._id,
        }));
        doDeleteJourneyAssetsAction(assetsToDelete, false);
      }
    }
  };

  const saveAction = () => {
    if (previousChecked) {
      if (tabIndex === 0) {
        saveClaims();
      }

      if (tabIndex === 1) {
        saveAssets(true);
      }

      if (tabIndex === 2) {
        saveAssets(false);
      }

      if (tabIndex === 3) {
        if (
          assetsOutput &&
          assetsOutput.length > 0 &&
          Object.values(data.outboundData.tableFields || {}).flat().length === 0
        ) {
          Toast.error(
            i18n.t(
              'You must capture data for all OUTPUT assets in order to complete the submission.'
            )
          );
        } else {
          saveClaims();
          saveAssets(true, true);
          saveAssets(false, true);
        }
      }
    } else {
      let message =
        'You cannot submit until the previous facility completes data entry.';
      if (!userFacility) message = 'You do not have access to this facility.';
      Toast.error(i18n.t(message));
    }
  };

  const saveActionDisabled =
    loading ||
    (tabIndex === 3
      ? data.reviewSubmit.certified
        ? !data.reviewSubmit.certified
        : true
      : false) ||
    nextChecked;

  const havePreviousOutputs = previousOutputs
    ? previousOutputs.length > 0
    : false;

  const stepData = data[number];

  return (
    <>
      <ContentBox>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={handleTabChange}
          initialSelectedIndex={getDefaultActiveTab()}
        >
          <TabList>
            {tabs.map((tab, index) => (
              <Tab key={index}>{tab}</Tab>
            ))}

            <ActionButtonsContainer>
              {tabIndex > 0 && (
                <Button
                  text={i18n.t('Back')}
                  type="secondary"
                  action={handleBackButton}
                  disabled={loading}
                />
              )}
              <div data-tip data-for="submit">
                <Button
                  text={tabIndex === 3 ? i18n.t('Submit') : i18n.t('Save')}
                  type="secondary"
                  action={saveAction}
                  disabled={saveActionDisabled}
                />
              </div>
              {saveActionDisabled && (
                <CustomTooltip id="submit">
                  <span>
                    {!data.reviewSubmit.certified &&
                    tabIndex === tabs.length - 1
                      ? i18n.t('You must agree to the certification.')
                      : i18n.t(
                          `The next step/facility has already submitted its data, so you cannot update this data.`
                        )}
                  </span>
                </CustomTooltip>
              )}
              {tabIndex < tabs.length - 1 && (
                <>
                  <div data-tip data-for="savenext">
                    <Button
                      text={i18n.t('Save & Next')}
                      type="secondary"
                      action={handleNextButton}
                      disabled={saveActionDisabled}
                    />
                  </div>
                  {saveActionDisabled && (
                    <CustomTooltip id="savenext">
                      <span>
                        {i18n.t(
                          `The next step/facility has already submitted its data, so you cannot update this data.`
                        )}
                      </span>
                    </CustomTooltip>
                  )}
                </>
              )}
            </ActionButtonsContainer>
          </TabList>

          <TabPanel>
            <Claims
              data={data.claimsData}
              onTabsEnd={handleNextButton}
              onChange={onChange}
              periodicClaims={periodicClaims}
              lotClaims={lotClaims}
              onetimeClaims={onetimeClaims}
              othersClaims={othersClaims}
              loading={loading}
              number={number}
            />
          </TabPanel>
          <TabPanel>
            <Asset
              dataProp={data}
              data={data.inboundData}
              onChange={onChange}
              message={step?.messages.inbound || ''}
              lotId={lotId}
              assetsTemplate={assetsInputTemplate}
              assets={assetsInput}
              userFacility={userFacility}
              locked={nextChecked}
              type="inbound"
              havePreviousOutputs={havePreviousOutputs}
            />
          </TabPanel>
          <TabPanel>
            <Asset
              data={data.outboundData}
              onChange={onChange}
              message={step?.messages.outbound || ''}
              lotId={lotId}
              assetsTemplate={assetsOutputTemplate}
              assets={assetsOutput}
              userFacility={userFacility}
              locked={nextChecked}
              type="outbound"
              inbounds={data.inboundData}
            />
          </TabPanel>
          <TabPanel>
            <ReviewSubmit
              data={data}
              messages={step?.messages}
              onChange={onChange}
              assetsInput={assetsInput}
              assetsInputTemplate={assetsInputTemplate}
              assetsOutput={assetsOutput}
              assetsOutputTemplate={assetsOutputTemplate}
              lotId={lotId}
              saveAction={saveAction}
              saveActionDisabled={saveActionDisabled}
              handleBackButton={handleBackButton}
              complete={complete}
            />
          </TabPanel>
        </Tabs>
      </ContentBox>
      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(withTranslation()(StepContent as any) as any);
