import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import NotLoggedInRoute from './notLoggedInRoute';

import LoggedLayout from '../Components/Layouts/loggedLayout';

import Home from '../Containers/Home';
import Login from '../Containers/Login';

import JourneysList from '../Containers/Journey/List';
import JourneyWizard from '../Containers/Journey/Wizard';
import NewJourney from '../Containers/Journey/New';
import ViewJourney from '../Containers/Journey/View';
import ProofJourney from '../Containers/Journey/Proof';

import ViewJourneyProduct from '../Containers/Journey/View/Product';

import AssetsList from '../Containers/Asset/List';
import NewAsset from '../Containers/Asset/New';

import FacilityList from '../Containers/Facility/List';
import NewFacility from '../Containers/Facility/New';

import ClaimsList from '../Containers/Claim/List';
import NewClaim from '../Containers/Claim/New';
import NewAttestor from '../Containers/Claim/NewAttestor';

import CampaignList from '../Containers/Campaign/List';
import NewCampaign from '../Containers/Campaign/New';

import Dashboard from '../Containers/Dashboard';
import PrivateRoute from './privateRoute';

import UserList from '../Containers/User/List';
import NewUser from '../Containers/User/New';

import OrganizationList from '../Containers/Organization/List';
import NewOrganization from '../Containers/Organization/New';

interface IProps {
  children?: any;
}

const RouterComponent = ({ children }: IProps) => (
  <Router>
    <Switch>
      <PrivateRoute path="/home">
        <LoggedLayout title="Home">
          <Dashboard />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/journeys/new">
        <LoggedLayout title="Journey Management">
          <NewJourney />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/journeys/wizard/:journeyId/draft">
        <LoggedLayout title="Journey Management">
          <JourneyWizard />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/journeys/wizard/:journeyId/edit">
        <LoggedLayout title="Journey Management">
          <JourneyWizard />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/journeys/wizard/draft">
        <LoggedLayout title="Journey Management">
          <JourneyWizard />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/journeys/:journeyTemplateId/view/:journeyId">
        <LoggedLayout title="Journey Management">
          <ViewJourneyProduct />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/journeys/:journeyTemplateId/proof/:journeyId">
        <LoggedLayout title="Journey Management">
          <ProofJourney />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/journeys/:journeyTemplateId/view">
        <LoggedLayout title="Journey Management">
          <ViewJourney />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/journeys/:journeyId/edit">
        <LoggedLayout title="Journey Management">
          <NewJourney />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/journeys">
        <LoggedLayout title="Journey Management">
          <JourneysList />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/facilities/draft">
        <LoggedLayout title="Facility Management">
          <NewFacility />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/facilities/:facilityId/draft">
        <LoggedLayout title="Facility Management">
          <NewFacility />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/facilities/:facilityId/edit">
        <LoggedLayout title="Facility Management">
          <NewFacility />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/facilities">
        <LoggedLayout title="Facility Management">
          <FacilityList />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/users/draft">
        <LoggedLayout title="User Management">
          <NewUser />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/users/:userId/draft">
        <LoggedLayout title="User Management">
          <NewUser />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/users/:userId/edit">
        <LoggedLayout title="User Management">
          <NewUser />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/users">
        <LoggedLayout title="User Management">
          <UserList />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/organizations/draft">
        <LoggedLayout title="Organization Management">
          <NewOrganization />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/organizations/:organizationId/draft">
        <LoggedLayout title="User Management">
          <NewOrganization />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/organizations/:organizationId/edit">
        <LoggedLayout title="User Management">
          <NewOrganization />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/organizations">
        <LoggedLayout title="Organizations">
          <OrganizationList />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/claims/attestors/:attestorId/edit">
        <LoggedLayout title="Claims Management">
          <NewAttestor />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/claims/attestors/:attestorId/draft">
        <LoggedLayout title="Claims Management">
          <NewAttestor />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/claims/attestors/draft">
        <LoggedLayout title="Claims Management">
          <NewAttestor />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/claims/draft">
        <LoggedLayout title="Claims Management">
          <NewClaim />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/claims/:claimId/draft">
        <LoggedLayout title="Claims Management">
          <NewClaim />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/claims/:claimId/edit">
        <LoggedLayout title="Claims Management">
          <NewClaim />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/claims">
        <LoggedLayout title="Claims Management">
          <ClaimsList />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/assets/draft">
        <LoggedLayout title="Asset Management">
          <NewAsset />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/assets/:skuku/draft">
        <LoggedLayout title="Asset Management">
          <NewAsset />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/assets/:skuku/edit">
        <LoggedLayout title="Asset Management">
          <NewAsset />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/assets">
        <LoggedLayout title="Asset Management">
          <AssetsList />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/campaigns/:campaignId/draft">
        <LoggedLayout title="Campaign Management">
          <NewCampaign />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/campaigns/:campaignId/edit">
        <LoggedLayout title="Campaign Management">
          <NewCampaign />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/campaigns/draft">
        <LoggedLayout title="Campaign Management">
          <NewCampaign />
        </LoggedLayout>
      </PrivateRoute>
      <PrivateRoute path="/campaigns">
        <LoggedLayout title="Campaign Management">
          <CampaignList />
        </LoggedLayout>
      </PrivateRoute>
      {/* </PrivateRoute> */}
      <NotLoggedInRoute path="/user">
        <Login />
      </NotLoggedInRoute>
      <NotLoggedInRoute exact path="/login">
        <Login />
      </NotLoggedInRoute>
      <Route path="/">
        <Redirect to="/login" />
      </Route>
    </Switch>
    {children}
  </Router>
);

export default RouterComponent;
