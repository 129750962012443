import React, { useState } from 'react';
import {
  Container,
  TableContainer,
  Table,
  HeadTextContainer,
  ActionContainer,
  TableHeader,
} from './styles';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

import { Button, TrashIcon } from '../index';

import moment from 'moment';

interface Props {
  headers: string[];
  data: any[][];
  vertical?: boolean;
  readonly?: boolean;
  header?: any | null;
  removable?: boolean;
  handleDelete?: any;
  defaultShow?: boolean;
}

const DynamicTable = ({
  headers,
  data,
  vertical = false,
  readonly = false,
  header = null,
  removable = false,
  handleDelete,
  defaultShow = true,
}: Props) => {
  const [show, setShow] = useState(defaultShow);

  const isDate = date => {
    return (
      /^\d{4}\-\d{2}\-\d{2}.*$/.test(date) &&
      moment(date, [moment.ISO_8601], true).isValid()
    );
  };

  return (
    <Container>
      {header && (
        <TableHeader className="table-header m-0">
          {header}
          <ActionContainer onClick={() => setShow(!show)}>
            {show ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </ActionContainer>
        </TableHeader>
      )}
      {show && (
        <TableContainer>
          <Table className="table" headers={headers} vertical={vertical}>
            <thead>
              <tr>
                {headers.map((header, headerId) => (
                  <th key={headerId}>
                    <HeadTextContainer>{header}</HeadTextContainer>
                  </th>
                ))}
                {removable && !readonly && <th></th>}
              </tr>
            </thead>
            <tbody>
              {!readonly
                ? data.map((row, index) => {
                    const rowId = row[0].props.rowNumber;
                    const { disabled, fromServer } = row[0].props;
                    return (
                      <tr key={`${index}-${rowId}`}>
                        {row.map((cell, cellId) => (
                          <td key={`${rowId}-${cellId}`}>
                            {cellId === 0 && <div className="first"></div>}
                            {cell}
                          </td>
                        ))}
                        {removable && (
                          <td className="remove-action">
                            {disabled === false && (
                              <Button
                                width={24}
                                height={24}
                                icon={<TrashIcon color="#fff" />}
                                type="roundDark"
                                action={evt => {
                                  evt.stopPropagation();
                                  handleDelete && handleDelete(rowId);
                                }}
                              />
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })
                : data.map((row, rowId) => (
                    <tr key={rowId}>
                      {row.map((col, colId) => {
                        return (
                          <td key={colId}>
                            {typeof col === 'string' && isDate(col)
                              ? moment(col)
                                  .format('MM/DD/YYYY')
                                  .toString()
                              : col}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
            </tbody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default DynamicTable;
