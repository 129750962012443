import * as productServices from '../../Services/Api/productService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { EProductActionTypes } from './productActionsTypes';

const getProduct = createAction(
  EProductActionTypes.GET_PRODUCT,
  (journeyId: any) => ({ journeyId })
);
const getProductSuccess = createAction(
  EProductActionTypes.GET_PRODUCT_SUCCESS,
  (payload: any) => payload
);
const getProductError = createAction(
  EProductActionTypes.GET_PRODUCT_ERROR,
  (message: string) => ({ message })
);

const cleanProduct = createAction(EProductActionTypes.CLEAN_PRODUCT);

export const doGetProduct = (
  journeyId
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getProduct(journeyId));
  return await tap(
    productServices.getProduct(journeyId),
    response => {
      dispatch(getProductSuccess(response || null));
    },
    error => {
      const _message = 'Failed to retrieve Journey. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getProductError(message));
    }
  );
};

export const doCleanProduct = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async dispatch => {
  dispatch(cleanProduct());
};
