import React, { useState } from 'react';
import i18n from 'i18next';
import { TableIcon, ListIcon, CustomTooltip } from '../';
import { Container, ToggleAction, TableTitle, Table, DataList } from './styles';

interface Props {
  titles: Array<string>;
  headers: Array<any>;
  data: Array<any>;
}

const ToggleTable = ({ titles, headers, data }: Props) => {
  const [toggleStatus, setToggleStatus] = useState(true);

  const handleToggle = () => setToggleStatus(!toggleStatus);

  const random = Math.random()
    .toString(36)
    .substring(6);

  return (
    <>
      <ToggleAction>
        <div onClick={handleToggle} data-tip data-for={`fieldType-${random}`}>
          {toggleStatus ? <ListIcon /> : <TableIcon />}
        </div>
      </ToggleAction>
      <CustomTooltip id={`fieldType-${random}`}>
        <span>
          {toggleStatus
            ? i18n.t('Switch to row view.')
            : i18n.t('Switch to column view.')}
        </span>
      </CustomTooltip>
      <Container>
        {toggleStatus ? (
          <>
            {Object.keys(headers).map((hKey, tIdx) => {
              const tableTitle = titles[hKey];
              const tableHeaders = headers[hKey];
              const tableData = data[hKey];

              return (
                <div key={`table-${tIdx}`}>
                  {tableTitle && <TableTitle>{tableTitle}</TableTitle>}
                  <Table>
                    <thead>
                      <tr>
                        {tableHeaders.map((header, hIdx) => (
                          <th key={`header-${tIdx}-${hIdx}`}>{header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, rIdx) => (
                        <tr key={`row-${tIdx}-${rIdx}`}>
                          {Object.values(row).map((rowValue, vIdx) => (
                            <td
                              key={`value-${tIdx}-${rIdx}-${vIdx}`}
                            >{`${rowValue || '-'}`}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <DataList>
              {Object.keys(headers).map((hKey, tIdx) => {
                const tableTitle = titles[hKey];
                const tableData = data[hKey];

                return (
                  <>
                    <TableTitle>{tableTitle}</TableTitle>
                    {tableData.map((rowValue, rIdx) => (
                      <ul key={`list-${tIdx}-${rIdx}`}>
                        {Object.keys(rowValue).map((dataValue, dIdx) => (
                          <li key={`item-${tIdx}-${rIdx}-${dIdx}`}>
                            <strong>{`${dataValue}`}</strong>
                            <span>{`${rowValue[dataValue] || '-'}`}</span>
                          </li>
                        ))}
                      </ul>
                    ))}
                  </>
                );
              })}
            </DataList>
          </>
        )}
      </Container>
    </>
  );
};

export default ToggleTable;
