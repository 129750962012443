import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';

import { Claim } from '../../../../Lib/Utils/Validations';

import {
  Label,
  FormInput,
  InfoIcon,
  CustomTooltip,
  Dropdown,
  Checkbox,
  DatePicker,
  FrequencyIcon,
  Button,
  MediaUpload,
} from '../../../../Components';

interface Props {
  onChange: Function;
  checkboxSelected?: number;
  attestorSelect?: string;
  attestorCompanyName?: string;
  attestorSalutation?: string;
  attestorFirstName?: string;
  attestorLastName?: string;
  attestorEmail?: string;
  attestorEmailConfirm?: string;
  attestorText?: string;
  attestorFrequency?: number;
  attestorFrequencyDays?: any;
  attestorPeriodStartDate?: Date;
  attestorPeriodEndDate?: Date;
  isAttestorNoEndDate?: boolean;
  claimVerificationFirstLoad?: boolean;
  attestors?: Array<any>;
  formMethods?: any;
  showPopUp?: any;
  verificationLinks?: Array<any>;
  links?: Array<any>;
}

interface CommonProps extends Props {
  useClaimLinks?: boolean;
  checkboxFrequency?: any;
  handleFrequencyCheckboxClick?: any;
}

const Common = ({
  checkboxFrequency,
  handleFrequencyCheckboxClick,
  attestorFrequencyDays,
  onChange,
  attestorPeriodStartDate,
  attestorPeriodEndDate,
  isAttestorNoEndDate,
  claimVerificationFirstLoad,
  links,
  verificationLinks,
  formMethods,
  useClaimLinks,
}: CommonProps) => {
  const {
    errors,
    control,
    getValues,
    register,
    triggerValidation,
    setValue,
    setError,
    clearError,
  } = formMethods;

  useEffect(() => {
    claimVerificationFirstLoad === false && triggerValidation();
    onChange('claimVerificationFirstLoad', false);
  }, []);

  useEffect(() => {
    claimVerificationFirstLoad === false &&
      triggerValidation(['attestorPeriodStartDate', 'attestorPeriodEndDate']);
  }, [isAttestorNoEndDate, attestorPeriodStartDate, attestorPeriodEndDate]);

  useEffect(() => {
    if (isAttestorNoEndDate) {
      onChange('attestorPeriodEndDate', '');
      setValue([{ attestorPeriodEndDate: '' }, { isAttestorNoEndDate }]);
    }
  }, [isAttestorNoEndDate]);

  useEffect(() => {
    setValue('verificationLinks', verificationLinks);
  }, [verificationLinks]);

  useEffect(() => {
    setValue('links', links);
  }, [links]);

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <Label>* {i18n.t('How frequently is attestation required?')}</Label>

          <div className="inline-checkbox">
            <div className="checkbox-wrapper">
              <Label>
                <Checkbox
                  onChange={() => handleFrequencyCheckboxClick(0)}
                  checked={checkboxFrequency === 0}
                  name="isAttestorOneTime"
                />
                {i18n.t('One time')}
              </Label>
            </div>

            <div className="checkbox-wrapper" data-tip data-for="eachlot">
              <Label>
                <Checkbox
                  disabled
                  onChange={() => handleFrequencyCheckboxClick(1)}
                  checked={checkboxFrequency === 1}
                  name="isAttestorRequiredForEachLot"
                />
                {i18n.t('Required for each lot/batch')}
              </Label>
              <CustomTooltip id="eachlot" placement="right">
                <span>{i18n.t('Coming soon!')}</span>
              </CustomTooltip>
            </div>

            <div className="checkbox-wrapper">
              <Label>
                <Checkbox
                  onChange={() => handleFrequencyCheckboxClick(2)}
                  checked={checkboxFrequency === 2}
                  name="isAttestorPeriodic"
                />
                {i18n.t('Periodic')}
              </Label>
            </div>
          </div>
        </div>

        <div className="col-md-3 col-sm-6 mt-4 mt-md-0">
          {checkboxFrequency === 2 && (
            <>
              <Label>
                {i18n.t('Frequency')}
                <div data-tip data-for="frequency">
                  <InfoIcon />
                </div>
                <CustomTooltip id="frequency" placement="right">
                  <span>
                    {i18n.t(
                      'Indicate how often the attestor will need to complete the attestation'
                    )}
                  </span>
                </CustomTooltip>
              </Label>
              <Controller
                as={
                  <FormInput
                    required
                    subType="number"
                    placeholder={i18n.t('Enter days')}
                    icon={FrequencyIcon}
                    min={1}
                  />
                }
                name="attestorFrequencyDays"
                value={attestorFrequencyDays}
                control={control}
                onChange={([evt]) => {
                  const value = evt.target.value;
                  if (!value || value > 0) {
                    onChange(evt.target.name, value);
                    return value;
                  } else {
                    onChange(evt.target.name, attestorFrequencyDays || '');
                    return attestorFrequencyDays;
                  }
                }}
                rules={Claim.frequency}
                defaultValue={attestorFrequencyDays}
              />
              {errors.attestorFrequencyDays && (
                <span className="inlineErrorMessage">
                  {errors.attestorFrequencyDays.message}.
                </span>
              )}
            </>
          )}
        </div>

        {(checkboxFrequency === 0 || checkboxFrequency === 2) && (
          <div className="col-12">
            <div>
              {useClaimLinks
                ? errors.links && (
                    <span className="inlineErrorMessage ml-0">
                      {errors.links.message}.
                    </span>
                  )
                : errors.verificationLinks && (
                    <span className="inlineErrorMessage ml-0">
                      {errors.verificationLinks.message}.
                    </span>
                  )}
            </div>
            <div className="mt-4">
              <MediaUpload
                accept={[
                  'image/png',
                  'image/jpg',
                  'image/jpeg',
                  'application/pdf',
                ]}
                maxFileSize={5 * 1024 * 1024}
                allowLinks={false}
                addButtonText={i18n.t('Add document')}
                handleChange={items => onChange('links', items)}
                proloadedItemsLinks={useClaimLinks ? links : verificationLinks}
                fileSizeErrorMessage={i18n.t('The file needs to be <5 MB')}
              />
            </div>
          </div>
        )}
      </div>

      {checkboxFrequency === 2 && (
        <>
          <div className="row mt-5">
            <div className="col-12">
              <Label>
                {i18n.t(
                  'During what period are attestation requests to be made?'
                )}
                <div data-tip data-for="request">
                  <InfoIcon />
                </div>
                <CustomTooltip id="request" placement="right">
                  <span>
                    {i18n.t('Send the first attestation request on this date')}
                  </span>
                </CustomTooltip>
              </Label>
            </div>

            <div className="col-md-8 col-sm-12">
              <div className="row">
                <div className="col">
                  <Label className="secondary">
                    {i18n.t('Next Trigger Date')}
                  </Label>
                  <Controller
                    as={
                      <DatePicker
                        placeholder={i18n.t('Select')}
                        required
                        value={attestorPeriodStartDate}
                        onChange={value =>
                          onChange('attestorPeriodStartDate', value)
                        }
                        disabledDays={{ before: new Date() }}
                      />
                    }
                    name="attestorPeriodStartDate"
                    value={attestorPeriodStartDate}
                    control={control}
                    inputRef={register}
                    rules={Claim.startDate(
                      isAttestorNoEndDate,
                      attestorPeriodEndDate
                    )}
                    onChange={([value]) => {
                      onChange('attestorPeriodStartDate', value);
                      triggerValidation('attestorPeriodStartDate');
                      return value;
                    }}
                    defaultValue={attestorPeriodStartDate}
                  />
                  {errors.attestorPeriodStartDate && (
                    <span className="inlineErrorMessage">
                      {errors.attestorPeriodStartDate.message}.
                    </span>
                  )}
                </div>

                <div className={`col ${isAttestorNoEndDate && 'disabled'} `}>
                  <Label className="secondary">
                    {i18n.t('Last Trigger Date')}
                  </Label>
                  <Controller
                    as={
                      <DatePicker
                        placeholder={i18n.t('Select')}
                        required={!isAttestorNoEndDate}
                        disabled={
                          isAttestorNoEndDate ? isAttestorNoEndDate : false
                        }
                        value={attestorPeriodEndDate}
                        onChange={value =>
                          onChange('attestorPeriodEndDate', value)
                        }
                        disabledDays={{ before: new Date() }}
                      />
                    }
                    name="attestorPeriodEndDate"
                    inputRef={register}
                    value={attestorPeriodEndDate}
                    control={control}
                    rules={Claim.endDate(
                      isAttestorNoEndDate,
                      attestorPeriodStartDate
                    )}
                    onChange={([value]) => {
                      onChange('attestorPeriodEndDate', value);
                      triggerValidation('attestorPeriodEndDate');
                      return value;
                    }}
                    defaultValue={attestorPeriodEndDate}
                  />
                  {errors.attestorPeriodEndDate && !isAttestorNoEndDate && (
                    <span className="inlineErrorMessage">
                      {errors.attestorPeriodEndDate.message}.
                    </span>
                  )}
                </div>

                <div className="col">
                  <div className="checkbox-wrapper">
                    <Label>
                      <Controller
                        as={
                          <Checkbox
                            name="isAttestorNoEndDate"
                            checked={
                              isAttestorNoEndDate ? isAttestorNoEndDate : false
                            }
                            onChange={evt =>
                              onChange(evt.target.name, evt.target.checked)
                            }
                          />
                        }
                        name="isAttestorNoEndDate"
                        inputRef={register}
                        value={isAttestorNoEndDate}
                        control={control}
                        onChange={([evt]) => {
                          onChange('isAttestorNoEndDate', evt.target.checked);
                          return evt.target.checked;
                        }}
                        defaultValue={isAttestorNoEndDate}
                      />
                      {i18n.t('No end date')}
                    </Label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const Content = ({
  onChange,
  checkboxSelected = 0,
  showPopUp,
  ...data
}: Props) => {
  const {
    attestorSelect,
    attestorText,
    attestorFrequency = 0,
    attestorFrequencyDays = '',
    attestorPeriodStartDate,
    attestorPeriodEndDate,
    isAttestorNoEndDate = false,
    claimVerificationFirstLoad = false,
    attestors = [],
    formMethods,
    verificationLinks = [],
    links = [],
  } = data;
  const [checkboxFrequency, setCheckboxFrequency] = useState(attestorFrequency);

  const useClaimLinks: boolean =
    links?.length && checkboxSelected === 2 ? true : false;

  const { errors, control, triggerValidation } = formMethods;

  useEffect(() => {
    claimVerificationFirstLoad === false && triggerValidation();
    onChange('claimVerificationFirstLoad', false);
  }, []);

  const handleFrequencyCheckboxClick = value => {
    setCheckboxFrequency(value);
    onChange('attestorFrequency', value);
  };

  const getSelectedAttestor = () => {
    let attestor = {} as any;
    if (attestorSelect) {
      attestor = attestors.find(a => a._id == attestorSelect) || {};
    }
    return attestor;
  };

  useEffect(() => {
    if (attestorSelect) {
      const attestorInfo = getSelectedAttestor();
      onChange('attestorInfo', attestorInfo);
    }
  }, [attestorSelect]);

  const addAttestor = () => {
    showPopUp();
  };

  return {
    '0': <></>,
    '1': (
      <div className="col-12">
        <div className="row">
          <div className="col-lg-4 col-md-7 col-sm-7">
            <Label>
              {i18n.t('Select an attestor from your attestor list')}
              <div data-tip data-for="selectattestor">
                <InfoIcon />
              </div>
              <CustomTooltip id="selectattestor" placement="right">
                <span>
                  {i18n.t(
                    'Select the individual who will respond to the attestation request.'
                  )}
                </span>
              </CustomTooltip>
            </Label>
            <Controller
              as={
                <Dropdown
                  required
                  options={attestors.map(a => ({
                    value: a._id,
                    label: a.shortName,
                  }))}
                  placeholder={i18n.t('Select')}
                  defaultOption={attestorSelect}
                  action={newValue => onChange('attestorSelect', newValue)}
                  searchable={true}
                />
              }
              name="attestorSelect"
              value={attestorSelect}
              control={control}
              onChange={([newValue]) => {
                onChange('attestorSelect', newValue);
                return newValue;
              }}
              rules={Claim.attestor}
              defaultValue={attestorSelect}
            />
            {errors.attestorSelect && (
              <span className="inlineErrorMessage">
                {errors.attestorSelect.message}.
              </span>
            )}
          </div>

          <div className="col-lg-3 col-md-5 col-sm-5 d-flex align-items-end">
            <span data-tip data-for="addAttestor">
              <Button text={i18n.t('Add attestor')} action={addAttestor} />
            </span>
            <CustomTooltip id="addAttestor" placement="top">
              <span>
                {i18n.t(
                  'Click here to save your progress and create an attestor.'
                )}
              </span>
            </CustomTooltip>
          </div>

          <div className="col-lg-5 col-md-12 col-sm-12 mt-4 mt-lg-0 d-flex flex-column justify-content-between">
            <Label>
              {i18n.t('Company')}
              <div data-tip data-for="company">
                <InfoIcon />
              </div>
              <CustomTooltip id="company" placement="right">
                <span>
                  {i18n.t('Organization that the attestor belongs to.')}
                </span>
              </CustomTooltip>
            </Label>
            <FormInput
              disabled
              name="attestorCompanyName"
              value={getSelectedAttestor().company}
              placeholder={i18n.t('Company name')}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6 col-sm-12 mt-4 mt-md-0">
            <Label>{i18n.t('First name')}</Label>
            <FormInput
              disabled
              name="attestorFirstName"
              value={getSelectedAttestor().firstName}
              placeholder={i18n.t('First name')}
            />
          </div>
          <div className="col-md-6 col-sm-12 mt-4 mt-md-0">
            <Label>{i18n.t('Last name')}</Label>
            <FormInput
              disabled
              name="attestorLastName"
              value={getSelectedAttestor().lastName}
              placeholder={i18n.t('Last name')}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6 col-sm-12">
            <Label>
              {i18n.t('Email')}
              <div data-tip data-for="email">
                <InfoIcon />
              </div>
              <CustomTooltip id="email" placement="right">
                <span>
                  {i18n.t(
                    'Email address that the attestation will be sent to.'
                  )}
                </span>
              </CustomTooltip>
            </Label>
            <FormInput
              disabled
              name="attestorEmail"
              value={getSelectedAttestor().email}
              placeholder={i18n.t('Email address')}
              subType="email"
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 mb-4">
            <Label>
              {i18n.t('Attestation text')}
              <div data-tip data-for="attestation">
                <InfoIcon />
              </div>
              <CustomTooltip id="attestation" placement="right">
                <span>
                  {i18n.t(
                    'When an attestation email is triggered by the system, the following text will be sent to the attestor to review and attest to.'
                  )}
                </span>
              </CustomTooltip>
            </Label>
            <Controller
              as={
                <FormInput
                  required
                  type="textarea"
                  placeholder={i18n.t('Enter attestation text')}
                  maxLength={300}
                />
              }
              name="attestorText"
              value={attestorText}
              control={control}
              onChange={([evt]) => {
                onChange(evt.target.name, evt.target.value);
                return evt.target.value;
              }}
              rules={Claim.attestorText}
              defaultValue={attestorText}
            />
            {errors.attestorText && (
              <span className="inlineErrorMessage">
                {errors.attestorText.message}.
              </span>
            )}
          </div>
        </div>

        <Common
          checkboxFrequency={checkboxFrequency}
          handleFrequencyCheckboxClick={handleFrequencyCheckboxClick}
          attestorFrequencyDays={attestorFrequencyDays}
          onChange={onChange}
          attestorPeriodStartDate={attestorPeriodStartDate}
          attestorPeriodEndDate={attestorPeriodEndDate}
          isAttestorNoEndDate={isAttestorNoEndDate}
          claimVerificationFirstLoad={claimVerificationFirstLoad}
          links={links}
          verificationLinks={verificationLinks}
          formMethods={formMethods}
          useClaimLinks={useClaimLinks}
        />
      </div>
    ),
    '2': (
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <Common
              checkboxFrequency={checkboxFrequency}
              handleFrequencyCheckboxClick={handleFrequencyCheckboxClick}
              attestorFrequencyDays={attestorFrequencyDays}
              onChange={onChange}
              attestorPeriodStartDate={attestorPeriodStartDate}
              attestorPeriodEndDate={attestorPeriodEndDate}
              isAttestorNoEndDate={isAttestorNoEndDate}
              claimVerificationFirstLoad={claimVerificationFirstLoad}
              formMethods={formMethods}
              links={links}
              verificationLinks={verificationLinks}
              useClaimLinks={useClaimLinks}
            />
          </div>
        </div>
      </div>
    ),
  }[checkboxSelected];
};

export default Content;
