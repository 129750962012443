import { ObjectID } from 'bson';
import { authAxiosCall } from '../axiosCall';
import {
  SaveAsset,
  SaveAssets,
  GetAssetActionType,
  SaveAssetActionType,
} from './types';
import { uploadMedia } from '../mediaService';
import { getUUIDForFile } from '../../../Lib/Utils/functions';
import { AxiosResponse } from 'axios';
import { isOrgAdmin } from '../../../Lib/Utils/auth';

import * as stepServices from '../stepService';

export const getAssetTemplates = async (
  includeDrafts?: boolean,
  payload?: any
): Promise<AxiosResponse<any> | undefined> => {
  let response = await authAxiosCall('/assettemplates?limit=1000', {
    method: 'GET',
    query: payload,
  });
  let assets = response && response.data ? response.data.data : [];

  assets = assets.filter(a => !a.archived);

  if (isOrgAdmin() && includeDrafts) {
    response = await authAxiosCall('/drafts/collections/assettemplates', {
      method: 'GET',
    });
    const drafts = response && response.data ? response.data.data : [];
    assets = assets.concat(
      drafts.map(d => ({
        ...d.draft,
        _id: d._id,
        assetId: d.draft._id,
        skuku: d._id,
        isDraft: true,
        createdAt: d.createdAt,
        updatedAt: d.updatedAt,
      }))
    );
  }
  return assets;
};

export const saveAssetTemplate = async (
  {
    _id,
    skuku,
    name,
    description,
    brandId,
    version = [],
    category,
    extras,
    ids,
    marketplaces,
    marketableProduct,
    previewVideo,
    photos = [],
    videos = [],
    urls,
    organizationId,
  }: SaveAsset,
  actionType: SaveAssetActionType
): Promise<AxiosResponse<any> | void> => {
  let path = '';
  let method = '';
  switch (actionType) {
    case 'create-draft':
      path = `/drafts/collections/assettemplates`;
      method = 'POST';
      break;
    case 'save-draft':
      path = `/drafts/${_id}`;
      method = 'PUT';
      break;
    case 'submit-create-entity':
      path = `/assettemplates/`;
      method = 'POST';
      break;
    case 'save-entity':
    case 'submit-save-entity':
      path = `/assettemplates/${skuku}`;
      method = 'PUT';
      skuku = undefined; // API does not support skuku on update
      break;
  }

  const assetPhotos = [] as Array<string>;
  for (const photo of photos) {
    if (photo && photo.file) {
      const filePath = `assets/${getUUIDForFile(_id)}_${name}/${
        photo.file.name
      }`;
      const [fileUrl] = await uploadMedia([
        {
          file: photo.file,
          path: filePath,
        },
      ]);
      if (fileUrl) {
        assetPhotos.push(fileUrl);
      }
    } else {
      assetPhotos.push(photo.src);
    }
  }

  const assetVideos = [] as Array<string>;
  for (const video of videos) {
    if (video && video.file) {
      const filePath = `assets/${getUUIDForFile(_id)}_${name}/${
        video.file.name
      }`;

      const [fileUrl] = await uploadMedia([
        {
          file: video.file,
          path: filePath,
        },
      ]);
      if (fileUrl) {
        assetVideos.push(fileUrl);
      }
    } else {
      assetVideos.push(video.src);
    }
  }

  let assetPreviewVideo: string | null;
  if (previewVideo && previewVideo.file) {
    assetPreviewVideo = (
      await uploadMedia([
        {
          file: previewVideo.file,
          path: `assets/${_id}/${previewVideo.file.name}`,
        },
      ])
    )[0];
  } else {
    assetPreviewVideo = previewVideo?.src || null;
  }

  const payload = {
    _id,
    skuku,
    name,
    description,
    brandId,
    version,
    category,
    extras,
    ids,
    marketplaces,
    photos: assetPhotos,
    previewVideo: assetPreviewVideo,
    videos: assetVideos,
    urls,
    marketableProduct,
    createdAt: new Date(),
    updatedAt: new Date(),
    organizationId,
  } as any;

  const response = await authAxiosCall(path, {
    method,
    body: JSON.stringify(payload),
  });

  if (actionType === 'submit-create-entity') {
    await deleteDraft(_id);
  }

  return response;
};

export const getAssetTemplate = async (
  skuku: string,
  actionType?: GetAssetActionType
): Promise<AxiosResponse<any> | undefined> => {
  const path =
    actionType === 'get-draft'
      ? `/drafts/${skuku}`
      : `/assettemplates/${skuku}`;

  return authAxiosCall(path, {
    method: 'GET',
  });
};

export const saveAssets = async (
  assets: Array<{
    skuku: string;
    lotId: string;
    version: number;
    attributes: any;
    assetId?: string | null;
    marketableProduct?: boolean;
  }>,
  inputs: boolean,
  stepId: string,
  complete?: boolean
) => {
  const newAssets = assets.filter(
    asset => asset.assetId === null || asset.assetId === undefined
  );
  const updateAssets = assets.filter(
    asset => asset.assetId !== null && asset.assetId !== undefined
  );

  const newCall =
    newAssets.length > 0
      ? authAxiosCall('/assets', {
          method: 'POST',
          body: JSON.stringify(newAssets),
        })
      : undefined;

  const updateCall = Promise.all(
    updateAssets.map(asset =>
      authAxiosCall(`/assets/${asset.assetId}`, {
        method: 'PUT',
        body: JSON.stringify(asset),
      })
    )
  );

  const newCallSolved = await newCall;
  await updateCall;

  let retVal = true as any;

  if (
    newCallSolved &&
    newCallSolved.data &&
    newCallSolved.data.data &&
    newCallSolved?.data?.data?.length > 0
  ) {
    const updateNewAssets = newCallSolved.data.data.map(asset => ({
      assetId: asset._id,
      skuku: asset.skuku,
    }));

    retVal = await authAxiosCall(
      `/steps/${stepId}/${inputs ? 'inputs' : 'outputs'}`,
      {
        method: 'PATCH',
        body: JSON.stringify(updateNewAssets),
      }
    );
  }

  if (complete) {
    retVal = stepServices.updateStep(stepId, { complete: true });
  }

  return retVal;
};

export const getAssetsArray = async assetIds =>
  authAxiosCall('/assets/assetIds', {
    method: 'POST',
    body: JSON.stringify(assetIds),
  });

export const getAssetAttributes = async payload =>
  authAxiosCall('/assets', {
    method: 'GET',
    query: payload && payload,
  });

export const getJourneyAssets = async (
  lotId: string,
  stepTemplateId: string,
  assets: Array<string>
) => {
  const assetsFromStep = assets.length > 0 && (await getAssetsArray(assets));
  const assetsFromStepData =
    assetsFromStep && assetsFromStep?.data ? assetsFromStep.data.data : [];

  let response = await authAxiosCall(`/steptemplates/${stepTemplateId}`, {
    method: 'GET',
  });
  const stepTemplate = response?.data ? response.data.data : {};

  const inputs = await Promise.all(
    stepTemplate.inputs.map(async input => {
      response = await getAssetTemplate(input.skuku);
      const assetTemplate = response?.data ? response.data.data : {};
      const assets = assetsFromStepData.filter(
        asset => asset.skuku === input.skuku && asset.lotId === lotId
      );

      return {
        assetTemplate,
        assets,
      };
    })
  );

  const outputs = await Promise.all(
    stepTemplate.outputs.map(async output => {
      response = await getAssetTemplate(output.skuku);
      const assetTemplate = response?.data ? response.data.data : {};
      const assets = assetsFromStepData.filter(
        asset => asset.skuku === output.skuku && asset.lotId === lotId
      );

      return {
        assetTemplate,
        assets,
      };
    })
  );

  return {
    inputs,
    outputs,
  };
};

export const deleteAssets = async (
  assets: Array<{ stepId: string; subDocumentId: string }>,
  inputs
) => {
  const allDeletes = assets.map(({ stepId, subDocumentId }) =>
    authAxiosCall(
      `/steps/${stepId}/${inputs ? 'inputs' : 'outputs'}/${subDocumentId}`,
      {
        method: 'DELETE',
      }
    )
  );

  return Promise.all(allDeletes);
};

export const deleteAssetTemplate = async (_id: string, isDraft: boolean) => {
  let response;
  if (isDraft) {
    response = await deleteDraft(_id);
  } else {
    response = await authAxiosCall(`/assettemplates/${_id}`, {
      method: 'PUT',
      body: JSON.stringify({
        archived: true,
      }),
    });
  }
  return response;
};

const deleteDraft = async (_id?: string) => {
  if (_id) {
    try {
      await authAxiosCall(`/drafts/${_id}`, {
        method: 'DELETE',
      });
    } catch (err) {
      // Do not throw draft might not exist
      console.log(`ERR removing draft`, err.message);
    }
  }
  return true;
};
