import React, { useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';

import { Claim } from '../../../Lib/Utils/Validations';
import {
  DetailsFormContainer as FormContainer,
  DetailsContainer as Container,
} from './styles';

import {
  Label,
  FormInput,
  InfoIcon,
  CustomTooltip,
  Dropdown,
  OrgSelector,
} from '../../../Components';

import { ClaimTypes } from '../../../Lib/Configs';

interface Props {
  claimShortName?: string;
  claimName?: string;
  claimType?: string;
  claimBullets?: string | Array<string>;
  claimDetailFirstLoad?: boolean;
  onChange: Function;
  formMethods?: any;
  organizationId?: string;
}

const ClaimDetails = ({ onChange, ...data }: Props) => {
  const {
    claimShortName,
    claimName,
    claimType,
    claimBullets,
    claimDetailFirstLoad = true,
    formMethods,
    organizationId,
  } = data;

  const { errors, control, triggerValidation, setValue } = formMethods;

  useEffect(() => {
    claimDetailFirstLoad === false && triggerValidation();
    onChange('claimDetailFirstLoad', false);
  }, []);

  useEffect(() => {
    if (data) {
      setValue('claimName', claimName);
      setValue('claimType', claimType);
      setValue('claimShortName', claimShortName);
      const lineBreakClaimBullets =
        claimBullets && typeof claimBullets === 'object'
          ? claimBullets.join('\n')
          : claimBullets;
      setValue('claimBullets', lineBreakClaimBullets);
      setValue('organizationId', organizationId);
    }
  }, [data]);

  return (
    <Container>
      <FormContainer className="row">
        <OrgSelector
          modelType="Claim"
          handleOrganizationChange={selected =>
            onChange('organizationId', selected)
          }
          defaultValue={organizationId}
        />
        <div className="col-12 col-lg-6">
          <Label>
            {i18n.t('Public claim name')}
            <div data-tip data-for="claimtitle">
              <InfoIcon />
            </div>
            <CustomTooltip id="claimtitle" placement="right">
              <span>
                {i18n.t('Claim title / text that will be seen by end consumer')}
              </span>
            </CustomTooltip>
          </Label>
          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Enter the public claim name')}
                maxLength={50}
              />
            }
            name="claimName"
            value={claimName}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Claim.shortName}
            defaultValue={claimName}
          />
          {errors.claimName && (
            <span className="inlineErrorMessage">
              {errors.claimName.message}.
            </span>
          )}
        </div>

        <div className="col-6 col-lg-3">
          <Label>
            {i18n.t('Claim type')}
            <div data-tip data-for="claimtype">
              <InfoIcon />
            </div>
            <CustomTooltip id="claimtype" placement="right">
              <span>
                {i18n.t('Select the type of claim and corresponding icon')}
              </span>
            </CustomTooltip>
          </Label>
          <Controller
            as={
              <Dropdown
                required
                options={ClaimTypes}
                placeholder={i18n.t('Select')}
                action={newValue => onChange('claimType', newValue)}
                defaultOption={claimType}
              />
            }
            name="claimType"
            value={claimType}
            control={control}
            onChange={([newValue]) => {
              onChange('claimType', newValue);
              return newValue;
            }}
            rules={Claim.claimType}
            defaultValue={claimType}
          />
          {errors.claimType && (
            <span className="inlineErrorMessage">
              {errors.claimType.message}.
            </span>
          )}
        </div>

        <div className="col-12 col-lg-6">
          <Label>
            {i18n.t('System claim name')}
            <div data-tip data-for="shortname">
              <InfoIcon />
            </div>
            <CustomTooltip id="shortname" placement="right">
              <span>
                {i18n.t(
                  'Friendly name for the claim that will be used throughout the SUKU system; this may be the same as the public name'
                )}
              </span>
            </CustomTooltip>
          </Label>
          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Enter the system claim name')}
                value={claimShortName}
                maxLength={50}
              />
            }
            name="claimShortName"
            value={claimShortName}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Claim.shortName}
            defaultValue={claimShortName}
          />
          {errors.claimShortName && (
            <span className="inlineErrorMessage">
              {errors.claimShortName.message}.
            </span>
          )}
        </div>

        <div className="col-12">
          <Label>
            {i18n.t('Claim bullets')}
            <div data-tip data-for="claimbullets">
              <InfoIcon />
            </div>
            <CustomTooltip id="claimbullets" placement="right">
              <p style={{ margin: 0 }}>
                {i18n.t('This content will be displayed to the end consumer')}
                <br />
                <br />
                {i18n.t('A bullet will be added to each line')}
                <br />
                <br />
                {i18n.t('International standard')}
              </p>
            </CustomTooltip>
          </Label>
          <Controller
            as={
              <FormInput
                required
                type="textarea"
                placeholder={i18n.t('Enter one claim per bullet')}
                maxLength={300}
                showChars
              />
            }
            name="claimBullets"
            value={claimBullets}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Claim.bullets}
            defaultValue={claimBullets}
          />
          {errors.claimBullets && (
            <span className="inlineErrorMessage">
              {errors.claimBullets.message}.
            </span>
          )}
        </div>
      </FormContainer>
    </Container>
  );
};

export default ClaimDetails;
