import React, { useState, useEffect, Dispatch, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import moment, { Moment } from 'moment';

import {
  doGetJourney,
  doSaveJourney,
  resetCurrentTemplate,
  doGetJourneyTemplate,
} from '../../../Redux/Journey/journeyActions';
import {
  Table,
  HeaderFilter,
  JourneyIcon,
  Link,
  Loading,
  PopUp,
  Label,
  FormInput,
} from '../../../Components';
import { Container, LotNumber } from './styles';
import { Controller, useForm } from 'react-hook-form';
import Journey from '../../../Lib/Utils/Validations/Journey';
import Toast from '../../../Lib/Utils/toast';
import { isFacilityManager, isOrgAdmin } from '../../../Lib/Utils/auth';
import { doDeleteJourney } from '../../../Redux/Journey/journeyActions';

import { isFirstOfParallels, findParallels } from './Product/helper';

const numberSort = ({ order: numberA }, { order: numberB }) => {
  if (numberA === -1) return 1;
  if (numberB === -1) return -1;

  if (numberA > numberB) {
    return 1;
  }
  if (numberB > numberA) {
    return -1;
  }
  return 0;
};

interface RootState {
  journey: any;
}

const mapState = (state: RootState) => ({
  currentJourney: state.journey.currentJourney,
  currentLot: state.journey.currentLot,
  currentTemplate: state.journey.currentTemplate,
  submitSuccess: state.journey.submitSuccess,
  loading: state.journey.loading,
  error: state.journey.error,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getJourneyAction: journeyId => dispatch(doGetJourney(journeyId)),
  saveJourneyAction: payload => dispatch(doSaveJourney(payload)),
  deleteJourneyAction: journeyId => dispatch(doDeleteJourney(journeyId)),
  resetFormAction: () => dispatch(resetCurrentTemplate()),
  getJourneyTemplateAction: journeyId =>
    dispatch(doGetJourneyTemplate(journeyId, 'get-entity')),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & { history?: any; location?: any; match?: any };

const ViewJourney = (props: Props) => {
  const [rows, setRows] = useState([] as any);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [filters, setFilters] = useState({});
  const [textFilter, setTextFilter] = useState('');
  const [journey, setJourney] = useState({} as any);
  const [showCreateLot, setShowCreateLot] = useState(false);
  const [lotId, setLotId] = useState(null);

  const headers = [
    { text: i18n.t('Lot ID'), order: true },
    { text: i18n.t('Start date'), order: true },
    { text: i18n.t('Last Submission'), order: true },
    { text: i18n.t('Next Facility'), order: true },
  ];

  const {
    history,
    location,
    match,
    getJourneyAction,
    saveJourneyAction,
    deleteJourneyAction,
    resetFormAction,
    currentJourney,
    loading,
    submitSuccess,
    currentLot,
    error,
    getJourneyTemplateAction,
    currentTemplate,
  } = props;

  useEffect(() => {
    getJourneyAction(match.params.journeyId || match.params.journeyTemplateId);

    getJourneyTemplateAction(match.params.journeyTemplateId);

    return () => {
      resetFormAction();
    };
  }, []);

  useEffect(() => {
    if (submitSuccess) {
      Toast.success(i18n.t('Your data has been successfully submitted.'));
      history.push(
        `/journeys/${match.params.journeyTemplateId}/view/${currentLot._id}`
      );
      setShowCreateLot(false);
    } else if (error) {
      Toast.error(error);
    }
  }, [error, submitSuccess]);

  useEffect(() => {
    if (currentJourney) {
      const defaultRows = currentJourney
        .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
        .map((row, index) => {
          const { lotId, _id, steps, templateLocator, createdAt } = row;

          const startDate = moment(createdAt).format('MM/DD/YYYY H:mm:ss');

          let endDate: Moment | string = moment(
            Math.max.apply(
              null,
              steps
                .flat()
                .map(({ step }) => step?.outputs)
                .flat()
                .map(output => new Date(output?.timestamp) || '')
            )
          );
          endDate = endDate.isValid()
            ? endDate.format('MM/DD/YYYY H:mm:ss')
            : '-';

          const noStaticOrParallelSteps = steps
            .sort(numberSort)
            .filter(({ step, order }, index) => {
              const parallelsTo = findParallels(steps, step, order, index);
              if (parallelsTo.length) {
                const haveCompletedSteps = parallelsTo.find(
                  ({ step }) => step.complete
                );
                return haveCompletedSteps === undefined;
              } else {
                return !step?.static;
              }
            });

          const facility = noStaticOrParallelSteps
            .sort(numberSort)
            .find(({ step }) => !step?.complete);

          const facilityName = facility
            ? facility.step &&
              facility.step.facility &&
              facility.step.facility.name
            : 'No awaiting tasks';

          return {
            id: _id,
            lotID: lotId,
            startDate,
            endDate,
            facilityName,
            navData: {
              url: `/journeys/${templateLocator}/view/${_id}`,
              data: { ...row },
            },
            templateLocator,
          };
        })
        .map(row => Object.values(row));

      setRows(defaultRows);

      !journey.journeyName &&
        setJourney({
          journeyName: currentJourney[0] && currentJourney[0].journeyName,
        });
    }
  }, [currentJourney]);

  const getLotsOptions = () =>
    currentJourney.map(({ lotId }) => ({ value: lotId, label: lotId }));

  const pagesBtw = 2;

  const selects = [
    {
      placeholder: i18n.t('Search by start date'),
      key: 'startDate',
      options: {},
      type: 'date',
    },
    {
      placeholder: i18n.t('Search by end date'),
      key: 'endDate',
      options: {},
      type: 'date',
    },
    {
      placeholder: i18n.t('Search Lot'),
      options: getLotsOptions(),
      key: 'lot',
    },
    // {
    //   placeholder: i18n.t('Search facilities'),
    //   options: {
    //     'Collins LLC': 'Collins LLC',
    //     'Homenick LLC': 'Homenick LLC',
    //     'Walsh-Nader': 'Walsh-Nader',
    //   },
    //   key: 'facility',
    // },
  ];

  const inputHandler = value => {
    setTextFilter(value);
  };

  const handleResetFilters = () => {
    setFilters({});
  };

  const applyFilters = () => {
    let newFilteredRows = rows;

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        if (key === 'lot') {
          newFilteredRows = newFilteredRows.filter(
            row => row[1] == filters[key]
          );
        }

        if (key === 'startDate') {
          newFilteredRows = newFilteredRows.filter(
            row => row[2] == moment(filters[key]).format('MM/DD/YYYY')
          );
        }

        if (key === 'endDate') {
          newFilteredRows = newFilteredRows.filter(
            row => row[3] == moment(filters[key]).format('MM/DD/YYYY')
          );
        }

        if (key === 'facility') {
          newFilteredRows = newFilteredRows.filter(
            row => row[3] == filters[key]
          );
        }
      }
    });

    if (textFilter && textFilter !== '') {
      newFilteredRows = newFilteredRows.filter(
        row =>
          row
            .join(' ')
            .toUpperCase()
            .search(textFilter.toUpperCase()) !== -1
      );
    }

    setFilteredRows(newFilteredRows);
  };

  useEffect(() => applyFilters(), [filters, textFilter, rows]);

  const { errors, control, setValue, triggerValidation, getValues } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    setValue([{ lotId }]);
  }, [lotId]);

  const handleCreateLot = async () => {
    const isValid = await triggerValidation();

    if (isValid) {
      const lotId = getValues('lotId');
      const lotExists = currentJourney?.some(
        j => j.lotId.toUpperCase() === lotId.toUpperCase()
      );

      if (!lotExists) {
        // setShowCreateLot(false);
        saveJourneyAction({
          templateLocator: match.params.journeyTemplateId,
          lotId,
        });
      } else {
        Toast.error(i18n.t('Lot/Batch ID already exists.'));
      }
    }
  };

  const handleArchiveLot = async lot => {
    await deleteJourneyAction(lot[0]);
    getJourneyAction(match.params.journeyTemplateId);
  };

  return (
    <>
      <Container>
        <HeaderFilter
          title={i18n.t('Product Journeys')}
          titleLink="/journeys"
          subtitle={currentTemplate?.journeyName}
          titleIcon={<JourneyIcon />}
          searchPlaceholder={i18n.t('Search all columns')}
          actionButtonText={`+ ${i18n.t('New Lot/Batch')}`}
          setFilters={setFilters}
          inputHandler={inputHandler}
          actionButtonHandler={() => {
            setShowCreateLot(true);
            setTimeout(() => {
              const docObj = document as any;
              docObj.getElementById('lotBatchInput').focus();
            }, 200);
            return null;
          }}
          selects={selects}
          useDropdown={true}
          resetFiltersHandler={handleResetFilters}
          actionButton={!!isFacilityManager()}
          name="journeyDetails"
        />
        <Table
          headers={headers}
          rows={filteredRows}
          pagination
          rowsPerPage={20}
          pagesBtw={pagesBtw}
          ignoreKeys={[0, 5, 6]}
          editable
          handleEdit={data =>
            history.push(`/journeys/${data[6]}/view/${data[0]}`)
          }
          archiveContent={i18n.t(
            'Are you sure you want to archive this lot? Archived lots will not be accessible to any supply chain participants or end consumers.'
          )}
          archivable={!!isOrgAdmin()}
          archiveTitle={i18n.t('Archieve Lot?')}
          handleArchive={handleArchiveLot}
          clickable
          navDataKey={5}
          handleBlockchainProof={data =>
            history.push(`/journeys/${data[6]}/proof/${data[0]}`, {
              journeyTemplateId: match.params.journeyTemplateId,
            })
          }
        />
        <PopUp
          enterConfirm={true}
          cancelAction={() => setShowCreateLot(false)}
          confirmAction={handleCreateLot}
          show={showCreateLot}
          showAlertIcon={false}
          closeOnConfirm={false}
          cancelText={i18n.t('Cancel')}
          confirmText={i18n.t('Submit')}
          title={i18n.t('Create new lot/batch')}
          loading={loading}
          content={
            <LotNumber>
              <Controller
                as={
                  <FormInput
                    required
                    placeholder={i18n.t('Enter the lot/batch number')}
                    maxLength={30}
                    autoFocus={true}
                    id="lotBatchInput"
                  />
                }
                name="lotId"
                value={lotId}
                control={control}
                onChange={([evt]) => {
                  setLotId(evt.target.value);
                  return evt.target.value;
                }}
                rules={Journey.lotId()}
                defaultValue={lotId}
              />
              {errors.lotId && (
                <span className="inlineErrorMessage">
                  {errors.lotId.message}.
                </span>
              )}
            </LotNumber>
          }
        />
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(ViewJourney) as any) as any
);
