import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { doGetUsers, doDeleteUser } from '../../../Redux/User/userActions';
import { doGetFacilities } from '../../../Redux/Facility/facilityActions';
import { doGetOrganizations } from '../../../Redux/Organization/organizationActions';
import {
  Table,
  HeaderFilter,
  Loading,
  PermissionInfo,
} from '../../../Components';
import { Container } from './styles';
import moment from 'moment';
import { FaUserAlt } from 'react-icons/fa';
import { isOrgAdmin } from '../../../Lib/Utils/auth';
import Toast from '../../../Lib/Utils/toast';

interface RootState {
  user: any;
  facility: any;
  organization: any;
}

const mapState = (state: RootState) => ({
  users: state.user.users,
  userLoading: state.user.loading,
  error: state.user.error,
  deleteSuccess: state.user.deleteSuccess,
  facilities: state.facility.facilities,
  facilitiesLoading: state.facility.loading,
  organizations: state.organization.organizations,
  organizationsLoading: state.organization.loading,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getUsersAction: () => dispatch(doGetUsers(false)),
  deleteUserAction: userId => dispatch(doDeleteUser(userId)),
  getFacilitiesAction: () => dispatch(doGetFacilities()),
  getOrganizationsAction: () => dispatch(doGetOrganizations()),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & { history?: any; location?: any };

const UsersList = (props: Props) => {
  const {
    history,
    users,
    userLoading,
    getUsersAction,
    deleteUserAction,
    error,
    deleteSuccess,
    getFacilitiesAction,
    facilitiesLoading,
    facilities,
    organizations,
    organizationsLoading,
    getOrganizationsAction,
  } = props;

  const loading = userLoading || facilitiesLoading || organizationsLoading;

  const [rows, setRows] = useState([] as any);
  const [filteredRows, setFilteredRows] = useState([] as any);
  const [filters, setFilters] = useState({});
  const [textFilter, setTextFilter] = useState('');

  useEffect(() => {
    getUsersAction();
    getFacilitiesAction();
    getOrganizationsAction();
  }, []);

  useEffect(() => {
    if (error) {
      Toast.error(error);
    } else if (deleteSuccess) {
      Toast.success(i18n.t(`The facility was discarded`));
      getUsersAction();
    }
  }, [error, deleteSuccess]);

  useEffect(() => {
    if (facilities.length && organizations.length) {
      const defaultRows = users
        .sort((a, b) =>
          moment(a.createdTimestamp || a.createdAt).isBefore(
            moment(b.createdTimestamp || a.createdAt)
          )
            ? 1
            : -1
        )
        .map(user => {
          const {
            _id,
            firstName,
            lastName,
            email,
            username,
            createdAt,
            accessControl,
          } = user;

          const PermissionsComponent = () => (
            <div className="d-flex justify-content-end">
              <PermissionInfo
                id={_id}
                facilities={facilities}
                organizations={organizations}
                accessControl={accessControl}
              />
            </div>
          );

          return {
            id: _id, // isDraft ? _id : id?.replace(new RegExp(/\-/g), ''),
            name: `${firstName || ''} ${lastName || ''}`,
            email: email || username,
            createdAt: moment(createdAt).format('MM/DD/YYYY'),
            permissions: <PermissionsComponent />,
            inProgress: false,
            objectId: _id, // isDraft ? _id : id,
          };
        })
        .map(row => Object.values(row));
      setRows(defaultRows);
    }
  }, [users, facilities, organizations]);

  const headers = [
    { text: i18n.t('ID'), order: true },
    { text: i18n.t('Name'), order: true },
    { text: i18n.t('Email'), order: true },
    { text: i18n.t('Created'), order: true },
    { text: '', order: false, key: 'permissions' },
    { text: '', order: false, key: 'inProgress' },
  ];

  const inputHandler = value => {
    setTextFilter(value);
  };

  const handleResetFilters = () => {
    setFilters({});
  };

  const applyFilters = () => {
    let newFilteredRows = rows;

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        if (key === 'inprogress') {
          newFilteredRows = newFilteredRows.filter(row => !!row[4]);
        }
      }
    });

    if (textFilter && textFilter !== '') {
      newFilteredRows = newFilteredRows.filter(
        row =>
          row
            .join(' ')
            .toUpperCase()
            .search(textFilter.toUpperCase()) !== -1
      );
    }

    setFilteredRows(newFilteredRows);
  };

  useEffect(() => applyFilters(), [filters, textFilter, rows]);

  const deleteUser = userId => {
    deleteUserAction(userId);
  };

  return (
    <>
      <Container>
        <HeaderFilter
          title={i18n.t('Existing Users')}
          titleIcon={<FaUserAlt color="rgb(216, 244, 12)" />}
          searchPlaceholder={i18n.t('Search all columns')}
          actionButtonText={`+ ${i18n.t('New User')}`}
          setFilters={setFilters}
          inputHandler={value => inputHandler(value)}
          actionButtonHandler={() => history.push('/users/draft')}
          selects={[]}
          resetFiltersHandler={handleResetFilters}
          name="users"
          withFilters={false}
          actionButton={!!isOrgAdmin()}
        />
        <Table
          headers={headers}
          rows={filteredRows}
          pagination
          rowsPerPage={20}
          pagesBtw={2}
          ignoreKeys={[5, 6]}
          editable={!!isOrgAdmin()}
          deletable={!!isOrgAdmin()}
          handleEdit={data => {
            data[5]
              ? history.push(`/users/${data[6]}/draft`)
              : history.push(`/users/${data[6]}/edit`);
          }}
          deleteTitle={i18n.t('Are you sure you want to delete this user?')}
          deleteContent={i18n.t(
            'This user will no longer be able to log into the system.'
          )}
          handleDelete={data => deleteUser(data[0])}
        />
      </Container>
      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(UsersList) as any) as any
) as any;
