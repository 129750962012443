export enum EUserActionTypes {
  LOGIN_USER = 'LOGIN_USER',
  LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS',
  LOGIN_USER_ERROR = 'LOGIN_USER_ERROR',

  GET_USERS = 'GET_USERS',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_ERROR = 'GET_USERS_ERROR',

  GET_USER = 'GET_USER',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_ERROR = 'GET_USER_ERROR',

  DELETE_USER = 'DELETE_USER',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR = 'DELETE_USER_ERROR',

  SAVE_USER = 'SAVE_USER',
  SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS',
  SAVE_USER_ERROR = 'SAVE_USER_ERROR',

  GET_ORGANIZATIONS = 'GET_ORGANIZATIONS',
  GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS',
  GET_ORGANIZATIONS_ERROR = 'GET_ORGANIZATIONS_ERROR',

  RESET_STATE = 'RESET_STATE',
}
