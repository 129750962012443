import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import {
  Container,
  List,
  ListItem,
  TitleContainer,
  ActionsContainer,
  ContentContainer,
  IconContainer,
} from './styles';

import { Button, PencilButton, TrashIcon, PermissionInfo } from '../index';

interface Props {
  items: Array<{
    title?: any;
    content?: any;
    icon?: any;
    data?: any;
  }>;
  defaultActiveIndex?: number;
  activeAction?: (any?, number?) => void;
  deleteAction?: (number?) => void;
  editAction?: (number?) => void;
  facilities?: Array<any>;
  organizations?: Array<any>;
}

const ItemsList = ({
  items,
  defaultActiveIndex,
  activeAction = undefined,
  deleteAction = undefined,
  editAction = undefined,
  facilities = [],
  organizations = [],
}: Props) => {
  const [active, setActive] = useState(defaultActiveIndex || null);

  useEffect(() => {
    defaultActiveIndex && setActive(defaultActiveIndex);
  }, [defaultActiveIndex]);

  const handleClick = (item, index) => {
    setActive(index);
    activeAction && activeAction(item.data, index);
  };

  const handleDelete = index => {
    deleteAction && deleteAction(index);
  };

  const handleEdit = index => {
    editAction && editAction(index);
  };

  return (
    <Container>
      <List>
        {items &&
          items.map((item, index) => {
            const { data, title, content, icon } = item;

            const isActive = active === index;
            return (
              <ListItem
                className={isActive ? 'active' : ''}
                key={`${index}-${title}`}
                onClick={() => handleClick(item, index)}
              >
                {title && (
                  <TitleContainer>
                    {icon && (
                      <IconContainer className={isActive ? 'active' : ''}>
                        {icon()}
                      </IconContainer>
                    )}
                    {title}
                  </TitleContainer>
                )}
                {content && <ContentContainer>{content}</ContentContainer>}
                <ActionsContainer>
                  {data.accessControl && (
                    <PermissionInfo
                      id={data._id}
                      facilities={facilities}
                      organizations={organizations}
                      accessControl={data.accessControl}
                    />
                  )}
                  {deleteAction && (
                    <Button
                      width={24}
                      height={24}
                      icon={<TrashIcon color="#fff" />}
                      type="roundDark"
                      action={evt => {
                        evt.stopPropagation();
                        handleDelete(index);
                      }}
                    />
                  )}
                  {editAction && (
                    <Button
                      type="text"
                      action={() => handleEdit(index)}
                      text={<PencilButton />}
                    />
                  )}
                </ActionsContainer>
              </ListItem>
            );
          })}
      </List>
    </Container>
  );
};

export default ItemsList;
