import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

import { JourneyIcon, Title, Steps, Loading } from '../../../../Components';

import { Container, StepsContainer } from './styles';

import StepContent from './StepContent';
import Header from './Header';
import { FacilityTypeIcons } from '../../../../Lib/Configs';
import { orderSort } from '../../../../Lib/Utils/functions';

import { Step } from '../../../../Components/Steps/types';

import {
  doGetProduct,
  doCleanProduct,
} from '../../../../Redux/Product/productActions';
import { doAddClaimVerification } from '../../../../Redux/Claim/claimActions';
import {
  doSaveAssets,
  doDeleteJourneyAssets,
} from '../../../../Redux/Asset/assetActions';
import { doUpdateStep } from '../../../../Redux/Step/stepActions';

import { isFirstOfParallels, findParallels } from './helper';

import { QrCodesType } from './Header';

interface RootState {
  journey: any;
  claim: any;
  asset: any;
  step: any;
  product: any;
}

const mapState = (state: RootState) => ({
  productRedux: state.product,
  assetRedux: state.asset,
  claimRedux: state.claim,
  stepRedux: state.step,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getProductAction: journeyId => dispatch(doGetProduct(journeyId)),
  cleanProductAction: () => dispatch(doCleanProduct()),
  doAddClaimVerificationAction: (verificationId, files) =>
    dispatch(doAddClaimVerification(verificationId, files)),
  doSaveAssetsAction: (assets, inputs = true, stepId, complete = false) =>
    dispatch(doSaveAssets(assets, inputs, stepId, complete)),
  doUpdateStepAction: (stepId, payload) =>
    dispatch(doUpdateStep(stepId, payload)),
  doDeleteJourneyAssetsAction: (assets, input = true) =>
    dispatch(doDeleteJourneyAssets(assets, input)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  history?: any;
  location?: any;
  match?: any;
  currentUser?: any;
};

const ViewJourneyProduct = (props: Props) => {
  const {
    history,
    location,
    match,
    getProductAction,
    cleanProductAction,
    doAddClaimVerificationAction,
    doSaveAssetsAction,
    doUpdateStepAction,
    doDeleteJourneyAssetsAction,
    assetRedux,
    productRedux,
    claimRedux,
    stepRedux,
  } = props;

  const loadingProduct = productRedux.loading;
  const product = productRedux.product;
  const loadingStep = stepRedux.loading;
  const assetLoading = assetRedux.loading;
  const assetSaveLoading = assetRedux.saveLoading;
  const addVerification = claimRedux.addVerification;
  const stepSubmitSuccess = stepRedux.submitSuccess;

  const [customLoading, setCustomLoading] = useState(true);
  const [activeStepNumber, setActiveStepNumber] = useState(
    null as number | null
  );
  useEffect(() => {
    if (activeStepNumber !== null) {
      setCustomLoading(false);
    }
  }, [activeStepNumber]);

  const defaultActiveStepNumber =
    activeStepNumber || (product?.steps?.length ? product.steps[0].number : 0);

  const loading =
    loadingProduct ||
    assetLoading ||
    loadingStep ||
    customLoading ||
    assetSaveLoading;

  const { journeyId } = match?.params;

  const getJourney = () => {
    if (journeyId) {
      getProductAction(journeyId);
    }
  };

  useEffect(() => {
    getJourney();
    return () => {
      cleanProductAction();
    };
  }, []);

  const getDefaultActiveStep = () => {
    const query = new URLSearchParams(location.search);
    const stepFromQuery = query.get('step');
    return stepFromQuery ? parseInt(stepFromQuery) : null;
  };

  useEffect(() => {
    if (location && product?.steps) {
      const stepFromQuery = getDefaultActiveStep();
      if (stepFromQuery !== null) {
        if (stepFromQuery !== activeStepNumber) {
          setActiveStepNumber(stepFromQuery);
        }
      } else {
        const firstOfNotCompleted = product?.steps
          ?.sort(orderSort)
          .find(({ step }, index) =>
            isFirstOfParallels(product?.steps, step, index) ||
            step.parallelToPreviousStep
              ? !step.complete
              : !step.complete && !step.static
          );
        setActiveStepNumber(firstOfNotCompleted?.order || 0);
      }
    }
  }, [location, product]);

  const handleChange = () => {
    // setJourneyProduct({ ...journeyProduct, [name]: value });
  };

  const handleStepChange = (number, subStep = false) => {
    if (subStep) {
      history.push({
        search: `?subStep=${number}`,
      });
    } else {
      history.push({
        search: `?step=${number}`,
      });
      setActiveStepNumber(number);
    }
  };

  const inputAssets = product?.steps
    .map(({ step }) => step.populatedInputs)
    .flat();

  const inputAssetTemplates = product?.steps
    .map(({ template }) => template.inputs)
    .flat();

  const outputAssets = product?.steps
    .map(({ step }) => step.populatedOutputs)
    .flat();

  const outputAssetTemplates = product?.steps
    .map(({ template }) => template.outputs)
    .flat();

  const qrCodesInput = ((inputAssetTemplates &&
    inputAssetTemplates.length &&
    inputAssetTemplates.map(assetTemplate => ({
      lotId: product?.lotId,
      skuku: assetTemplate.skuku,
      name: assetTemplate.template.name,
    }))) ||
    []) as QrCodesType;

  const qrCodesOutput = ((outputAssetTemplates &&
    outputAssetTemplates.length &&
    outputAssetTemplates.map(assetTemplate => ({
      lotId: product?.lotId,
      skuku: assetTemplate.skuku,
      name: assetTemplate.template.name,
    }))) ||
    []) as QrCodesType;

  const uniqueQrCodesInput = qrCodesInput.filter(
    (v, i, a) => a.findIndex(t => t.skuku === v.skuku) === i
  );
  const uniqueQrCodesOutput = qrCodesOutput.filter(
    (v, i, a) => a.findIndex(t => t.skuku === v.skuku) === i
  );

  const qrCodes = uniqueQrCodesInput.concat(uniqueQrCodesOutput);

  const populateInboundsFromPreviousOutbounds = currentStep => {
    if (product && product.steps) {
      const currentStepIndex = product.steps.findIndex(
        ({ step }) => step._id === currentStep._id
      );

      if (currentStepIndex > 0) {
        const previousStep = product.steps[currentStepIndex - 1];
        return previousStep?.step?.populatedOutputs;
      }
    }
    return [];
  };

  let stepNumber = 0;
  const stepsMapped: Array<Step> = product?.steps
    ?.sort(orderSort)
    .map(({ order, step, template }, index) => {
      stepNumber = step.parallelToPreviousStep ? stepNumber : stepNumber + 1;
      const firstOfParallel = isFirstOfParallels(product?.steps, step, index);

      const lotClaims = step.claims?.filter(
        claim =>
          claim.supportingVerifications &&
          claim.supportingVerifications[0] &&
          claim.supportingVerifications[0].renewalType === 'journey'
      );

      const onetimeClaims = step.claims?.filter(
        claim => claim.links && claim.links.length > 0
      );

      const othersClaims = step.claims?.filter(
        claim =>
          claim.supportingVerifications.length === 0 && claim.links.length === 0
      );

      const parallelTo = findParallels(product?.steps, step, order, index);

      const checked =
        firstOfParallel || step.parallelToPreviousStep
          ? parallelTo.filter(({ step }) => step.complete).length > 0
          : step.complete || step.static;

      const previousStep = product?.steps
        ?.sort(orderSort)
        .reverse()
        .find(
          ({ order: pOrder }) =>
            order > pOrder &&
            !parallelTo.map(({ order: pToOrder }) => pToOrder).includes(pOrder)
        );

      const previousCheckedFunction = () => {
        if (previousStep === undefined) return true;

        if (previousStep.step.parallelToPreviousStep) {
          const indexOfPreviousStep = product?.steps
            ?.sort(orderSort)
            .indexOf(
              product?.steps.find(({ order }) => order === previousStep.order)
            );
          const parallelToPreviousStep = findParallels(
            product?.steps,
            previousStep.step,
            previousStep.order,
            indexOfPreviousStep
          );
          const completedOrStaticPreviousParallels = parallelToPreviousStep.find(
            ({ step }) => step.complete
          );
          return completedOrStaticPreviousParallels;
        } else {
          return previousStep.step.complete || previousStep.step.static;
        }
      };
      const previousChecked = previousCheckedFunction();

      const nextChecked =
        product?.steps?.sort(orderSort)[index + 1] &&
        product?.steps?.sort(orderSort)[index + 1].step.complete;

      const previousOutputs = populateInboundsFromPreviousOutbounds(step);

      const subTitle = () => {
        if (firstOfParallel && parallelTo.find(({ step }) => step.complete)) {
          return parallelTo.find(({ step }) => step.complete).step.facility
            .name;
        } else {
          return step.facility.name;
        }
      };

      return {
        parallelTo,
        loading,
        number: order,
        checked,
        showDate: step.showDate,
        claims: step.claims,
        createdAt: step.createdAt,
        facilityId: step.facility._id,
        value: step.facilityType,
        title: `Step ${stepNumber}`,
        subTitle: subTitle(),
        isParallel: step.parallelToPreviousStep,
        firstOfParallel,
        icon: FacilityTypeIcons[step.facilityTypeIcon || step.facilityType],
        facilities: firstOfParallel
          ? parallelTo.map(({ step }) => step.facility.name)
          : [],
        done: false,
        complete: step.complete,
        content: StepContent,
        facilityName: step.facility.name,
        contentProps: {
          number: order,
          step,
          complete: step.complete,
          template,
          claims: step.claims,
          lotClaims,
          onetimeClaims,
          othersClaims,
          lotId: product?.lotId,
          doAddClaimVerificationAction,
          doSaveAssetsAction,
          doUpdateStepAction,
          doDeleteJourneyAssetsAction,
          asset: assetRedux,
          addVerification,
          stepSubmitSuccess,
          nextChecked,
          previousChecked,
          previousOutputs,
          getJourney,
          loading,
        },
      };
    });

  return (
    <>
      <Container>
        <Title
          title={i18n.t('Product Journeys')}
          titleLink="/journeys"
          icon={<JourneyIcon />}
          subtitleArray={
            product?.journeyName && product?.lotId
              ? [product?.journeyName, `${product?.lotId}`]
              : []
          }
          links={
            product?.journeyName && product?.lotId
              ? [`/journeys/${product?.templateLocator}/view`]
              : []
          }
        />
      </Container>
      <StepsContainer>
        <Steps
          steps={stepsMapped}
          header={
            <Header
              lotId={product?.lotId}
              handleChange={handleChange}
              qrCodes={qrCodes}
            />
          }
          onChange={handleStepChange}
          loading={loading}
          defaultStep={defaultActiveStepNumber}
        />
      </StepsContainer>
      {loading && <Loading />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(ViewJourneyProduct) as any) as any
);
