import React, { useEffect } from 'react';
import { FormInput, QrPdf } from '../../../../Components';
import {
  HeaderInput,
  LotLabel,
  LotInputContainer,
  QRHeadContainer,
} from './styles';
import { Controller, useForm } from 'react-hook-form';
import { Journey } from '../../../../Lib/Utils/Validations';
import i18n from 'i18next';

export type QrCodesType = Array<{
  lotId: string;
  skuku: string;
  name: string;
}>;

type Props = {
  lotId: string;
  handleChange: Function;
  qrCodes: QrCodesType;
};

const Header = ({ lotId, handleChange, qrCodes }: Props) => {
  const { errors, control, setValue } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    setValue('lotId', lotId);
  }, [lotId]);

  return (
    <div className="col-12 mb-3 mt-1 p-0">
      <HeaderInput className="justify-content-end">
        <LotLabel className="mr-2">
          Lot <span>#</span>
        </LotLabel>
        <LotInputContainer lotChars={lotId?.length}>
          <Controller
            as={
              <FormInput
                placeholder="Lot ID"
                styleType="roundBorder"
                maxLength={30}
              />
            }
            name="lotId"
            value={lotId}
            control={control}
            onChange={([evt]) => {
              handleChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Journey.lotId()}
            defaultValue={lotId}
            disabled={true}
          />
          {errors.lotId && (
            <span className="inlineErrorMessage">{errors.lotId.message}.</span>
          )}
        </LotInputContainer>
        <QRHeadContainer>
          <QrPdf
            qr={qrCodes}
            tooltip={
              qrCodes.length === 0
                ? i18n.t(
                    'No inbound or outbound assets to generate QR codes for.'
                  )
                : i18n.t(
                    'Generate QR codes for all the assets at this facility.'
                  )
            }
            disabled={qrCodes.length === 0}
          />
        </QRHeadContainer>
      </HeaderInput>
    </div>
  );
};

export default Header;
