import styled from 'styled-components';
import OrderType from './OrderType';

const Container = styled.div``;

const TableContainer = styled.div`
  border-radius: 0 25px 25px 25px;
  overflow: hidden;
  overflow-x: auto;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  padding-bottom: 15px;
`;

const Table = styled.table`
  border-spacing: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  text-align: left;
  flex-direction: column;
  th,
  td {
    padding: 5px;
    vertical-align: middle;
    border: none;
    text-align: left;
  }

  th {
    text-align: left;
  }
`;

const TableRow = styled.tr<{ clickable?: boolean }>`
  height: 40px;
  align-items: center;
  padding: 0 30px;

  td:first-child,
  th:first-child {
    padding-left: 30px;
  }

  td:last-child,
  th:last-child {
    padding-right: 15px;
  }

  ${({ clickable }) =>
    clickable &&
    `
  cursor: pointer;  
  tr&:hover {
    td:first-child{ 
      color: #A7BF2E;
    }
  } 
  `};
`;

const TableHead = styled.thead`
  background: radial-gradient(
    50% 25628.48% at 50% 0%,
    #3a3a3a 0%,
    #000000 100%
  );
  text-transform: uppercase;
  color: #ffffff;
  height: 50px;
  ${TableRow} {
    height: 100%;
  }
`;

const TableHeadCell = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
  height: 50px;
`;

const TableCell = styled.td<{ isId?: boolean }>`
  flex: 1;
  padding: 0 5px;

  &.action-cell {
    width: 35px;
    padding: 0px;

    button {
      transition: all 0.2s ease-in-out;
      &:hover {
        opacity: 0.5;
      }
    }
  }

  ${({ isId }) => isId && `width: 100px;`}
`;

const FriendlyId = styled.span`
  cursor: pointer;
`;

const TableBody = styled.tbody`
  & ${TableRow}:nth-child(even) {
    background-color: #f9f9fb;
  }
`;

const PaginationArrow = styled.div`
  background-color: #a7bf2e;
  font-weight: 600;
  color: #fff;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 10px;
  padding: 0;
  &.inactive {
    background-color: #75757550;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
`;

const PaginationList = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  & li {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    text-align: center;
    cursor: pointer;
    border-radius: 100%;
    color: #757575;
    &.active {
      background: #a7bf2e;
      font-weight: 600;
      color: #fff;
    }
  }
`;

const OrderIconContainer = styled.span<{ order: OrderType }>`
  display: flex;
  margin-right: 10px;
  & svg {
    & path:last-child {
      fill: white;
      ${({ order }) =>
        order && (order === 'down' ? `fill: white` : `fill: #D8F40C`)};
    }
    & path:first-child {
      fill: white;
      ${({ order }) =>
        order && (order === 'up' ? `fill: white` : `fill: #D8F40C`)};
    }
  }
`;

const HeadTextContainer = styled.span`
  display: flex;
`;

export {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  PaginationContainer,
  PaginationList,
  PaginationArrow,
  TableHeadCell,
  OrderIconContainer,
  HeadTextContainer,
  FriendlyId,
};
