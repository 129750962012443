export enum ECampaignActionTypes {
  GET_CAMPAIGNS = 'GET_CAMPAIGNS',
  GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS',
  GET_CAMPAIGNS_ERROR = 'GET_CAMPAIGNS_ERROR',

  GET_CAMPAIGN = 'GET_CAMPAIGN',
  GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS',
  GET_CAMPAIGN_ERROR = 'GET_CAMPAIGN_ERROR',

  SAVE_CAMPAIGN = 'SAVE_CAMPAIGN',
  SAVE_CAMPAIGN_SUCCESS = 'SAVE_CAMPAIGN_SUCCESS',
  SAVE_CAMPAIGN_ERROR = 'SAVE_CAMPAIGN_ERROR',

  DELETE_CAMPAIGN = 'DELETE_CAMPAIGN',
  DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS',
  DELETE_CAMPAIGN_ERROR = 'DELETE_CAMPAIGN_ERROR',

  RESET_CURRENT_CAMPAIGN = 'RESET_CURRENT_CAMPAIGN',
}
