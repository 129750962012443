import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import {
  doGetFacilities,
  doDeleteFacility,
} from '../../../Redux/Facility/facilityActions';
import { doGetJourneyTemplates } from '../../../Redux/Journey/journeyActions';
import {
  Table,
  HeaderFilter,
  FacilityIcon,
  InProgressButton,
  Loading,
} from '../../../Components';
import { Container } from './styles';
import Toast from '../../../Lib/Utils/toast';
import moment from 'moment';
import { isFacilityManager, isOrgAdmin } from '../../../Lib/Utils/auth';

interface RootState {
  facility: any;
  journey: any;
}

interface InheritProps {
  showHeader?: boolean;
  parentFacilities?: Array<any>;
  editCallback?: Function;
}

const mapState = (state: RootState) => ({
  facilities: state.facility.facilities,
  loading: state.facility.loading,
  error: state.facility.error,
  journeyTemplates: state.journey.journeyTemplates,
  deleteSuccess: state.facility.deleteSuccess,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getFacilitiesAction: includeDrafts =>
    dispatch(doGetFacilities(includeDrafts)),
  getJourneyTemplatesAction: () => dispatch(doGetJourneyTemplates()),
  deleteFacilityAction: (facilityId, isDraft) =>
    dispatch(doDeleteFacility(facilityId, isDraft)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & InheritProps & { history?: any; location?: any };

const FacilitiesList = (props: Props) => {
  const {
    history,
    facilities,
    parentFacilities,
    journeyTemplates,
    loading,
    error,
    showHeader = true,
    editCallback,
    getFacilitiesAction,
    getJourneyTemplatesAction,
    deleteFacilityAction,
    deleteSuccess,
    location,
  } = props;

  const [rows, setRows] = useState([] as any);
  const [filteredRows, setFilteredRows] = useState([] as any);
  const [filters, setFilters] = useState({});
  const [textFilter, setTextFilter] = useState('');

  useEffect(() => {
    if (!parentFacilities) {
      getFacilitiesAction(true);
    }
    getJourneyTemplatesAction();

    const query = new URLSearchParams(location.search);
    const filterIdFromQuery = query.get('id');

    if (filterIdFromQuery) {
      setFilters({ ...filters, id: filterIdFromQuery });
    }
  }, []);

  useEffect(() => {
    if (error) {
      Toast.error(error);
    } else if (deleteSuccess) {
      Toast.success(i18n.t(`The facility was discarded`));
      getFacilitiesAction(true);
    }
  }, [error, deleteSuccess]);

  useEffect(() => {
    const defaultRows = (parentFacilities || facilities)
      .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
      .map(facility => {
        const { _id, registeredName, description, isDraft } = facility;
        return {
          _id,
          registeredName,
          description,
          inProgress: isDraft ? (
            <InProgressButton
              action={() =>
                editCallback
                  ? editCallback(_id)
                  : history.push(`/facilities/${_id}/draft`)
              }
            />
          ) : (
            false
          ),
        };
      })
      .map(row => Object.values(row));
    setRows(defaultRows);
  }, [facilities, parentFacilities]);

  const getJourneysOptions = () =>
    journeyTemplates.map(({ _id, journeyName }) => ({
      value: _id,
      label: journeyName,
    }));

  const headers = [
    { text: i18n.t('ID'), order: true },
    { text: i18n.t('Name'), order: true },
    { text: i18n.t('Description'), order: true },
    { text: '', order: false, key: 'inProgress' },
  ];

  const selects = [
    {
      type: 'checkbox',
      label: i18n.t('In progress'),
      key: 'inprogress',
    },
    {
      placeholder: i18n.t('Search Journey'),
      options: getJourneysOptions(),
      key: 'journey',
    },
  ];

  const inputHandler = value => {
    setTextFilter(value);
  };

  const handleResetFilters = () => {
    setFilters({});
  };

  const applyFilters = () => {
    let newFilteredRows = rows;

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        if (key === 'inprogress') {
          newFilteredRows = newFilteredRows.filter(row => !!row[3]);
        }

        if (key === 'journey' && journeyTemplates) {
          const selectedJourney = journeyTemplates.find(
            j => j._id === filters[key]
          );
          if (selectedJourney && selectedJourney.stepTemplates) {
            const facilities = selectedJourney.stepTemplates.map(st =>
              st.stepTemplate ? st.stepTemplate.facilityId : ''
            );
            newFilteredRows = newFilteredRows.filter(row =>
              facilities.includes(row[0])
            );
          }
        }

        if (key === 'name') {
          newFilteredRows = newFilteredRows.filter(
            row => row[2] == filters[key]
          );
        }

        if (key === 'id') {
          newFilteredRows = newFilteredRows.filter(
            row => row[0] == filters[key]
          );
        }
      }
    });

    if (textFilter && textFilter !== '') {
      newFilteredRows = newFilteredRows.filter(
        row =>
          row
            .join(' ')
            .toUpperCase()
            .search(textFilter.toUpperCase()) !== -1
      );
    }

    setFilteredRows(newFilteredRows);
  };

  useEffect(() => applyFilters(), [filters, textFilter, rows]);

  return (
    <>
      <Container>
        {showHeader && (
          <HeaderFilter
            title={i18n.t('Existing Facilities')}
            titleIcon={<FacilityIcon />}
            searchPlaceholder={i18n.t('Search all columns')}
            actionButtonText={`+ ${i18n.t('New Facility')}`}
            setFilters={setFilters}
            inputHandler={value => inputHandler(value)}
            actionButtonHandler={() => history.push('/facilities/draft')}
            selects={selects}
            useDropdown={true}
            resetFiltersHandler={handleResetFilters}
            name="facilities"
            actionButton={!!isOrgAdmin()}
          />
        )}
        <Table
          headers={headers}
          rows={filteredRows}
          pagination
          rowsPerPage={20}
          pagesBtw={2}
          ignoreKeys={[]}
          editable={!!isOrgAdmin()}
          handleEdit={data => {
            editCallback
              ? editCallback(data[0])
              : data[3]
              ? history.push(`/facilities/${data[0]}/draft`)
              : history.push(`/facilities/${data[0]}/edit`);
          }}
          archivable={!!isOrgAdmin()}
          archiveTitle={i18n.t(
            'Are you sure you want to archive this facility?'
          )}
          archiveContent={i18n.t(
            'This facility will no longer be able to capture data in the supply chain journey.'
          )}
          handleArchive={data => deleteFacilityAction(data[0], !!data[3])}
        />
      </Container>
      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(FacilitiesList) as any) as any
) as any;
