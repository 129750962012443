import React from 'react';

const UserPermission = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="910.000000pt"
    height="980.000000pt"
    viewBox="0 0 910.000000 980.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,980.000000) scale(0.100000,-0.100000)"
      fill="#fff"
      stroke="none"
    >
      <path
        d="M3505 9794 c-464 -48 -849 -193 -1191 -447 -123 -91 -307 -265 -397
-377 -674 -834 -624 -2006 119 -2780 337 -350 793 -589 1269 -664 55 -8 125
-20 155 -25 l55 -9 -52 -1 c-82 -2 -286 -30 -444 -62 -819 -164 -1574 -622
-2125 -1289 -459 -556 -755 -1223 -864 -1950 -30 -198 -25 -275 23 -373 44
-90 103 -147 195 -190 l67 -32 2159 -3 2158 -2 59 26 c70 32 131 94 164 167
17 39 25 79 29 162 26 433 161 848 386 1190 234 354 535 618 916 805 155 76
198 113 229 194 22 57 16 145 -13 203 -25 49 -203 220 -362 347 -414 333 -879
569 -1385 701 -209 55 -313 72 -670 111 l-60 7 120 18 c829 123 1527 697 1784
1467 227 678 90 1416 -366 1982 -80 99 -277 290 -379 366 -305 229 -679 385
-1054 439 -109 16 -440 28 -525 19z"
      />
      <path
        d="M7104 3639 c-297 -26 -590 -131 -849 -304 -272 -181 -475 -414 -620
-710 -138 -282 -193 -542 -182 -864 6 -175 20 -273 62 -429 170 -621 679
-1118 1310 -1275 165 -42 250 -51 445 -51 195 0 280 9 445 51 640 160 1148
661 1314 1295 49 189 56 246 55 483 0 199 -3 232 -27 346 -59 280 -164 513
-330 736 -311 415 -782 676 -1307 723 -129 11 -184 11 -316 -1z m710 -729 c84
-28 200 -104 262 -173 141 -155 211 -347 201 -542 -9 -165 -58 -276 -171 -383
-127 -120 -260 -167 -453 -160 l-112 4 -413 -473 -413 -473 -125 -2 c-228 -5
-217 -6 -255 33 l-35 34 -21 199 c-18 168 -20 199 -8 207 8 5 57 9 110 9 53 0
98 2 100 5 3 2 0 51 -6 109 l-10 104 44 6 c24 3 72 6 107 6 74 0 70 -10 54
135 -4 44 -7 81 -6 82 1 1 42 7 91 13 50 6 101 13 114 16 l24 5 -6 118 -7 117
81 92 81 93 -17 62 c-42 150 -27 309 44 453 68 137 233 274 381 315 113 31
251 27 364 -11z"
      />
      <path
        d="M7702 2648 c-57 -13 -116 -69 -132 -127 -22 -83 13 -173 88 -226 39
-27 52 -30 117 -30 67 0 77 3 112 31 56 45 75 87 71 160 -8 133 -126 221 -256
192z"
      />
    </g>
  </svg>
);

export default UserPermission;
