import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 74px);
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  > div {
    display: flex;
    flex-direction: column;
  }
`;

const ToggleAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: calc(100% + 74px);
  padding-right: 5px;
  overflow: hidden;
  div {
    cursor: pointer;
    padding: 5px 0;
    svg {
      width: 24px;
    }
  }
`;

const TableTitle = styled.h3`
  font-size: 14px;
  background-color: #000;
  color: white;
  padding: 10px 20px;
  margin: 0;
`;

const Table = styled.table`
  th {
    height: auto !important;
    font-size: 14px;
    background-color: #303030;
    color: white;
    padding: 10px 20px;
  }
`;

const DataList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 14px;
    background-color: #303030;
    color: white;
    margin: 0px;
    padding: 10px 20px;
  }
  ul {
    :nth-child(even) {
      background-color: #eee;
    }
    padding: 2px 20px !important;
    > li {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #d6d6d6;
      :last-child {
        border-bottom: none;
      }
    }
  }
`;

export { Container, ToggleAction, TableTitle, Table, DataList };
