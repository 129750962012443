import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';

import { Brand } from '../../../Lib/Utils/Validations';
import {
  Container,
  FormContainer,
  ListContainer,
  FormSubHeader,
} from './styles';

import {
  Label,
  FormInput,
  InfoIcon,
  CustomTooltip,
  Button,
  Dropdown,
  ItemsList,
  PopUp,
  MediaUpload,
  ColorPicker,
  Checkbox,
} from '../../../Components';

export interface Brand {
  _id?: string;
  name?: string;
  description?: string;
  logo?: string;
  themes?: {
    default?: {
      useDefaultColor?: boolean;
      colorPrimary?: string;
      colorPrimaryVariant?: string;
      colorOnPrimary?: string;
      colorSecondary?: string;
      colorSecondaryVariant?: string;
      colorOnSecondary?: string;
    };
  };
  updated?: boolean;
}

interface Props {
  onChange: any;
  onChangeMultiple: any;
  brands: Array<any>;
  organizationBrandsFirstLoad?: boolean;
  organizationBrands: any;
  existingOrganizationBrand?: any;
  organizationColor?: string;
}

const OrganizationBrand = ({
  onChange: onChangeParent,
  onChangeMultiple,
  brands = [],
  organizationBrandsFirstLoad = true,
  organizationBrands,
  existingOrganizationBrand,
  organizationColor = '',
}: Props) => {
  const initBrand = {
    name: '',
    description: '',
    logo: '',
    themes: {
      default: {
        useDefaultColor: true,
      },
    },
    updated: false,
  };

  const [items, setItems] = useState<Array<{ title: string; data: any }>>([]);
  const [currentBrand, setCurrentBrand] = useState<Brand>(initBrand);
  const [currentBrandIndex, setCurrentBrandIndex] = useState(-1);
  const [popUpShow, setPopUpShow] = useState(false);
  const [popupNewItems, setPopupNewItems] = useState<any>([]);
  const [brandOptions, setBrandOptions] = useState(
    [] as Array<{ label: string; value: string }>
  );
  const [showFields, setShowFields] = useState(false);

  const {
    errors,
    control,
    register,
    setError,
    setValue,
    clearError,
    getValues,
    reset,
    triggerValidation,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    organizationBrandsFirstLoad === false && triggerValidation();
    onChangeParent('organizationBrandsFirstLoad', false);
  }, []);

  useEffect(() => {
    register({ name: 'organizationBrands' });
  }, [register]);

  useEffect(() => {
    const options = brands
      .filter(({ _id }) => !items?.find(i => i.data?._id === _id))
      .map(({ _id, name }) => ({
        label: `${name || ''}`,
        value: _id,
      }));
    setBrandOptions(options);
  }, [brands, items]);

  const switchCurrentBrand = brandId => {
    if (brandId) {
      const brand = brands.find(({ _id }) => _id === brandId) || ({} as any);
      if (brand) {
        const mapped = {
          ...brand,
        };
        setCurrentBrand(mapped);
      }
      setShowFields(true);
    } else {
      setCurrentBrand(initBrand);
    }
  };

  useEffect(() => {
    if (existingOrganizationBrand) {
      const user =
        brands.find(({ _id }) => _id === existingOrganizationBrand) ||
        ({} as any);
      const mapped = {
        ...user,
        emailConfirm: user.email,
      };
      setCurrentBrand(mapped);
    } else {
      setCurrentBrand(initBrand);
    }
  }, [existingOrganizationBrand]);

  useEffect(() => {
    const { name, description, logo, themes, updated } = currentBrand;
    setValue([
      { name },
      { description },
      { logo },
      { themes },
      { organizationBrands },
      { updated },
    ]);
  }, [currentBrand, organizationBrands]);

  useEffect(() => {
    if (
      organizationBrands &&
      JSON.stringify(organizationBrands) !== JSON.stringify(items)
    ) {
      setItems(organizationBrands);
    }
  }, [organizationBrands]);

  const onChange = (key: string, value: any) => {
    setCurrentBrand({ ...currentBrand, [key]: value });
  };

  useEffect(() => {
    const colorPrimary =
      currentBrand.themes?.default?.colorPrimary || organizationColor;
    const newThemes = {
      default: {
        colorPrimary: colorPrimary,
        useDefaultColor: currentBrand.themes?.default?.useDefaultColor,
      },
    };
    if (currentBrand.themes?.default?.useDefaultColor) {
      newThemes.default.colorPrimary = organizationColor;
    }
    if (JSON.stringify(currentBrand.themes) !== JSON.stringify(newThemes))
      onChange('themes', newThemes);
  }, [currentBrand.themes]);

  const currentItem = currentBrand && {
    title: `${currentBrand.name || ''}`,
    data: currentBrand,
  };

  const resetForm = (event?: any) => {
    if (event) event?.preventDefault();
    setCurrentBrand(initBrand);
    setCurrentBrandIndex(-1);
    setShowFields(false);
    onChangeParent('existingOrganizationBrand', null);
    // Reset form validations
    Object.keys(initBrand).forEach(k => setValue(k, initBrand[k]));
  };

  const addNewItem = (event, selectedBrand) => {
    event.preventDefault();
    triggerValidation().then(isValid => {
      if (isValid) {
        let newItems = [] as any;
        const newItem = {
          ...currentItem,
          data: { ...currentItem.data, updated: true },
        };
        if (currentBrandIndex === -1) {
          newItems = [...items, newItem];
          setItems(newItems);
        } else {
          newItems = [...items];
          newItems.splice(currentBrandIndex, 1, newItem);
          setItems(newItems);
        }

        setCurrentBrand(initBrand);
        setCurrentBrandIndex(-1);
        // Reset form validations
        Object.keys(initBrand).forEach(k => setValue(k, initBrand[k]));

        onChangeMultiple({
          brands: newItems.map(({ data }) => data),
          existingOrganizationBrands: null,
        });

        setCurrentBrand(initBrand);
        setCurrentBrandIndex(-1);
        setShowFields(false);
      }
    });
  };

  const handleDeleteAction = index => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setPopUpShow(true);
    setPopupNewItems(newItems);
  };

  const popUpConfirmAction = () => {
    if (popupNewItems) {
      setItems(popupNewItems);
      onChangeMultiple({
        brands: popupNewItems.map(({ data }) => data),
        existingOrganizationBrand: null,
      });
    }
    setPopUpShow(false);
  };

  const handleActiveAction = (item, index) => {
    setCurrentBrand(item);
    setCurrentBrandIndex(index);
    setShowFields(true);
    Object.keys(item).forEach(k => setValue(k, item[k]));
  };

  const handleAddNew = () => {
    setShowFields(true);
    setCurrentBrand(initBrand);
    setCurrentBrandIndex(-1);
    onChangeParent('existingOrganizationBrand', null);
  };

  const selectedBrand = items[currentBrandIndex] ? true : false;

  const defaultLogo = (currentBrand.logo ? [currentBrand.logo] : []) as Array<
    any
  >;

  const colorPrimary =
    currentBrand.themes?.default?.colorPrimary || organizationColor;
  const useDefaultColor =
    currentBrand.themes?.default?.useDefaultColor === undefined
      ? true
      : currentBrand.themes?.default?.useDefaultColor;

  return (
    <Container>
      <FormSubHeader>
        <div className="mr-2">{i18n.t('Add organization brands')}</div>
        <div data-tip data-for="addbrands">
          <InfoIcon />
        </div>
        <CustomTooltip id="addbrands" placement="right">
          <span>
            {i18n.t(
              'Brands are created so that they can be assigned to assets that you create in the system.'
            )}
          </span>
        </CustomTooltip>
      </FormSubHeader>
      <div className="pageContainer">
        <FormContainer id="BrandForm">
          <div className="row">
            <div className="col-4 action-btn">
              <Button
                text={`+ ${i18n.t('New Brand')}`}
                type="dark"
                action={evt => {
                  evt.preventDefault();
                  handleAddNew();
                }}
              />
            </div>
          </div>

          {(showFields || existingOrganizationBrand) && (
            <>
              <div className="row label-spacing">
                <div className="col-5">
                  <Label>{i18n.t('What is the name of the brand?')}</Label>
                  <Controller
                    as={
                      <FormInput
                        required
                        placeholder={i18n.t('Enter the brand name')}
                        maxLength={50}
                      />
                    }
                    name="name"
                    value={currentBrand.name}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={Brand.name}
                    defaultValue={currentBrand.name}
                  />
                  {errors.firstName && (
                    <span className="inlineErrorMessage">
                      {errors.firstName.message}.
                    </span>
                  )}
                </div>

                <div className="col-7">
                  <Label>{i18n.t('Description')}</Label>
                  <Controller
                    as={
                      <FormInput
                        required
                        placeholder={i18n.t('Enter the brand description')}
                        maxLength={500}
                        type="textarea"
                      />
                    }
                    name="description"
                    value={currentBrand.description}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={Brand.description}
                    defaultValue={currentBrand.description}
                  />
                  {errors.lastName && (
                    <span className="inlineErrorMessage">
                      {errors.lastName.message}.
                    </span>
                  )}
                </div>
              </div>

              <div className="row label-spacing">
                <div className="col-12 mb-3">
                  <Label>
                    {`* `}
                    {i18n.t('Add a logo for your brand')}
                    <div data-tip data-for="logo">
                      <InfoIcon />
                    </div>
                    <CustomTooltip id="logo" placement="right">
                      <span>
                        {`* `}
                        {i18n.t(
                          'The logo must be square and be between 60x60px and 180x180px.'
                        )}
                      </span>
                    </CustomTooltip>
                  </Label>
                </div>
                <div className="col-12 media-container">
                  <div>
                    {errors.logo && (
                      <span className="inlineErrorMessage mr-3 mt-2">
                        {errors.logo.message}.
                      </span>
                    )}
                  </div>
                  <MediaUpload
                    proloadedItemsLinks={defaultLogo}
                    handleChange={item => {
                      let newItem = item;
                      if (Array.isArray(item)) {
                        newItem = item.length === 0 ? '' : item[0];
                      }
                      onChange('logo', newItem);
                    }}
                    accept={['image/png', 'image/jpg', 'image/jpeg']}
                    maxFileSize={2 * 1024 * 1024} // 2 MB
                    showLink
                    placeholder={i18n.t('Add logo')}
                    multiple={false}
                    dimensions={{
                      min: { width: 60, height: 60 },
                      max: { width: 180, height: 180 },
                      square: true,
                    }}
                  />
                </div>
              </div>

              <div className="row label-spacing">
                <Label>
                  <Checkbox
                    onChange={evt =>
                      onChange('themes', {
                        default: {
                          ...currentBrand.themes?.default,
                          useDefaultColor: !useDefaultColor,
                        },
                      })
                    }
                    checked={useDefaultColor}
                    name="defaultColor"
                    disabled={organizationColor === ''}
                  />
                  {i18n.t('Set to default scanner app main color')}
                </Label>
              </div>

              <div className="row label-spacing">
                <div className="col-12">
                  <Label>
                    {i18n.t('Color')}
                    <div data-tip data-for="colorSelector">
                      <InfoIcon />
                    </div>
                    <CustomTooltip id="colorSelector" placement="right">
                      <span>
                        {i18n.t(
                          'This is the default color of the mobile consumer scanner app.'
                        )}
                      </span>
                    </CustomTooltip>
                  </Label>
                  <ColorPicker
                    defaultColor={colorPrimary}
                    onChange={colorPrimary =>
                      onChange('themes', {
                        default: {
                          useDefaultColor,
                          colorPrimary,
                        },
                      })
                    }
                    disabled={useDefaultColor}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <Button text={i18n.t('Clear')} action={resetForm} />
                  <Button
                    text={selectedBrand ? i18n.t('Save') : i18n.t('Add')}
                    type="dark"
                    action={event => addNewItem(event, selectedBrand)}
                  />
                </div>
              </div>
            </>
          )}
        </FormContainer>
        {items.length > 0 && (
          <ListContainer>
            <div className="row">
              <div className="col-12">
                <Label>{i18n.t('Brand list')}</Label>
                <ItemsList
                  items={items}
                  defaultActiveIndex={currentBrandIndex}
                  deleteAction={handleDeleteAction}
                  activeAction={handleActiveAction}
                />
                {errors.organizationBrands && (
                  <span className="inlineErrorMessage">
                    {errors.organizationBrands.message}.
                  </span>
                )}
              </div>
            </div>
          </ListContainer>
        )}

        <PopUp
          title={i18n.t('Delete brand?')}
          content={i18n.t('Are you sure you want to delete this brand?')}
          cancelText={i18n.t('Cancel')}
          confirmText={i18n.t('Yes')}
          confirmAction={popUpConfirmAction}
          cancelAction={() => setPopUpShow(false)}
          show={popUpShow}
        />
      </div>
    </Container>
  );
};

export default OrganizationBrand;
