import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ObjectID } from 'bson';

import { Asset } from '../../../Lib/Utils/Validations';
import {
  doSaveAssetTemplate,
  doGetAssetTemplate,
  resetCurrentAsset,
  doGetAssetTemplates,
} from '../../../Redux/Asset/assetActions';
import {
  doGetIdentifierTypes,
  doGetCategories,
  doGetBrands,
  doGetMarketplaces,
} from '../../../Redux/Catalog/catalogActions';
import {
  AssetIcon,
  ContentBox,
  Title,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Button,
  Loading,
  CustomTooltip,
  PopUp,
} from '../../../Components';
import Toast from '../../../Lib/Utils/toast';

import AssetDetails from './AssetDetails';
import AssetReviewSubmit from './AssetReviewSubmit';
import AssetExtras from './AssetExtras';
import AssetMedia from './AssetMedia';
import AssetSmartTags from './AssetSmartTags';
import AssetData from './AssetData';
import { AssetActionButtonsContainer } from './styles';
import { useForm } from 'react-hook-form';
import { DataProps, DynamicField, Version } from './types';
import NavigationPrompt from 'react-router-navigation-prompt';

interface RootState {
  asset: any;
  catalog: any;
}

interface InheritProps {
  showTitle?: boolean;
  onSave?: Function;
  skuku?: string;
  draft?: boolean;
}

const mapState = (state: RootState) => ({
  asset: state.asset.currentAsset,
  assets: state.asset.assets,
  identifierTypes: state.catalog.identifierTypes,
  categories: state.catalog.categories,
  brands: state.catalog.brands,
  marketplaces: state.catalog.marketplaces,
  loading: state.asset.loading,
  error: state.asset.error,
  submitSuccess: state.asset.submitSuccess,
  saveSuccess: state.asset.saveSuccess,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  saveAssetTemplateAction: (payload, actionType, notifySuccess) =>
    dispatch(doSaveAssetTemplate(payload, actionType, notifySuccess)),
  getAssetTemplateAction: (skuku, actionType) =>
    dispatch(doGetAssetTemplate(skuku, actionType)),
  getIdentifierTypesAction: () => dispatch(doGetIdentifierTypes()),
  getCategoriesAction: () => dispatch(doGetCategories()),
  getBrandsAction: () => dispatch(doGetBrands()),
  getMarketplacesAction: () => dispatch(doGetMarketplaces()),
  resetFormAction: () => dispatch(resetCurrentAsset()),
  getAssetTemplatesAction: () => dispatch(doGetAssetTemplates()),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
  InheritProps & { history?: any; location?: any; match?: any };

const NewAsset = (props: Props) => {
  const {
    saveAssetTemplateAction,
    getAssetTemplateAction,
    getIdentifierTypesAction,
    getCategoriesAction,
    getBrandsAction,
    getMarketplacesAction,
    resetFormAction,
    loading,
    error,
    submitSuccess,
    saveSuccess,
    history,
    location,
    match,
    asset,
    identifierTypes,
    categories,
    brands,
    marketplaces,
    showTitle = true,
    onSave,
    skuku,
    draft,
    getAssetTemplatesAction,
    assets,
  } = props;

  const entityId = match.params.skuku || skuku;
  const isDraft = draft != null ? draft : !!match.path.match('/draft');

  const [tabIndex, setTabIndex] = useState(0);
  const [data, setData] = useState({
    name: '',
  } as DataProps);

  const [showPopup, setShowPopup] = useState(false);
  const [disableHistoryLock, setDisableHistoryLock] = useState(false);

  useEffect(() => {
    if (entityId) {
      getAssetTemplateAction(entityId, isDraft ? 'get-draft' : 'get-entity');
    }
    getIdentifierTypesAction();
    getCategoriesAction();
    getBrandsAction();
    getMarketplacesAction();
    getAssetTemplatesAction();

    // will be called on component unmount
    return () => {
      resetFormAction();
    };
  }, []);

  useEffect(() => {
    if (submitSuccess) {
      setDisableHistoryLock(true);
      Toast.success(i18n.t('Your data has been successfully submitted.'));
      // If onSave exists then this component is controlled as child and should not redirect
      if (onSave) {
        history.push({
          search: '',
        });
        onSave(asset._id || entityId, true);
      } else {
        history.push(`/assets`);
      }
    } else if (saveSuccess) {
      setDisableHistoryLock(true);
      Toast.success(i18n.t('Your data has been successfully saved.'));
    } else if (error) {
      Toast.error(error);
    }
  }, [error, submitSuccess, saveSuccess]);

  useEffect(() => {
    if (disableHistoryLock && !onSave) {
      setDisableHistoryLock(false);
      history.push(`/assets`);
    }
  }, [disableHistoryLock]);

  useEffect(() => {
    let {
      _id,
      skuku,
      name,
      brandId,
      ids,
      description,
      category,
      urls,
      marketplaces,
      extras,
      photos,
      previewVideo,
      videos,
      version,
      marketableProduct,
      organizationId,
    } = asset;

    if (photos && photos.length) {
      photos = photos.map(p => ({ src: p }));
    }
    if (videos && videos.length) {
      videos = videos.map(v => ({ src: v }));
    }
    if (previewVideo) {
      videos?.unshift({ src: previewVideo, featured: true });
    }

    const fields = [] as Array<DynamicField>;
    if ((version as Array<Version>) && version.length) {
      Object.keys(version[0]).forEach(k => {
        const f = version[0][k];
        fields.push({
          readonly: true,
          fieldName: k,
          dataSource: f.source.type,
          fieldType: f.type,
          visibilityScope: f.visibility,
          isEditable: 'yes',
          collapsed: true,
          isMandatory: f.mandatory,
          fieldPlaceholder: f.placeholder,
          maxChars: f.maxChars,
          options: f.values || [],
          valid: true,
        });
      });
    }

    setData({
      _id,
      skuku,
      name,
      brandId,
      type: ids && ids.length ? ids[0].type?.toUpperCase() : null,
      value: ids && ids.length ? ids[0].id : '',
      description,
      category,
      website: urls && urls.length ? urls[0] : '',
      marketplaces,
      isMarketableProduct: marketableProduct,
      extras: extras ? extras.details : [],
      noExtras: !extras || !extras.details || extras.details.length === 0,
      photos,
      videos,
      fields,
      noDataCapture: !version || version.length === 0,
      organizationId,
    });
  }, [asset]);

  useEffect(() => {
    if (location) {
      const newIndex = getDefaultActiveTab();
      if (tabIndex !== newIndex) handleTabChange(newIndex);
    }
  }, [location, tabIndex]);

  const formMethods = useForm({ mode: 'onBlur' });

  const tabs = [
    i18n.t('Overview'),
    i18n.t('Extras'),
    i18n.t('Data'),
    i18n.t('Smart Tags'),
    i18n.t('Media'),
    isDraft || !entityId ? i18n.t('Review & Submit') : i18n.t('Review & Save'),
  ];

  const handleChange = (name: string, value: any) => {
    setData({ ...data, [name]: value });
  };

  const getDefaultActiveTab = () => {
    const query = new URLSearchParams(location.search);
    const tabFromQuery = query.get('tab');
    const findTabIndex = tabs.findIndex(
      tab => tab.replace(/[^a-zA-Z0-9]/g, '') === tabFromQuery
    );
    return findTabIndex !== -1 ? findTabIndex : 0;
  };

  const handleTabChange = index => {
    const tabName = tabs[index].replace(/[^a-zA-Z0-9]/g, '');
    const query = new URLSearchParams(location.search);
    const stepFromQuery = query.get('step');
    const stepSearch = stepFromQuery ? `&step=${stepFromQuery}` : '';
    history.push({
      search: `?tab=${tabName}${stepSearch}`,
    });
    setTabIndex(index);
  };

  const handleBackButton = () => {
    saveAsset(false);
    handleTabChange(tabIndex - (tabIndex === 4 ? 2 : 1));
  };

  const handleNextButton = () => {
    saveAsset(false);
    handleTabChange(tabIndex + (tabIndex === 2 ? 2 : 1));
  };

  const getMappedData = (fieldsFromProps?: Array<any>) => {
    const {
      _id,
      type = '',
      value,
      website,
      extras,
      photos,
      videos,
      fields,
      isMarketableProduct,
      noExtras,
      organizationId,
    } = data;

    const version = [] as Array<Version>;

    const newFields = fieldsFromProps || fields;
    if (newFields && newFields.length) {
      version.push({});
      newFields.forEach(
        ({
          fieldName,
          visibilityScope,
          fieldType,
          isMandatory,
          dataSource,
          options,
          isEditable,
          maxChars,
          fieldPlaceholder,
        }) => {
          fieldName = fieldName.replace(new RegExp(/\./g), ' '); // API does not allow dots on fieldName
          version[0][fieldName] = {
            type: fieldType,
            visibility: visibilityScope,
            mandatory: isMandatory,
            source: {
              type: isEditable === 'no' ? 'external-strict' : dataSource,
            },
            values: options,
            maxChars,
            placeholder: fieldPlaceholder,
          };
        }
      );
    }

    const mappedData = {
      ...data,
      _id: _id || entityId || new ObjectID().toString(),
      ids:
        type || value
          ? [
              {
                type: type ? type.toUpperCase() : undefined,
                id: value ? value : undefined,
              },
            ]
          : [],
      urls: [website],
      extras: noExtras
        ? null
        : {
            coupons:
              asset.extras && asset.extras.coupons ? asset.extras.coupons : [],
            details: extras,
          },
      photos,
      previewVideo: videos?.find(v => v.featured),
      videos: videos?.filter(v => !v.featured),
      version,
      marketableProduct: isMarketableProduct,
      organizationId,
    };
    return mappedData;
  };

  const validate = async () => {
    const { isMarketableProduct } = data;

    if (isMarketableProduct) {
      formMethods.register({ name: 'type' }, Asset.type);
      formMethods.register({ name: 'value' }, Asset.identifierValue);
      formMethods.register({ name: 'photos' }, Asset.photos());
      formMethods.register({ name: 'brandId' }, Asset.brand);
      formMethods.register({ name: 'website' }, Asset.website);
    }
    formMethods.register({ name: 'name' }, Asset.name);
    formMethods.register({ name: 'description' }, Asset.description);
    formMethods.register({ name: 'category' }, Asset.typeOptions);
    formMethods.register({ name: 'fields' }, Asset.fields());

    formMethods.setValue(
      Object.keys(data).map(key => ({
        [key]: data[key],
      }))
    );

    const result = await formMethods.triggerValidation();
    if (!result) {
      setData({ ...data, validate: result });
    }

    return result;
  };

  const saveAsset = async (notifySuccess?: boolean, fields?: Array<any>) => {
    // Name must be entered at minimum
    formMethods.register({ name: 'name' }, Asset.name);
    formMethods.setValue('name', data.name);
    await formMethods.triggerValidation();
    const validName = !!formMethods.errors.name;

    if (validName) {
      Toast.error(i18n.t('Must enter name to save data.'));
      handleTabChange(0);
      return false;
    }

    if (!isDraft) {
      const result = await validate();
      if (!result) {
        Toast.error(i18n.t('There are items that require your attention.'));
        return false;
      }
    }

    const actionType = isDraft
      ? data._id
        ? 'save-draft'
        : 'create-draft'
      : 'save-entity';
    const mappedData = getMappedData(fields);

    if (mappedData.noExtras) {
      handleChange('extras', []);
    }

    saveAssetTemplateAction(mappedData, actionType, notifySuccess);
    onSave && onSave(mappedData._id);
  };

  const submitAsset = async () => {
    const result = await validate();

    if (result) {
      const mappedData = getMappedData();
      const actionType =
        isDraft || !entityId ? 'submit-create-entity' : 'submit-save-entity';
      await saveAssetTemplateAction(mappedData, actionType, false);
    } else {
      Toast.error(i18n.t('There are items that require your attention.'));
      return false;
    }
  };

  const copyAsset = newAsset => {
    const { version } = newAsset;
    let { photos, videos, previewVideo } = newAsset;

    if (photos && photos.length) {
      photos = photos.map(p => ({ src: p }));
    }
    if (videos && videos.length) {
      videos = videos.map(v => ({ src: v }));
    }
    if (previewVideo) {
      videos?.unshift({ src: previewVideo, featured: true });
    }

    const fields = [] as Array<DynamicField>;
    if ((version as Array<Version>) && version.length) {
      Object.keys(version[0]).forEach(k => {
        const f = version[0][k];
        fields.push({
          readonly: true,
          fieldName: k,
          dataSource: f.source.type,
          fieldType: f.type,
          visibilityScope: f.visibility,
          isEditable: 'yes',
          collapsed: true,
          isMandatory: f.mandatory,
          fieldPlaceholder: f.placeholder,
          maxChars: f.maxChars,
          options: f.values || [],
          valid: true,
        });
      });
    }

    setData({
      name: '',
      brandId: newAsset.brandId,
      type:
        newAsset.ids && newAsset.ids.length
          ? newAsset.ids[0].type?.toUpperCase()
          : null,
      value: newAsset.ids && newAsset.ids.length ? newAsset.ids[0].id : '',
      description: newAsset.description,
      category: newAsset.category,
      website: newAsset.urls && newAsset.urls.length ? newAsset.urls[0] : '',
      marketplaces: newAsset.marketplaces,
      isMarketableProduct: newAsset.marketableProduct,
      extras: newAsset.extras ? newAsset.extras.details : [],
      noExtras:
        !newAsset.extras ||
        !newAsset.extras.details ||
        newAsset.extras.details.length === 0,
      photos: photos,
      videos: videos,
      fields: fields,
      noDataCapture: !newAsset.version || newAsset.version.length === 0,
    });
  };

  return (
    <>
      {showTitle && (
        <Title
          title={i18n.t('New Asset')}
          subtitle={data.name}
          icon={<AssetIcon />}
        />
      )}
      <ContentBox>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={handleTabChange}
          initialSelectedIndex={getDefaultActiveTab()}
        >
          <TabList>
            <CustomTooltip placement="top" id="coming-soon">
              <span>{i18n.t('Coming soon!')}</span>
            </CustomTooltip>
            {tabs.map((tab, index) => (
              <Tab key={index} disabled={index === 3}>
                {index === 3 ? (
                  <div data-tip data-for="coming-soon">
                    {tab}
                  </div>
                ) : (
                  tab
                )}
              </Tab>
            ))}

            <AssetActionButtonsContainer>
              {tabIndex > 0 && (
                <Button
                  text={i18n.t('Back')}
                  type="secondary"
                  action={handleBackButton}
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  text={i18n.t('Save')}
                  type="secondary"
                  action={() => saveAsset(true)}
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  action={handleNextButton}
                  text={i18n.t('Save & Next')}
                  type="secondary"
                />
              )}
              {tabIndex === tabs.length - 1 && (
                <Button
                  action={() => submitAsset()}
                  text={
                    isDraft || !entityId ? i18n.t('Submit') : i18n.t('Save')
                  }
                  type="secondary"
                />
              )}
            </AssetActionButtonsContainer>
          </TabList>

          <TabPanel>
            <AssetDetails
              onChange={(name, value) => handleChange(name, value)}
              identifierTypes={identifierTypes}
              categories={categories}
              brands={brands}
              markets={marketplaces}
              formErrors={formMethods.errors}
              assets={assets}
              copyAsset={copyAsset}
              {...data}
            />
          </TabPanel>
          <TabPanel>
            <AssetExtras
              onChange={(name, value) => handleChange(name, value)}
              {...data}
            />
          </TabPanel>
          <TabPanel>
            <AssetData
              onChange={(name, value) => handleChange(name, value)}
              {...data}
              saveAsset={saveAsset}
            />
          </TabPanel>
          <TabPanel>
            <AssetSmartTags
              onChange={(name, value) => handleChange(name, value)}
              {...data}
            />
          </TabPanel>
          <TabPanel>
            <AssetMedia
              onChange={(name, value) => handleChange(name, value)}
              formErrors={formMethods.errors}
              {...data}
            />
          </TabPanel>
          <TabPanel>
            <AssetReviewSubmit
              onChange={(name, value) => handleChange(name, value)}
              {...data}
              formMethods={formMethods}
              brands={brands}
              markets={marketplaces}
              isDraft={isDraft}
              entityId={entityId}
              embedded={!!onSave}
            />
          </TabPanel>
        </Tabs>
      </ContentBox>

      <NavigationPrompt
        beforeConfirm={clb => {
          clb();
        }}
        renderIfNotActive={false}
        when={(crntLocation, nextLocation) =>
          (!nextLocation ||
            !nextLocation.pathname.startsWith(crntLocation.pathname)) &&
          !disableHistoryLock
        }
      >
        {({ isActive, onCancel, onConfirm }) => {
          setShowPopup(true);
          return (
            <PopUp
              title={i18n.t('Save Changes?')}
              content={i18n.t(
                'Unsaved work may be lost. Do you want to save changes?'
              )}
              cancelText={i18n.t('Cancel')}
              confirmText={i18n.t('Yes')}
              confirmAction={async () => {
                setShowPopup(false);
                const save = await submitAsset();

                if (save === false) {
                  onCancel();
                } else {
                  onConfirm();
                }
              }}
              cancelAction={() => {
                setShowPopup(false);
                onCancel();
              }}
              negativeText="No"
              negativeAction={() => {
                setShowPopup(false);
                onConfirm();
              }}
              show={isActive && showPopup}
            />
          );
        }}
      </NavigationPrompt>

      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(NewAsset) as any) as any
) as any;
