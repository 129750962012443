import * as marketplaceService from '../../Services/Api/marketplaceService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { EMarketplaceActionTypes } from './marketplaceActionsTypes';
import { SaveBMarketplaceActionType } from '../../Services/Api/marketplaceService/types';

const getMarketplaces = createAction(EMarketplaceActionTypes.GET_MARKETPLACES);
const getMarketplacesSuccess = createAction(
  EMarketplaceActionTypes.GET_MARKETPLACES_SUCCESS,
  (marketplaces: any) => ({ marketplaces })
);
const getMarketplacesError = createAction(
  EMarketplaceActionTypes.GET_MARKETPLACES_ERROR,
  (message: string) => ({ message })
);

const saveMarketplace = createAction(EMarketplaceActionTypes.SAVE_MARKETPLACE);
const saveMarketplaceSuccess = createAction(
  EMarketplaceActionTypes.SAVE_MARKETPLACE_SUCCESS,
  (
    marketplace: any,
    isDraft?: boolean,
    submitted?: boolean,
    notifySuccess?: boolean
  ) => ({
    marketplace,
    isDraft,
    submitted,
    notifySuccess,
  })
);
const saveMarketplaceError = createAction(
  EMarketplaceActionTypes.SAVE_MARKETPLACE_ERROR,
  (message: string) => ({ message })
);

export const doGetMarketplaces = (
  payload = null
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getMarketplaces());
  return await tap(
    marketplaceService.getMarketplaces(payload),
    response => {
      dispatch(getMarketplacesSuccess(response.data));
    },
    error => {
      const _message =
        'Failed to retrieve Marketplaces. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getMarketplacesError(message));
    }
  );
};

export const doSaveMarketplace = (
  payload: any,
  actionType: SaveBMarketplaceActionType,
  notifySuccess?: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(saveMarketplace());
  return await tap(
    marketplaceService.saveMarketplace(payload, actionType),
    response => {
      let data = response.data ? response.data.data : {};
      const isDraft = !!actionType.match('draft');
      if (isDraft) {
        data = { ...data.draft, _id: data._id }; // The Draft has its own _id different from the user _id
      }
      dispatch(
        saveMarketplaceSuccess(
          data,
          isDraft,
          !!actionType.match('submit'),
          notifySuccess
        )
      );
    },
    error => {
      const _message = 'Failed to save Marketplace. Please try again later';

      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(saveMarketplaceError(message));
    }
  );
};
