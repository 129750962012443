import { AxiosResponse } from 'axios';
import { authAxiosCall } from '../axiosCall';
import {
  SaveFacility,
  SaveFacilityActionType,
  GetFacilityActionType,
} from './types';
import { uploadMedia } from '../mediaService';
import { getUUIDForFile } from '../../../Lib/Utils/functions';
import { getOrganization, isOrgAdmin } from '../../../Lib/Utils/auth';
import { saveUser } from '../userService';

export const getFacilityUsers = facilityId => {
  return authAxiosCall(`/users/facility/${facilityId}`, {
    method: 'GET',
  });
};

export const getFacilities = async (
  includeDrafts?: boolean,
  includeMembers?: boolean,
  payload?: any,
  excludeOrganization?: boolean
): Promise<AxiosResponse<any> | undefined> => {
  const organizationId = getOrganization()?._id;
  let response = await authAxiosCall('/facilities', {
    method: 'GET',
    query: payload,
    excludeOrganization: excludeOrganization,
  });

  let facilities = response && response.data ? response.data.data : [];

  facilities = facilities.filter(f => !f.archived);

  if (includeMembers) {
    facilities = await Promise.all(
      facilities.map(async facility => {
        let users = [];
        try {
          if (organizationId) {
            response = await getFacilityUsers(facility._id);
            users = response?.data || [];
          }
        } catch (err) {
          console.log('ERR Getting members ', err);
        }
        return {
          ...facility,
          users,
        };
      })
    );
  }

  if (isOrgAdmin() && includeDrafts) {
    response = await authAxiosCall('/drafts/collections/facilities', {
      method: 'GET',
    });
    const drafts = response && response.data ? response.data.data : [];
    facilities = facilities.concat(
      drafts.map(d => ({
        ...d.draft,
        facilityId: d.draft._id,
        _id: d._id,
        isDraft: true,
        createdAt: d.createdAt,
        updatedAt: d.updatedAt,
      }))
    );
  }
  return facilities;
};

export const getFacility = async (
  facilityId: string,
  actionType?: GetFacilityActionType
): Promise<AxiosResponse<any> | undefined> => {
  const path =
    actionType === 'get-draft'
      ? `/drafts/${facilityId}`
      : `/facilities/${facilityId}`;

  let response = await authAxiosCall(path, {
    method: 'GET',
  });
  let data = response?.data?.data || {};

  const isDraft = !!actionType?.match('draft');
  if (isDraft) {
    data = { ...data.draft, _id: data._id }; // The Draft has its own _id different from the facility _id
  } else {
    let users = [];
    const organizationId = getOrganization()?._id;
    // Get facility members
    if (organizationId) {
      response = await getFacilityUsers(facilityId);
      users = response?.data.data || [];
    }
    data = {
      ...data,
      users,
    };
  }

  return data;
};

const addUserToFacility = async (userId, facilityTd, permission) => {
  const accessEntry = {
    resourceId: facilityTd,
    type: 'facility',
    permission,
  };
  return authAxiosCall(`/users/${userId}/access-control`, {
    method: 'POST',
    body: accessEntry,
  });
};

const removeUserFromFacility = async (userId, facilityTd) => {
  const accessEntry = {
    resourceId: facilityTd,
    type: 'facility',
  };
  return authAxiosCall(`/users/${userId}/access-control`, {
    method: 'DELETE',
    body: accessEntry,
  });
};

export const saveFacility = async (
  {
    _id,
    registeredName,
    organizationId,
    description,
    claimsSummary,
    ethAddress,
    quote,
    photos = [],
    videos = [],
    address,
    location,
    email,
    phone,
    url,
    claims,
    primaryContact,
    users,
    previousUsers,
    logo,
    noEmail,
    noPhone,
    themes,
  }: SaveFacility,
  actionType: SaveFacilityActionType
): Promise<AxiosResponse<any> | undefined> => {
  let path = '';
  let method = '';
  switch (actionType) {
    case 'create-draft':
      path = `/drafts/collections/facilities`;
      method = 'POST';
      break;
    case 'save-draft':
      path = `/drafts/${_id}`;
      method = 'PUT';
      break;
    case 'submit-create-entity':
      path = `/facilities`;
      method = 'POST';
      break;
    case 'save-entity':
    case 'submit-save-entity':
      path = `/facilities/${_id}`;
      method = 'PUT';
      break;
  }

  let response;

  try {
    const facilityPhotos = [] as Array<string>;
    for (const photo of photos) {
      if (photo && photo.file) {
        const filePath = `facilities/${getUUIDForFile(_id)}_${registeredName}/${
          photo.file.name
        }`;
        const [fileUrl] = await uploadMedia([
          {
            file: photo.file,
            path: filePath,
          },
        ]);
        if (fileUrl) {
          facilityPhotos.push(fileUrl);
        }
      } else {
        facilityPhotos.push(photo.src);
      }
    }

    const facilityVideos = [] as Array<string>;
    for (const video of videos) {
      if (video && video.file) {
        const filePath = `facilities/${getUUIDForFile(_id)}_${registeredName}/${
          video.file.name
        }`;
        const [fileUrl] = await uploadMedia([
          {
            file: video.file,
            path: filePath,
          },
        ]);
        if (fileUrl) {
          facilityVideos.push(fileUrl);
        }
      } else {
        facilityVideos.push(video.src);
      }
    }

    let facilityLogo = (logo?.src || '') as string;
    if (logo && logo.file) {
      const filePath = `facilities/${getUUIDForFile(_id)}_${registeredName}/${
        logo.file.name
      }`;
      const [fileUrl] = await uploadMedia([
        {
          file: logo.file,
          path: filePath,
        },
      ]);
      if (fileUrl) {
        facilityLogo = fileUrl;
      }
    } else if (logo && logo[0] && logo[0].src) {
      facilityLogo = logo[0].src;
    }

    response = await authAxiosCall(path, {
      method,
      body: JSON.stringify({
        _id,
        registeredName,
        organizationId,
        description,
        claimsSummary,
        ethAddress,
        quote,
        photos: facilityPhotos,
        videos: facilityVideos,
        logo: facilityLogo,
        address,
        location,
        email,
        phone,
        url,
        claims,
        primaryContact,
        users,
        noEmail,
        noPhone,
        themes,
      }),
    });
  } catch (err) {
    console.error(`ERR `, err.message);
    return;
  }

  const organization = getOrganization() || ({} as any);

  if (actionType === 'submit-create-entity') {
    try {
      await authAxiosCall(`/drafts/${_id}`, {
        method: 'DELETE',
      });

      // create on keycloak Deprecated
      /* await authAxiosCall(
        `/organizations/${organization._id}/facilities/${_id}`,
        {
          method: 'PUT',
          source: 'keycloak_api',
        }
      ); */
    } catch (err) {
      // Do not throw
      console.log(`ERR removing draft`, err.message);
    }
  }

  if (
    ['submit-create-entity', 'save-entity', 'submit-save-entity'].includes(
      actionType
    )
  ) {
    const usersToRemove = [...previousUsers].filter(
      pu => !users.find(uu => pu._id === uu._id)
    );
    const usersToAdd = users;

    console.log('to add', usersToAdd);
    console.log('to remove', usersToRemove);

    // save new facility users
    await Promise.all(
      usersToAdd.map(
        async ({
          _id: userId,
          firstName,
          lastName,
          password,
          email,
          permission,
        }) => {
          if (userId) {
            // add the user to the group
            await addUserToFacility(userId, _id, permission);
          } else {
            // create the user and add it to the group
            const user = await saveUser(
              {
                id: userId,
                firstName,
                lastName,
                email,
                password,
                groups: [_id],
              },
              'submit-create-entity'
            );
            await addUserToFacility(user?.data?.data._id, _id, permission);
          }
        }
      )
    );

    // remove previous members
    await Promise.all(
      usersToRemove.map(async ({ _id: userId }) => {
        removeUserFromFacility(userId, _id);
      })
    );
  }

  return response;
};

export const deleteFacility = async (_id: string, isDraft: boolean) => {
  let response;
  if (isDraft) {
    response = await deleteDraft(_id);
  } else {
    response = await authAxiosCall(`/facilities/${_id}`, {
      method: 'PUT',
      body: JSON.stringify({
        archived: true,
      }),
    });
  }
  return response;
};

const deleteDraft = async (_id?: string) => {
  if (_id) {
    try {
      await authAxiosCall(`/drafts/${_id}`, {
        method: 'DELETE',
      });
    } catch (err) {
      // Do not throw draft might not exist
      console.log(`ERR removing draft`, err.message);
    }
  }
  return true;
};
