import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 28px;
  > div {
    margin-bottom: 19px;
  }
`;

const BackButtonTittle = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const DataList = styled.div`
  max-height: 250px;
  width: calc(100% + 74px);
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 14px;
    background-color: #303030;
    color: white;
    margin: 0px;
    padding: 10px 20px;
  }
  ul {
    :nth-child(even) {
      background-color: #eee;
    }
    padding: 2px 20px !important;
    > li {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #d6d6d6;
      :last-child {
        border-bottom: none;
      }
    }
  }
`;

export { Container, BackButtonTittle, DataList };
