import moment from 'moment';
const storage = localStorage;

const tokenKey = 'suku_auth';
const userKey = 'suku_curr';
const orgKey = 'suku_org';

export const setToken = ({
  access_token,
  refresh_token,
  expires_in,
  refresh_expires_in,
}) => {
  const expires_at = moment().add(expires_in, 'seconds');
  const refresh_expires_at = moment().add(refresh_expires_in, 'seconds');

  storage.setItem(
    tokenKey,
    JSON.stringify({
      access_token,
      refresh_token,
      expires_at,
      refresh_expires_at,
    })
  );
};

export const setCurrentUser = user => {
  storage.setItem(userKey, JSON.stringify(user));
};

export const setCurrentOrganization = organization => {
  storage.setItem(orgKey, JSON.stringify(organization));
};

export const getCurrentOrganization = () => {
  const encodedOrg = storage.getItem(orgKey);
  if (encodedOrg) {
    try {
      const org = JSON.parse(encodedOrg);
      return org;
    } catch (e) {
      return null;
    }
  }
  return null;
};

export const getCurrentUser = () => {
  const encodedUser = storage.getItem(userKey);
  if (encodedUser) {
    try {
      const user = JSON.parse(encodedUser);
      return user;
    } catch (e) {
      return null;
    }
  }
  return null;
};

export const getOrganization = () => {
  try {
    const currentOrg = getCurrentOrganization();
    return {
      ...currentOrg,
      _id: currentOrg?.resourceId,
    };
  } catch (e) {
    console.log('ERR getting organization: ', e);
    return null;
  }
};

export const isSuperAdmin = () => {
  return getCurrentUser()?.discoverUser?.accessControl?.find(
    ({ type, permission }) => type == 'global' && permission === 'admin'
  );
};

export const isOrgAdmin = () => {
  return getOrganization()?.permission === 'admin' || isSuperAdmin();
};

export const isFacilityManager = () => {
  return (
    isOrgAdmin() ||
    getCurrentUser()?.discoverUser?.accessControl?.find(
      ({ type, permission }) => type == 'facility' && permission === 'manager'
    )
  );
};

export const changeOrganization = orgId => {
  if (isSuperAdmin()) {
    if (orgId === 'all') setCurrentOrganization({});
    else
      setCurrentOrganization({
        type: 'organization',
        permission: 'admin',
        resourceId: orgId,
      });
  } else {
    const newOrg = getCurrentUser()?.discoverUser?.accessControl?.find(
      ({ type, resourceId }) => type == 'organization' && resourceId === orgId
    );
    if (newOrg) setCurrentOrganization(newOrg);
    else throw new Error('Organization not found in acces control');
  }
};

export const getOrganizations = () => {
  return getCurrentUser()?.discoverUser?.accessControl?.filter(
    ({ type }) => type == 'organization'
  );
};

export const getFacilities = () => {
  return getCurrentUser()?.discoverUser?.accessControl?.filter(
    ({ type }) => type == 'facility'
  );
};

export const getToken = () => {
  const encodedStoredToken = storage.getItem(tokenKey);
  if (encodedStoredToken) {
    try {
      const token = JSON.parse(encodedStoredToken);
      return token;
    } catch (e) {
      return null;
    }
  }
  return null;
};

export function deleteToken() {
  storage.removeItem(tokenKey);
}

export function deleteCurrentUser() {
  storage.removeItem(userKey);
}

export const setInitialOrganization = () => {
  try {
    const { _id } = getOrganization();
    // If has a previous organization and access use that
    if (_id) changeOrganization(_id);
    else {
      const firstOrg = getCurrentUser()?.discoverUser?.accessControl?.find(
        entry => entry.type === 'organization'
      );
      setCurrentOrganization(firstOrg);
    }
  } catch (e) {
    const firstOrg = getCurrentUser()?.discoverUser?.accessControl?.find(
      entry => entry.type === 'organization'
    );
    setCurrentOrganization(firstOrg);
  }
};
