import styled from 'styled-components';

const Content = styled.div`
  width: fit-content;
  text-transform: none;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Clickable = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: fit-content;
  filter: ${({ disabled }) => disabled && 'grayscale(100%)'};
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    white-space: nowrap;
  }
`;

const QRIcon = styled.img`
  width: 20px;
  background-color: white;
`;

const ActionContainer = styled.div`
  margin-top: 15px;

  div:first-child {
    display: inline-block;
  }
`;

const CopyButtonContent = styled.div`
  svg {
    margin-left: 5px;
    path {
      fill: #a7bf2e;
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  Container,
  Clickable,
  Content,
  CheckboxContainer,
  QRIcon,
  ActionContainer,
  CopyButtonContent,
};
