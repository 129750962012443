export enum EOrganizationActionTypes {
  GET_ORGANIZATIONS = 'GET_ORGANIZATIONS',
  GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS',
  GET_ORGANIZATIONS_ERROR = 'GET_ORGANIZATIONS_ERROR',

  GET_ORGANIZATION = 'GET_ORGANIZATION',
  GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS',
  GET_ORGANIZATION_ERROR = 'GET_ORGANIZATION_ERROR',

  GET_ORGANIZATION_USERS = 'GET_ORGANIZATION_USERS',
  GET_ORGANIZATION_USERS_SUCCESS = 'GET_ORGANIZATION_SUCCESS_USERS',
  GET_ORGANIZATION_USERS_ERROR = 'GET_ORGANIZATION_ERROR_USERS',

  SAVE_ORGANIZATION = 'SAVE_ORGANIZATION',
  SAVE_ORGANIZATION_SUCCESS = 'SAVE_ORGANIZATION_SUCCESS',
  SAVE_ORGANIZATION_ERROR = 'SAVE_ORGANIZATION_ERROR',

  DELETE_ORGANIZATION = 'DELETE_ORGANIZATION',
  DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS',
  DELETE_ORGANIZATION_ERROR = 'DELETE_ORGANIZATION_ERROR',

  RESET_STATE = 'RESET_STATE',
}
