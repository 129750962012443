import React from 'react';
import i18n from 'i18next';
import { PermissionsIcon, PermissionsList } from './styles';
import { Button, UserPermissionIcon, CustomTooltip } from '..';
import {
  OrganizationPermissionTypes,
  FacilityPermissionTypes,
} from '../../Lib/Configs';

interface Props {
  id?: string;
  facilities?: Array<any>;
  organizations?: Array<any>;
  accessControl?: Array<any>;
}

const PermissionInfo = ({
  id,
  facilities = [],
  organizations = [],
  accessControl = [],
}: Props) => {
  const findPermissionsByType = searchType =>
    accessControl
      .filter(({ type }) => type === searchType)
      .filter(({ resourceId, type }) => {
        const resourceName = {
          facility: facilities.find(({ _id }) => resourceId === _id)
            ?.registeredName,
          organization: organizations.find(({ _id }) => resourceId === _id)
            ?.name,
        }[type];
        return resourceName;
      })
      .map(({ permission, resourceId, type }) => {
        const permissionObject = {
          facility: FacilityPermissionTypes.find(
            ({ value }) => value === permission
          ),
          organization: OrganizationPermissionTypes.find(
            ({ value }) => value === permission
          ),
        }[type];
        const resourceName = {
          facility: facilities.find(({ _id }) => resourceId === _id)
            ?.registeredName,
          organization: organizations.find(({ _id }) => resourceId === _id)
            ?.name,
        }[type];
        return (
          <li key={resourceId}>
            <strong>{resourceName}</strong>: {permissionObject?.label}
          </li>
        );
      }) || null;

  const facilityPermissions = findPermissionsByType('facility');
  const organizationPermissions = findPermissionsByType('organization');

  return (
    <>
      <PermissionsIcon data-tip data-for={`${id}-opermission`}>
        <Button width={35} type="roundDark" icon={<UserPermissionIcon />} />
      </PermissionsIcon>
      <CustomTooltip id={`${id}-opermission`}>
        {facilityPermissions.length + organizationPermissions.length > 0 ? (
          <PermissionsList>
            {organizationPermissions.length > 0 && (
              <>
                <h6>Organization:</h6>
                <ul>{organizationPermissions}</ul>
              </>
            )}
            {facilityPermissions.length > 0 && (
              <>
                <h6>Facility:</h6>
                <ul>{facilityPermissions}</ul>
              </>
            )}
          </PermissionsList>
        ) : (
          i18n.t('No permissions assigned.')
        )}
      </CustomTooltip>
    </>
  );
};

export default PermissionInfo;
