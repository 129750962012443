const storage = localStorage;

const tableConfigKey = 'tableConfig';

export const saveTableConfig = newConfig => {
  const newTableConfig = JSON.parse(storage.getItem(tableConfigKey)) || {};
  Object.entries(newConfig).forEach(value => {
    newTableConfig[value[0]] = value[1];
  });
  storage.setItem(tableConfigKey, JSON.stringify(newTableConfig));
};

export const getTableConfig = () =>
  JSON.parse(storage.getItem(tableConfigKey)) || {};
