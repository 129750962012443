import * as journeyServices from '../journeyService';
import * as stepServices from '../stepService';
import * as assetServices from '../assetService';
import * as claimServices from '../claimService';
import { orderSort } from '../../../Lib/Utils/functions';

import { Journey, Step } from './types';

const sortStepsByOrder = steps => {
  return steps.sort(orderSort);
};

const getpopulatedSteps = async (stepTemplates, journeySteps) => {
  const steps = await Promise.all(
    stepTemplates.map(async ({ step, order }) => {
      const stepRequest = await stepServices.getStepTemplate(
        step.templateLocator
      );

      const stepTemplateData = stepRequest?.data.data;

      const findJourneyStep = journeySteps.find(
        ({ order: journeyStepOrder }) => journeyStepOrder === order
      );

      const stepTemplatePopulated = {
        ...stepTemplateData,
        outputs: await Promise.all(
          stepTemplateData.outputs.map(async output => {
            const templateData = await assetServices.getAssetTemplate(
              output.skuku
            );
            return {
              ...output,
              template: templateData?.data.data,
            };
          })
        ),
        inputs: await Promise.all(
          stepTemplateData.inputs.map(async input => {
            const templateData = await assetServices.getAssetTemplate(
              input.skuku
            );
            return {
              ...input,
              template: templateData?.data.data,
            };
          })
        ),
      };

      const { inputs, outputs } = findJourneyStep.step;
      // const { inputs, outputs } = step;
      const mergedAssetIds = inputs
        .map(({ assetId }) => assetId)
        .concat(outputs.map(({ assetId }) => assetId));

      let assetsArrayData = [] as Array<Step>;

      if (mergedAssetIds.length > 0) {
        const getAssetsArray = await assetServices.getAssetsArray(
          mergedAssetIds
        );

        assetsArrayData = getAssetsArray?.data.data;
      }

      const claims = await claimServices.getClaims(step.facility._id);

      return {
        order,
        step: {
          ...findJourneyStep.step,
          populatedInputs: findJourneyStep.step.inputs.map(input => {
            return {
              ...input,
              asset: {
                ...assetsArrayData.find(asset => asset._id === input.assetId),
              },
            };
          }),
          populatedOutputs: findJourneyStep.step.outputs.map(output => {
            return {
              ...output,
              asset: {
                ...assetsArrayData.find(asset => asset._id === output.assetId),
              },
            };
          }),
          claims,
        },
        template: stepTemplatePopulated,
      };
    })
  );

  return steps || [];
};

export const getProduct = async journeyId => {
  let mappedJourney = null as Journey | null;

  const journeyRequest = await journeyServices.getJourneys(journeyId);
  const journey = journeyRequest?.data.data;

  if (journey) {
    const journeyTemplateRequest = await journeyServices.getJourney(
      journey.templateLocator,
      journey.lotId
    );
    const journeyTemplate = journeyTemplateRequest?.data.data[0];

    if (journeyTemplate) {
      const steps = await getpopulatedSteps(
        journeyTemplate.steps,
        journey.steps
      );

      const sortedSteps = sortStepsByOrder(steps);

      mappedJourney = {
        _id: journey._id,
        lotId: journey.lotId,
        journeyName: journey.journeyName,
        description: journey.description,
        archived: journey.archived,
        organizationId: journey.organizationId,
        templateLocator: journey.templateLocator,
        steps: sortedSteps,
      };
    }
  }

  return mappedJourney;
};
