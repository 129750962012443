export enum EBrandActionTypes {
  GET_BRANDS = 'GET_BRANDS',
  GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS',
  GET_BRANDS_ERROR = 'GET_BRANDS_ERROR',

  GET_BRAND = 'GET_BRAND',
  GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS',
  GET_BRAND_ERROR = 'GET_BRAND_ERROR',

  DELETE_BRAND = 'DELETE_BRAND',
  DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS',
  DELETE_BRAND_ERROR = 'DELETE_BRAND_ERROR',

  SAVE_BRAND = 'SAVE_BRAND',
  SAVE_BRAND_SUCCESS = 'SAVE_BRAND_SUCCESS',
  SAVE_BRAND_ERROR = 'SAVE_BRAND_ERROR',

  RESET_STATE = 'RESET_STATE',
}
