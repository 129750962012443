import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { OrganizationPermissionTypes } from '../../../Lib/Configs';

import { Facility } from '../../../Lib/Utils/Validations';
import {
  Container,
  FormContainer,
  ListContainer,
  FormSubHeader,
} from './styles';

import {
  Label,
  FormInput,
  InfoIcon,
  CustomTooltip,
  Button,
  Dropdown,
  ItemsList,
  PopUp,
} from '../../../Components';

export interface User {
  noWebsite?: boolean;
  _id?: string;
  userId?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  emailConfirm?: string;
  password?: string;
  permission?: string | null;
}

interface Props {
  onChange: any;
  onChangeMultiple: any;
  users: Array<any>;
  organizationUsersFirstLoad?: boolean;
  organizationUsers: any;
  existingOrganizationUser?: any;
  facilities?: Array<any>;
  organizations?: Array<any>;
}

const OrganizationUsers = ({
  onChange: onChangeParent,
  onChangeMultiple,
  users = [],
  organizationUsersFirstLoad = true,
  organizationUsers,
  existingOrganizationUser,
  facilities = [],
  organizations = [],
  ...data
}: Props) => {
  const initUser = {
    _id: '',
    userId: '',
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    emailConfirm: '',
    password: '',
    permission: 'admin',
  };

  const [items, setItems] = useState<Array<{ title: string; data: any }>>([]);
  const [currentUser, setCurrentUser] = useState<User>(initUser);
  const [currentUserIndex, setCurrentUserIndex] = useState(-1);
  const [popUpShow, setPopUpShow] = useState(false);
  const [popupNewItems, setPopupNewItems] = useState<any>([]);
  const [showPassPopUp, setShowPassPopUp] = useState(false);
  const [userOptions, setUserOptions] = useState(
    [] as Array<{ label: string; value: string }>
  );
  const [showFields, setShowFields] = useState(false);

  const {
    errors,
    control,
    register,
    setError,
    setValue,
    clearError,
    getValues,
    reset,
    triggerValidation,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    organizationUsersFirstLoad === false && triggerValidation();
    onChangeParent('organizationUsersFirstLoad', false);
  }, []);

  useEffect(() => {
    register({ name: 'organizationUsers' });
  }, [register]);

  useEffect(() => {
    // if (JSON.stringify(organizationUsers) !== JSON.stringify(items)) {
    //   onChangeMultiple({ organizationUsers: items, existingFacilityUser: null });
    //   // setValue('organizationUsers', items);
    // }
    if (!items || !items.length) {
      setError(
        'organizationUsers',
        'required',
        i18n.t('At least one user is required')
      );
    } else {
      errors.organizationUsers && clearError('organizationUsers');
    }
  }, [items]);

  useEffect(() => {
    const options = [{ label: '', value: '' }].concat(
      users
        .filter(({ _id }) => !items.find(i => i.data?._id === _id))
        .map(({ _id, firstName, lastName }) => ({
          label: `${firstName || ''} ${lastName || ''}`,
          value: _id,
        }))
    );
    setUserOptions(options);
  }, [users, items]);

  const switchCurrentUser = userId => {
    if (userId) {
      const user = users.find(({ _id }) => _id === userId) || ({} as any);
      if (user) {
        const mapped = {
          ...user,
          emailConfirm: user.email,
          permission: 'admin',
        };
        setCurrentUser(mapped);
      }
      setShowFields(true);
    } else {
      setCurrentUser(initUser);
    }
  };

  useEffect(() => {
    if (existingOrganizationUser) {
      const user =
        users.find(({ _id }) => _id === existingOrganizationUser) ||
        ({} as any);
      const mapped = {
        ...user,
        emailConfirm: user.email,
      };
      setCurrentUser(mapped);
    } else {
      setCurrentUser(initUser);
    }
  }, [existingOrganizationUser]);

  useEffect(() => {
    setValue([
      { firstName: currentUser.firstName },
      { lastName: currentUser.lastName },
      { email: currentUser.email },
      { emailConfirm: currentUser.emailConfirm },
      { organizationUsers },
    ]);
  }, [
    currentUser.firstName,
    currentUser.lastName,
    currentUser.email,
    currentUser.emailConfirm,
    organizationUsers,
  ]);

  useEffect(() => {
    if (JSON.stringify(organizationUsers) !== JSON.stringify(items)) {
      setItems(organizationUsers);
    }
  }, [organizationUsers]);

  const onChange = (key: string, value: any) => {
    setCurrentUser({ ...currentUser, [key]: value });
  };

  const currentItem = currentUser && {
    title: `${currentUser.firstName || ''} ${currentUser.lastName || ''}`,
    data: currentUser,
  };

  const resetForm = event => {
    event.preventDefault();
    setCurrentUser(initUser);
    setCurrentUserIndex(-1);
    setShowFields(false);
    onChangeParent('existingOrganizationUser', null);
    // Reset form validations
    Object.keys(initUser).forEach(k => setValue(k, initUser[k]));
  };

  const addNewItem = event => {
    event.preventDefault();
    triggerValidation().then(isValid => {
      if (isValid) {
        let newItems = [] as any;
        if (currentUserIndex === -1) {
          newItems = [...items, currentItem];
          setItems(newItems);
        } else {
          newItems = [...items];
          newItems.splice(currentUserIndex, 1, currentItem);
          setItems(newItems);
        }
        setCurrentUser(initUser);
        setCurrentUserIndex(-1);
        // Reset form validations
        Object.keys(initUser).forEach(k => setValue(k, initUser[k]));

        onChangeMultiple({
          users: newItems.map(({ data }) => data),
          existingOrganizationUsers: null,
        });
      }
    });
  };

  const handleDeleteAction = index => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setPopUpShow(true);
    setPopupNewItems(newItems);
  };

  const popUpConfirmAction = () => {
    if (popupNewItems) {
      setItems(popupNewItems);
      onChangeMultiple({
        users: popupNewItems.map(({ data }) => data),
        existingOrganizationUser: null,
      });
    }
    setPopUpShow(false);
  };

  const handleActiveAction = (item, index) => {
    setCurrentUser(item);
    setCurrentUserIndex(index);
    setShowFields(true);
    // Set form validation values
    Object.keys(item).forEach(k => setValue(k, item[k]));
  };

  const handleAddNew = () => {
    setShowFields(true);
    setCurrentUser(initUser);
    setCurrentUserIndex(-1);
    onChangeParent('existingOrganizationUser', null);
  };

  const selectedUser = items[currentUserIndex] ? true : false;

  return (
    <Container>
      <FormSubHeader>
        <div className="mr-2">{i18n.t('Add organization users')}</div>
        <div data-tip data-for="addusers">
          <InfoIcon />
        </div>
        <CustomTooltip id="addusers" placement="right">
          <span>
            {i18n.t(
              'Organization admins are able to create add/edit journeys, assets, facilities, facility users, claims, etc. An organization admin is the highest level of permissions for a client.'
            )}
          </span>
        </CustomTooltip>
      </FormSubHeader>
      <div className="pageContainer">
        <FormContainer id="UserForm">
          <div className="row">
            <div className="col-6">
              <Label>{i18n.t('Select an existing user')}</Label>
              <Dropdown
                options={userOptions}
                defaultOption={currentUser._id}
                placeholder={i18n.t('Select')}
                action={value => {
                  switchCurrentUser(value || null);
                  setCurrentUserIndex(-1);
                }}
              />
            </div>
            <div className="col-4 action-btn">
              <Button
                text={`+ ${i18n.t('New User')}`}
                type="dark"
                action={evt => {
                  evt.preventDefault();
                  handleAddNew();
                }}
              />
            </div>
          </div>

          {(showFields || existingOrganizationUser) && (
            <>
              <div className="row label-spacing">
                <div className="col-5">
                  <Label>{i18n.t('First name')}</Label>
                  <Controller
                    as={
                      <FormInput
                        required
                        disabled={!!currentUser._id}
                        placeholder={i18n.t('Enter the first name')}
                        maxLength={50}
                      />
                    }
                    name="firstName"
                    value={currentUser.firstName}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={Facility.name}
                    defaultValue={currentUser.firstName}
                  />
                  {errors.firstName && (
                    <span className="inlineErrorMessage">
                      {errors.firstName.message}.
                    </span>
                  )}
                </div>

                <div className="col-5">
                  <Label>{i18n.t('Last name')}</Label>
                  <Controller
                    as={
                      <FormInput
                        required
                        disabled={!!currentUser._id}
                        placeholder={i18n.t('Enter the last name')}
                        maxLength={50}
                      />
                    }
                    name="lastName"
                    value={currentUser.lastName}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={Facility.name}
                    defaultValue={currentUser.lastName}
                  />
                  {errors.lastName && (
                    <span className="inlineErrorMessage">
                      {errors.lastName.message}.
                    </span>
                  )}
                </div>
              </div>

              <div className="row label-spacing">
                <div className="col-6">
                  <Label>
                    {i18n.t('Email')}
                    <div data-tip data-for="email">
                      <InfoIcon />
                    </div>
                    <CustomTooltip id="email" placement="right">
                      <span>
                        {i18n.t(
                          'This email will also act as the user login to the SUKU Field App.'
                        )}
                      </span>
                    </CustomTooltip>
                  </Label>
                  <Controller
                    as={
                      <FormInput
                        required
                        disabled={!!currentUser._id}
                        value={currentUser.email}
                        placeholder={i18n.t('Enter email address')}
                        maxLength={75}
                      />
                    }
                    name="email"
                    value={currentUser.email}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={Facility.email}
                    defaultValue={currentUser.email}
                  />
                  {errors.email && (
                    <span className="inlineErrorMessage">
                      {errors.email.message}.
                    </span>
                  )}
                </div>

                <div className="col-6">
                  <Label>{i18n.t('Confirm email')}</Label>
                  <Controller
                    as={
                      <FormInput
                        required
                        disabled={!!currentUser._id}
                        value={
                          !!currentUser._id
                            ? currentUser.email
                            : currentUser.emailConfirm
                        }
                        placeholder={i18n.t('Confirm email address')}
                        maxLength={75}
                      />
                    }
                    name="emailConfirm"
                    value={currentUser.emailConfirm}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={{
                      required: {
                        value: true,
                        message: i18n.t('This field is required'),
                      },
                      validate: {
                        matchesEmail: value => {
                          const { email } = getValues();
                          return email !== value
                            ? i18n.t('Emails do not match').toString()
                            : undefined;
                        },
                      },
                    }}
                    defaultValue={
                      !!currentUser._id
                        ? currentUser.email
                        : currentUser.emailConfirm
                    }
                  />
                  {errors.emailConfirm && (
                    <span className="inlineErrorMessage">
                      {errors.emailConfirm.message}.
                    </span>
                  )}
                </div>
              </div>

              {!existingOrganizationUser && !currentUser._id && (
                <div className="row label-spacing">
                  <div className="col-9">
                    <Label>
                      {i18n.t('Default password')}
                      <div data-tip data-for="defaultpassword">
                        <InfoIcon />
                      </div>
                      <CustomTooltip id="defaultpassword" placement="right">
                        <span>
                          {i18n.t(
                            'User will be asked to change the password when they log in for the first time into the SUKU Field App.'
                          )}
                        </span>
                      </CustomTooltip>
                    </Label>
                    <Controller
                      as={
                        <FormInput
                          required
                          type="password"
                          value={currentUser.password}
                          placeholder={i18n.t('Enter password')}
                          maxLength={50}
                        />
                      }
                      name="password"
                      value={currentUser.password}
                      control={control}
                      onChange={([evt]) => {
                        onChange(evt.target.name, evt.target.value);
                        return evt.target.value;
                      }}
                      rules={Facility.password}
                      defaultValue={currentUser.password}
                    />
                    {errors.password && (
                      <span className="inlineErrorMessage">
                        {errors.password.message}.
                      </span>
                    )}
                  </div>
                </div>
              )}

              <div className="row label-spacing">
                <div className="col-lg-7 col-sm-12">
                  <Label>
                    {i18n.t('What permissions does this user have?')}
                    <div data-tip data-for="permissions">
                      <InfoIcon />
                    </div>
                    <CustomTooltip id="permissions" placement="right">
                      <p>
                        <strong>{i18n.t('Organization Admin')}: </strong>
                        {i18n.t(
                          'User can create other Organization and Facility users. Additionally, user can create/edit all models in the system (e.g., Journeys, Assets, Facilities, Claims, Campaigns).'
                        )}
                        <br />
                        <br />
                        <strong>{i18n.t('Organization Viewer')}: </strong>
                        {i18n.t(
                          'User has read only access to all data in the system.'
                        )}
                      </p>
                    </CustomTooltip>
                  </Label>
                  <Controller
                    as={
                      <Dropdown
                        required
                        options={OrganizationPermissionTypes}
                        defaultOption={currentUser.permission}
                        placeholder={i18n.t('Select')}
                        action={value => onChange('permission', value)}
                      />
                    }
                    name="permission"
                    value={currentUser.permission}
                    control={control}
                    onChange={([newValue]) => {
                      onChange('permission', newValue);
                      return newValue;
                    }}
                    rules={Facility.phoneType}
                    defaultValue={currentUser.permission}
                  />
                  {errors.permission && (
                    <span className="inlineErrorMessage">
                      {errors.permission.message}.
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <Button text={i18n.t('Clear')} action={resetForm} />
                  <Button
                    text={selectedUser ? i18n.t('Save') : i18n.t('Add')}
                    type="dark"
                    action={addNewItem}
                  />
                </div>
              </div>
            </>
          )}
        </FormContainer>
        <ListContainer>
          <div className="row">
            <div className="col-12">
              <Label>{i18n.t('User list')}</Label>
              <ItemsList
                items={items}
                defaultActiveIndex={currentUserIndex}
                deleteAction={handleDeleteAction}
                activeAction={handleActiveAction}
                facilities={facilities}
                organizations={organizations}
              />
              {errors.organizationUsers && (
                <span className="inlineErrorMessage">
                  {errors.organizationUsers.message}.
                </span>
              )}
            </div>
          </div>
        </ListContainer>

        <PopUp
          title={i18n.t('Remove user from organization?')}
          content={i18n.t(
            'Removing the user from the organization will not allow him/her to capture any data for this organization.'
          )}
          cancelText={i18n.t('Cancel')}
          confirmText={i18n.t('Yes')}
          confirmAction={popUpConfirmAction}
          cancelAction={() => setPopUpShow(false)}
          show={popUpShow}
        />

        <PopUp
          title={i18n.t('Reset password to default?')}
          content={i18n.t(
            'Are you sure you want to reset the user password to the default password?'
          )}
          cancelText={i18n.t('Cancel')}
          confirmText={i18n.t('Yes')}
          confirmAction={() => setShowPassPopUp(false)}
          cancelAction={() => setShowPassPopUp(false)}
          show={showPassPopUp}
        />
      </div>
    </Container>
  );
};

export default OrganizationUsers;
