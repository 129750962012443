import React from 'react';
import { TableRow as Container } from './styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {
  clickable?: boolean;
  nav?: { url?: string; data?: any };
  children?: any;
  history: any;
}

const TableRow = withRouter(
  ({ clickable, nav = {}, history, children }: Props) => {
    const handleClick = evt => {
      evt.stopPropagation();
      if (nav && nav.url) {
        history.push({ pathname: nav.url || '', state: { data: nav.data } });
      }
    };

    if (clickable) {
      return (
        <Container clickable onClick={evt => handleClick(evt)}>
          {children}
        </Container>
      );
    }
    return <Container>{children}</Container>;
  }
);

export default TableRow;
