import React, { useState, useEffect, useRef } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import ReactDOM from 'react-dom';

import 'react-day-picker/lib/style.css';
import {
  FormInput,
  CalendarIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  Dropdown,
} from '../index';

import {
  Container,
  Overlay,
  IconContainer,
  WeekDayContainer,
  NavBarContainer,
} from './styles';

import moment from 'moment';

const CustomInput = ({
  onChange,
  placeholder = 'Select',
  required = true,
  disabled = false,
  value = undefined,
  ref = null,
  disabledDays = {},
  fixed = false,
}) => {
  const dayPickerInputRef = useRef(null);
  // const newDateValue = value ? new Date(value) : new Date();
  const getValue = () => {
    if (value && typeof value === 'string') {
      if (value === 'Invalid date') {
        return '';
      }
      return new Date(value.replaceAll('-', '/'));
    } else {
      return value;
    }
  };

  const newDateValue = getValue();

  const [selectedMonth, setMonth] = useState(newDateValue);

  const [inputElement, setInputElement] = useState(null);
  const [inputPosition, setInputPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (dayPickerInputRef) {
      setInputElement(ReactDOM.findDOMNode(dayPickerInputRef.current));
    }
  }, [dayPickerInputRef]);

  const updateInputPosition = element => {
    const position = element.getBoundingClientRect();
    setInputPosition({ left: position.left, top: position.top });
  };

  useEffect(() => {
    if (inputElement) {
      updateInputPosition(inputElement);
    }
  }, [inputElement]);

  useEffect(() => {
    const newDateValue = getValue();

    if (selectedMonth !== newDateValue) {
      setMonth(newDateValue);
    }
  }, [value]);

  const today = new Date();

  const FORMAT = 'MM/DD/YYYY';

  const OverlayComponent = ({ children, ...props }) => (
    <Overlay
      className="datepicker-overlay"
      {...props}
      fixed={fixed}
      inputPosition={inputPosition}
    >
      {children}
    </Overlay>
  );

  const InputComponent = props => (
    <FormInput
      {...props}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      ref={ref}
    />
  );

  const Weekday = props => {
    const { weekday, className, localeUtils, locale } = props;
    const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
    return (
      <WeekDayContainer className={className} title={weekdayName}>
        {weekdayName.slice(0, 3)}
      </WeekDayContainer>
    );
  };

  const Navbar = props => {
    const {
      nextMonth,
      previousMonth,
      onPreviousClick,
      onNextClick,
      className,
      localeUtils,
      selectedMonth,
    } = props;

    return (
      <NavBarContainer className={className}>
        <button
          onClick={() => {
            setMonth(previousMonth);
            return onPreviousClick();
          }}
        >
          <ArrowLeftIcon />
        </button>
        <button
          onClick={() => {
            setMonth(nextMonth);
            return onNextClick();
          }}
        >
          <ArrowRightIcon />
        </button>
      </NavBarContainer>
    );
  };

  const currentYear = selectedMonth
    ? selectedMonth.getFullYear()
    : today.getFullYear();
  const fromMonth = new Date(currentYear, 0);
  const toMonth = new Date(currentYear + 10, 11);

  const formatOnChange = value => {
    const formattedDate = value ? moment(value).format(FORMAT) : '';
    onChange && onChange(formattedDate);
  };

  function YearMonthForm({ date, localeUtils, onChange }) {
    const months = localeUtils.getMonths();

    const currentDateYear = new Date().getFullYear();
    const years = Array(120)
      .fill()
      .map((_, idx) => currentDateYear - 100 + idx);

    const handleChange = function handleChange(name, value) {
      const defaultYear = selectedMonth
        ? selectedMonth.getFullYear()
        : today.getFullYear();
      const defaultMonth = selectedMonth
        ? selectedMonth.getMonth()
        : today.getMonth();

      const year = name === 'year' ? value : defaultYear;
      const month = name === 'month' ? value : defaultMonth;

      const newDate = new Date(year, month);

      setMonth(newDate);
      onChange(newDate);
    };

    return (
      <form id="dateForm" className="DayPicker-Caption">
        <Dropdown
          name="month"
          options={months.map((month, i) => ({ label: month, value: i }))}
          defaultOption={date.getMonth()}
          action={selected => handleChange('month', selected)}
        />

        <Dropdown
          name="year"
          options={years.map(year => ({ label: year, value: year }))}
          defaultOption={date.getFullYear()}
          action={selected => handleChange('year', selected)}
        />
      </form>
    );
  }

  return (
    <Container
      className={disabled ? 'disabled' : ''}
      fixed={fixed}
      name="main-container"
    >
      <DayPickerInput
        ref={dayPickerInputRef}
        value={selectedMonth ? formatDate(selectedMonth) : ''}
        onDayChange={day => {
          formatOnChange(day);
        }}
        onDayPickerHide={() => formatOnChange(value ? value : '')}
        component={InputComponent}
        overlayComponent={OverlayComponent}
        format={FORMAT}
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          showOutsideDays: true,
          month: selectedMonth,
          fromMonth: fromMonth,
          toMonth: toMonth,
          weekdayElement: <Weekday />,
          navbarElement: <Navbar />,
          captionElement: ({ localeUtils }) => (
            <YearMonthForm
              date={selectedMonth || today}
              onChange={onChange}
              localeUtils={localeUtils}
            />
          ),
          disabledDays,
          onBlur: () => {
            const elClicked = document.activeElement;
            const container = document.getElementsByName(`main-container`);
            if (container && !container[0].contains(elClicked)) {
              dayPickerInputRef && dayPickerInputRef.current.hideDayPicker();
            }
          },
        }}
      />
      <IconContainer fixed={fixed}>
        <CalendarIcon />
      </IconContainer>
    </Container>
  );
};

export default CustomInput;
