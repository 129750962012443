import { AxiosResponse } from 'axios';
import { authAxiosCall } from '../axiosCall';
import { GetBrandActionType, SaveBrandActionType, SaveBrand } from './types';
import { uploadMedia } from '../mediaService';
import { getUUIDForFile } from '../../../Lib/Utils/functions';

const modelName = 'brands';

export const getDraftBrands = async () => {
  let users = [];
  const response = await authAxiosCall(`/drafts/collections/${modelName}`, {
    method: 'GET',
  });
  const drafts = response && response.data ? response.data.data : [];
  users = users.concat(
    drafts.map(d => ({
      ...d.draft,
      userId: d.draft.id,
      _id: d._id,
      isDraft: true,
      createdAt: d.createdAt,
      updatedAt: d.updatedAt,
    }))
  );
};

export const getBrands = async (): Promise<AxiosResponse<any> | undefined> => {
  const response = await authAxiosCall(`/${modelName}`, {
    method: 'GET',
    excludeOrganization: true,
  });
  return response?.data.data;
};

export const getBrand = async (
  brandId: string,
  actionType?: GetBrandActionType
): Promise<AxiosResponse<any> | undefined> => {
  if (actionType === 'get-draft') {
    return authAxiosCall(`/drafts/${brandId}`, {
      method: 'GET',
    });
  } else if (actionType === 'get-discover') {
    return authAxiosCall(`/${modelName}/${brandId}`, {
      method: 'GET',
    });
  } else if (actionType === 'get-keycloak') {
    return authAxiosCall(`/${modelName}/${brandId}`, {
      method: 'GET',
      query: { type: 'keycloak' },
    });
  } else {
    return authAxiosCall(`/${modelName}/${brandId}`, {
      method: 'GET',
      source: 'keycloak_api',
    });
  }
};

export const deleteBrand = async brandId => {
  return authAxiosCall(`/${modelName}/${brandId}`, {
    method: 'DELETE',
  });
};

export const saveBrand = async (
  {
    _id, // the draft _id
    id,
    name,
    description,
    logo,
    themes,
    organizationId,
  }: SaveBrand,
  actionType: SaveBrandActionType
): Promise<AxiosResponse<any> | undefined> => {
  let path = '';
  let method = '';
  const source = 'api';
  try {
    let brandLogo = (logo?.src || '') as string;
    if (logo && logo.file) {
      const filePath = `brands/${getUUIDForFile(_id)}_${name}/${
        logo.file.name
      }`;
      const [fileUrl] = await uploadMedia([
        {
          file: logo.file,
          path: filePath,
        },
      ]);
      if (fileUrl) {
        brandLogo = fileUrl;
      }
    } else if (logo && logo[0] && logo[0].src) {
      brandLogo = logo[0].src;
    }

    switch (actionType) {
      case 'create-draft':
        path = `/drafts/collections/${modelName}`;
        method = 'POST';
        break;
      case 'save-draft':
        path = `/drafts/${_id}`;
        method = 'PUT';
        break;
      case 'submit-create-entity':
        path = `/${modelName}`;
        method = 'POST';
        break;
      case 'submit-save-entity':
        path = `/${modelName}/${_id}`;
        method = 'PUT';
        break;
    }
    const response = await authAxiosCall(path, {
      method,
      source,
      body: JSON.stringify({
        name,
        description,
        logo: brandLogo,
        themes,
        organizationId,
      }),
    });
    return response;
  } catch (e) {
    if (e.message === 'brand already exists') {
    } else {
      throw e;
    }
  }
};
