import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';
import { Journey, Facility } from '../../../../Lib/Utils/Validations';

import {
  JourneyStepsFacilityContainer as Container,
  SwitchLabel,
} from '../styles';

import {
  Label,
  CustomTooltip,
  Switch,
  InfoIcon,
  TransferList,
  Dropdown,
  FormInput,
} from '../../../../Components';
import { FacilityTypes } from '../../../../Lib/Configs';
import moment from 'moment';

interface Props {
  onChange: Function;
  facilityId?: any;
  facilityType?: string;
  facilityTypeIcon?: string;
  description?: string;
  isActiveFacility?: boolean;
  isParallel?: boolean;
  isComplex?: boolean;
  facilityClaims?: Array<any>;
  facilities?: Array<any>;
  claims?: Array<any>;
  previousStep?: any;
  number?: number;
}

const JourneyStepsFacility = ({
  onChange,
  facilityId,
  facilityType,
  facilityTypeIcon = 'QuestionMark',
  description,
  isActiveFacility = false,
  isParallel = false,
  isComplex = false,
  facilities = [],
  facilityClaims = [],
  claims = [],
  previousStep,
  number,
}: Props) => {
  const [defaultClaimsList, setDefaultClaimsList] = useState([] as any);
  const [defaultFacilities, setDefaultFacilities] = useState([] as any);

  const { errors, control, setValue } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    const facilitiesOptions =
      facilities?.map(f => ({
        value: f._id,
        label: f.registeredName,
      })) || [];
    setDefaultFacilities(facilitiesOptions);
  }, []);

  useEffect(() => {
    let claimsList = [] as any;
    claimsList = claims
      .filter(c => c.facilityId === facilityId)
      .map(claim => {
        const { supportingVerifications = [], name, description, _id } = claim;
        const from = supportingVerifications.length
          ? supportingVerifications[0].issueDate
          : '';
        const to = supportingVerifications.length
          ? supportingVerifications[0].expirationDate
          : '';
        const selected = !!facilityClaims.find(
          claimId => claimId === claim._id
        );

        return {
          text: name,
          selected,
          description,
          from: moment(from).format('MM/DD/YYYY'),
          to: moment(to).format('MM/DD/YYYY'),
          data: _id,
          tooltip: { items: description },
        };
      });
    setDefaultClaimsList(claimsList);
  }, [facilityId, claims, number]);

  useEffect(() => {
    setValue([
      { facilityId },
      { facilityType },
      { isActiveFacility },
      { description },
      { isParallel },
      { facilityTypeIcon },
    ]);
  }, [
    facilityId,
    facilityType,
    isActiveFacility,
    description,
    isParallel,
    facilityTypeIcon,
  ]);

  const selectedFacilityDescription =
    (facilityId && facilities?.find(f => f._id === facilityId)?.description) ||
    '';

  useEffect(() => {
    if (isParallel && previousStep) {
      if (previousStep.facilityType) {
        onChange('facilityType', previousStep.facilityType);
        setValue('facilityType', previousStep.facilityType);
      }

      if (previousStep.facilityTypeIcon) {
        onChange('facilityTypeIcon', previousStep.facilityTypeIcon);
        setValue('facilityTypeIcon', previousStep.facilityTypeIcon);
      }
    } else {
      onChange('isParallel', false);
      setValue('isParallel', false);
    }
  }, [isParallel]);

  const isParallelDisabled =
    !previousStep?.facilityType && !previousStep?.isParallel;

  return (
    <Container>
      <div className="row">
        <div className="col-12 col-xl-6">
          <Label>
            {i18n.t('Parallel with previous step/facility')}
            <div data-tip data-for="parallel">
              <InfoIcon />
            </div>
          </Label>

          <div className="switch-wrapper" data-tip data-for="isParallelSwitch">
            <SwitchLabel selected={!isParallel}>{i18n.t('No')}</SwitchLabel>
            <Switch
              alwaysOn
              onChange={evt => onChange(evt.target.name, evt.target.checked)}
              checked={isParallelDisabled ? false : isParallel}
              name="isParallel"
              disabled={isParallelDisabled}
            />
            <SwitchLabel selected={isParallel}>{i18n.t('Yes')}</SwitchLabel>
          </div>

          <CustomTooltip id="parallel" placement="right">
            <span>
              {i18n.t(
                'Useful when there are multiple facilities or processes for one step.'
              )}
              <br />
              {i18n.t(
                'For example, if there are two Warehouses or if there is Production Line A and Production Line B.'
              )}
            </span>
          </CustomTooltip>

          {isParallelDisabled && (
            <CustomTooltip id="isParallelSwitch" placement="right">
              <span>
                {i18n.t('Must select a facility type on previous step')}
              </span>
            </CustomTooltip>
          )}
        </div>

        {/*
        <div className="col-12 col-lg-6">
          <Label>
            {i18n.t('Description')}
            <div data-tip data-for="description">
              <InfoIcon />
            </div>
            <CustomTooltip id="description" placement="right">
              <span>{i18n.t('Description of the facility')}</span>
            </CustomTooltip>
          </Label>
          <SwitchLabel selected={!!facilityId} className="mt-3">
            {facilityId
              ? selectedFacilityDescription
              : i18n.t('Select a facility to show the description')}
          </SwitchLabel>
        </div>
        */}
        <div className="col-12 col-xl-6">
          <Label>
            {i18n.t('Any inbound or outbound assets?')}
            <div data-tip data-for="facilitystatus">
              <InfoIcon />
            </div>
            <CustomTooltip id="facilitystatus" placement="right">
              <p style={{ margin: 0 }}>
                <strong>{i18n.t('Static')}: </strong>
                {i18n.t('Facilities will not be required to capture any data')}
                <br />
                <br />
                <strong>{i18n.t('Active')}: </strong>
                {i18n.t('Facilities will require supply chain')}
                <br />
                <br />
                <strong>{i18n.t('Claims')}: </strong>
                {i18n.t(
                  'Facility claims that are added to the facility will be displayed to end consumers'
                )}
              </p>
            </CustomTooltip>
          </Label>
          <div className="switch-wrapper">
            <SwitchLabel selected={!isActiveFacility}>
              {i18n.t('No, does not have assets (Static)')}
            </SwitchLabel>
            <Switch
              alwaysOn
              onChange={evt => onChange(evt.target.name, evt.target.checked)}
              checked={isActiveFacility}
              name="isActiveFacility"
            />
            <SwitchLabel selected={isActiveFacility}>
              {i18n.t('Yes, has assets (Active)')}
            </SwitchLabel>
          </div>
        </div>

        <div className="col-12">
          <Label>
            {i18n.t('Step main heading')}
            <div data-tip data-for="facility">
              <InfoIcon />
            </div>
            <CustomTooltip id="facility" placement="right">
              <span>
                {i18n.t(
                  'This will appear on the product journey step overview (before user taps to see the step details).'
                )}
              </span>
            </CustomTooltip>
          </Label>

          <Controller
            as={
              <Dropdown
                required
                searchable
                options={defaultFacilities.sort((a, b) =>
                  a.label.toLowerCase().localeCompare(b.label.toLowerCase())
                )}
                placeholder={i18n.t('Select a facility for this journey step')}
                action={() => {}}
                defaultOption={facilityId}
              />
            }
            name="facilityId"
            value={facilityId}
            control={control}
            onChange={([newValue]) => {
              // onChange('isActiveFacility', true);
              newValue && onChange('facilityId', newValue);
              return newValue;
            }}
            rules={Facility.name}
            defaultValue={facilityId}
          />
          {errors.facilityId && (
            <span className="inlineErrorMessage">
              {errors.facilityId.message}.
            </span>
          )}
        </div>

        {/*
        <div className="col-12 col-md-6 col-lg-6">
          <Label>
            {i18n.t('Is this a simple or complex step')}
            <div data-tip data-for="iscomplex">
              <InfoIcon />
            </div>
            <CustomTooltip id="iscomplex" placement="right">
              <p style={{ margin: 0 }}>
                <strong>{i18n.t('Simple')}: </strong>
                {i18n.t(
                  'If "Simple" is selected, the mobile scanner app will only show one tab called "Overview" where all information will be consolidated (company description, contact information, and claims). It will not show the "About" tab. Additionally, the "Quote" that was previously captured for the facility will not be shown.'
                )}
                <br />
                <br />
                <strong>{i18n.t('Complex')}: </strong>
                {i18n.t(
                  'If "Complex" is selected, the mobile scanner app will show two tabs "Overview" and "About". Additionally, it will display the "Quote" for that facility on the "Overview" tab.'
                )}
              </p>
            </CustomTooltip>
          </Label>
          <div className="switch-wrapper">
            <SwitchLabel selected={!isComplex}>{i18n.t('Simple')}</SwitchLabel>
            <Switch
              alwaysOn
              onChange={evt => onChange(evt.target.name, evt.target.checked)}
              checked={isComplex}
              name="isComplex"
            />
            <SwitchLabel selected={isComplex}>{i18n.t('Complex')}</SwitchLabel>
          </div>
        </div>
        */}

        <div className="col-12 col-lg-6">
          <Label>
            {i18n.t('Step sub-heading')}
            <div data-tip data-for="facilitytype">
              <InfoIcon />
            </div>
            <CustomTooltip id="facilitytype" placement="right">
              <span>
                {i18n.t(
                  'This will appear on the product journey step overview (before user taps to see step details).'
                )}
              </span>
            </CustomTooltip>
          </Label>
          <div data-tip data-for="facilityTypeTooltip">
            <Controller
              as={
                <FormInput
                  required
                  placeholder={i18n.t('Enter the facility Type')}
                  maxLength={40}
                  disabled={isParallel}
                />
              }
              name="facilityType"
              value={facilityType}
              control={control}
              onChange={([evt]) => {
                onChange(evt.target.name, evt.target.value);
                return evt.target.value;
              }}
              rules={Facility.type}
              defaultValue={facilityType}
            />
          </div>
          {isParallel && (
            <CustomTooltip id="facilityTypeTooltip" placement="top">
              <span>
                {i18n.t('Must be the same as the parallel previous facility.')}
              </span>
            </CustomTooltip>
          )}
          {errors.facilityType && (
            <span className="inlineErrorMessage">
              {errors.facilityType.message}.
            </span>
          )}
        </div>
        <div className="col-12 col-lg-6">
          <Label>
            {i18n.t('Step icon')}
            <div data-tip data-for="facilitytypeicon">
              <InfoIcon />
            </div>
            <CustomTooltip id="facilitytypeicon" placement="right">
              <span>
                {i18n.t(
                  'This will appear on the product journey step overview (before user taps to see the step details).'
                )}
              </span>
            </CustomTooltip>
          </Label>

          <div data-tip data-for="facilityTypeIconTooltip">
            <Controller
              as={
                <Dropdown
                  required
                  searchable
                  options={FacilityTypes}
                  placeholder={i18n.t('Select a facility type icon')}
                  action={value => {}}
                  defaultOption={facilityTypeIcon}
                  disabled={isParallel}
                />
              }
              name="facilityTypeIcon"
              value={facilityTypeIcon}
              control={control}
              onChange={([newValue]) => {
                onChange('facilityTypeIcon', newValue);
                return newValue;
              }}
              rules={Facility.type}
              defaultValue={facilityTypeIcon}
            />
          </div>

          {errors.facilityTypeIcon && (
            <span className="inlineErrorMessage">
              {errors.facilityTypeIcon.message}.
            </span>
          )}
          {isParallel && (
            <CustomTooltip id="facilityTypeIconTooltip" placement="top">
              <span>
                {i18n.t(
                  'Must be the same as the parallel previous step/facility.'
                )}
              </span>
            </CustomTooltip>
          )}
        </div>
        <div className="col-12">
          <Label>
            {i18n.t('Step description')}
            <div data-tip data-for="stepdescription">
              <InfoIcon />
            </div>
            <CustomTooltip id="stepdescription" placement="right">
              <div>
                {i18n.t(
                  'This will appear on the product journey step overview (before user taps to see the step details).'
                )}
              </div>
            </CustomTooltip>
          </Label>
          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Enter the step description')}
                maxLength={75}
              />
            }
            name="description"
            value={description}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Facility.shortDescription}
            defaultValue={description}
          />
          {errors.description && (
            <span className="inlineErrorMessage">
              {errors.description.message}.
            </span>
          )}
        </div>
        {facilityId && (
          <div className="col-12">
            <Label>
              {i18n.t('Select the facility claims that')}
              <div data-tip data-for="facilityclaims">
                <InfoIcon />
              </div>
              <CustomTooltip id="facilityclaims" placement="right">
                <span>
                  {i18n.t(
                    'These are the claims that were created under Claim Management'
                  )}
                </span>
              </CustomTooltip>
            </Label>
            <div>
              <TransferList
                fromPlaceholder={i18n.t('Search the facility claims')}
                items={defaultClaimsList}
                handleChange={items =>
                  onChange(
                    'facilityClaims',
                    items.filter(i => i.selected).map(i => i.data)
                  )
                }
              />
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default forwardRef(JourneyStepsFacility);
