import { orderSort } from '../../../../Lib/Utils/functions';

export const generateNewRow = (
  rowNumber,
  valuesProp,
  template,
  id = null,
  subDocumentId = null,
  editLocked = false,
  tableFieldValue,
  setTableFieldValue,
  fromServer = valuesProp !== null
) => {
  if (template) {
    if (template.version && template.version[0]) {
      const newHeaders = Object.keys(template.version[0]).map(v => v);
      const newField = newHeaders.map((header, index) => {
        const {
          mandatory,
          type,
          values = null,
          placeholder = header,
          maxChars,
        } = template.version[0][header];
        let defaultValue = '';
        if (valuesProp && valuesProp !== null && valuesProp[header]) {
          defaultValue = valuesProp[header];
        } else if (tableFieldValue) {
          defaultValue =
            (tableFieldValue[rowNumber] &&
              tableFieldValue[rowNumber][header]) ||
            '';
        }
        return {
          // disabled: valuesProp !== null,
          disabled: editLocked,
          rowNumber: rowNumber,
          index: index,
          header: header,
          type: type,
          mandatory: mandatory,
          values: values,
          value: defaultValue,
          tableFieldValue: tableFieldValue,
          setTableFieldValue: setTableFieldValue,
          fromServer: valuesProp !== null,
          id,
          subDocumentId: subDocumentId,
          maxChars,
          placeholder,
          marketableProduct: template.marketableProduct,
          name: template.name,
        };
      });
      return newField;
    }
  }
};

export const isFirstOfParallels = (steps, step, index) =>
  !step.parallelToPreviousStep &&
  steps.sort(orderSort)[index + 1] &&
  steps.sort(orderSort)[index + 1].step.parallelToPreviousStep;

export const findParallels = (steps, step, order, index) => {
  const parallelTo = [] as any;
  const firstOfParallel = isFirstOfParallels(steps, step, index);
  if (firstOfParallel || step.parallelToPreviousStep) {
    let parallelsFinishFlag = false;
    if (firstOfParallel) {
      steps
        ?.sort(orderSort)
        .filter(({ order: pOrder }) => pOrder >= order)
        .forEach((pStep, idx, allSteps) => {
          if (!parallelsFinishFlag) {
            parallelTo.push(pStep);
            parallelsFinishFlag =
              allSteps[idx + 1]?.step.parallelToPreviousStep === false;
          }
        });
    } else if (step.parallelToPreviousStep) {
      parallelsFinishFlag = false;
      steps
        ?.sort(orderSort)
        .filter(({ order: pOrder }) => pOrder < order)
        .reverse()
        .forEach(pStep => {
          const pFirstOfParallel = !pStep.step.parallelToPreviousStep;
          if (
            !parallelsFinishFlag &&
            (pStep.step.parallelToPreviousStep || pFirstOfParallel)
          ) {
            parallelTo.push(pStep);
            parallelsFinishFlag = pFirstOfParallel;
          }
        });

      parallelsFinishFlag = false;
      steps
        ?.sort(orderSort)
        .filter(({ order: pOrder }) => pOrder >= order)
        .forEach((pStep, idx, allSteps) => {
          if (!parallelsFinishFlag && pStep.step.parallelToPreviousStep) {
            parallelTo.push(pStep);
            parallelsFinishFlag = !allSteps[idx + 1].step
              .parallelToPreviousStep;
          }
        });
    }
  }
  return parallelTo;
};
