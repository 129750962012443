import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { Table, HeaderFilter, Loading } from '../../../Components';
import { Container } from './styles';
import moment from 'moment';
import { FaUserAlt } from 'react-icons/fa';
import { isOrgAdmin } from '../../../Lib/Utils/auth';
import { doGetOrganizations } from '../../../Redux/Organization/organizationActions';

interface RootState {
  organization: any;
}

const mapState = (state: RootState) => ({
  organizations: state.organization.organizations,
  loading: state.organization.loading,
  error: state.organization.error,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getOrganizationsAction: () => dispatch(doGetOrganizations()),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & { history?: any; location?: any };

const OrganizationList = (props: Props) => {
  const { history, organizations, loading, getOrganizationsAction } = props;

  const [rows, setRows] = useState([] as Array<any>);
  const [filteredRows, setFilteredRows] = useState([] as Array<any>);
  const [filters, setFilters] = useState({});
  const [textFilter, setTextFilter] = useState('');

  useEffect(() => {
    getOrganizationsAction();
  }, []);

  useEffect(() => {
    if (organizations?.length > 0) {
      const defaultRows = organizations
        .sort((a, b) =>
          moment(a.createdTimestamp || a.createdAt).isBefore(
            moment(b.createdTimestamp || a.createdAt)
          )
            ? 1
            : -1
        )
        .map(organization => {
          const {
            id,
            _id,
            name,
            description,
            isDraft,
            createdTimestamp,
            createdAt,
          } = organization;
          return {
            id: _id,
            name,
            description,
            createdAt: moment(createdTimestamp || createdAt).format(
              'MM/DD/YYYY'
            ),
            inProgress: false,
            objectId: _id,
          };
        })
        .map(row => Object.values(row));
      setRows(defaultRows);
    }
  }, [organizations]);

  const headers = [
    { text: i18n.t('ID'), order: true },
    { text: i18n.t('Name'), order: true },
    { text: i18n.t('Description'), order: true },
    { text: i18n.t('Created'), order: true },
    { text: '', order: false, key: 'inProgress' },
  ];

  const inputHandler = value => {
    setTextFilter(value);
  };

  const handleResetFilters = () => {
    setFilters({});
  };

  const applyFilters = () => {
    let newFilteredRows = rows;

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        if (key === 'inprogress') {
          newFilteredRows = newFilteredRows.filter(row => !!row[4]);
        }
      }
    });

    if (textFilter && textFilter !== '') {
      newFilteredRows = newFilteredRows.filter(
        row =>
          row
            .join(' ')
            .toUpperCase()
            .search(textFilter.toUpperCase()) !== -1
      );
    }

    setFilteredRows(newFilteredRows);
  };

  useEffect(() => applyFilters(), [filters, textFilter, rows]);

  return (
    <>
      <Container>
        <HeaderFilter
          title={i18n.t('Existing Organizations')}
          titleIcon={<FaUserAlt color="rgb(216, 244, 12)" />}
          searchPlaceholder={i18n.t('Search all columns')}
          actionButtonText={`+ ${i18n.t('New Organization')}`}
          setFilters={setFilters}
          inputHandler={value => inputHandler(value)}
          actionButtonHandler={() => history.push('/organizations/draft')}
          selects={[]}
          resetFiltersHandler={handleResetFilters}
          name="organization"
          withFilters={false}
          actionButton={!!isOrgAdmin()}
        />
        <Table
          headers={headers}
          rows={filteredRows}
          pagination
          rowsPerPage={20}
          pagesBtw={2}
          ignoreKeys={[5]}
          editable={!!isOrgAdmin()}
          handleEdit={data => {
            data[4]
              ? history.push(`/organizations/${data[5]}/draft`)
              : history.push(`/organizations/${data[5]}/edit`);
          }}
        />
      </Container>
      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(OrganizationList) as any) as any
) as any;
