import i18n from 'i18next';

const name = {
  required: { value: true, message: i18n.t('Required field') },
  maxLength: { value: 50, message: i18n.t('Max 50 characters') },
};

const description = {
  maxLength: { value: 500, message: i18n.t('Max 255 characters') },
};

const logo = {
  maxLength: { value: 255, message: i18n.t('Max 255 characters') },
};

export default {
  name,
  description,
  logo,
};
