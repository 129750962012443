import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import {
  doGetCampaigns,
  doDeleteCampaign,
  resetCurrentCampaign,
} from '../../../Redux/Campaign/campaignActions';
import Toast from '../../../Lib/Utils/toast';
import {
  Table,
  HeaderFilter,
  CampaignIcon,
  InProgressButton,
  Loading,
} from '../../../Components';

import { Container } from './styles';
import moment from 'moment';
import { isOrgAdmin } from '../../../Lib/Utils/auth';

const mockData = require('./mockData.json');

interface RootState {
  campaign: any;
}

interface InheritProps {
  showHeader?: boolean;
  parentCampaigns?: Array<any>;
  editCallback?: Function;
}

const mapState = (state: RootState) => ({
  campaigns: state.campaign.campaigns || [],
  loadingCampaigns: state.campaign.loading,
  deleteSuccess: state.campaign.deleteSuccess,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getCampaignsAction: (payload = {}, includeDrafts) =>
    dispatch(doGetCampaigns(payload, includeDrafts)),
  deleteCampaignAction: (campaignId, isDraft) =>
    dispatch(doDeleteCampaign(campaignId, isDraft)),
  doResetCurrentCampaign: () => dispatch(resetCurrentCampaign()),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & InheritProps & { history?: any };

const CampaignsList = (props: Props) => {
  const {
    campaigns,
    loadingCampaigns,
    history,
    deleteSuccess,
    showHeader = true,
    editCallback,
    parentCampaigns,
    getCampaignsAction,
    deleteCampaignAction,
    doResetCurrentCampaign,
  } = props;

  const loading = loadingCampaigns;

  const [rows, setRows] = useState([] as any);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [filters, setFilters] = useState({});
  const [textFilter, setTextFilter] = useState('');

  const headers = [
    { text: i18n.t('ID'), order: true },
    { text: i18n.t('Campaign Name'), order: true },
    { text: i18n.t('Campaign Description'), order: true },
    { text: i18n.t('Start date'), order: true },
    { text: i18n.t('End date'), order: true },
    { text: '', order: false, key: 'inProgress' },
  ];

  useEffect(() => {
    doResetCurrentCampaign();
    getCampaignsAction({}, true);
  }, []);

  useEffect(() => {
    if (deleteSuccess) {
      Toast.success(i18n.t(`The campaign was discarded`));
      getCampaignsAction({}, true);
    }
  }, [deleteSuccess]);

  const applyFilters = () => {
    let newFilteredRows = rows;

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        if (key === 'inprogress') {
          newFilteredRows = newFilteredRows.filter(row => !!row[6]);
        }

        if (key === 'journey') {
          newFilteredRows = newFilteredRows.filter(
            row => row[1] == filters[key]
          );
        }
      }

      if (textFilter && textFilter !== '') {
        newFilteredRows = newFilteredRows.filter(
          row =>
            row
              .join(' ')
              .toUpperCase()
              .search(textFilter.toUpperCase()) !== -1
        );
      }
    });

    setFilteredRows(newFilteredRows);
  };

  useEffect(() => {
    const defaultRows = campaigns
      .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
      .map((row, index) => {
        const { _id, name, description, startDate, endDate, isDraft } = row;

        return {
          _id,
          journeyId: 1,
          name,
          description,
          startDate,
          endDate,
          inProgress: isDraft ? (
            <InProgressButton
              action={() =>
                editCallback
                  ? editCallback(_id)
                  : history.push(`/campaigns/${_id}/draft`)
              }
            />
          ) : (
            false
          ),
        };
      })
      .map(row => Object.values(row));
    setRows(defaultRows);
  }, [campaigns]);

  useEffect(() => applyFilters(), [filters, textFilter, rows]);

  const pagesBtw = 2;

  const selects = [
    {
      type: 'checkbox',
      label: i18n.t('In progress'),
      key: 'inprogress',
    },
    {
      placeholder: i18n.t('Search Journey'),
      options: [
        { value: '1', label: 'Journey Sample 1' },
        { value: '2', label: 'Journey Sample 2' },
        { value: '3', label: 'Journey Sample 3' },
      ],
      key: 'journey',
    },
    {
      placeholder: i18n.t('Search by start date'),
      key: 'd1',
      options: {},
      type: 'date',
    },
    {
      placeholder: i18n.t('Search by end date'),
      key: 'd2',
      options: {},
      type: 'date',
    },
  ];

  const inputHandler = value => {
    setTextFilter(value);
  };

  const handleResetFilters = () => {
    setFilters({});
  };

  const handleDeleteCampaign = id => {
    const newRows = rows.filter(r => r[0] !== id);
    setRows(newRows);
    applyFilters();
  };

  return (
    <>
      <Container>
        {showHeader && (
          <HeaderFilter
            title={i18n.t('Existing Campaigns')}
            titleIcon={<CampaignIcon />}
            searchPlaceholder={i18n.t('Search all columns')}
            actionButtonText={`+ ${i18n.t('New Campaign')}`}
            setFilters={setFilters}
            inputHandler={inputHandler}
            actionButtonHandler={() => history.push('/campaigns/draft')}
            selects={selects}
            useDropdown={true}
            resetFiltersHandler={handleResetFilters}
            name="campaigns"
            actionButton={!!isOrgAdmin()}
          />
        )}
        <Table
          headers={headers}
          rows={filteredRows}
          pagination
          rowsPerPage={20}
          pagesBtw={pagesBtw}
          editable={!!isOrgAdmin()}
          handleEdit={data => {
            editCallback
              ? editCallback(data[0])
              : data[6]
              ? history.push(`/campaigns/${data[0]}/draft`)
              : history.push(`/campaigns/${data[0]}/edit`);
          }}
          handleDelete={id => handleDeleteCampaign(id)}
          deleteTitle={i18n.t('Delete the campaign?')}
          deleteContent={i18n.t('Deleting the campaign will remove')}
          ignoreKeys={[1]}
          archivable={!!isOrgAdmin()}
          archiveTitle={i18n.t(
            'Are you sure you want to archive this campaign?'
          )}
          handleArchive={data => deleteCampaignAction(data[0], !!data[6])}
        />
      </Container>
      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(CampaignsList) as any) as any
) as any;
