import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StepsList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1;
  min-height: 170px;
  ul {
    min-width: 300px;
    &.list {
      list-style: none;
      padding-left: 15px;
      min-width: auto;

      li::before {
        content: '•';
        color: #a7bf2e;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }

    li {
      white-space: nowrap;
    }
  }

  justify-content: center;
`;

const StepItem = styled.li`
  .facilityList {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
  }

  .facilities {
    position: absolute;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    border-radius: 20px;
    height: 20px;
    width: 20px;
    left: 30px;
    top: -10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  list-style: none;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  text-align: center;
  justify-content: center;

  align-items: flex-start;

  max-width: 150px;
  min-width: 100px;

  &:last-child {
    flex: 0.5;
    width: 80px;
    max-width: fit-content;
    min-width: fit-content;
    &:before {
      display: none;
    }
  }

  font-family: Encode Sans;

  div {
    width: 80px;
    z-index: 1;
  }

  &:before {
    top: 38px;
    bottom: 0;
    position: absolute;
    content: '';
    width: 100%;
    height: 4px;
    background-color: #ababab;
    z-index: 0;
  }

  button {
    margin: 0;
    padding: 0;
    outline: none;
    background-color: #b6b6b6;
    color: white;
    width: 80px;
    height: 80px;
    text-align: center;
    font-size: 26px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    z-index: 2;

    svg {
      min-width: 30px;
      min-height: 30px;
      path {
        fill: white;
      }
    }

    &[disabled] {
      background-color: #707070;
      cursor: default;
    }
  }

  &.done {
    button:not([disabled]) {
      background-color: #a7bf2e;
      svg {
        path {
          fill: white;
        }
      }
    }
    &:before {
      background-color: #a7bf2e;
    }
  }

  &.active {
    button {
      background-color: #303030 !important;
      svg {
        path {
          fill: #d8f40c !important;
        }
      }
    }
  }

  .step-wrapper:hover .add-item-icon {
    opacity: 1;
  }
`;

const IconContainer = styled.div``;

const TitleContainer = styled.div`
  width: 80px;
  margin-top: 10px;
  height: 80px;
  font-weight: 600;
  small {
    font-weight: normal;
    display: block;
    color: #757575;
  }

  & ~ button.active {
    color: red;
  }

  &.clickable {
    cursor: pointer;
  }
`;

const NumberContainer = styled.div``;

const StepContent = styled.div`
  margin-top: 20px;
  position: relative;
`;

const DropdownContainer = styled.div`
  width: 300px;
  margin: 20px 0;
`;

const AddItemIcon = styled.div`
  height: 32px;
  margin-top: -20px;
  margin-left: 17px;
  opacity: 0;

  button {
    background: #000;
    border: 1px solid white;

    svg {
      min-width: auto;
      min-height: auto;
      path {
        fill: #fff !important;
      }
    }
  }
`;

const Check = styled.div`
  position: absolute;
  left: 60px;
  top: 60px;
  width: 22px !important;
  height: 22px;
  border-radius: 20px;
  background-color: #d8f40c;
  border: 2px solid white;
  svg {
    margin-top: -9px;
    margin-left: -1px;
    path {
      fill: #000;
    }
  }
`;

const EditableItemsContainer = styled.div`
  display: flex;
`;

export {
  Container,
  StepsList,
  StepItem,
  IconContainer,
  TitleContainer,
  NumberContainer,
  StepContent,
  DropdownContainer,
  AddItemIcon,
  Check,
  EditableItemsContainer,
};
