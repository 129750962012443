import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #303030;
  margin-bottom: 16px;
  letter-spacing: 1px;
  white-space: nowrap;
  .title {
    text-transform: uppercase;
    margin-left: 10px;
  }
  .arrow {
    margin: 0 10px;
    color: #a7bf2e;
  }

  @media (max-width: 1400px) {
    font-size: 16px;
  }
`;

const TitleContainer = styled.div<{ clickable?: boolean }>`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'normal')};
`;

export { Container, TitleContainer };
