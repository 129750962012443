import { EOrganizationActionTypes } from './organizationActionsTypes';

const initialState = {
  currentOrganization: {},
  loading: false,
  users: [],
  organizations: [],
};

interface State {
  currentOrganization: any;
  loading: boolean;
  error?: string;
  organizations?: Array<any>;
  users?: Array<any>;
  saveSuccess?: boolean;
  deleteSuccess?: boolean;
}

interface OrganiaztionAction extends State {
  type: EOrganizationActionTypes;
  payload: any;
}

type TUserReducerActions = OrganiaztionAction;

export default function(
  state: State = initialState,
  action: TUserReducerActions
) {
  switch (action.type) {
    case EOrganizationActionTypes.GET_ORGANIZATIONS:
      return { ...state, loading: true, error: null };
    case EOrganizationActionTypes.GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.payload.organizations,
        loading: false,
      };
    case EOrganizationActionTypes.GET_ORGANIZATIONS_ERROR:
      return { ...state, error: action.error, loading: false };

    case EOrganizationActionTypes.GET_ORGANIZATION:
      return { ...state, loading: true, error: null };
    case EOrganizationActionTypes.GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        currentOrganization: action.payload.organization,
        loading: false,
      };
    case EOrganizationActionTypes.GET_ORGANIZATION_ERROR:
      return { ...state, error: action.error, loading: false };

    case EOrganizationActionTypes.GET_ORGANIZATION_USERS:
      return { ...state, loading: true, error: null };
    case EOrganizationActionTypes.GET_ORGANIZATION_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        loading: false,
      };
    case EOrganizationActionTypes.GET_ORGANIZATION_USERS_ERROR:
      return { ...state, error: action.error, loading: false };

    case EOrganizationActionTypes.SAVE_ORGANIZATION:
      return {
        ...state,
        loading: true,
        saveSuccess: false,
      };
    case EOrganizationActionTypes.SAVE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        currentOrganization: action.payload.organization,
        saveSuccess: !!action.payload.notifySuccess,
      };
    }
    case EOrganizationActionTypes.SAVE_ORGANIZATION_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        submitSuccess: false,
        saveSuccess: false,
      };

    case EOrganizationActionTypes.DELETE_ORGANIZATION:
      return {
        ...state,
        loading: true,
        deleteSuccess: false,
      };
    case EOrganizationActionTypes.DELETE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        deleteSuccess: !!action.payload.data,
      };
    }
    case EOrganizationActionTypes.DELETE_ORGANIZATION_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        submitSuccess: false,
        deleteSuccess: false,
      };

    case EOrganizationActionTypes.RESET_STATE:
      return {
        ...state,
        error: false,
        users: [],
        organizations: [],
        currentOrganization: false,
        saveSuccess: false,
        deleteSuccess: false,
      };

    default:
      return state;
  }
}
