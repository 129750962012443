import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import moment from 'moment';

import {
  doGetAssetTemplates,
  doDeleteAssetTemplate,
  resetCurrentAsset,
} from '../../../Redux/Asset/assetActions';
import { doGetBrands } from '../../../Redux/Catalog/catalogActions';
import { doGetFacilities } from '../../../Redux/Facility/facilityActions';
import { doGetJourneyTemplates } from '../../../Redux/Journey/journeyActions';
import {
  Table,
  HeaderFilter,
  BarcodeIcon,
  InProgressButton,
  Loading,
} from '../../../Components';
import { Container } from './styles';
import Toast from '../../../Lib/Utils/toast';
import { isOrgAdmin } from '../../../Lib/Utils/auth';

interface RootState {
  asset: any;
  catalog: any;
  journey: any;
  facility: any;
}

interface InheritProps {
  showHeader?: boolean;
  parentAssets?: Array<any>;
  editCallback?: Function;
  useStore?: Array<string>;
}

const mapState = (state: RootState) => ({
  assets: state.asset.assets,
  loading: state.asset.loading,
  error: state.asset.error,
  brands: state.catalog.brands,
  facilities: state.facility.facilities,
  journeyTemplates: state.journey.journeyTemplates,
  deleteSuccess: state.asset.deleteSuccess,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getAssetTemplatesAction: includeDrafts =>
    dispatch(doGetAssetTemplates(includeDrafts)),
  getBrandsAction: () => dispatch(doGetBrands()),
  getFacilitiesAction: () => dispatch(doGetFacilities()),
  getJourneyTemplatesAction: () => dispatch(doGetJourneyTemplates()),
  deleteAssetTemplateAction: (assetId, isDraft) =>
    dispatch(doDeleteAssetTemplate(assetId, isDraft)),
  resetFormAction: () => dispatch(resetCurrentAsset()),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & InheritProps & { history?: any };

const AssetsList = (props: Props) => {
  const {
    history,
    getAssetTemplatesAction,
    getBrandsAction,
    getJourneyTemplatesAction,
    getFacilitiesAction,
    deleteAssetTemplateAction,
    deleteSuccess,
    assets,
    parentAssets,
    brands,
    facilities,
    journeyTemplates,
    loading,
    error,
    showHeader = true,
    editCallback,
    useStore = [],
    resetFormAction,
  } = props;

  const [rows, setRows] = useState([] as any);
  const [filteredRows, setFilteredRows] = useState([] as any);
  const [filters, setFilters] = useState({});
  const [textFilter, setTextFilter] = useState('');

  useEffect(() => {
    resetFormAction();
    if (!parentAssets) {
      getAssetTemplatesAction(true);
    }
    if (!useStore.includes('facilities')) {
      getFacilitiesAction();
    }
    getBrandsAction();
    getJourneyTemplatesAction();
  }, []);

  useEffect(() => {
    if (error) {
      Toast.error(error);
    } else if (deleteSuccess) {
      Toast.success(i18n.t(`The asset was discarded`));
      getAssetTemplatesAction(true);
    }
  }, [error, deleteSuccess]);

  useEffect(() => {
    const defaultRows = (parentAssets || assets)
      .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
      .map(asset => {
        const {
          _id,
          name,
          description,
          brandId,
          skuku,
          createdAt,
          updatedAt,
          isDraft,
          marketplaces,
        } = asset;

        return {
          _id,
          name,
          description,
          brandId,
          skuku,
          createdAt: moment(createdAt).format('MM/DD/YYYY'),
          updatedAt: moment(updatedAt).format('MM/DD/YYYY'),
          marketable: marketplaces && marketplaces.length > 0,
          inProgress: isDraft ? (
            <InProgressButton
              action={() =>
                editCallback
                  ? editCallback(_id, skuku)
                  : history.push(`/assets/${_id}/draft`)
              }
            />
          ) : (
            false
          ),
        };
      })
      .map(row => Object.values(row));
    setRows(defaultRows);
  }, [assets, parentAssets]);

  const getBrandsOptions = () =>
    brands.map(b => ({ value: b._id, label: b.name }));

  const getFacilitiesOptions = () =>
    facilities.map(f => ({ value: f._id, label: f.registeredName }));

  const getJourneysOptions = () =>
    journeyTemplates.map(j => ({ value: j._id, label: j.journeyName }));

  const headers = [
    { text: 'ID', order: true },
    { text: i18n.t('Name'), order: true },
    { text: i18n.t('Description'), order: true },
    { text: '', order: false, key: 'brand', show: false },
    { text: '', order: false, key: 'skuku', show: false },
    { text: i18n.t('Created'), order: true },
    { text: i18n.t('Updated'), order: true },
    { text: '', order: false, key: 'marketable' },
    { text: '', order: false, key: 'inProgress' },
  ];

  const selects = [
    {
      type: 'checkbox',
      label: i18n.t('In progress'),
      key: 'inprogress',
    },
    {
      type: 'checkbox',
      options: {},
      label: i18n.t('Marketable products only'),
      key: 'marketable',
    },
    {
      placeholder: i18n.t('Search Journey'),
      options: getJourneysOptions(),
      key: 'journey',
    },
    {
      placeholder: i18n.t('Search Facility'),
      options: getFacilitiesOptions(),
      key: 'facility',
    },
    {
      placeholder: i18n.t('Search Brand'),
      options: getBrandsOptions(),
      key: 'brand',
    },
  ];

  const inputHandler = value => {
    setTextFilter(value);
  };

  const handleResetFilters = () => {
    setFilters({});
  };

  const applyFilters = () => {
    let newFilteredRows = rows;

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        if (key === 'inprogress') {
          newFilteredRows = newFilteredRows.filter(row => !!row[8]);
        }

        if (key === 'brand') {
          newFilteredRows = newFilteredRows.filter(
            row => row[3] == filters[key]
          );
        }

        if (key === 'marketable') {
          newFilteredRows = newFilteredRows.filter(row => row[7]);
        }

        if (key === 'facility' && facilities) {
          if (journeyTemplates) {
            let assetsSkukus = [] as Array<string>;
            journeyTemplates.forEach(jt => {
              jt.stepTemplates.forEach(st => {
                if (
                  st.stepTemplate &&
                  st.stepTemplate.facilityId == filters[key]
                ) {
                  if (st.stepTemplate && st.stepTemplate.inputs) {
                    assetsSkukus = assetsSkukus.concat(st.stepTemplate.inputs);
                  }
                  if (st.stepTemplate && st.stepTemplate.outputs) {
                    assetsSkukus = assetsSkukus.concat(st.stepTemplate.outputs);
                  }
                }
              });
            });
            newFilteredRows = newFilteredRows.filter(row =>
              assetsSkukus.includes(row[4])
            );
          }
        }

        if (key === 'journey' && journeyTemplates) {
          const selectedJourney = journeyTemplates.find(
            j => j._id === filters[key]
          );
          if (selectedJourney && selectedJourney.stepTemplates) {
            let assetsSkukus = [] as Array<string>;
            selectedJourney.stepTemplates.forEach(st => {
              if (st.stepTemplate && st.stepTemplate.inputs) {
                assetsSkukus = assetsSkukus.concat(st.stepTemplate.inputs);
              }
              if (st.stepTemplate && st.stepTemplate.outputs) {
                assetsSkukus = assetsSkukus.concat(st.stepTemplate.outputs);
              }
            });
            newFilteredRows = newFilteredRows.filter(row =>
              assetsSkukus.includes(row[4])
            );
          }
        }
      }
    });

    if (textFilter && textFilter !== '') {
      newFilteredRows = newFilteredRows.filter(
        row =>
          row
            .join(' ')
            .toUpperCase()
            .search(textFilter.toUpperCase()) !== -1
      );
    }

    setFilteredRows(newFilteredRows);
  };

  useEffect(() => applyFilters(), [filters, textFilter, rows]);

  return (
    <>
      <Container>
        {showHeader && (
          <HeaderFilter
            title={i18n.t('Existing Assets')}
            titleIcon={<BarcodeIcon />}
            searchPlaceholder={i18n.t('Search all columns')}
            actionButtonText={`+ ${i18n.t('New Asset')}`}
            setFilters={setFilters}
            inputHandler={inputHandler}
            actionButtonHandler={() => history.push('/assets/draft')}
            selects={selects}
            useDropdown={true}
            resetFiltersHandler={handleResetFilters}
            name="assets"
            actionButton={!!isOrgAdmin()}
          />
        )}
        <Table
          headers={headers}
          rows={filteredRows}
          pagination
          rowsPerPage={20}
          pagesBtw={2}
          ignoreKeys={[3, 4]}
          editable={!!isOrgAdmin()}
          handleEdit={data => {
            editCallback
              ? editCallback(data[0], data[4])
              : data[8]
              ? history.push(`/assets/${data[0]}/draft`)
              : history.push(`/assets/${data[4]}/edit`);
          }}
          archivable={!!isOrgAdmin()}
          archiveTitle={i18n.t('Archive Asset?')}
          archiveContent={i18n.t(
            'Are you sure you want to archive this asset? It will no longer be included in future journeys.'
          )}
          handleArchive={data => deleteAssetTemplateAction(data[4], !!data[8])}
        />
      </Container>
      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(AssetsList) as any) as any
) as any;
