import styled from 'styled-components';

const IconContainer = styled.div`
  background: #000000;
  border-radius: 4px 12px 8px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 32px 32px 32px;
  padding-bottom: 60px;

  .switch-wrapper {
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);
  }

  .label-spacing {
    label {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      > div {
        margin-left: 6px;
      }
    }

    @media (max-width: 991px) {
      flex-direction: column;
    }
  }

  .reset-link {
    cursor: pointer;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #a7bf2e;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
    text-align: right;
    justify-content: flex-end;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const UserFormContainer = styled.form`
  flex: 1;
  margin-right: 10px;
  .row {
    margin: 33px 0 0 0;
  }
`;

const UserListContainer = styled.div`
  flex: 0.4;
  .row {
    margin: 33px 0 0 0;
  }
`;

const FormSubHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 32px;
  margin: 0 -32px;
  background: #303030;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  label {
    color: #ffffff;
    > span {
      padding: 0 10px 0 0;
    }
  }
`;

const ReviewSubmitContainer = styled.div`
  padding: 40px 50px;
`;

const ReviewSubmitItemsWrapper = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(117, 117, 117, 0.25);
`;

const ReviewSubmitItemsContainer = styled.div`
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  &.quote-item > div:first-child {
    flex: 2;
  }
  &.contact-item {
    > div:nth-child(1),
    > div:nth-child(2),
    > div:nth-child(4) {
      flex: 2;
    }
    > div:nth-child(3) {
      flex: 1;
    }
  }

  .address-display {
    display: flex;
    flex-direction: column;
  }
`;

const SwitchLabel = styled.div<{ selected?: boolean }>`
  ${props => `
    ${props.selected ? 'color: #303030;' : 'color: rgba(117, 117, 117, 0.3);'}
  `}
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  display: inline;
`;

export {
  IconContainer,
  ActionButtonsContainer,
  UserContainer,
  UserFormContainer,
  UserListContainer,
  FormSubHeader,
  ReviewSubmitContainer,
  ReviewSubmitItemsWrapper,
  ReviewSubmitItemsContainer,
  SwitchLabel,
};
