import * as authService from '../../Services/Api/authService';
import * as userService from '../../Services/Api/userService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { deleteToken, deleteCurrentUser } from '../../Lib/Utils/auth';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { EUserActionTypes } from './userActionsTypes';
import {
  SaveUserActionType,
  GetUserActionType,
} from '../../Services/Api/userService/types';

const loginUser = createAction(EUserActionTypes.LOGIN_USER);
const loginUserSuccess = createAction(
  EUserActionTypes.LOGIN_USER_SUCCESS,
  (user: any) => ({
    user,
  })
);
const loginUserError = createAction(
  EUserActionTypes.LOGIN_USER_ERROR,
  (message: string) => ({
    message,
  })
);

const getUsers = createAction(EUserActionTypes.GET_USERS);
const getUsersSuccess = createAction(
  EUserActionTypes.GET_USERS_SUCCESS,
  (users: any) => ({ users })
);
const getUsersError = createAction(
  EUserActionTypes.GET_USERS_ERROR,
  (message: string) => ({ message })
);

const getUser = createAction(EUserActionTypes.GET_USER);
const getUserSuccess = createAction(
  EUserActionTypes.GET_USER_SUCCESS,
  (user: any) => ({ user })
);
const getUserError = createAction(
  EUserActionTypes.GET_USER_ERROR,
  (message: string) => ({ message })
);

const deleteUser = createAction(EUserActionTypes.DELETE_USER);
const deleteUserSuccess = createAction(
  EUserActionTypes.DELETE_USER_SUCCESS,
  (user: any) => ({ user })
);
const deleteUserError = createAction(
  EUserActionTypes.DELETE_USER_ERROR,
  (message: string) => ({ message })
);

const saveUser = createAction(EUserActionTypes.SAVE_USER);
const saveUserSuccess = createAction(
  EUserActionTypes.SAVE_USER_SUCCESS,
  (
    user: any,
    isDraft?: boolean,
    submitted?: boolean,
    notifySuccess?: boolean
  ) => ({
    user,
    isDraft,
    submitted,
    notifySuccess,
  })
);
const saveUserError = createAction(
  EUserActionTypes.SAVE_USER_ERROR,
  (message: string) => ({ message })
);

const getOrganizations = createAction(EUserActionTypes.GET_ORGANIZATIONS);
const getOrganizationsSuccess = createAction(
  EUserActionTypes.GET_ORGANIZATIONS_SUCCESS,
  (organizations: any) => ({ organizations })
);
const getOrganizationsError = createAction(
  EUserActionTypes.GET_ORGANIZATIONS_ERROR,
  (message: string) => ({ message })
);

export const resetState = createAction(EUserActionTypes.RESET_STATE);

export const doLogin = (
  email: string,
  password: string
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(loginUser());
  return await tap(
    authService.login(email, password),
    async response => {
      const { decoded } = response;
      dispatch(loginUserSuccess(decoded));
    },
    error => {
      const _message =
        'The username or password was incorrect, please try again.';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(loginUserError(message));
    }
  );
};

export const doLogout = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async dispatch => {
  deleteToken();
  deleteCurrentUser();
  dispatch(resetState());
};

export const doGetUsers = (
  includeAll?: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getUsers());
  return await tap(
    userService.getUsers(includeAll),
    users => {
      dispatch(getUsersSuccess(users));
    },
    error => {
      const _message = 'Failed to retrieve Users. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getUsersError(message));
    }
  );
};

export const doSaveUser = (
  payload: any,
  actionType: SaveUserActionType,
  notifySuccess?: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(saveUser());
  return await tap(
    userService.saveUser(payload, actionType),
    response => {
      let data = response.data ? response.data.data : {};
      const isDraft = !!actionType.match('draft');
      if (isDraft) {
        data = { ...data.draft, _id: data._id }; // The Draft has its own _id different from the user _id
      }
      dispatch(
        saveUserSuccess(
          data,
          isDraft,
          !!actionType.match('submit'),
          notifySuccess
        )
      );
    },
    error => {
      let _message = 'Failed to save User. Please try again later';
      if (error.args === 409) {
        _message =
          'The user email you are trying to add already exists. You must use another email.';
      }

      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(saveUserError(message));
    }
  );
};

export const doGetUser = (
  userId: string,
  actionType: GetUserActionType
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getUser());
  return await tap(
    userService.getUser(userId, actionType),
    response => {
      let data;
      if (actionType === 'get-discover') {
        data = response.data ? response.data.data : {};
      } else {
        data = response.data;
      }

      const isDraft = !!actionType.match('draft');
      if (isDraft) {
        data = { ...data.draft, _id: data._id }; // The Draft has its own _id different from the user _id
      }
      dispatch(getUserSuccess(data));
    },
    error => {
      const _message = 'Failed to retrieve User. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getUserError(message));
    }
  );
};

export const doDeleteUser = (
  userId: string
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(deleteUser());
  return await tap(
    userService.deleteUser(userId),
    response => {
      const data = response.data ? response.data.data : {};
      dispatch(deleteUserSuccess(data));
    },
    error => {
      const _message = 'Failed to delete User. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(deleteUserError(message));
    }
  );
};

export const doGetOrganizations = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async dispatch => {
  dispatch(getOrganizations());
  return await tap(
    userService.getOrganizations(),
    response => {
      const organizations = response.data ? response.data.keycloak : [];
      dispatch(getOrganizationsSuccess(organizations));
    },
    error => {
      const _message =
        'Failed to retrieve organizations. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getOrganizationsError(message));
    }
  );
};
