import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';

import { User } from '../../../Lib/Utils/Validations';
import {
  UserContainer as Container,
  UserFormContainer as FormContainer,
  UserListContainer as ListContainer,
  FormSubHeader,
  UserFormContainer,
  SwitchLabel,
} from './styles';

import {
  Label,
  FormInput,
  InfoIcon,
  CustomTooltip,
  Dropdown,
  TransferList,
  Switch,
} from '../../../Components';

import {
  FacilityPermissionTypes,
  OrganizationPermissionTypes,
} from '../../../Lib/Configs';
import { Checkbox } from '../../../Components/index';
import { isOrgAdmin, isSuperAdmin } from '../../../Lib/Utils/auth';
import { doSaveAssets } from '../../../Redux/Asset/assetActions';

interface Props {
  onChange: Function;
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  formErrors?: any;
  confirmEmail?: string;
  userPermission?: string;
  orgPermission?: string;
  superAdmin?: boolean;
  validate?: boolean;
  userDetailsFirstLoad?: boolean;
  userFacilities: Array<string>;
  facilities?: any;
  isDraft?: boolean;
}

const FacilityUsers = ({
  onChange,
  formErrors,
  facilities,
  isDraft,
  ...data
}: Props) => {
  const {
    userDetailsFirstLoad = true,
    id,
    firstName,
    password,
    lastName,
    email,
    confirmEmail,
    userFacilities,
    userPermission,
    orgPermission,
    superAdmin = false,
    validate,
  } = data;

  const [defaultFacilities, setDefaultFacilities] = useState([] as any);

  const {
    errors,
    control,
    register,
    setError,
    setValue,
    clearError,
    getValues,
    triggerValidation,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    userDetailsFirstLoad === false && triggerValidation();
    onChange('userDetailsFirstLoad', false);
  }, []);

  useEffect(() => {
    setValue([{ firstName }, { lastName }, { email }, { confirmEmail }]);
  }, [firstName, lastName, email, confirmEmail]);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      triggerValidation();
    }
  }, [formErrors, validate]);

  useEffect(() => {
    if (facilities) {
      const mapped = facilities?.map(f => ({
        text: f.registeredName,
        selected: !!userFacilities.find(uf => uf === f._id),
        data: f._id,
      }));
      setDefaultFacilities(mapped);
    }
  }, [facilities, userFacilities]);

  useEffect(() => {
    setValue('userFacilities', userFacilities);
    const facilitiesError = !userFacilities || userFacilities.length === 0;

    if (facilitiesError) {
      setError(
        'userFacilities',
        'required',
        i18n.t('You must select at least one facility')
      );
    } else {
      errors.userFacilities && clearError('userFacilities');
    }
  }, [userFacilities]);

  return (
    <Container>
      <FormSubHeader>
        <div className="mr-2">
          {i18n.t('Add a user who will enter supply chain data')}
        </div>
        <div data-tip data-for="addusers">
          <InfoIcon />
        </div>
        <CustomTooltip id="addusers" placement="right">
          <span>
            {i18n.t(
              'This information is internal and will not be seen by end consumers'
            )}
          </span>
        </CustomTooltip>
      </FormSubHeader>
      <div>
        <FormContainer id="UserForm">
          <div className="row label-spacing">
            <div className="col-5">
              <Label>{i18n.t('First name')}</Label>
              <Controller
                as={
                  <FormInput
                    required
                    placeholder={i18n.t('Enter the first name')}
                    maxLength={50}
                  />
                }
                name="firstName"
                value={firstName}
                control={control}
                onChange={([evt]) => {
                  onChange(evt.target.name, evt.target.value);
                  return evt.target.value;
                }}
                rules={User.name}
                defaultValue={firstName}
              />
              {errors.firstName && (
                <span className="inlineErrorMessage">
                  {errors.firstName.message}.
                </span>
              )}
            </div>

            <div className="col-5">
              <Label>{i18n.t('Last name')}</Label>
              <Controller
                as={
                  <FormInput
                    required
                    placeholder={i18n.t('Enter the last name')}
                    maxLength={50}
                  />
                }
                name="lastName"
                value={lastName}
                control={control}
                onChange={([evt]) => {
                  onChange(evt.target.name, evt.target.value);
                  return evt.target.value;
                }}
                rules={User.name}
                defaultValue={lastName}
              />
              {errors.lastName && (
                <span className="inlineErrorMessage">
                  {errors.lastName.message}.
                </span>
              )}
            </div>
          </div>

          <div className="row label-spacing">
            <div className="col-6">
              <Label>
                {i18n.t('Email')}
                <div data-tip data-for="email">
                  <InfoIcon />
                </div>
                <CustomTooltip id="email" placement="right">
                  <span>
                    {i18n.t(
                      'This email will also act as the user login to the SUKU Field App.'
                    )}
                  </span>
                </CustomTooltip>
              </Label>
              <div data-tip data-for="useredit">
                <Controller
                  as={
                    <FormInput
                      required
                      value={email}
                      placeholder={i18n.t('Enter email address')}
                      maxLength={75}
                      disabled={!!id}
                    />
                  }
                  name="email"
                  value={email}
                  control={control}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.value);
                    return evt.target.value;
                  }}
                  rules={User.email}
                  defaultValue={email}
                />
              </div>
              {errors.email && (
                <span className="inlineErrorMessage">
                  {errors.email.message}.
                </span>
              )}
            </div>
            {!!id && (
              <CustomTooltip id="useredit" placement="right">
                <span>
                  {i18n.t('Cannot update username/email once user is created.')}
                </span>
              </CustomTooltip>
            )}

            {!id && (
              <div className="col-6">
                <Label>{i18n.t('Confirm email')}</Label>
                <Controller
                  as={
                    <FormInput
                      required
                      value={confirmEmail}
                      placeholder={i18n.t('Confirm email address')}
                      maxLength={75}
                    />
                  }
                  name="confirmEmail"
                  value={confirmEmail}
                  control={control}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.value);
                    return evt.target.value;
                  }}
                  rules={{
                    required: {
                      value: true,
                      message: i18n.t('This field is required'),
                    },
                    validate: {
                      matchesEmail: value => {
                        const { email } = getValues();
                        return email !== value
                          ? i18n.t('Emails do not match').toString()
                          : undefined;
                      },
                    },
                  }}
                  defaultValue={confirmEmail}
                />
                {errors.confirmEmail && (
                  <span className="inlineErrorMessage">
                    {errors.confirmEmail.message}.
                  </span>
                )}
              </div>
            )}
          </div>

          {isDraft && (
            <div className="row label-spacing">
              <div className="col-9">
                <Label>
                  {i18n.t('Default password')}
                  <div data-tip data-for="defaultpassword">
                    <InfoIcon />
                  </div>
                  <CustomTooltip id="defaultpassword" placement="right">
                    <span>
                      {i18n.t(
                        'User will be asked to change the password when they log in for the first time into the SUKU Field App.'
                      )}
                    </span>
                  </CustomTooltip>
                </Label>
                <Controller
                  as={
                    <FormInput
                      required
                      type="password"
                      value={password}
                      placeholder={i18n.t('Enter password')}
                      maxLength={75}
                    />
                  }
                  name="password"
                  value={password}
                  control={control}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.value);
                    return evt.target.value;
                  }}
                  rules={User.password}
                  defaultValue={password}
                />
                {errors.password && (
                  <span className="inlineErrorMessage">
                    {errors.password.message}.
                  </span>
                )}
              </div>
            </div>
          )}
          <div className="row label-spacing">
            {isSuperAdmin() && (
              <div className="col-lg-6 col-sm-12">
                <Label>
                  {i18n.t('Make super admin user')}
                  <div data-tip data-for="super-admin">
                    <InfoIcon />
                  </div>
                  <CustomTooltip id="super-admin" placement="right">
                    <span>
                      {i18n.t(
                        'Super Admins can create organizations and access/edit all models in the system.'
                      )}
                    </span>
                  </CustomTooltip>
                </Label>

                <div className="switch-wrapper">
                  <SwitchLabel selected={!superAdmin}>
                    {i18n.t('No')}
                  </SwitchLabel>
                  <Switch
                    alwaysOn
                    onChange={evt =>
                      onChange(evt.target.name, evt.target.checked)
                    }
                    checked={superAdmin}
                    name="superAdmin"
                  />
                  <SwitchLabel selected={superAdmin}>
                    {i18n.t('Yes')}
                  </SwitchLabel>
                </div>
              </div>
            )}
            {isOrgAdmin() && !superAdmin && (
              <div className="col-lg-6 col-sm-12">
                <Label>
                  {i18n.t(
                    'Can this user edit/create/delete supply chain models (see "i" for more details)?'
                  )}
                  <div data-tip data-for="orgPermission">
                    <InfoIcon />
                  </div>
                  <CustomTooltip id="orgPermission" placement="right">
                    <p>
                      {i18n.t(
                        'Selecting Yes will make the user an Organization Admin.'
                      )}
                      <br />
                      <br />
                      {i18n.t(
                        'This will allow the user to read/create/edit/delete all journeys, lots, facilities, assets, claims, attestors, users, and campaigns.'
                      )}
                      <br />
                      <br />
                      {i18n.t(
                        'Additionally, this user will be able to capture data for any facility/step in a lot. This role is usually reserved for an oversight or management user.'
                      )}
                    </p>
                  </CustomTooltip>
                </Label>
                <div
                  className="switch-wrapper"
                  data-tip
                  data-for="isParallelSwitch"
                >
                  <SwitchLabel selected={orgPermission !== 'admin'}>
                    {i18n.t('No')}
                  </SwitchLabel>
                  <Switch
                    alwaysOn
                    onChange={evt =>
                      onChange(
                        'orgPermission',
                        evt.target.checked ? 'admin' : 'user'
                      )
                    }
                    checked={orgPermission === 'admin'}
                    name="orgPermission"
                  />
                  <SwitchLabel selected={orgPermission === 'admin'}>
                    {i18n.t('Yes')}
                  </SwitchLabel>
                </div>
              </div>
            )}
          </div>
          {!superAdmin && orgPermission !== 'admin' && (
            <div>
              <div className="row label-spacing">
                <div className="col-lg-6 col-sm-12">
                  <Label>
                    {i18n.t('What facility permissions does this user have?')}
                    <div data-tip data-for="permissions">
                      <InfoIcon />
                    </div>
                    <CustomTooltip id="permissions" placement="right">
                      <p>
                        <strong>{i18n.t('Facility Manager')}: </strong>
                        {i18n.t(
                          'User can create new lots and capture facility data in a journey lot for this facility he/she is mapped to.'
                        )}
                        <br />
                        <br />
                        <strong>{i18n.t('Facility User')}: </strong>
                        {i18n.t(
                          'User can capture facility data in a journey lot for the facility he/she is mapped to.'
                        )}
                      </p>
                    </CustomTooltip>
                  </Label>
                  <Dropdown
                    required
                    options={FacilityPermissionTypes}
                    defaultOption={userPermission}
                    placeholder={i18n.t('Select')}
                    action={value => onChange('userPermission', value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-3">
                  <Label>
                    {i18n.t('Map the user to one or more facility')}
                  </Label>
                  <div>
                    <TransferList
                      fromPlaceholder={i18n.t('Search')}
                      toPlaceholder={i18n.t('Type to filter')}
                      items={defaultFacilities}
                      handleChange={items => {
                        onChange(
                          'userFacilities',
                          items.filter(i => i.selected).map(i => i.data)
                        );
                      }}
                    />
                    {errors.userFacilities && (
                      <span className="inlineErrorMessage pull-right mr-3 mt-3">
                        {errors.userFacilities.message}.
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </FormContainer>
      </div>
    </Container>
  );
};

export default FacilityUsers;
