import { authAxiosCall } from '../axiosCall';
import { saveUser } from '../userService/index';
import * as brandService from '../brandService';
import * as marketplaceService from '../marketplaceService';

export const getOrganizations = async (includeArchived = false) => {
  const response = await authAxiosCall(`/organizations`, {
    method: 'GET',
  });
  let organizations = response && response.data ? response.data.data : [];
  organizations = !includeArchived
    ? organizations.filter(f => !f.archived)
    : organizations;
  return organizations;
};

export const getOrganization = async (organizationId: string) =>
  await authAxiosCall(`/organizations/${organizationId}`, {
    method: 'GET',
  });

export const getOrganizationUsers = async (organizationId: string) =>
  await authAxiosCall(`/organizations/${organizationId}/users`, {
    method: 'GET',
  });

export function addUserToOrganization(
  userId: string,
  organizationId: string,
  permission: string
) {
  return authAxiosCall(`/organizations/${organizationId}/add-user`, {
    method: 'POST',
    body: { userId, permission },
  });
}

const removeUserFromOrganization = async (userId, organizationId) => {
  const accessEntry = {
    resourceId: organizationId,
    type: 'organization',
  };
  return authAxiosCall(`/users/${userId}/access-control`, {
    method: 'DELETE',
    body: accessEntry,
  });
};

const updateOrganizationUsers = async (newUsers, currentUsers, orgId) => {
  const usersToRemove = [...currentUsers].filter(
    pu => !newUsers.find(uu => pu._id === uu._id)
  );

  await Promise.all(
    usersToRemove.map(async ({ _id: userId }) => {
      removeUserFromOrganization(userId, orgId);
    })
  );

  const createdUsers = await Promise.all(
    newUsers.map(async user => {
      let userResponse;
      const toSaveUser = {
        id: user._id,
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: user.password,
        groups: [],
      };

      if (!toSaveUser._id)
        userResponse = await saveUser(
          toSaveUser,
          'submit-create-entity',
          false
        );
      else userResponse = await saveUser(toSaveUser, 'submit-save-entity');

      return addUserToOrganization(
        userResponse?.data?.data?._id,
        orgId,
        user.permission
      );
    })
  );
};

const updateOrganizationBrands = async (newBrands, currentBrands, orgId) => {
  const brandsToRemove = [...currentBrands].filter(
    brand => !newBrands.find(uu => brand._id === uu._id)
  );

  const brandsToAdd = [...newBrands].filter(
    brand =>
      !currentBrands.find(current => brand._id === current._id) &&
      (!brand.updated || !brand._id)
  );

  const brandsToUpdate = [...newBrands].filter(
    ({ updated, _id }) => updated && _id
  );

  await Promise.all(
    brandsToRemove.map(async ({ _id: brandId }) =>
      brandService.deleteBrand(brandId)
    )
  );

  await Promise.all(
    brandsToUpdate.map(
      async brand =>
        await brandService.saveBrand(
          {
            ...brand,
            organizationId: orgId,
          },
          'submit-save-entity'
        )
    )
  );

  const createBrands = await Promise.all(
    brandsToAdd.map(
      async brand =>
        await brandService.saveBrand(
          {
            ...brand,
            organizationId: orgId,
          },
          'submit-create-entity'
        )
    )
  );

  return createBrands;
};

const updateOrganizationMarketplaces = async (
  newMarketplaces,
  currentMarketplaces,
  orgId
) => {
  const marketplacesToRemove = [...newMarketplaces].filter(
    marketplace => marketplace.hidden && marketplace._id
  );

  const marketplacesToAdd = [...newMarketplaces]
    .filter(marketplace => marketplace.updated && !marketplace._id)
    .map(marketplace => {
      const { organizations } = marketplace;
      if (marketplace.selected) {
        organizations.push(orgId);
      }
      return {
        ...marketplace,
        organizations,
      };
    });

  const marketplacesToUpdate = [...newMarketplaces]
    .filter(
      marketplace =>
        (marketplace.updated && marketplace._id) ||
        (!currentMarketplaces.map(({ _id }) => _id).includes(marketplace._id) &&
          marketplace.selected) ||
        (currentMarketplaces.map(({ _id }) => _id).includes(marketplace._id) &&
          !marketplace.selected)
    )
    .map(marketplace => {
      const { organizations } = marketplace;
      if (marketplace.selected) {
        if (organizations.indexOf(orgId) === -1) organizations.push(orgId);
      } else {
        const idxToRemove = organizations.indexOf(orgId);
        organizations.splice(idxToRemove, 1);
      }
      return {
        ...marketplace,
        organizations,
      };
    });

  await Promise.all(
    marketplacesToRemove.map(async ({ _id: marketplaceId }) =>
      marketplaceService.deleteMarketplace(marketplaceId)
    )
  );

  await Promise.all(
    marketplacesToUpdate.map(
      async marketplace =>
        await marketplaceService.saveMarketplace(
          {
            ...marketplace,
          },
          'submit-save-entity'
        )
    )
  );

  const createMarketplaces = await Promise.all(
    marketplacesToAdd.map(
      async marketplace =>
        await marketplaceService.saveMarketplace(
          {
            ...marketplace,
          },
          'submit-create-entity'
        )
    )
  );

  return createMarketplaces;
};

export const saveOrganization = async organization => {
  let orgResponse;
  const {
    users,
    organizationUsers,
    brands,
    organizationBrands,
    marketplaces,
    organizationMarketplaces,
  } = organization;
  if (organization._id) {
    orgResponse = await authAxiosCall(`/organizations/${organization._id}`, {
      method: 'PATCH',
      body: organization,
    });
  } else {
    orgResponse = await authAxiosCall(`/organizations`, {
      method: 'POST',
      body: organization,
    });
  }

  const { data } = orgResponse.data;
  const orgId = data._id;
  if (users?.length) {
    const newUsers = await updateOrganizationUsers(
      users,
      organizationUsers,
      orgId
    );
  }

  const newBrands = await updateOrganizationBrands(
    brands || [],
    organizationBrands,
    orgId
  );

  const newMarketplaces = await updateOrganizationMarketplaces(
    marketplaces || [],
    organizationMarketplaces,
    orgId
  );

  return orgResponse;
};

export const deleteOrganization = async id => {
  return authAxiosCall(`/organizations/${id}`, {
    method: 'DELETE',
  });
};
