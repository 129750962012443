import React, { useState } from 'react';
import { ViewItemContainer as ItemContainer } from './styles';
import {
  FormInput,
  Label,
  Checkbox,
  RadioGroup,
  Dropdown,
  DatePicker,
} from '../index';

interface Props {
  index: number;
  name: string;
  fieldName: string;
  fieldType: string;
  fieldPlaceholder?: string;
  value?: any;
  maxChars?: number;
  isMandatory?: boolean;
  options?: Array<string>;
  placeholder?: string;
  defaultOpen?: number | null;
  onChange?: any;
  disabled?: boolean;
  fixed?: boolean;
}

const ViewItem = ({
  index,
  name,
  fieldName,
  fieldType,
  fieldPlaceholder,
  maxChars,
  isMandatory,
  options,
  value,
  onChange = () => {},
  disabled = false,
  fixed = false,
}: Props) => {
  return (
    <ItemContainer className="view-item-container">
      {
        {
          checkBoxes: (
            <>
              {options?.map((option, index) => {
                return (
                  <Label
                    key={`${option}-${index}`}
                    className="mb-0 checkbox-label"
                  >
                    <Checkbox
                      name={option}
                      onChange={evt =>
                        onChange(name, {
                          ...value,
                          [option]: evt.target.checked,
                        })
                      }
                      checked={value ? !!value[option] : false}
                      disabled={disabled}
                    />
                    {option}
                  </Label>
                );
              })}
            </>
          ),
          textArea: (
            <FormInput
              type="textarea"
              placeholder={fieldPlaceholder}
              maxLength={maxChars}
              required={isMandatory}
              name={name}
              onChange={evt => onChange(name, evt.target.value)}
              value={value}
              disabled={disabled}
            />
          ),
          radioButtons: (
            <RadioGroup
              name={name}
              options={options?.map(o => ({ value: o, label: o })) || []}
              onChange={evt => onChange(name, evt.target.value)}
              disabled={disabled}
              value={value}
            />
          ),
          dropDown: (
            <Dropdown
              options={options?.map(o => ({ value: o, label: o })) || []}
              action={() => {}}
              searchable
              required={isMandatory}
              onChange={value => onChange(name, value)}
              defaultOption={value}
              disabled={disabled}
            />
          ),
          listBox: (
            <Dropdown
              options={options?.map(o => ({ value: o, label: o })) || []}
              action={value => onChange(name, value)}
              searchable
              required={isMandatory}
              onChange={value => onChange(name, value)}
              defaultOption={value}
              disabled={disabled}
              multi={true}
            />
          ),
          string: (
            <FormInput
              type="text"
              placeholder={fieldPlaceholder}
              maxLength={maxChars}
              required={isMandatory}
              name={name}
              onChange={evt => onChange(name, evt.target.value)}
              value={value}
              disabled={disabled}
            />
          ),
          number: (
            <FormInput
              type="number"
              placeholder={fieldPlaceholder}
              maxLength={maxChars}
              required={isMandatory}
              name={name}
              onChange={evt => onChange(name, evt.target.value)}
              value={value}
              disabled={disabled}
            />
          ),
          date: (
            <DatePicker
              placeholder={fieldPlaceholder || 'Select'}
              required={isMandatory}
              onChange={value => onChange(name, value)}
              value={value}
              disabled={disabled}
              fixed={fixed}
            />
          ),
        }[fieldType]
      }
    </ItemContainer>
  );
};

export default ViewItem;
