import { config } from 'dotenv';

config({ silent: true });
export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_PREFIX = process.env.REACT_APP_API_URL_PREFIX || '';
export const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
export const KEYCLOAK_REALM =
  process.env.REACT_APP_KEYCLOAK_REALM || 'suku-dev';
export const PRESIGNED_URL = process.env.REACT_APP_PRESIGNED_URL;
export const I18N_DEBUG = process.env.REACT_APP_I18N_DEBUG;
export const REACT_APP_BLOCKCHAIN_PROOF_BASE_URL =
  process.env.REACT_APP_BLOCKCHAIN_PROOF_BASE_URL || 'https://hedera.suku.app';
export const BUCKET_URL =
  process.env.REACT_APP_BUCKET_URL ||
  'https://suku-scanner-app.s3.amazonaws.com/ https://suku-s3-api-bucket.s3.amazonaws.com/';
