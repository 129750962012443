import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import {
  FormInput,
  Label,
  QrPdf,
  DynamicTable,
  Checkbox,
  InfoIcon,
  CustomTooltip,
  CopyIcon,
  Button,
  EditableItem,
} from '../../../../Components';

import {
  Container,
  Content,
  AddItemContainer,
  AddItemIcon,
  QRHeadContainer,
} from './styles';
import { MdAdd } from 'react-icons/md';
import { Controller, useForm } from 'react-hook-form';
import DynamicFields from './DynamicFields';
import { generateNewRow } from './helper';
import { saveTableConfig, getTableConfig } from '../../../../Lib/Utils/storage';
import { capitalizeString } from '../../../../Lib/Utils/functions';

type Props = {
  data: any;
  onChange?: any;
  message?: string;
  lotId?: string;
  assets?: Array<any>;
  assetsTemplate: Array<any>;
  userFacility?: boolean;
  locked?: boolean;
  type: 'inbound' | 'outbound';
  havePreviousOutputs?: boolean;
  inbounds?: { tableFields?: any };
};

const Asset = ({
  data,
  onChange,
  assets,
  assetsTemplate = [],
  message = '',
  userFacility,
  locked = false,
  lotId,
  type,
  havePreviousOutputs = false,
  inbounds = {},
}: Props) => {
  const editLocked = !userFacility || locked;

  const { tableFields, deletedRows = [] } = data;

  const [fields, setFields] = useState({}) as any;
  const [addAmount, setAddAmount] = useState(1);
  const [tableFieldValue, setTableFieldValue] = useState([]) as any;
  const [verticalTable, setVerticalTable] = useState({} as any);

  const { errors, control, setValue } = useForm({
    mode: 'onBlur',
  });

  const handleChange = (name, value) => {
    if (type === 'inbound') {
      onChange('inboundData', { [name]: value });
    } else {
      onChange('outboundData', { [name]: value });
    }
  };

  useEffect(() => {
    saveTableConfig(verticalTable);
  }, [verticalTable]);

  useEffect(() => {
    if (
      assetsTemplate.length > 0 &&
      tableFields &&
      Object.entries(tableFields).length > 0
    ) {
      if (JSON.stringify(fields) !== JSON.stringify(tableFields)) {
        setFields(tableFields);
      }
    }
  }, [tableFields, assetsTemplate]);

  useEffect(() => {
    if (
      assetsTemplate.length > 0 &&
      assets &&
      Object.entries(data.tableFields).length === 0
    ) {
      if (
        type === 'outbound' &&
        Object.entries(tableFields || {}).length === 0 &&
        Object.entries(fields).length !== 0
      ) {
        setFields({});
      } else if (
        assets.length === 0 &&
        Object.entries(fields).length !== 0 &&
        Object.entries(tableFields || {}).length !== 0 &&
        !havePreviousOutputs
      ) {
        setFields({});
      } else if (Object.entries(fields).length === 0) {
        if (assets.length > 0) {
          let newFieldsStatus = {};
          const newFields = [] as any;
          assets.forEach((asset, idx) => {
            const { templateDetails } = asset;
            const { 0: assetTemplate } = templateDetails;
            const newRow = generateNewRow(
              idx,
              asset.attributes,
              assetTemplate,
              asset._id,
              asset.subDocumentId,
              editLocked,
              tableFieldValue,
              setTableFieldValue
            );
            if (newFields[assetTemplate._id]) {
              newFields[assetTemplate._id].push(newRow);
            } else {
              newFields[assetTemplate._id] = [newRow];
            }
            newFieldsStatus = {
              ...newFieldsStatus,
              ...newFields,
            };
          });

          setFields({ ...fields, ...newFieldsStatus });

          const verticalTableInit = getTableConfig();
          assets.forEach(asset => {
            const { templateDetails } = asset;
            const { 0: assetTemplate } = templateDetails;
            if (verticalTableInit[assetTemplate._id] === undefined) {
              verticalTableInit[assetTemplate._id] = false;
            }
          });
          setVerticalTable(verticalTableInit);
        }
      }
    }
  }, [assets, tableFields, assetsTemplate]);

  const handleTableInputChange = (name, value, index, template) => {
    const newTableFields = [...fields[template._id]].map(fieldsSet => {
      const rowNumber = fieldsSet[0].rowNumber;
      if (rowNumber === index) {
        return fieldsSet.map(f => {
          if (f.header === name) {
            return {
              ...f,
              value: value,
            };
          } else {
            return f;
          }
        });
      } else {
        return fieldsSet;
      }
    });

    const newFields = {
      ...fields,
      [template._id]: newTableFields,
    };
    setFields(newFields);
    handleChange('tableFields', newFields);
  };

  const handleAddRows = template => {
    const newFields = fields[template._id]
      ? { ...fields }
      : { ...fields, [template._id]: [] };
    for (let i = 0; i < addAmount; i++) {
      let maxRowNumber =
        Math.max(...newFields[template._id].flat().map(e => e.rowNumber)) || 0;
      maxRowNumber = isFinite(maxRowNumber) ? maxRowNumber : 0;

      newFields[template._id].push(
        generateNewRow(
          maxRowNumber + 1 + i,
          null,
          template,
          null,
          null,
          editLocked,
          tableFieldValue,
          setTableFieldValue
        )
      );
    }
    setFields(newFields);
  };

  const handleDeleteRow = (rowId, template) => {
    const newTableFields = [...fields[template._id]].filter(
      field => field[0].rowNumber !== rowId
    );
    const newFields = {
      ...fields,
      [template._id]: newTableFields,
    };
    setFields(newFields);
    handleChange('tableFields', newFields);

    const deletedRow = fields[template._id].find(
      field => field[0].rowNumber === rowId
    )[0];

    if (deletedRow.id) {
      deletedRows.push(deletedRow);
      handleChange('deletedRows', deletedRows);
    }
  };

  const createHeaders = template => {
    if (template.version && template.version[0]) {
      const newHeaders = Object.keys(template.version[0]).map(v => v);
      return newHeaders;
    }
    return [];
  };

  const copyFromInbounds = templateId => {
    if (fields[templateId]) {
      const rowsToDelete = fields[templateId].forEach(rowToDelete => {
        deletedRows.push(rowToDelete[0]);
      });
    }

    const findOnInbounds = inbounds.tableFields[templateId];

    const inboundsWithoutIds = findOnInbounds.map(row =>
      row.map(col => ({ ...col, id: null, fromServer: false }))
    );

    const newFields = {
      ...fields,
      [templateId]: inboundsWithoutIds,
    };

    setFields(newFields);

    onChange('outboundData', {
      tableFields: newFields,
      deletedRows: deletedRows,
    });
  };
  const messageValue = data?.[`${type}Message`] || message;

  return (
    <Container>
      <Content className="px-0">
        {assetsTemplate?.length === 0 ? (
          <div className="row">
            <div className="col-12 mx-4 my-3">
              <Label>
                {i18n.t(
                  `Per journey definition there are no ${type} assets for this facility.`
                )}
              </Label>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 px-5">
              <EditableItem
                editable={false}
                title={i18n.t(`${capitalizeString(type)} message (optional)`)}
                collapsible={true}
                defaultOpen={messageValue !== ''}
                arrowNextToText={true}
                content={
                  <FormInput
                    maxLength={350}
                    type="textarea"
                    placeholder={i18n.t(`Enter an ${type} message`)}
                    name={`${type}Message`}
                    onChange={evt =>
                      handleChange(evt.target.name, evt.target.value)
                    }
                    value={messageValue}
                    disabled={editLocked}
                  />
                }
              />
            </div>
            {assetsTemplate &&
              assetsTemplate.length > 0 &&
              assetsTemplate.map((asset, idx) => {
                const { template: assetTemplate } = asset;
                const headers = createHeaders(assetTemplate);
                const existOnInbounds =
                  type === 'outbound' &&
                  inbounds.tableFields[assetTemplate._id];
                return (
                  <div
                    className={`col-12 ${idx !== 0 && 'mt-5'} p0`}
                    key={assetTemplate._id}
                  >
                    <div className="table-header">
                      <div>
                        {assetTemplate.name}
                        <QRHeadContainer>
                          <QrPdf
                            qr={[
                              {
                                lotId: lotId || '',
                                skuku: assetTemplate.skuku,
                                name: assetTemplate.name,
                              },
                            ]}
                            tooltip={i18n.t(
                              'Generate QR code for this asset only.'
                            )}
                          />
                        </QRHeadContainer>
                        {existOnInbounds && headers.length > 0 && (
                          <>
                            <div
                              className="copy-button"
                              data-tip
                              data-for={`copyTableCheckboxTooltip-${assetTemplate._id}`}
                            >
                              <Button
                                type="text"
                                text={<CopyIcon />}
                                action={() =>
                                  copyFromInbounds(assetTemplate._id)
                                }
                              />
                            </div>
                            <CustomTooltip
                              id={`copyTableCheckboxTooltip-${assetTemplate._id}`}
                            >
                              <span>
                                {i18n.t(
                                  'This outbound asset is the same as the inbound asset. Click to copy the table values from the inbound asset to the outbound asset.'
                                )}
                              </span>
                            </CustomTooltip>{' '}
                          </>
                        )}
                      </div>
                      {headers.length > 0 && (
                        <div>
                          <Label>
                            <Checkbox
                              onChange={evt => {
                                const newVerticalTable = { ...verticalTable };
                                newVerticalTable[assetTemplate._id] =
                                  evt.target.checked;
                                setVerticalTable(newVerticalTable);
                              }}
                              name={`verticalTableCheckbox-${assetTemplate._id}`}
                              checked={verticalTable[assetTemplate._id]}
                            />
                            {i18n.t('Rows')}
                            <div
                              data-tip
                              data-for={`verticalTableCheckboxTooltip-${assetTemplate._id}`}
                            >
                              <InfoIcon />
                            </div>
                            <CustomTooltip
                              id={`verticalTableCheckboxTooltip-${assetTemplate._id}`}
                            >
                              <span>
                                {i18n.t(
                                  'Alternate table view between rows and columns'
                                )}
                              </span>
                            </CustomTooltip>
                          </Label>
                        </div>
                      )}
                    </div>
                    {headers.length > 0 ? (
                      <DynamicTable
                        readonly={editLocked}
                        removable={true}
                        handleDelete={rowId =>
                          handleDeleteRow(rowId, assetTemplate)
                        }
                        headers={headers}
                        data={
                          fields[assetTemplate._id]
                            ? fields[assetTemplate._id].map((field, pIndex) =>
                                field.map((f, cIndex) => (
                                  <DynamicFields
                                    fixed={true}
                                    key={`${pIndex}-${cIndex}`}
                                    {...f}
                                    handleTableInputChange={(
                                      name,
                                      value,
                                      index
                                    ) =>
                                      handleTableInputChange(
                                        name,
                                        value,
                                        index,
                                        assetTemplate
                                      )
                                    }
                                  />
                                ))
                              )
                            : []
                        }
                        vertical={verticalTable[assetTemplate._id]}
                      />
                    ) : (
                      <Label className="pt-2 px-3 font-italic font-weight-light">
                        {i18n.t(
                          'No data fields configured for this asset.  You can configure data fields under Asset > Data.'
                        )}
                      </Label>
                    )}
                    {headers.length > 0 && !editLocked && (
                      <div className="add-rows mt-3">
                        <Controller
                          as={
                            <FormInput
                              required
                              placeholder="Rows to add"
                              subType="number"
                              value={addAmount}
                              min={1}
                            />
                          }
                          name="addAmount"
                          value={addAmount}
                          control={control}
                          onChange={([evt]) => {
                            const value = evt.target.value;
                            if (value > 0) {
                              setAddAmount(value);
                              return value;
                            } else {
                              setAddAmount(addAmount || 1);
                              return addAmount;
                            }
                          }}
                          defaultValue={addAmount}
                        />

                        <AddItemContainer
                          className="ml-2"
                          onClick={() => handleAddRows(asset.template)}
                        >
                          <span>{i18n.t('Add rows')}</span>
                          <AddItemIcon>
                            <MdAdd color="#fff" />
                          </AddItemIcon>
                        </AddItemContainer>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </Content>
    </Container>
  );
};

export default withTranslation()(Asset as any) as any;
