import React from 'react';
import styled from 'styled-components';
import ViewItem from '../../../../Components/DynamicInput/ViewIitem';

const ComponentContainer = styled.div`
  flex: 1;
  padding: 10px 0;
`;

interface Props {
  index: number;
  key: any;
  header: string;
  type: any;
  mandatory: boolean;
  values?: Array<any>;
  attributes?: Array<any>;
  handleTableInputChange: any;
  value?: any;
  tableFieldValue?: any;
  setTableFieldValue?: any;
  rowNumber?: number;
  disabled?: boolean;
  placeholder?: string;
  maxChars?: number;
  fixed?: boolean;
}

const DynamicFields = ({
  index,
  header,
  type,
  mandatory,
  values,
  attributes,
  handleTableInputChange,
  value,
  rowNumber,
  disabled,
  placeholder = header,
  maxChars,
  fixed = false,
}: Props) => {
  return (
    <ComponentContainer key={index}>
      <ViewItem
        index={index}
        name={header}
        fieldName={header}
        fieldPlaceholder={placeholder}
        fieldType={type}
        isMandatory={mandatory}
        options={values && values.map(v => v)}
        onChange={(name, value) =>
          handleTableInputChange(name, value, rowNumber)
        }
        value={(attributes && attributes[header]) || value || ''}
        disabled={disabled}
        maxChars={maxChars}
        fixed={fixed}
      />
    </ComponentContainer>
  );
};

export default DynamicFields;
