import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 28px;

  .checkbox-wrapper {
    margin-left: -11px;
    > label {
      display: flex;
      align-items: center;
    }
  }
`;

const StepsContainer = styled.div`
  margin-top: 30px;
  padding: 0 40px;
  ul {
    border: 0;
  }
`;

const Content = styled.div`
  padding: 40px 30px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #757575;

  label {
    margin-bottom: 12px;
    display: flex;
    > div {
      margin-left: 6px;
    }
  }

  .checkbox-label {
    display: flex;
    align-items: center;
  }

  .view-item-container {
    margin-top: 0;
  }

  .endorsement {
    border-bottom: 1px solid rgba(117, 117, 117, 0.15);
    .endorsement-title {
      display: flex;
      align-items: flex-start;
    }
    a {
      color: #757575;
      font-weight: bolder;
    }
  }

  .table-header {
    background: radial-gradient(
      50% 25628.48% at 50% 0%,
      #3a3a3a 0%,
      #000000 100%
    );
    .copy-button {
      svg {
        width: 22px !important;
        height: 22px !important;
        color: #fff;
        path {
          color: #fff;
          fill: #fff;
        }
      }
    }
    text-transform: uppercase;
    color: #ffffff;
    height: 50px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      flex-direction: row;
      display: flex;
      align-items: center;

      label {
        margin: 0px;
        align-items: center;
        justify-content: center;
        display: flex;
        color: white;
        padding-right: 15px;
        > div {
          align-items: center;
          justify-content: center;
          display: flex;
        }
      }
    }
  }

  .add-rows {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const TableContainer = styled.div`
  margin: 20px 0;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const Subtitle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #303030;
  text-transform: uppercase;
`;

const HeaderInput = styled.div`
  display: flex;
  align-items: center;

  > div {
    flex: 1;
  }
`;

const AddItemContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
  float: right;
  margin-right: 32px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  > span {
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const AddItemIcon = styled.div`
  width: 34px;
  height: 34px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const NoAssets = styled.p`
  font-family: Encode Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #757575;
`;

const EditableContent = styled.div`
  padding-left: 70px;
`;

const LotLabel = styled.label`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #303030;
  span {
    color: #a7bf2e;
    margin-left: 5px;
  }
  margin: 0;
`;

const LotInputContainer = styled.div<{ lotChars: number }>`
  ${({ lotChars }) =>
    `min-width: ${lotChars * 16 < 140 ? 140 : lotChars * 16}px;`}
  flex: 0 !important;
`;

const QRHeadContainer = styled.div`
  flex: 0 !important;
  margin-left: 20px;
`;

const TabBubble = styled.span`
  color: #333;
  position: absolute;
  right: -14px;
  top: 2px;
  font-size: 70%;
  display: flex;
  background-color: #d7f30a;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  opacity: 1 !important;

  .expiredClaim & {
    background-color: rgb(244, 12, 40);
    color: #fff;
  }
`;

const ClaimsTabListContainer = styled.div`
  .claimTabWithBubble {
    margin-right: 25px;
  }
  .react-tabs__tab {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export {
  Container,
  Content,
  StepsContainer,
  TableContainer,
  ActionButtonsContainer,
  Subtitle,
  HeaderInput,
  AddItemContainer,
  AddItemIcon,
  NoAssets,
  EditableContent,
  LotLabel,
  LotInputContainer,
  TabBubble,
  ClaimsTabListContainer,
  QRHeadContainer,
};
