import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import ClaimsList from '../../Claim/List';
import NewClaim from '../../Claim/New';
import {
  JourneyDetailsSubtitle as Subtitle,
  JourneyDetailsContainer as Container,
} from './styles';
import { Label, Loading } from '../../../Components';

interface Props {
  onChange: Function;
  onRefresh: (entityName: string) => void;
  createMode?: boolean;
  claims?: Array<any>;
  editCallback?: Function;
  setCreateMode?: Function;
  refClaims?: Array<string>;
  currentClaimId?: string;
}

const ClaimStep = ({
  onChange,
  onRefresh,
  currentClaimId,
  createMode,
  claims,
  refClaims = [],
  setCreateMode = () => {},
}: Props) => {
  const [claimId, setClaimId] = useState(currentClaimId);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (createMode) {
      setClaimId(undefined);
      setLoading(true);
      setTimeout(() => setLoading(false), 500);
    }
  }, [createMode]);

  const handleSave = (claimId, submit) => {
    if (claimId && !refClaims.includes(claimId)) {
      onChange('refClaims', [...refClaims, claimId], true);
    }
    onRefresh('claim');

    if (submit) {
      setCreateMode(false);
      setEditMode(false);
    }
  };

  return (
    <Container>
      <div className="col-12 mb-5">
        <Label>{i18n.t('Create claims')}</Label>
        <Subtitle className="mb-3">
          {i18n.t('Here you can create')} <b>{i18n.t('new')}</b>{' '}
          {i18n.t(
            'claims and associate them to any facility (e.g., a facility you created in'
          )}{' '}
          <b>{i18n.t('Step')} 2</b> {i18n.t('or an existing facility).')}
        </Subtitle>
        <Subtitle>
          {i18n.t('If you didnt create any facilities in')}{' '}
          <b>{i18n.t('Step')} 2</b>{' '}
          {i18n.t(
            'or if all the claims that you need already in already exist, then you do not need to create any new claims here.'
          )}
        </Subtitle>
      </div>
      {loading ? (
        <Loading top={300} />
      ) : createMode || editMode ? (
        <NewClaim
          showTitle={false}
          onSave={handleSave}
          claimId={claimId}
          useStore={['facilities']}
          draft={
            !claimId || !!claims?.find(c => c._id === claimId && c.isDraft)
          }
        />
      ) : (
        <ClaimsList
          showHeader={false}
          showAttestors={false}
          parentClaims={claims}
          editCallback={claimId => {
            setClaimId(claimId);
            setEditMode(true);
          }}
          useStore={['facilities']}
        />
      )}
    </Container>
  );
};

export default ClaimStep;
