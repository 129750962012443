import { EProductActionTypes } from './productActionsTypes';

const initialState = {
  loading: false,
  product: null,
  error: null,
};

interface State {
  product: any;
  loading: boolean;
  error?: any;
}

interface ProductAction extends State {
  type: EProductActionTypes;
  payload: any;
}

type TProductReducerActions = ProductAction;

export default function(
  state: State = initialState,
  action: TProductReducerActions
) {
  switch (action.type) {
    case EProductActionTypes.GET_PRODUCT:
      return {
        ...state,
        loading: true,
        product: null,
      };
    case EProductActionTypes.GET_PRODUCT_SUCCESS:
      return { ...state, product: action.payload, loading: false };
    case EProductActionTypes.GET_PRODUCT_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        product: null,
      };

    case EProductActionTypes.CLEAN_PRODUCT:
      return {
        ...state,
        loading: false,
        product: null,
        error: null,
      };

    default:
      return state;
  }
}
