import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';

import { Marketplace } from '../../../Lib/Utils/Validations';
import { Container, FormContainer, FormSubHeader } from './styles';

import {
  Label,
  FormInput,
  InfoIcon,
  CustomTooltip,
  Button,
  PopUp,
  ColorPicker,
  Checkbox,
  TransferList,
} from '../../../Components';

export interface Marketplace {
  _id?: string;
  name?: string;
  description?: string;
  themes?: {
    default?: {
      useDefaultColor?: boolean;
      colorPrimary?: string;
      colorPrimaryVariant?: string;
      colorOnPrimary?: string;
      colorSecondary?: string;
      colorSecondaryVariant?: string;
      colorOnSecondary?: string;
    };
  };
  updated?: boolean;
  organizations: Array<string>;
  selected?: boolean;
}

interface Props {
  onChange: any;
  onChangeMultiple: any;
  marketplaces: Array<any>;
  organizationMarketplacesFirstLoad?: boolean;
  organizationMarketplaces: any;
  existingOrganizationMarketplace?: any;
  organizationColor?: string;
}

const OrganizationMarketplace = ({
  onChange: onChangeParent,
  onChangeMultiple,
  marketplaces = [],
  organizationMarketplacesFirstLoad = true,
  organizationMarketplaces,
  existingOrganizationMarketplace,
  organizationColor = '',
}: Props) => {
  const initMarketplace = {
    name: '',
    description: '',
    themes: {},
    updated: false,
    organizations: [],
    selected: false,
  };

  const [items, setItems] = useState<Array<{ title: string; data: any }>>([]);
  const [currentMarketplace, setCurrentMarketplace] = useState<Marketplace>(
    initMarketplace
  );
  const [currentMarketplaceIndex, setCurrentMarketplaceIndex] = useState(-1);
  const [popUpShow, setPopUpShow] = useState(false);
  const [popupNewItems, setPopupNewItems] = useState<any>([]);
  const [showFields, setShowFields] = useState(false);
  const [listOptions, setListOptions] = useState([] as any);
  const [selected, setSelected] = useState(null as any);

  const {
    errors,
    control,
    register,
    setError,
    setValue,
    clearError,
    getValues,
    reset,
    triggerValidation,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    organizationMarketplacesFirstLoad === false && triggerValidation();
    onChangeParent('organizationMarketplacesFirstLoad', false);
  }, []);

  useEffect(() => {
    register({ name: 'organizationMarketplaces' });
  }, [register]);

  useEffect(() => {
    if (existingOrganizationMarketplace) {
      const user =
        marketplaces.find(
          ({ _id }) => _id === existingOrganizationMarketplace
        ) || ({} as any);
      const mapped = {
        ...user,
        emailConfirm: user.email,
      };
      setCurrentMarketplace(mapped);
    } else {
      setCurrentMarketplace(initMarketplace);
    }
  }, [existingOrganizationMarketplace]);

  useEffect(() => {
    const { name, description, themes, updated } = currentMarketplace;
    setValue([
      { name },
      { description },
      { themes },
      { organizationMarketplaces },
      { updated },
    ]);
  }, [currentMarketplace, organizationMarketplaces]);

  useEffect(() => {
    if (
      organizationMarketplaces &&
      JSON.stringify(organizationMarketplaces) !== JSON.stringify(items)
    ) {
      setItems(organizationMarketplaces);
    }
  }, [organizationMarketplaces]);

  const onChange = (key: string, value: any) => {
    setCurrentMarketplace({ ...currentMarketplace, [key]: value });
  };

  useEffect(() => {
    const colorPrimary =
      currentMarketplace.themes?.default?.colorPrimary || organizationColor;
    const newThemes = {
      default: {
        colorPrimary: colorPrimary,
        useDefaultColor: currentMarketplace.themes?.default?.useDefaultColor,
      },
    };
    if (currentMarketplace.themes?.default?.useDefaultColor) {
      newThemes.default.colorPrimary = organizationColor;
    }
    if (JSON.stringify(currentMarketplace.themes) !== JSON.stringify(newThemes))
      onChange('themes', newThemes);
  }, [currentMarketplace.themes]);

  useEffect(() => {
    if (organizationMarketplaces && marketplaces) {
      const newListOptions =
        (marketplaces &&
          marketplaces.length &&
          marketplaces?.map((marketplace, index) => {
            const selected = organizationMarketplaces
              ?.map(({ data }) => data._id)
              .includes(marketplace._id);
            return {
              text: marketplace.name,
              selected,
              data: { ...marketplace, index, selected },
            };
          })) ||
        [];

      setListOptions(newListOptions);
    }
  }, [organizationMarketplaces, marketplaces]);

  useEffect(() => {
    if (selected && selected.data) {
      const { data } = selected;
      setCurrentMarketplace({ ...data, selected: selected.selected });
      setShowFields(true);
      Object.keys(data).forEach(k => setValue(k, data[k]));
    }
  }, [selected]);

  const currentItem = currentMarketplace && {
    title: `${currentMarketplace.name || ''}`,
    data: currentMarketplace,
  };

  const resetForm = event => {
    event.preventDefault();
    setCurrentMarketplace(initMarketplace);
    setShowFields(false);
    onChangeParent('existingOrganizationMarketplace', null);
    setSelected(null);
    // Reset form validations
    Object.keys(initMarketplace).forEach(k => setValue(k, initMarketplace[k]));
  };

  const addNewItem = (event, selectedMarketplace) => {
    event.preventDefault();
    triggerValidation().then(isValid => {
      if (isValid) {
        let newItems = [] as any;
        const { data } = currentItem;
        const newItem = {
          text: data.name,
          selected: data.selected || false,
          data: { ...data, updated: true },
        };

        if (!selectedMarketplace) {
          const listIndex = [...listOptions].map((item, index) => ({
            ...item,
            index,
          }));
          newItems = [...listIndex, newItem].map((item, index) => ({
            ...item,
            data: { ...item.data, index },
          }));
        } else {
          const { index } = selected.data;
          newItems = [...listOptions].map(item => {
            if (item.data.index === index) {
              return {
                ...item,
                text: currentMarketplace.name,
                data: { ...item.data, ...currentMarketplace, updated: true },
                selected: currentMarketplace.selected,
              };
            } else {
              return item;
            }
          });
        }
        setListOptions(newItems);

        setCurrentMarketplace(initMarketplace);
        setSelected(null);
        // Reset form validations
        Object.keys(initMarketplace).forEach(k =>
          setValue(k, initMarketplace[k])
        );

        onChangeMultiple({
          marketplaces: newItems.map(({ data }) => data),
          existingOrganizationMarketplaces: null,
        });

        setCurrentMarketplace(initMarketplace);
        setShowFields(false);
      }
    });
  };

  const handleDeleteAction = marketplace => {
    if (marketplace) {
      const { index } = marketplace.data;
      const newItems = [...listOptions].map(item => {
        if (item.data.index === index) {
          return {
            ...item,
            data: { ...item.data, hidden: true },
            hidden: true,
          };
        } else {
          return item;
        }
      });
      setPopUpShow(true);
      setPopupNewItems(newItems);
    }
  };

  const popUpConfirmAction = () => {
    if (popupNewItems) {
      setListOptions(popupNewItems);
      onChangeMultiple({
        marketplaces: popupNewItems.map(({ data }) => data),
        existingOrganizationMarketplace: null,
      });
      setCurrentMarketplace(initMarketplace);
      setSelected(null);
      setShowFields(false);
    }
    setPopUpShow(false);
  };

  const handleActiveAction = (item, index) => {
    setCurrentMarketplace(item);
    setCurrentMarketplaceIndex(index);
    setShowFields(true);
    Object.keys(item).forEach(k => setValue(k, item[k]));
  };

  const handleAddNew = () => {
    setShowFields(true);
    setCurrentMarketplace(initMarketplace);
    setSelected(null);
    onChangeParent('existingOrganizationMarketplace', null);
  };

  const handleSelectOption = item => {
    if (item && item.checked) {
      setSelected(item);
    }
  };

  const selectedMarketplace = selected !== null;

  const colorPrimary =
    currentMarketplace.themes?.default?.colorPrimary || organizationColor;
  const useDefaultColor =
    currentMarketplace.themes?.default?.useDefaultColor === undefined
      ? true
      : currentMarketplace.themes?.default?.useDefaultColor;

  return (
    <Container>
      <FormSubHeader>
        <div className="mr-2">{i18n.t('Add organization marketplaces')}</div>
        <div data-tip data-for="addmarketplaces">
          <InfoIcon />
        </div>
        <CustomTooltip id="addmarketplaces" placement="right">
          <span>
            {i18n.t(
              'Marketplaces are used to organize products on the scanner app.  For instance, an Amazon.com marketplace may be used to organize many products on the scanner app.'
            )}
          </span>
        </CustomTooltip>
      </FormSubHeader>
      <div className="pageContainer">
        <FormContainer id="MarketplaceForm">
          <div className="row">
            <div className="col-12">
              <Label>{i18n.t('Add existing marketplaces')}</Label>

              <TransferList
                fromPlaceholder={i18n.t('Search for marketplaces')}
                items={listOptions}
                handleChange={items => {
                  const newItems = items.map(({ data, selected }) => ({
                    ...data,
                    selected,
                  }));
                  onChangeParent('marketplaces', newItems);
                  setCurrentMarketplace(initMarketplace);
                  setShowFields(false);
                  setSelected(null);
                }}
                handleSelectOption={item => handleSelectOption(item)}
              />
            </div>
            <div className="col-4 action-btn mt-5">
              <Button
                text={`+ ${i18n.t('New Marketplace')}`}
                type="dark"
                action={evt => {
                  evt.preventDefault();
                  handleAddNew();
                }}
              />
            </div>
          </div>

          {(showFields || existingOrganizationMarketplace) && (
            <>
              <div className="row label-spacing">
                <div className="col-5">
                  <Label>
                    {i18n.t('What is the name of the marketplace?')}
                  </Label>
                  <Controller
                    as={
                      <FormInput
                        required
                        placeholder={i18n.t('Enter the marketplace name')}
                        maxLength={50}
                      />
                    }
                    name="name"
                    value={currentMarketplace.name}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={Marketplace.name}
                    defaultValue={currentMarketplace.name}
                  />
                  {errors.firstName && (
                    <span className="inlineErrorMessage">
                      {errors.firstName.message}.
                    </span>
                  )}
                </div>

                <div className="col-7">
                  <Label>{i18n.t('Description')}</Label>
                  <Controller
                    as={
                      <FormInput
                        required
                        placeholder={i18n.t(
                          'Enter the marketplace description'
                        )}
                        maxLength={500}
                        type="textarea"
                      />
                    }
                    name="description"
                    value={currentMarketplace.description}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={Marketplace.description}
                    defaultValue={currentMarketplace.description}
                  />
                  {errors.lastName && (
                    <span className="inlineErrorMessage">
                      {errors.lastName.message}.
                    </span>
                  )}
                </div>
              </div>

              <div className="row label-spacing">
                <Label>
                  <Checkbox
                    onChange={evt =>
                      onChange('themes', {
                        default: {
                          ...currentMarketplace.themes?.default,
                          useDefaultColor: !useDefaultColor,
                        },
                      })
                    }
                    checked={useDefaultColor}
                    name="defaultColor"
                    disabled={organizationColor === ''}
                  />
                  {i18n.t('Set to default scanner app main color')}
                </Label>
              </div>

              <div className="row label-spacing">
                <div className="col-12">
                  <Label>
                    {i18n.t('Color')}
                    <div data-tip data-for="colorSelector">
                      <InfoIcon />
                    </div>
                    <CustomTooltip id="colorSelector" placement="right">
                      <span>
                        {i18n.t(
                          'This is the default color of the mobile consumer scanner app.'
                        )}
                      </span>
                    </CustomTooltip>
                  </Label>
                  <ColorPicker
                    defaultColor={colorPrimary}
                    onChange={colorPrimary =>
                      onChange('themes', {
                        default: {
                          useDefaultColor,
                          colorPrimary,
                        },
                      })
                    }
                    disabled={useDefaultColor}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <Button
                    text={i18n.t('Delete')}
                    action={() => handleDeleteAction(selected)}
                  />
                  <Button text={i18n.t('Clear')} action={resetForm} />
                  <Button
                    text={selected ? i18n.t('Save') : i18n.t('Add')}
                    type="dark"
                    action={event => addNewItem(event, selectedMarketplace)}
                  />
                </div>
              </div>
            </>
          )}
        </FormContainer>

        <PopUp
          title={i18n.t('Delete marketplace?')}
          content={i18n.t(
            'CAUTION: Are you sure you want to delete this Marketplace from the system?  Some products may no longer be visible in the scanner app!',
            { keySeparator: '>', nsSeparator: '|' }
          )}
          cancelText={i18n.t('Cancel')}
          confirmText={i18n.t('Yes')}
          confirmAction={popUpConfirmAction}
          cancelAction={() => setPopUpShow(false)}
          show={popUpShow}
        />
      </div>
    </Container>
  );
};

export default OrganizationMarketplace;
