import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';
import { Journey } from '../../../Lib/Utils/Validations';

import {
  JourneyDetailsChecklist as Checklist,
  JourneyDetailsFormContainer as FormContainer,
  JourneyDetailsContainer as Container,
  JourneyDetailsSubtitle as Subtitle,
  Header,
  RadioIcon,
} from './styles';

import {
  Label,
  FormInput,
  FormSelect,
  InfoIcon,
  CustomTooltip,
  ContentBox,
  Dropdown,
  PopUp,
  OrgSelector,
} from '../../../Components';

interface Props {
  onChange: Function;
  journeyName?: string;
  description?: string;
  templateLocator?: string;
  journeyTemplates?: Array<any>;
  journeyDetailFirstLoad?: boolean;
  isDraft?: boolean;
  onTemplateChange: Function;
  organizationId?: string;
}

const JourneyDetails = (
  { onChange, onTemplateChange, isDraft, ...data }: Props,
  ref
) => {
  const {
    journeyName,
    description,
    templateLocator,
    journeyTemplates,
    journeyDetailFirstLoad = true,
    organizationId,
  } = data;

  const [templateChangeShow, setTemplateChangeShow] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null as any);
  const [templateOptions, setTemplateOptions] = useState(
    [] as Array<{ value: string; label: string }>
  );

  const { errors, control, setValue, triggerValidation } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    journeyDetailFirstLoad === false && triggerValidation();
    onChange('journeyDetailFirstLoad', false);
  }, []);

  useEffect(() => {
    setValue([{ journeyName }, { description }]);
  }, [journeyName, description]);

  useEffect(() => {
    setSelectedTemplate(templateLocator);
  }, [templateLocator]);

  useEffect(() => {
    const options = [{ label: '', value: '' }]
      .concat(
        journeyTemplates?.map(({ _id, journeyName = ' ' }) => ({
          value: _id,
          label: journeyName,
        })) || []
      )
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );
    setTemplateOptions(options);
  }, [journeyTemplates]);

  useImperativeHandle(ref, () => ({
    validate() {
      return triggerValidation();
    },
  }));

  return (
    <Container className="row">
      <div className="col-12 mb-5">
        <Label>{i18n.t('Create a journey')}</Label>
        <Subtitle>{i18n.t('Provide a brief overview of the journey')}</Subtitle>
      </div>
      {/* <div className="col-12">
        <Checklist className="row mt-5">
          <div className="col-12 col-lg-6">
            <RadioIcon />
            <div>
              <Label>{i18n.t('Facility')}</Label>
              <p>{i18n.t('Create facilities for each step')}</p>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <RadioIcon />
            <div>
              <Label>{i18n.t('Assets')}</Label>
              <p>{i18n.t('Create a marketable product')}</p>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <RadioIcon />
            <div>
              <Label>{i18n.t('Claims')}</Label>
              <p>{i18n.t('Create claims that each facility')}</p>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <RadioIcon />
            <div>
              <Label>{i18n.t('Campaigns')}</Label>
              <p>{i18n.t('Create campaigns that each facility')}</p>
            </div>
          </div>
        </Checklist>
      </div> */}

      <div className="col-12">
        <ContentBox>
          <Header>
            <div className="title">{i18n.t('Journey overview')}</div>
          </Header>
          <FormContainer className="row">
            <OrgSelector
              modelType="Journey"
              handleOrganizationChange={selected =>
                onChange('organizationId', selected)
              }
              defaultValue={organizationId}
            />
            <div className="col-12 col-lg-6">
              <Label>
                {i18n.t('Journey name')}
                <div data-tip data-for="journeyname">
                  <InfoIcon />
                </div>
                <CustomTooltip id="journeyname" placement="right">
                  <span>
                    {i18n.t('Unique name to identify journey template')}
                  </span>
                </CustomTooltip>
              </Label>

              <Controller
                as={
                  <FormInput
                    required
                    placeholder={i18n.t(
                      'Enter the name you want for this journey'
                    )}
                    maxLength={50}
                  />
                }
                name="journeyName"
                value={journeyName}
                control={control}
                onChange={([evt]) => {
                  onChange(evt.target.name, evt.target.value);
                  return evt.target.value;
                }}
                rules={Journey.name}
                defaultValue={journeyName}
              />
              {errors.journeyName && (
                <span className="inlineErrorMessage">
                  {errors.journeyName.message}.
                </span>
              )}
            </div>
            {isDraft && (
              <div className="col-12 col-lg-6">
                <Label>
                  {i18n.t('Journey templates')}
                  <div data-tip data-for="templates">
                    <InfoIcon />
                  </div>
                  <CustomTooltip id="templates" placement="right">
                    <span>
                      {i18n.t('Template to accelerate the journey steps')}
                    </span>
                  </CustomTooltip>
                </Label>
                <Dropdown
                  defaultOption={selectedTemplate}
                  options={templateOptions}
                  placeholder={i18n.t(
                    'Select a template to accelerate creation of the journey'
                  )}
                  action={newValue => {
                    if (newValue) {
                      setTemplateChangeShow(true);
                    }
                    setSelectedTemplate(newValue || null);
                  }}
                />
              </div>
            )}
            <div className="col-12">
              <Label>
                {i18n.t('Journey description')}
                <div data-tip data-for="description">
                  <InfoIcon />
                </div>
                <CustomTooltip id="description" placement="right">
                  <span>
                    {i18n.t('Description that explains what this journey is')}
                  </span>
                </CustomTooltip>
              </Label>

              <Controller
                as={
                  <FormInput
                    required
                    maxLength={350}
                    type="textarea"
                    placeholder={i18n.t(
                      'Enter the description you want for this journey'
                    )}
                  />
                }
                name="description"
                value={description}
                control={control}
                onChange={([evt]) => {
                  onChange(evt.target.name, evt.target.value);
                  return evt.target.value;
                }}
                rules={Journey.description}
                defaultValue={description}
              />
              {errors.description && (
                <span className="inlineErrorMessage">
                  {errors.description.message}.
                </span>
              )}
            </div>
          </FormContainer>
        </ContentBox>
      </div>
      <PopUp
        title={i18n.t('Use template?')}
        content={
          <div>
            {i18n.t('Replace all progress with selected template?')}
            <br />
            <br />
            {i18n.t(
              'All facilities, assets, and claims will be removed from this journey and replaced with the selected journey template.'
            )}
          </div>
        }
        cancelText={i18n.t('Cancel')}
        confirmText={i18n.t('Yes')}
        confirmAction={() => {
          setTemplateChangeShow(false);
          onTemplateChange(selectedTemplate);
        }}
        cancelAction={() => {
          setTemplateChangeShow(false);
          setSelectedTemplate(templateLocator);
        }}
        show={templateChangeShow}
      />
    </Container>
  );
};

export default forwardRef(JourneyDetails);
