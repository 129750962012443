import * as journeyService from '../../Services/Api/journeyService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { EJourneyActionTypes } from './journeyActionsTypes';
import {
  SaveJourneyActionType,
  GetJourneyActionType,
} from '../../Services/Api/journeyService/types';

const getJourneys = createAction(EJourneyActionTypes.GET_JOURNEYS);
const getJourneysSuccess = createAction(
  EJourneyActionTypes.GET_JOURNEYS_SUCCESS,
  (journeys: any) => ({ journeys })
);
const getJourneysError = createAction(
  EJourneyActionTypes.GET_JOURNEYS_ERROR,
  (message: string) => ({ message })
);

const saveJourney = createAction(EJourneyActionTypes.SAVE_JOURNEY);
const saveJourneySuccess = createAction(
  EJourneyActionTypes.SAVE_JOURNEY_SUCCESS,
  (journey: any) => ({ journey })
);
const saveJourneyError = createAction(
  EJourneyActionTypes.SAVE_JOURNEY_ERROR,
  (message: string) => ({ message })
);

const saveJourneyTemplate = createAction(
  EJourneyActionTypes.SAVE_JOURNEY_TEMPLATE
);
const saveJourneyTemplateSuccess = createAction(
  EJourneyActionTypes.SAVE_JOURNEY_TEMPLATE_SUCCESS,
  (
    journey: any,
    isDraft?: boolean,
    submitted?: boolean,
    notifySuccess?: boolean
  ) => ({
    journey,
    isDraft,
    submitted,
    notifySuccess,
  })
);
const saveJourneyTemplateError = createAction(
  EJourneyActionTypes.SAVE_JOURNEY_TEMPLATE_ERROR,
  (message: string) => ({ message })
);

const getJourney = createAction(
  EJourneyActionTypes.GET_JOURNEY,
  (journeyId: any) => ({ journeyId })
);
const getJourneySuccess = createAction(
  EJourneyActionTypes.GET_JOURNEY_SUCCESS,
  (payload: any) => payload
);
const getJourneyError = createAction(
  EJourneyActionTypes.GET_JOURNEY_ERROR,
  (message: string) => ({ message })
);

const getJourneyTemplates = createAction(
  EJourneyActionTypes.GET_JOURNEY_TEMPLATES
);
const getJourneyTemplatesSuccess = createAction(
  EJourneyActionTypes.GET_JOURNEY_TEMPLATES_SUCCESS,
  (journeyTemplates: any) => ({ journeyTemplates })
);
const getJourneyTemplatesError = createAction(
  EJourneyActionTypes.GET_JOURNEY_TEMPLATES_ERROR,
  (message: string) => ({ message })
);

const getJourneyTemplate = createAction(
  EJourneyActionTypes.GET_JOURNEY_TEMPLATE,
  (journeyTemplateId: any) => ({ journeyTemplateId })
);
const getJourneyTemplateSuccess = createAction(
  EJourneyActionTypes.GET_JOURNEY_TEMPLATE_SUCCESS,
  (payload: any) => payload
);
const getJourneyTemplateError = createAction(
  EJourneyActionTypes.GET_JOURNEY_TEMPLATE_ERROR,
  (message: string) => ({ message })
);

const deleteJourneyTemplate = createAction(
  EJourneyActionTypes.DELETE_JOURNEY_TEMPLATE,
  (journeyTemplateId: any) => ({ journeyTemplateId })
);
const deleteJourneyTemplateSuccess = createAction(
  EJourneyActionTypes.DELETE_JOURNEY_TEMPLATE_SUCCESS,
  (payload: any) => payload
);

const deleteJourney = createAction(
  EJourneyActionTypes.DELETE_JOURNEY,
  (journeyId: any) => ({ journeyId })
);
const deleteJourneySuccess = createAction(
  EJourneyActionTypes.DELETE_JOURNEY_SUCCESS,
  (payload: any) => payload
);
const deleteJourneyError = createAction(
  EJourneyActionTypes.DELETE_JOURNEY_ERROR,
  (message: string) => ({ message })
);

const deleteJourneyTemplateError = createAction(
  EJourneyActionTypes.DELETE_JOURNEY_TEMPLATE_ERROR,
  (message: string) => ({ message })
);

const getActiveJourneyStatus = createAction(
  EJourneyActionTypes.ACTIVE_JOURNEY_STATUS
);
const getActiveJourneyStatusSuccess = createAction(
  EJourneyActionTypes.ACTIVE_JOURNEY_STATUS_SUCCESS,
  (journeys: any) => ({ journeys })
);
const getActiveJourneyStatusError = createAction(
  EJourneyActionTypes.ACTIVE_JOURNEY_STATUS_ERROR,
  (message: string) => ({ message })
);

export const resetCurrentTemplate = createAction(
  EJourneyActionTypes.RESET_CURRENT_JOURNEY_TEMPLATE
);

export const doGetJourneys = (
  journeyId = null
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getJourneys());
  return await tap(
    journeyService.getJourneys(journeyId),
    response => {
      dispatch(getJourneysSuccess(response.data));
    },
    error => {
      const _message = 'Failed to retrieve Journeys. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getJourneysError(message));
    }
  );
};

export const doCleanJourneys = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async dispatch => {
  dispatch(createAction(EJourneyActionTypes.CLEAN_JOURNEYS)());
};

export const doSaveJourney = (
  payload
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(saveJourney());
  return await tap(
    journeyService.saveJourney(payload),
    response => {
      dispatch(saveJourneySuccess(response.data ? response.data.data : {}));
    },
    error => {
      let _message = '';
      if (error.data.status.message === 'jourey-lotId-already-created') {
        _message = 'Lot/Batch ID already exists';
      } else {
        _message = 'Failed to save Journey. Please try again later';
      }

      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(saveJourneyError(message));
    }
  );
};

export const doGetJourney = (
  journeyId
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getJourney(journeyId));
  return await tap(
    journeyService.getJourney(journeyId),
    response => {
      dispatch(getJourneySuccess(response.data ? response.data.data : []));
    },
    error => {
      const _message = 'Failed to retrieve Journey. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getJourneyError(message));
    }
  );
};

export const doGetJourneyTemplates = (
  includeDrafts?: boolean,
  includeArchived?: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getJourneyTemplates());
  return await tap(
    journeyService.getJourneyTemplates(includeDrafts, includeArchived),
    journeyTemplates => {
      dispatch(getJourneyTemplatesSuccess(journeyTemplates));
    },
    error => {
      const _message = 'Failed to retrieve journeys. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getJourneyTemplatesError(message));
    }
  );
};
export const doGetJourneyTemplate = (
  journeyTemplateId,
  actionType: GetJourneyActionType
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getJourneyTemplate(journeyTemplateId));
  return await tap(
    journeyService.getJourneyTemplate(journeyTemplateId, actionType),
    response => {
      let data = response.data ? response.data.data : {};
      const isDraft = !!actionType.match('draft');
      if (isDraft) {
        data = { ...data.draft, _id: data._id }; // The Draft has its own _id different from the journey _id
      }
      dispatch(getJourneyTemplateSuccess(data));
    },
    error => {
      const _message = 'Failed to retrieve Journey. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getJourneyTemplateError(message));
    }
  );
};

export const doSaveJourneyTemplate = (
  payload,
  actionType: SaveJourneyActionType,
  notifySuccess?: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(saveJourneyTemplate());
  return await tap(
    journeyService.saveJourneyTemplate(payload, actionType),
    response => {
      let data = response.data ? response.data.data : {};
      const isDraft = !!actionType.match('draft');
      if (isDraft) {
        data = { ...data.draft, _id: data._id }; // The Draft has its own _id different from the journey _id
      }
      dispatch(
        saveJourneyTemplateSuccess(
          data,
          isDraft,
          !!actionType.match('submit'),
          notifySuccess
        )
      );
    },
    error => {
      const _message = 'Failed to save Journey. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(saveJourneyTemplateError(message));
    }
  );
};

export const doDeleteJourneyTemplate = (
  journeyTemplateId: string,
  isDraft: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(deleteJourneyTemplate(journeyTemplateId));
  return await tap(
    journeyService.deleteJourneyTemplate(journeyTemplateId, isDraft),
    response => {
      dispatch(deleteJourneyTemplateSuccess(response.data));
    },
    error => {
      const _message = 'Failed to delete Journey. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(deleteJourneyTemplateError(message));
    }
  );
};

export const doDeleteJourney = (
  journeyId: string
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  console.log('Do deletes');
  dispatch(deleteJourney(journeyId));
  return await tap(
    journeyService.deleteJourney(journeyId),
    response => {
      dispatch(deleteJourneySuccess(response.data));
    },
    error => {
      const _message = 'Failed to delete Journey. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(deleteJourneyError(message));
    }
  );
};

export const doGetActiveJourneyStatus = (
  payload
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getActiveJourneyStatus());
  return await tap(
    journeyService.getActiveJourneyStatus(payload),
    response => {
      dispatch(getActiveJourneyStatusSuccess(response.data));
    },
    error => {
      const _message = 'Failed to retrieve Journeys. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getActiveJourneyStatusError(message));
    }
  );
};
