import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import { Container, TitleContainer } from './styles';

interface Iprops extends RouteComponentProps<any> {
  title: string;
  subtitle?: string;
  subtitleArray?: Array<string>;
  icon?: any;
  titleLink?: string;
  subTitleLink?: string;
  links?: Array<string>;
}

const Title = ({
  history,
  title,
  subtitle,
  subtitleArray,
  icon,
  titleLink = '',
  subTitleLink = '',
  links = [],
}: Iprops) => {
  return (
    <Container>
      {icon}
      <TitleContainer
        className="title"
        onClick={() => titleLink && history.push(titleLink)}
        clickable={titleLink !== ''}
      >
        {title}
      </TitleContainer>
      {subtitle && (
        <>
          <div className="arrow"> {`>`} </div>
          <TitleContainer
            className="subtitle"
            onClick={() => subTitleLink && history.push(subTitleLink)}
            clickable={subTitleLink !== ''}
          >
            {subtitle}
          </TitleContainer>
        </>
      )}
      {subtitleArray &&
        subtitleArray.map((sub, index) => (
          <div key={`title=-${index}`} className="d-flex">
            <div className="arrow"> {`>`} </div>
            <TitleContainer
              className="subtitle"
              onClick={() =>
                links && links[index] && history.push(links[index])
              }
              clickable={links[index] && links[index] !== '' ? true : false}
            >
              {sub}
            </TitleContainer>
          </div>
        ))}
    </Container>
  );
};

export default withRouter(Title);
