import React, { useState, useEffect } from 'react';
import i18n from 'i18next';

import {
  Container,
  StepsList,
  StepItem,
  IconContainer,
  TitleContainer,
  NumberContainer,
  StepContent,
  DropdownContainer,
  AddItemIcon,
  Check,
  EditableItemsContainer,
} from './styles';

import {
  CustomTooltip,
  Dropdown,
  Button,
  Label,
  CheckFillIcon,
  EditableItem,
} from '../index';
import { MdAdd } from 'react-icons/md';

import { Step } from './types';

interface Props {
  steps?: Array<Step>;
  showCreate?: boolean;
  handleAdd?: Function;
  defaultStep?: number;
  header?: any;
  showFacilities?: boolean;
  onChange?: (value: number, subStep?: boolean) => {} | void | null;
  loading?: boolean;
}

const numberSort = ({ number: numberA }, { number: numberB }) => {
  if (numberA === -1) return 1;
  if (numberB === -1) return -1;

  if (numberA > numberB) {
    return 1;
  }
  if (numberB > numberA) {
    return -1;
  }
  return 0;
};

const Steps = ({
  steps,
  header,
  defaultStep,
  showFacilities = true,
  showCreate = false,
  handleAdd,
  onChange,
  loading = false,
}: Props) => {
  const [active, setActive] = useState(defaultStep || 0);
  const [createMode, setCreateMode] = useState(false);
  const [activeSubstep, setActiveSubstep] = useState(null as any);
  const [manualChangeStep, setManualChangeStep] = useState(false);

  const handleSubStepChange = number => {
    setManualChangeStep(true);
    setActiveSubstep(number);
    setActive(number);
    onChange && onChange(number);
  };

  const handleButtonClick = number => {
    setManualChangeStep(false);
    setActive(number);
    setActiveSubstep(null);
    onChange && onChange(number);
  };

  const activeStep = steps && steps.find(({ number }) => number === active);
  const activeSubStepData =
    steps && steps.find(({ number }) => number === activeSubstep);

  const Content = activeSubStepData?.content || activeStep?.content;

  useEffect(() => {
    if (!activeStep && steps && steps[0]) {
      const firstStepNumber = steps[0].number;
      setActive(firstStepNumber);
    }
  }, [steps]);

  useEffect(() => {
    if (
      typeof defaultStep === 'number' &&
      defaultStep !== active &&
      steps &&
      steps.length
    ) {
      setActive(defaultStep);

      if (steps?.find(step => step.number === defaultStep)?.isParallel) {
        setActiveSubstep(defaultStep);
      }
    }
  }, [defaultStep, steps]);

  const parallelsToActive = [] as any;

  if (activeStep && activeStep.firstOfParallel) {
    parallelsToActive.push(activeStep);
    steps
      ?.filter(step => step.number > activeStep.number && step.isParallel)
      .forEach(current => {
        if (
          current.isParallel &&
          current.number ===
            parallelsToActive[parallelsToActive.length - 1].number + 1
        ) {
          parallelsToActive.push(current);
        } else {
          return;
        }
      });
  } else if (activeStep && activeStep.isParallel) {
    parallelsToActive.push(activeStep);
    const filtered = steps
      ?.sort(numberSort)
      .filter(step => step.number > activeStep.number && step.isParallel);

    filtered?.forEach(current => {
      if (
        current.isParallel &&
        current.number ===
          parallelsToActive[parallelsToActive.length - 1].number + 1
      ) {
        parallelsToActive.push(current);
      } else {
        return;
      }
    });

    const reverseFiltered = steps
      ?.sort(numberSort)
      .reverse()
      .filter(
        step =>
          step.number < activeStep.number &&
          (step.isParallel || step.firstOfParallel)
      );

    reverseFiltered?.forEach(current => {
      if (
        current.number === parallelsToActive[0].number - 1 &&
        current.isParallel
      ) {
        parallelsToActive.unshift(current);
      } else {
        return;
      }
    });

    const firstOfParallel = reverseFiltered?.find(step => step.firstOfParallel);
    parallelsToActive.unshift(firstOfParallel);
  }

  useEffect(() => {
    if (!loading && activeStep?.firstOfParallel && !manualChangeStep) {
      const newActive = parallelsToActive.find(step => step.complete);
      setActiveSubstep(newActive?.number || parallelsToActive[0].number);
    }
  }, [activeStep, loading, parallelsToActive]);

  const contentProps =
    activeSubStepData?.contentProps || activeStep?.contentProps;

  const previousSteps =
    activeStep?.isParallel &&
    steps?.filter(step => step.number <= activeStep.number);

  const activeParallelStepParent =
    previousSteps && previousSteps.find(step => step.firstOfParallel);

  const facilityData =
    (activeStep?.facilityData && activeStep?.facilityData[0]) || null;

  return (
    <Container>
      <StepsList className="nav nav-tabs">
        {steps
          ?.sort(numberSort)
          .filter(({ isParallel }) => !isParallel)
          .map(
            (
              {
                number,
                icon,
                title,
                subTitle,
                disabled = false,
                facilities,
                done = false,
                showAddButton = false,
                checked = false,
                firstOfParallel,
              },
              index
            ) => {
              return (
                <React.Fragment key={index}>
                  <StepItem
                    className={`${
                      active === number ||
                      (activeParallelStepParent &&
                        activeParallelStepParent.number === number)
                        ? 'active'
                        : ''
                    }${done ? ' done' : ''} `}
                    key={number}
                  >
                    <div className="step-wrapper">
                      {facilities && facilities.length > 0 && (
                        <>
                          <div
                            data-tip
                            data-for={`${number}-facilities`}
                            className="facilities"
                          >
                            {facilities.length}
                          </div>
                        </>
                      )}
                      {checked && (
                        <Check>
                          <CheckFillIcon />
                        </Check>
                      )}

                      <button
                        disabled={disabled}
                        onClick={() => {
                          handleButtonClick(number);
                          setCreateMode(false);
                        }}
                      >
                        {icon ? (
                          <IconContainer>{icon}</IconContainer>
                        ) : (
                          <NumberContainer>{number}</NumberContainer>
                        )}
                      </button>
                      {showCreate && (
                        <AddItemIcon className="add-item-icon">
                          {showAddButton && (
                            <Button
                              width={34}
                              height={34}
                              icon={<MdAdd color="#fff" size={16} />}
                              type="roundDark"
                              action={() => {
                                handleButtonClick(number);
                                setCreateMode(true);
                              }}
                            />
                          )}
                        </AddItemIcon>
                      )}
                      <TitleContainer
                        className={showCreate ? 'clickable' : ''}
                        onClick={() => {
                          handleButtonClick(number);
                          setCreateMode(false);
                        }}
                      >
                        {title}
                        <small>
                          {firstOfParallel &&
                          activeSubstep &&
                          activeParallelStepParent &&
                          activeParallelStepParent.number === number
                            ? activeSubStepData?.subTitle
                            : subTitle}
                        </small>
                      </TitleContainer>
                    </div>
                  </StepItem>
                  {facilities && facilities.length > 0 && (
                    <CustomTooltip
                      id={`${number}-facilities`}
                      placement="bottom"
                    >
                      <ul className="facilityList list">
                        {facilities.map((facility, index) => (
                          <li key={index}>{facility}</li>
                        ))}
                      </ul>
                    </CustomTooltip>
                  )}
                </React.Fragment>
              );
            }
          )}
      </StepsList>

      <StepContent>
        {activeStep && (activeStep.firstOfParallel || activeStep.isParallel) && (
          <DropdownContainer>
            <Label className="mr-2">{i18n.t('Select facility')}</Label>
            <Dropdown
              defaultOption={activeSubstep}
              options={parallelsToActive.sort(numberSort).map(step => ({
                value: step.number,
                label: step.facilityName,
              }))}
              action={selected => handleSubStepChange(selected)}
            />
          </DropdownContainer>
        )}
        {facilityData && (
          <EditableItem
            editable={false}
            title={i18n.t('Point of contact')}
            collapsible={true}
            defaultOpen={false}
            arrowNextToText={true}
            content={
              <div>
                <EditableItemsContainer>
                  <EditableItem
                    editable={false}
                    title={i18n.t('Name')}
                    content={
                      (facilityData.primaryContact.salutation ||
                        facilityData.primaryContact.firstName ||
                        facilityData.primaryContact.lastName) &&
                      `${facilityData.primaryContact.salutation ||
                        ''} ${facilityData.primaryContact.firstName ||
                        ''} ${facilityData.primaryContact.lastName || ''}`
                    }
                  />
                  <EditableItem
                    editable={false}
                    title={i18n.t('Email')}
                    content={facilityData.primaryContact.email.address}
                  />
                  <EditableItem
                    editable={false}
                    title={i18n.t('Phone number')}
                    content={`${
                      facilityData.primaryContact.phone.countryCode
                        ? `(${facilityData.primaryContact.phone.countryCode}) `
                        : ''
                    }${facilityData.primaryContact.phone.number || ''}`}
                  />
                  <EditableItem
                    editable={false}
                    title={i18n.t('Ext')}
                    content={facilityData.primaryContact.phone.ext}
                  />
                  <EditableItem
                    editable={false}
                    title={i18n.t('Type')}
                    content={facilityData.primaryContact.phone.type}
                  />
                </EditableItemsContainer>

                <EditableItem
                  collapsible={false}
                  editable={false}
                  title={i18n.t('Company description')}
                  content={facilityData.description}
                />
              </div>
            }
          />
        )}
        {header}
        {Content && (
          <Content
            createMode={createMode}
            setCreateMode={setCreateMode}
            {...contentProps}
          />
        )}
      </StepContent>
    </Container>
  );
};

export default Steps;
