import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 20px 5px;
  border-left: 5px solid #a7bf2e;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  align-items: center;
  padding: 20px 25px;
  margin-bottom: 20px;
`;

const Head = styled.div<{ clickable?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  ${({ clickable }) => clickable && `cursor:pointer`}
`;
const Body = styled.div`
  width: 100%;
  margin-top: 10px;
  height: 100%;
  flex: 1;
  position: relative;
`;

const TitleContainer = styled.div``;
const ValueContainer = styled.div`
  text-align: right;
  flex: 1;
  justify-content: flex-end;
`;
const IconContainer = styled.div`
  background: #303030;
  border-radius: 4px 12px 8px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 15px;
  path {
    fill: #fff;
  }
`;

const NumberIcon = styled.span`
  color: #a7bf2e;
`;

export {
  Container,
  Head,
  Body,
  TitleContainer,
  ValueContainer,
  IconContainer,
  NumberIcon,
};
