import React, { useState } from 'react';
import { generateQRUrl } from '../../Lib/Utils/QR';
import { generateQrPdf } from '../../Lib/Utils/PDF';
import { v4 as uuidv4 } from 'uuid';
import { PopUp, Label, Checkbox, CustomTooltip, Button, CopyIcon } from '..';
import i18n from 'i18next';
import { QR } from '../../Lib/Configs';
import Toast from '../../Lib/Utils/toast';

import {
  Content,
  Container,
  Clickable,
  CheckboxContainer,
  QRIcon,
  CopyButtonContent,
  ActionContainer,
} from './styles';

interface Props {
  qr: Array<{
    lotId: string;
    skuku: string;
    name: string;
  }>;
  children?: any;
  tooltip?: string;
  disabled?: boolean;
}

const QrPdf = ({ qr, children, tooltip, disabled = false }: Props) => {
  const { baseUrl } = QR;
  const generateUrl = (skuku, lotId) =>
    `${baseUrl}?skuku=${skuku}&lotId=${lotId}`;
  const id = `qr-${uuidv4()}`;

  const qrsData = qr.map(({ lotId, skuku, name }, index) => {
    const qrId = `qr-${index}-${uuidv4()}`;
    return { skuku, lotId, qrId, name };
  });

  const [popUpShow, setPopUpShow] = useState(false);
  const [lotIdSelected, setLotIdSelected] = useState(true);
  const [skukuSelected, setSkukuSelected] = useState(false);
  const [assetNameSelected, setAssetNameSelected] = useState(false);

  const confirmGenerateAction = () => {
    generateQrPdf(qrsData, lotIdSelected, skukuSelected, assetNameSelected);
    setPopUpShow(false);
  };

  const cancelAction = () => {
    setPopUpShow(false);
  };

  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    Toast.success(i18n.t('Copied!'));
  };

  const disabledCopy = qrsData.length > 1;

  return (
    <Content>
      {qrsData.map(({ skuku, lotId, qrId }) =>
        generateQRUrl(generateUrl(skuku, lotId), qrId)
      )}
      <Clickable
        onClick={() => !disabled && setPopUpShow(true)}
        data-tip
        data-for={`qr-code-${id}`}
        disabled={disabled}
      >
        {children ? (
          children
        ) : (
          <QRIcon src={require('../../Rsc/Images/qr-icon.png')} />
        )}
      </Clickable>
      <CustomTooltip id={`qr-code-${id}`}>
        <span>{tooltip || i18n.t('Generate QR code.')}</span>
      </CustomTooltip>
      <PopUp
        title={i18n.t('QR Code Generation')}
        content={
          <Container>
            <p>
              {i18n.t(
                "Please select what you'd like to include in the QR code file."
              )}
            </p>
            <CheckboxContainer>
              <Label className="text-dark">
                <Checkbox
                  onChange={evt => setLotIdSelected(evt.target.checked)}
                  checked={lotIdSelected}
                  name="lotIdSelected"
                />
                Lot #
              </Label>
              <Label className="text-dark">
                <Checkbox
                  onChange={evt => setAssetNameSelected(evt.target.checked)}
                  checked={assetNameSelected}
                  name="assetNameSelected"
                />
                {i18n.t('Asset name')}
              </Label>
              <Label className="text-dark">
                <Checkbox
                  onChange={evt => setSkukuSelected(evt.target.checked)}
                  checked={skukuSelected}
                  name="skukuSelected"
                />
                SKUKU
              </Label>
              <Label
                data-tip
                data-for={`qr-code-required-${id}`}
                className="text-dark"
              >
                <Checkbox
                  checked={true}
                  name="sukuLogoSelected"
                  disabled={true}
                />
                SUKU logo
              </Label>
              <CustomTooltip id={`qr-code-required-${id}`}>
                <span>{i18n.t('Required.')}</span>
              </CustomTooltip>
            </CheckboxContainer>
            <ActionContainer>
              <div data-tip data-for={`qr-code-action-${id}`}>
                <Button
                  text={
                    <CopyButtonContent>
                      {i18n.t('Copy QR data to clipboard.')}
                      <CopyIcon />
                    </CopyButtonContent>
                  }
                  action={() =>
                    copyToClipboard(
                      generateUrl(qrsData[0].skuku, qrsData[0].lotId)
                    )
                  }
                  type="text"
                  disabled={disabledCopy}
                />
              </div>

              {disabledCopy && (
                <CustomTooltip id={`qr-code-action-${id}`}>
                  <span>
                    {i18n.t(
                      'Cannot copy QR content because there is more than one QR code in this step.'
                    )}
                  </span>
                </CustomTooltip>
              )}
            </ActionContainer>
          </Container>
        }
        cancelText={i18n.t('Cancel')}
        confirmText={i18n.t('Generate')}
        confirmAction={confirmGenerateAction}
        cancelAction={cancelAction}
        negativeAction={cancelAction}
        show={popUpShow}
      />
    </Content>
  );
};

export default QrPdf;
