import { FacilityTypeIcons } from './FacilityTypes';
import FacilityPermissionTypes from './FacilityPermissionTypes';
import OrganizationPermissionTypes from './OrganizationPermissionTypes';
import GlobalPermissionTypes from './GlobalPermissionTypes';

export { default as Salutations } from './Salutations';
export { default as PhoneTypes } from './PhoneTypes';
export { default as FacilityPermissionTypes } from './FacilityPermissionTypes';
export { default as OrganizationPermissionTypes } from './OrganizationPermissionTypes';
export { default as GlobalPermissionTypes } from './GlobalPermissionTypes';
export { default as ClaimTypes } from './ClaimTypes';
export { default as FieldTypes } from './FieldTypes';
export { default as VisibilityScopes } from './VisibilityScopes';
export { default as DataSource } from './DataSource';
export { default as FacilityTypes } from './FacilityTypes';
export { default as QR } from './QR';
export { default as ScannerApp } from './ScannerApp';
export { FacilityTypeIcons };

export const PermissionTypes = [
  ...GlobalPermissionTypes,
  ...OrganizationPermissionTypes,
  ...FacilityPermissionTypes,
];
