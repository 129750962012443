import styled from 'styled-components';

const TableContainer = styled.div`
  border-radius: 0 25px 25px 25px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  padding-bottom: 15px;
`;

const TableStyled = styled.table`
  border-spacing: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  text-align: left;
  flex-direction: column;

  tr:nth-child(even) {
    background: #f9f9fb;
  }

  th,
  td {
    padding: 8px;
    border: none;
    text-align: left;

    strong {
      font-weight: 600;
    }

    ul {
      font-family: Encode Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #303030;
      padding: 0;
      li {
        white-space: nowrap;
        text-overflow: ellipsis;
        a {
          color: #a7bf2e;
          text-decoration: none;
          :hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  td:first-child,
  th:first-child {
    padding-left: 30px;
  }

  .normalRow {
    vertical-align: top;
  }

  th {
    height: 50px;
    text-transform: uppercase;
    font-weight: normal;
    white-space: nowrap;
  }

  tr.subHeader,
  thead {
    text-align: left;
    background: radial-gradient(
      50% 25628.48% at 50% 0%,
      #3a3a3a 0%,
      #000000 100%
    );
    color: #ffffff;
    height: 50px;
  }

  tr.subHeader {
    background: #303030;
    td {
      vertical-align: center;
      :last-child {
        padding: 0 20px;
        width: 24px;
      }
    }
  }

  .infoTitle {
    span.txInfo {
      display: block;
      white-space: nowrap;
      width: fit-content;
      :first-child {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        text-transform: uppercase;
      }

      :nth-child(2) {
        font-family: Encode Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #a7bf2e;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 7ch;
        cursor: pointer;
      }
    }
  }

  tr.subHeader {
    span {
      :last-child {
        color: #d7f30a;
      }
    }
  }
`;

export { TableContainer, TableStyled };
