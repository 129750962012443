import styled from 'styled-components';

const PermissionsIcon = styled.div`
  svg {
    width: 20px;
    height: 20px;
    path {
      fill: #fff !important;
    }
  }
`;

const PermissionsList = styled.div`
  ul {
    padding: 0;
    margin-left: 15px;
    margin-bottom: 15px;
  }
  h6 {
    font-size: 13px;
    margin-left: 0px;
    padding-left: 0;
  }
`;

export { PermissionsIcon, PermissionsList };
