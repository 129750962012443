import React, { useState, useEffect } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import i18n from 'i18next';

import SearchInput from './SearchInput';
import Tooltip from '../Tooltip';
import {
  Container,
  List,
  ListContainer,
  Checkbox,
  ControlButton,
  SelectAll,
  ItemContainer,
  ControlsContainer,
} from './styles';

interface EnumItem {
  text: string;
  selected: boolean;
  tooltip?: any;
  data?: any;
  highlighted?: boolean;
  hidden?: boolean;
}

interface Props {
  fromPlaceholder?: string;
  toPlaceholder?: string;
  items: Array<EnumItem>;
  handleChange?: any;
  filter?: any;
  handleSelectOption?: Function;
  disabled?: boolean;
}

const tooltipStyles = {
  ul: {
    fontFamily: 'Encode Sans',
    paddingLeft: 20,
  },
  li: {
    marginBottom: 8,
    fontSize: 12,
    color: '#303030',
  },
  title: {
    fontSize: 12,
    color: '#303030',
    fontFamily: 'Encode Sans',
  },
  wrapper: {
    marginTop: 10,
  },
};

const TransferList = ({
  items,
  filter,
  handleChange,
  handleSelectOption,
  fromPlaceholder = i18n.t('Type to filter'),
  toPlaceholder = i18n.t('Type to filter'),
  disabled = false,
}: Props) => {
  const itemsCustom = items.map(item => {
    return { ...item, checked: false };
  });

  const [listItems, setListItems] = useState(itemsCustom);

  useEffect(() => {
    const itemsCustom = items.map(item => {
      return { ...item, checked: false };
    });
    if (items) {
      if (JSON.stringify(itemsCustom) !== JSON.stringify(listItems)) {
        setListItems(itemsCustom);
      }
    }
    setSelectFilter('');
    setSelectedFilter('');
  }, [items]);

  const [selectFilter, setSelectFilter] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');

  let select = listItems.filter(i => !i.selected && !i.hidden);
  if (filter) {
    select = select.filter(filter);
  }
  const selected = listItems.filter(i => i.selected && !i.hidden);

  const handleCheckbox = e => {
    if (!disabled) {
      const key = e.target.dataset.key;
      const value = e.target.checked;
      setListItems(
        listItems.map(i => {
          const matchKey = i.data?.matchKey || i.text;
          const highlighted = key === matchKey && value;
          return {
            ...i,
            checked: key === matchKey ? value : i.checked,
            highlighted,
          };
        })
      );
      handleSelectOption &&
        handleSelectOption({
          ...listItems.find(i => {
            const matchKey = i.data?.matchKey || i.text;
            return key === matchKey;
          }),
          checked: value,
        });
    }
  };

  const handleTransferButtonClick = select => {
    if (!disabled) {
      const newListItem = listItems.map(item => {
        if (item.selected === !select) {
          return {
            ...item,
            selected: item.checked ? select : item.selected,
            checked: false,
          };
        } else {
          return item;
        }
      });
      setListItems(newListItem);
      handleChange && handleChange(newListItem);
    }
  };

  const handleSelectAll = select => {
    if (!disabled) {
      const allSelected = !listItems
        .filter(i => i.selected === select)
        .some(ele => ele.checked === false);

      const newListItem = listItems.map(item => {
        if (item.selected === select && !item.hidden) {
          return {
            ...item,
            checked: allSelected ? false : true,
          };
        } else {
          return item;
        }
      });
      setListItems(newListItem);
    }
  };

  const handleSearch = (select, text) => {
    if (select) {
      setSelectedFilter(text);
    } else {
      setSelectFilter(text);
    }
  };

  useEffect(() => {
    const upperSelectedFilter = selectedFilter.toUpperCase();
    const upperSelectFilter = selectFilter.toUpperCase();
    const newListItem = listItems.map(item => {
      const upperItemText = item.text.toUpperCase();
      if (item.selected) {
        return {
          ...item,
          hidden: upperItemText.search(upperSelectedFilter) === -1,
          checked:
            upperItemText.search(upperSelectedFilter) === -1
              ? false
              : item.checked,
        };
      } else {
        return {
          ...item,
          hidden: upperItemText.search(upperSelectFilter) === -1,
          checked:
            upperItemText.search(upperSelectFilter) === -1
              ? false
              : item.checked,
        };
      }
    });
    if (JSON.stringify(newListItem) !== JSON.stringify(listItems)) {
      setListItems(newListItem);
    }
  }, [selectFilter, selectedFilter]);

  return (
    <Container className="row">
      <div className={`list-wrapper ${disabled && 'disabled'} col-auto`}>
        <SearchInput
          disabled={disabled}
          placeholder={fromPlaceholder}
          onChangeAction={value => handleSearch(false, value)}
          value={selectFilter}
        />
        <ListContainer>
          <List>
            {select.map((item, idx) => (
              <ItemContainer key={idx}>
                <label title={item.text}>
                  <input
                    type="checkbox"
                    onChange={handleCheckbox}
                    data-key={item.data?.matchKey || item.text}
                    checked={item.checked}
                  />
                  <Checkbox />
                  {item.tooltip ? (
                    <Tooltip
                      secondary
                      placement="bottom-start"
                      text={
                        <div style={tooltipStyles.wrapper}>
                          {item.tooltip.title && (
                            <>
                              <b style={tooltipStyles.title}>
                                {item.tooltip.title}
                              </b>
                              <br />
                              <br />
                            </>
                          )}

                          {item.tooltip.items && item.tooltip.items.length ? (
                            <ul style={tooltipStyles.ul}>
                              {item.tooltip.items.map((item, index) => (
                                <li key={index} style={tooltipStyles.li}>
                                  {item}
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </div>
                      }
                    >
                      <p className={item.highlighted ? 'last-highlighted' : ''}>
                        {item.text}
                      </p>
                    </Tooltip>
                  ) : (
                    <p className={item.highlighted ? 'last-highlighted' : ''}>
                      {item.text}
                    </p>
                  )}
                </label>
              </ItemContainer>
            ))}
          </List>
          <SelectAll>
            <ItemContainer>
              <label
                onClick={e => {
                  e.preventDefault();
                  handleSelectAll(false);
                }}
              >
                <input
                  type="checkbox"
                  checked={
                    select.length > 0
                      ? !select.some(ele => ele.checked === false)
                      : false
                  }
                />
                <Checkbox />
                {i18n.t('Select All')}
              </label>
            </ItemContainer>
          </SelectAll>
        </ListContainer>
      </div>
      <ControlsContainer className="col-1">
        <ControlButton
          onClick={e => e.preventDefault()}
          className={disabled ? 'disabled' : ''}
        >
          <MdKeyboardArrowRight
            size="24px"
            onClick={() => handleTransferButtonClick(true)}
          />
        </ControlButton>
        <ControlButton
          onClick={e => e.preventDefault()}
          className={disabled ? 'disabled' : ''}
        >
          <MdKeyboardArrowLeft
            size="24px"
            onClick={() => handleTransferButtonClick(false)}
          />
        </ControlButton>
      </ControlsContainer>
      <div className={`list-wrapper ${disabled && 'disabled'} col-auto`}>
        <SearchInput
          disabled={disabled}
          filterIcon={true}
          placeholder={toPlaceholder}
          onChangeAction={value => handleSearch(true, value)}
          value={selectedFilter}
        />
        <ListContainer>
          <List>
            {selected.map((item, idx) => (
              <ItemContainer key={idx}>
                <label title={item.text}>
                  <input
                    type="checkbox"
                    onChange={handleCheckbox}
                    data-key={item.data?.matchKey || item.text}
                    checked={item.checked}
                  />
                  <Checkbox />
                  {item.tooltip ? (
                    <Tooltip
                      secondary
                      placement="bottom-start"
                      text={
                        <div style={tooltipStyles.wrapper}>
                          {item.tooltip.title && (
                            <>
                              <strong style={tooltipStyles.title}>
                                {item.tooltip.title}
                              </strong>
                              <br />
                              <br />
                            </>
                          )}
                          <ul style={tooltipStyles.ul}>
                            {item.tooltip.items.map((i, index) => (
                              <li key={index} style={tooltipStyles.li}>
                                {i}
                              </li>
                            ))}
                          </ul>
                        </div>
                      }
                    >
                      <p className={item.highlighted ? 'last-highlighted' : ''}>
                        {item.text}
                      </p>
                    </Tooltip>
                  ) : (
                    <p className={item.highlighted ? 'last-highlighted' : ''}>
                      {item.text}
                    </p>
                  )}
                </label>
              </ItemContainer>
            ))}
          </List>
          <SelectAll>
            <ItemContainer>
              <label
                onClick={e => {
                  e.preventDefault();
                  handleSelectAll(true);
                }}
              >
                <input
                  type="checkbox"
                  checked={
                    selected.length > 0
                      ? !selected.some(ele => ele.checked === false)
                      : false
                  }
                />
                <Checkbox />
                {i18n.t('Select All')}
              </label>
            </ItemContainer>
          </SelectAll>
        </ListContainer>
      </div>
    </Container>
  );
};

export default TransferList;
