import React, { useState } from 'react';
import { PencilIcon } from '../CustomIcons';
import styled from 'styled-components';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import { useHistory } from 'react-router';
import i18n from 'i18next';
import { CustomTooltip } from '..';
import {
  Title,
  TitleContainer,
  Container,
  EditContainer,
  IncompleteLabel,
  CollapseActionContainer,
  Content,
} from './styles';

interface Props {
  title?: any;
  content?: any;
  editable?: boolean | null;
  collapsible?: boolean;
  defaultOpen?: boolean;
  linkTo?: string | Function | { search: string };
  incomplete?: boolean;
  editTooltip?: string;
  overflow?: string;
  arrowNextToText?: boolean;
}

const EditableItem = (props: Props) => {
  // Props
  const {
    title,
    content,
    editable = true,
    collapsible = false,
    defaultOpen = true,
    incomplete = false,
    linkTo,
    editTooltip,
    overflow,
    arrowNextToText = false,
  } = props;

  const history = useHistory();

  const [open, setOpen] = useState(defaultOpen);

  // Render
  return (
    <Container>
      <TitleContainer editable={editable || undefined}>
        <Title>{title}</Title>
        {collapsible && (
          <CollapseActionContainer
            onClick={() => setOpen(!open)}
            arrowNextToText={arrowNextToText}
          >
            {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </CollapseActionContainer>
        )}
        {editable && (
          <div data-tip data-for={editTooltip}>
            <EditContainer
              onClick={() => {
                if (linkTo) {
                  if (typeof linkTo === 'function') {
                    linkTo();
                  } else {
                    history.push(linkTo);
                  }
                }
              }}
            >
              <PencilIcon />
            </EditContainer>
          </div>
        )}
        {editable && editTooltip && (
          <CustomTooltip id={editTooltip} placement="right">
            <span>{editTooltip}</span>
          </CustomTooltip>
        )}
        {incomplete && <IncompleteLabel>* Incomplete Section</IncompleteLabel>}
      </TitleContainer>
      <Content
        collapsible={collapsible}
        open={open}
        style={{ overflow: overflow || 'hidden' }}
      >
        {content === '' ||
        content === false ||
        content === undefined ||
        (content && content.textContent && content.textContent.length === 0)
          ? i18n.t('No information captured or not applicable!')
          : content}
      </Content>
    </Container>
  );
};

export default EditableItem;
